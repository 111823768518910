import { useEffect, useState } from "react";
import { changePassword, getSocialOptionsList, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik, useFormik } from "formik";
import { useAuth } from "../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { onLinkDelete, onMyLinksList, onNewLinkAdd, onProfileVerification, onRaisedTicketsList } from "../../../api/AuthService";
import notFound from '../../../assets/images/not_found_new.png';

import { formatDate } from "../../../utils/utils";
import Modal from "../../common/Modal";
import { useNavigate } from "react-router-dom";

const DisplayingSocialErrorMessagesSchema = Yup.object().shape({
    link_name_social: Yup.string().required("Link Name is required"),
    link_address: Yup.string().required("Link Address is required")
});
const DisplayingCustomErrorMessagesSchema = Yup.object().shape({
    link_name_custom: Yup.string().required("Link Name is required"),
    link_address: Yup.string().required("Link Address is required")
});

function MyLinks() {
    const [loading, setLoading] = useState(false);

    const { user, globalToken } = useAuth();
    const [myLinks, setMyLinks] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [socialMediaOptions, setSocialMediaOptions] = useState([]);
    const [type, setType] = useState('social');
    const [linkName, setLinkName] = useState('facebook');


    const navigate = useNavigate()

    const handleMyLinksList = async () => {
        setLoading(true)
        const res = await onMyLinksList({ token: globalToken });
        if (res?.success) {
            setMyLinks(res?.data)
        } else {
            toast.error(res?.message)
        }
        setLoading(false)
    }

    const handleAddLink = async (values) => {
        
        setBtnLoading(true)
        const res = await onNewLinkAdd({link_name:type=='social'?values?.link_name_social:values?.link_name_custom, link_address:values.link_address, type:type, token:globalToken});
        if (res?.success) {
            toast.success(res?.message)
            setMyLinks(res?.data);
            handleMyLinksList();
        } else {
            toast.error(res?.message)
        }
        setBtnLoading(false)
    }

    const handleSocialMediaOptionsList = async () =>{
        const res = await getSocialOptionsList();
            if (res?.status==1) {
                setSocialMediaOptions(res?.data);
            } else {
                toast.error(res?.message)
            }
    }
    

    useEffect(() => {
        handleSocialMediaOptionsList();
        handleMyLinksList();
    }, [])
    const formData = [
        {
            id: 'module',
            title: 'Module Name',
            type: 'text',
            name: 'module',
            placeholder: 'eg. Question Bank, Interview Question, Fullform etc.',
            required: false,
        },
        {
            id: 'subject',
            title: 'Subject',
            type: 'text',
            name: 'subject',
            placeholder: 'Subject',
            required: true,
        },
        {
            id: 'message',
            title: 'Message',
            type: 'text',
            name: 'message',
            placeholder: 'Message',
            required: true,
        }
    ]

    const DeleteLink = ({
        linkId
    })=>{
        const [deleteLoader, setDeleteLoader] = useState(false);
        const handleDeleteLink = async (link_id) => {
            setDeleteLoader(true)
            const res = await onLinkDelete({ link_id:link_id, token: globalToken });
            if (res?.success) {
                handleMyLinksList();
            } else {
                toast.error(res?.message)
            }
            setDeleteLoader(false)
        }
        return (
            <button type="button" className="btn btn-default text-danger" onClick={()=>handleDeleteLink(linkId)}>
               
                {deleteLoader ? (
                    <div className="h-5 w-5 theme-color">
                        <Loadingicon />
                    </div>
                ) : <>{
                    <i className="fa fa-trash"></i>
                }</>}
            </button>
        )
    }

    const handleTypeChange = (e, setFieldValue) =>{        
        setFieldValue('type', e.target.value);
        setFieldValue('link_address', '');
        setType(e.target.value);
        if(e.target.value=='social'){
            setFieldValue('link_name_social', e.target.value || 'facebook');
            setLinkName(e.target.value || 'facebook');
        }else{
            setFieldValue('link_name_custom', '');
            setLinkName('');
        }      
    }

    return (
        <>
            <BreadCrumbs />

            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="px-3">
                        <div class="card mb-3 border-0 shadow"><div class="card-body">
                            <div className="row mb-2">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        link_name_social: linkName,
                                        link_name_custom: '',
                                        link_address: "",
                                        type:"social"
                                    }}
                                    validationSchema={type==='social'?DisplayingSocialErrorMessagesSchema:DisplayingCustomErrorMessagesSchema}
                                    onSubmit={(values) => {
                                        if (values)
                                            handleAddLink(values)
                                        
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        setFieldValue,
                                        handleSubmit,
                                    }) => (
                                        <form className="" onSubmit={handleSubmit}>
                                            <div className="d-md-flex">
                                                <div className="flex-1 me-2">
                                                    <label className="mb-2">Link Type</label>
                                                    <select className="text-red-500 form-control min-h-[20px]" onChange={(e)=>handleTypeChange(e, setFieldValue)} onBlur={handleBlur}  name="type">
                                                        {/* <option value={''} disabled selected>Select from list</option> */}
                                                        <option value={'social'}>Social</option>
                                                        <option value={'custom'}>Custom</option>
                                                    </select>
                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                        {touched['type'] && errors['type'] && errors['type']}
                                                    </div>
                                                </div>
                                                {
                                                    type=='social'? <div className="flex-1 me-2">
                                                    <label className="mb-2">Link For</label>
                                                    <select className="text-red-500 form-control min-h-[20px]" onChange={handleChange} onBlur={handleBlur}  name="link_name_social">
                                                        {
                                                            socialMediaOptions?.length?socialMediaOptions?.map((option, index)=>(
                                                                <option value={option?.name.toLowerCase()}>{option?.name}</option>
                                                            )):null
                                                        }
                                                        {/* <option value={'facebook'}>Facebook</option>
                                                        <option value={'instagram'}>Instagram</option>
                                                        <option value={'twitter'}>X</option>
                                                        <option value={'linkedin'}>Linked In</option>
                                                        <option value={'youtube'}>Youtube</option> */}
                                                    </select>
                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                        {touched['link_name_social'] && errors['link_name_social'] && errors['link_name_social']}
                                                    </div>
                                                </div>: <div className="flex-1 me-2">
                                                    <label className="mb-2">Link Title</label>
                                                    <input type="text" name="link_name_custom" onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="e.g. tuteehub, facebook, twitter etc." />
                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                        {touched['link_name_custom'] && errors['link_name_custom'] && errors['link_name_custom']}
                                                    </div>
                                                </div>
                                                }
                                               
                                                <div className="flex-1 ms-2">
                                                    <label className="mb-2">Link Address</label>
                                                    <input type="text" name="link_address" onChange={handleChange} onBlur={handleBlur} value={values.link_address} className="form-control" placeholder="e.g. https://tuteehub.com/343423dsD" />
                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                        {touched['link_address'] && errors['link_address'] && errors['link_address']}
                                                    </div>
                                                </div>

                                                {/* <div className="flex-1 d-flex justify-content-end"> */}

                                                <div className="ms-2 flex-column d-flex">
                                                    <label className="mb-2 opacity-0">d</label>
                                                    <button disabled={btnLoading} className="btn bg-theme text-white" style={{ backgroundColor: '#ff7200' }}>  {btnLoading ? (
                                                        <div className="h-5 w-5">
                                                            <Loadingicon />
                                                        </div>
                                                    ) : (
                                                        "Add Link"
                                                    )}</button>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </form>)}
                                </Formik>

                            </div>
                            </div>
                            </div>
                            <div className="row">

                                <div className="col-md-12">

                                    {
                                        myLinks?.length ? myLinks?.map(link => (
                                            <div className="card mb-1 d-flex w-100 flex-row justify-content-between align-items-center p-3 shadow top-leader-board-container" style={{ borderColor: '#f0f0f0' }}>
                                                <div>
                                                    <h6 className="mb-0 text-capitalize">{link?.link_name}</h6>
                                                    <small className="text-black-50 break-words" style={{wordBreak:'break-all'}}>{link?.link_address}</small>
                                                    {/* <p className="mb-0 mt-2 text-black-60">{ticket?.message}</p> */}
                                                </div>
                                                <div style={{ minWidth: 100, textAlign: 'right' }}>
                                                    {/* <button type="button" className="btn btn-default"><i className="fa fa-pencil"></i></button> */}
                                                    <DeleteLink linkId={link?.id}/>
                                                </div>

                                            </div>
                                        )) : (
                                            <div className="flex-column not-found d-flex text-center">
                                                <img src={notFound} className="notFoundImg" />
                                                <span style={{ opacity: 0.7 }}>Links yet to create</span>
                                            </div>
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    </>
                )
                }
            </div>

        </>
    );
}
export default MyLinks;
