import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getServiceList, globalSearchQuestion } from "../../../api/OurService";
import { toast } from "react-toastify";
import { isArray, isEmpty } from "lodash";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { useAuth } from "../../../Context/AuthContext";
import InfiniteScroll from "react-infinite-scroll-component";
import Nodata from "../../common/NoData";
import { convertLongNumberAbbreviated, yearSatra } from "../../../utils/utils";
import { useSearch } from "../../../Context/SearchContext";
import QBRectangleBox from "../../common/rectanglebox/qb";
import SectionDescription from "../../common/sectionDescription";

function QuestionBank() {
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [imgBaseUrl, setImgBaseUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { listData, setListData,searchValue, setSearchValue } = useSearch();
  const navigate = useNavigate();
  const { qToken, iQToken, ffToken, dcToken, globalToken } = useAuth();
  const { name } = useParams();

  useEffect(() => {
    // alert(name)
    setCurrentPage(1);
    // alert(searchValue)
    setListData([]);
    getBankList(true);
  }, [searchValue, name]);

  useEffect(() => {
    if (currentPage > 1) getBankList();
  }, [currentPage]);

  useEffect(() => {
    return () => localStorage.setItem('feedDetail', 'feedDetail');
  }, []);

  const getBankList = async (isFirstLoad = false) => {
    const token = globalToken;
    
    if (isFirstLoad) {
      setLoading(true);
    }
    setScrollLoading(true);
    try {
      let res;
      if (!searchValue) {
        res = await getServiceList(currentPage, name);
      } else {
        res = await globalSearchQuestion("subject", searchValue, token, currentPage, name);
      }
      // console.log('fr', res);
      if (currentPage === 1) {
        setImgBaseUrl(res?.data?.image_base_url || res?.image_base_url+'/subjects' || "");
      }
      const newData = res?.data?.data || res?.data || [];
      setListData(prevData => (currentPage === 1 ? newData : [...prevData, ...newData]));
      setHasMore(newData.length >= (searchValue ? 10 : 30));
    } catch (error) {
      toast.error(error.message || "Something went wrong!");
    } finally {
      setLoading(false);
      setScrollLoading(false);
    }
  };

  const handleNavigation = (id, subjectTitle) => {
    setSearchValue(null);
    navigate(subjectTitle.replaceAll(" ", "-").toLowerCase(), { state: { subid: id } });
  };

  return (
    <>
    <div className="d-flex align-items-center justify-content-between mb-2">
    <BreadCrumbs />
    {/* <div className="search-boxs px-2 d-flex w-50 align-items-center border rounded bg-white d-flex justify-content-between" style={{marginBottom:'1rem'}}><input type="text" autocomplete="chrome-off" className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Solved Questions Across Any Subject Or Topic..." value=""/>
      <i className="fa fa-search opacity-5"></i>
    </div> */}
    </div>
      

      <div className="grid grid-cols-12 graph-card-box text-center px-10">
        {/* Search form and other header components */}
      </div>
      
      {loading ? (
        <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
          <div className="h-10 w-10">
            <Loadingicon />
          </div>
        </div>
      ) : isEmpty(listData) ? (
        <div className="flex justify-center mt-3">
          <Nodata />
        </div>
      ) : (
             
<div className="grid grid-cols-1 group-card card-body gap-1">
  <div className="px-4 w-100">
  <SectionDescription title={name=='question-Bank'?`<strong>Popular Question Bank Subjects For Session ${yearSatra()}</strong>`:name=='interview-questions'?`<strong>Popular Interview Questions For Session ${yearSatra()}</strong>`:name=='fullforms'?`<strong>Popular Full Forms For Session ${yearSatra()}</strong>`:name=='dictionary'?`<strong>Popular Word Meanings For Session ${yearSatra()}</strong>`:''} subTitle={name=='question-Bank'?`TuteeHub has over 1.5 Million Practice questions subjectwise/topicwise that are typically included in any exam syllabus. There are free practice questions for beginners with explanation along with subscription based packages with unlimited questions of various difficulty levels. If you're preparing for any competition, employment, entrance exam that includes multiple-choice questions then TuteeHub can help you practice with confidence.`:name=='interview-questions'?`TuteeHUB has over 1.5 Million questions with answers subjectwise/topicwise/skillwise/designationwise that are typically asked in any job interview.To help you prepare for any thing, we've taken the time to prepare this huge list of commonly asked interview questions. You won't be asked all of these questions at your job interview, but if you expect it to be asked then you'll find it in this list.`:name=='fullforms'?`Our comprehensive list of full forms on different topics is categorized in educational, organizational, finance, IT, technology, science, computer and general etc. To view each acronym, select a category and click on its link.`:name=='dictionary'?`Our comprehensive list of Dictionarys on different topics is categorized in educational, organizational, finance, IT, technology, science, computer and general etc. To view each acronym, select a category and click on its link.`:''}/>
  <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-0" />
  </div>

        <InfiniteScroll
          dataLength={listData?.length}
          next={() => setCurrentPage(currentPage + 1)}
          hasMore={hasMore}
          loader={scrollLoading && (
            <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
              <div className="h-10 w-10">
                <Loadingicon />
              </div>
            </div>
          )}
          pullDownToRefresh
          pullDownToRefreshThreshold={90}
          refreshFunction={() => {
            setCurrentPage(1);
            setListData([]);
            getBankList(true);
          }}
          scrollableTarget={"qBank"}
          className={"relative"}
        >
          <div className="py-8 grid pr-2 p-4 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom" style={{ overflow: "auto" }} id="qBank">
            {isArray(listData) && listData.map(item => (<>
            <QBRectangleBox 
              key={item?.id}
              nData={item}
              boxSize={'col-md-12 px-0'}
              mH={true}
              type={name}
              thumbBaseUrl={imgBaseUrl}
              redirectionFn={()=>handleNavigation(item.id, item.subject_title || item.name)}
            />
            </>
              // <div
              //   key={item.id}
              //   className="rounded overflow-hidden hover:shadow-lg shadow card-body card-img cursor-pointer"
              //   style={{ minHeight: "300px" }}
              //   onClick={() => handleNavigation(item.id, item.subject_title || item.name)}
              // >
              //   <img
              //     className="w-full"
              //     src={item.image ? `${imgBaseUrl}/${item.image}` : require('../../../assets/images/feeds_default_placeholder.webp')}
              //     alt={item.subject_title}
              //   />
              //   <div className="px-6 py-4 child-box">
              //     <div className="font-bold text-xl mb-2 category-text">
              //       {item.subject_title || item.name}
              //     </div>
              //     <nav aria-label="breadcrumb" className="q-breadcum">
              //       <ol className="breadcrumb">
              //         <li className="breadcrumb-item2 fsize-11">
              //           {item.topic_count ? `${convertLongNumberAbbreviated(item.topic_count)} Topics` : null}
              //         </li>
              //         {item.question_count >= 0 && (
              //           <li className="breadcrumb-item2 fsize-11">
              //             {`${convertLongNumberAbbreviated(name === 'fullforms' ? item.fullform_count : name === 'dictionary' ? item.dictionary_count : item.question_count)} ${name === 'fullforms' ? 'Full Forms' : name === 'dictionary' ? 'Word Meanings' : 'Questions'}`}
              //           </li>
              //         )}
              //       </ol>
              //     </nav>
              //     <div className="arr-r">
              //       <svg width="30" height="25" viewBox="0 0 43 35" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
              //         <path d="M28.6844 16.0416H7.1665V18.9583H28.6844V23.3333L35.8332 17.5L28.6844 11.6666V16.0416Z" fill="#0A2B43"></path>
              //       </svg>
              //     </div>
              //   </div>
              // </div>
            ))}
          </div>
        </InfiniteScroll>
        </div>
      )}
    </>
  );
}

export default QuestionBank;
