import React, { useState, useEffect, useRef } from "react";
// import Image from "next/image";
import { placeholderImages, apiToken, closeModal, formatTime, CDN_BASE_URL, USER_INFO_KEY } from "../../../utils/utils";
import styles from "../../../assets/css/styles/Home.module.css";
import { PostDataAuth } from "../../../api/feeds/PostDataAuth";
import { PostDataFile } from "../../../api/feeds/PostDataFile";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "../../../Context/AuthContext";
import sent_ico from "../../../assets/images/sent_ico.svg";
import Modal from "react-bootstrap/Modal";
import { GetDataAuth } from "../../../api/feeds/GetDataAuth";
import { useFeeds } from "../../../Context/FeedsContext";
import { getUserInfo } from "../../../api/OurService";
import { Loadingicon } from "../../../AppIcons";
import { toast } from "react-toastify";
export default function FeedsComments({
  placeHolderTxt,
  hideSent,
  feed,
  _allFeed,
  modalOpen,
  setModalOpen,
  postType,
  feedRefresh,
  feedRefreshMain
}) {
  // const [loggedUser, setLoggedUser] = useState(null);
  const { handleLogout, user, setUser, setFinance, globalToken, setIsEmailVerifyCover } = useAuth();
  const {feedDetail, setFeedDetail} = useFeeds();
  const [sendCommentBtnLoader, setSendCommentBtnLoader] = useState(false);
  const [comments, setComments] = useState(null);
  const [message, setMessage] = useState('');
  const [reply, setReply] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [choosenImage, setChoosenImage] = useState();
  const [feedText, setFeedText] = useState();
  const [choosenImageName, setChoosenImageName] = useState();
  const [replyCommentId, setReplyCommentId] = useState(0);
  const [showHideMoreComment, setShowHideMoreComment] = useState(false);
  const[showCreatePostModal, setShowCreatePostModal] = useState(false);
  let commentInput = useRef();
  let createPostInput = useRef();
  useEffect(() => {
    // console.log(feed?.id)
    // setLoggedUser(JSON.parse(localStorage.getItem("__loggedIn")));
    if (feed?.id) allCommentsList(feed?.id);
    createPostInput?.current?.focus();
  }, [feed]);

  const fetchUserInfo = async (userId) => {
    const res = await getUserInfo(userId, user?.access_token);
    if(res.success){
      // console.log(res)
        setUser({...user, credits:res?.data?.credits})
        localStorage.setItem('userDetails',JSON.stringify({...user, credits:res?.data?.credits}))
        localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))
    setFinance({__crdt:res.data?.credits}) 
    } 
    // console.log(userDetails?.slug)

}

  const allCommentsList = async (_fId) => {
    // const apiToken = localStorage.getItem("___fTkn");
    if (globalToken) {
      const apiCommentsData = await PostDataAuth(
        `feed/comment/list`,
        globalToken,
        { feed_id: _fId }
      );
      const reverseComments = {...apiCommentsData, data:apiCommentsData?.data} 
      setComments(reverseComments);
    }
  };

  const browseImage = () => {
    const browseFile = document.querySelector("#choosepostfile");
    browseFile?.click();
  };

  const sendComment = async (commentId) => {
    
    if(!message){
      alert('Please write a word atleast.');
      return;
    }
    setSendCommentBtnLoader(true);
    // const apiToken = localStorage.getItem("___fTkn");
    if (globalToken) {
      const sendCommentReturn = await PostDataAuth(
        `feed/comment/create`,
        globalToken,
        { feed_id: feed?.id, comment_message: message, comment_id: commentId }
      );
      if(sendCommentReturn?.success){
        
        // commentInput?.current?.value('');
        toast.success(sendCommentReturn?.message)
        fetchUserInfo(user?.tuteehub_id);
        setMessage('');
        allCommentsList(feed?.id);
        _feedDetails(feed?.id);
      setReply(false);
      feedRefresh && feedRefresh(feed?.id);
      feedRefreshMain && feedRefreshMain(1);
      setReplyCommentId(0);
      
      }else{
        if(sendCommentReturn?.status==5){              
          toast.error(sendCommentReturn?.errors);
          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
      }
      }
      // setMessage(null);
      
    }
    setSendCommentBtnLoader(false);
  };

  const _feedDetails = async (id) => {
    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const feedDetailsReturn = await GetDataAuth(
        `feed-more/details/${id}/via-authentic-user`,
        globalToken
      );
      if (feedDetailsReturn?.success) {
        
        if(feedDetailsReturn?.data){
            setFeedDetail([feedDetailsReturn?.data])
        }else{
            // naivigation('/')
        }
      } else {
        
      }
    } else {
    }
  };

  const onReply = (cId, userName) => {
    setMessage(`@${userName} `);
    commentInput?.current?.focus();
    setReplyCommentId(cId);
    setReply(true);
  };

  const handleCreatePost = async () => {
    setIsLoading(true);
    if (!feedText) {
      setMessage("Please write something to post");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
      setIsLoading(false);
      return;
    }
    let formdata = new FormData();
    formdata.append("feed_text", feedText);
    if (choosenImage?.length > 0) {
      for (let i = 0; i < choosenImage?.length; i++) {
        formdata.append("feed_attachment[]", choosenImage[i]);
      }
    }

    // const apiToken = localStorage.getItem("___fTkn");
    if (globalToken) {
      const addPostReturn = await PostDataFile(
        `feed/create`,
        globalToken,
        formdata
      );
      if (addPostReturn?.success) {
        fetchUserInfo(user?.tuteehub_id);
        _allFeed(1);
        setMessage(addPostReturn?.message);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
         handleCreatePostModal()
        }, 2000);

        setIsLoading(false);
      } else {
        if(addPostReturn?.status==5){    
          setShowCreatePostModal(false);       
            toast.error(addPostReturn?.errors);
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
        }
        setIsLoading(false);
      }
    }
  };

  const handleCreatePostModal = () =>{
    setChoosenImageName("Choose Video/Image");
    setShowCreatePostModal(!showCreatePostModal);
  }

  const showChoosenImages = (allFiles) => {
    let imagesChoosen = "";
    for (let i = 0; i < allFiles?.length; i++) {
      imagesChoosen = imagesChoosen + allFiles[i].name ;
    }
    // console.log(imagesChoosen);
    setChoosenImageName(imagesChoosen);
  };
  // const chooseImage = (e) => {
  //   let imagesChoosen = [];
  //   const allFiles = e.target.files;
  //   for (let i = 0; i < allFiles?.length; i++) {
  //     imagesChoosen.push(allFiles[i]);
  //   }
  //   setChoosenImage(imagesChoosen);
  //   showChoosenImages(allFiles);
  // };

  const chooseImage = (e) => {
    let imagesChoosen = [];
    const allFiles = e.target.files;
    let validFiles = true;

    for (let i = 0; i < allFiles?.length; i++) {
      const file = allFiles[i];
      const fileType = file.type.split('/')[0];
      // console.log(allFiles[i].size)
      if (allFiles[i].size > 2 * 1024 * 1024) { // 1 MB in bytes
        validFiles = false;
        toast.error('Image or Video size should not exceed 2 MB.');
        e.target.value = '';
        setChoosenImageName("Choose Video/Image");
        break;
      }else if (fileType !== 'image' && fileType !== 'video') {
        validFiles = false;
        toast.error('Only image or video file are allowed.');
        // Clear the file input
        e.target.value = '';
        setChoosenImageName("Choose Video/Image");
        break;
      } else {
        imagesChoosen.push(allFiles[i]);
      }
    }

    if (validFiles) {
      setChoosenImage(imagesChoosen);
      showChoosenImages(allFiles);
    }
  };

  // https://www.tuteehub.com/media/user/profileimage/
  return (
    <>
      {!hideSent ? (
        <>
        {
          comments?.data?.length>3?
          <span role={'button'} onClick={()=>setShowHideMoreComment(!showHideMoreComment)} className="show-previous-comments text-black-50 mt-4 mb-3 w-100 float-left d-flex">{showHideMoreComment?'Hide Previous Comments':'View Previous Comments'}</span>
          :null
        }<div className="comments-all">
          {comments &&
            comments?.data?.map((comment, index) => (
              <>{
                index<3?
                <><div key={index} className="d-flex mt-2">
                  <div style={{minWidth:28,height:28}}>
                    <img
                      src={
                        comment?.user_detail?.profile_details?.profileImage
                          ? `${CDN_BASE_URL}` +
                            comment?.user_detail?.profile_details?.profileImage
                          : null || require(`../../../assets/images/profile_placeholder.png`)
                      }
                      width={28}
                      height={28}
                      className="siderbarIco"
                      layout="fixed"
                      style={{ borderRadius: 100, height:28 }}
                    />
                  </div>
                  <div className="ms-3 rounded d-flex flex-column chatbubble p-2 mb-2">
                    <span
                      className="message-box text-black"
                      style={{ fontWeight: 600 }}
                    >
                      {comment?.user_detail?.name}
                    </span>
                    <span>{comment?.comment_message}</span>
                    <div className="col-12 my-2 px-0 border-top"></div>
                    <div
                      className="d-flex justify-content-between align-items-center small text-black-50"
                      style={{ fontSize: 11 }}
                    >
                      {formatTime(comment)}
                      <span
                        className="button font-bold"
                        role="button"
                        onClick={() => onReply(comment?.id, comment?.user_detail?.name)}
                      >
                        <strong>Reply</strong>
                      </span>
                    </div>
                  </div>
                </div>
                {comment?.comment_reply?.length > 0 &&
                  comment?.comment_reply?.map((replycomment, i) => (
                    <div
                      key={i}
                      className="d-flex mt-2"
                      style={{ marginLeft: 45 }}
                    >
                      <div style={{minWidth:22,height:22}}>
                        <img
                          src={
                            replycomment?.user_detail?.profile_details
                              ?.profileImage
                              ? `${CDN_BASE_URL}`  +
                                replycomment?.user_detail?.profile_details
                                  ?.profileImage
                              : null || require(`../../../assets/images/profile_placeholder.png`)
                          }
                          width={22}
                          height={22}
                          className="siderbarIco"
                          style={{ borderRadius: 100, height:22 }}
                        />
                      </div>
                      <div className="ms-3 rounded d-flex flex-column chatbubble p-2 mb-2">
                        <span
                          className="message-box text-black"
                          style={{ fontWeight: 600 }}
                        >
                          {replycomment?.user_detail?.name}
                        </span>
                        <span>{replycomment?.comment_message_reply}</span>
                        <div className="col-12 my-2 px-0 border-top"></div>
                        <div
                          className="d-flex justify-content-between align-items-center small text-black-50"
                          style={{ fontSize: 11 }}
                        >
                          {formatTime(replycomment)}
                        </div>
                      </div>
                    </div>
                  ))}</>
                :index>=3 && showHideMoreComment?
                <><div key={index} className="d-flex mt-2">
                  <div style={{minWidth:28,height:28}}>
                    <img
                      src={
                        comment?.user_detail?.profile_details?.profileImage
                          ? `${CDN_BASE_URL}` +
                            comment?.user_detail?.profile_details?.profileImage
                          : null || require(`../../../assets/images/profile_placeholder.png`)
                      }
                      width={28}
                      height={28}
                      className="siderbarIco"
                      layout="fixed"
                      style={{ borderRadius: 100, height:28 }}
                    />
                  </div>
                  <div className="ms-3 rounded d-flex flex-column chatbubble p-2 mb-2">
                    <span
                      className="message-box text-black"
                      style={{ fontWeight: 600 }}
                    >
                      {comment?.user_detail?.name}
                    </span>
                    <span>{comment?.comment_message}</span>
                    <div className="col-12 my-2 px-0 border-top"></div>
                    <div
                      className="d-flex justify-content-between align-items-center small text-black-50"
                      style={{ fontSize: 11 }}
                    >
                      {formatTime(comment)}
                      <span
                        className="button font-bold"
                        role="button"
                        onClick={() => onReply(comment?.id, comment?.user_detail?.name)}
                      >
                        <strong>Reply</strong>
                      </span>
                    </div>
                  </div>
                </div>
                {comment?.comment_reply?.length > 0 &&
                  comment?.comment_reply?.map((replycomment, i) => (
                    <div
                      key={i}
                      className="d-flex mt-2"
                      style={{ marginLeft: 28 }}
                    >
                      <div style={{minWidth:28,height:28}}>
                        <img
                          src={
                            replycomment?.user_detail?.profile_details
                              ?.profileImage
                              ? `${CDN_BASE_URL}` +
                                replycomment?.user_detail?.profile_details
                                  ?.profileImage
                              : null || require(`../../../assets/images/profile_placeholder.png`)
                          }
                          width={28}
                          height={28}
                          className="siderbarIco"
                          style={{ borderRadius: 100, height:28 }}
                        />
                      </div>
                      <div className="ms-3 rounded d-flex flex-column chatbubble p-2 mb-2">
                        <span
                          className="message-box text-black"
                          style={{ fontWeight: 600 }}
                        >
                          {replycomment?.user_detail?.name}
                        </span>
                        <span>{replycomment?.comment_message_reply}</span>
                        <div className="col-12 my-2 px-0 border-top"></div>
                        <div
                          className="d-flex justify-content-between align-items-center small text-black-50"
                          style={{ fontSize: 11 }}
                        >
                          {formatTime(replycomment)}
                        </div>
                      </div>
                    </div>
                  ))}</>:null
              }
                
              </>
            ))}{" "}
        </div></>
      ) : null}

      <div className="col d-flex align-items-center py-3">
        <div className="d-flex">
          <img
            alt="postedByImage"
            src={user?.profile_image || require(`../../../assets/images/profile_placeholder.png`)}
            width={40}
            height={40}
            style={{width:40, height:40}}
            className={styles.imgcircle}
          />
        </div>
        <div className="ms-2 postCommentFrm">
          {/* <div className="repyUser me-2">@{}</div> */}
          {user && hideSent ? (
            <input
              type="text" 
              onClick={()=>handleCreatePostModal()}
              ref={commentInput}
              onChange={(e) => setMessage(e?.target.value)}
              tabIndex={1}
              className="commentInput"
              placeholder={placeHolderTxt}
            />
          ) : user && !hideSent ? (
            <input
              type="text"
              value={message}
              ref={commentInput}
              onChange={(e) => setMessage(e?.target.value)}
              tabIndex={1}
              onKeyUp={(e)=>e.key=='Enter'?sendComment(replyCommentId):null}
              className="commentInput"
              placeholder={placeHolderTxt}
            />
          ) : (
            <input
              type="text"
              onClick={() => setModalOpen(!modalOpen)}
              tabIndex={1}
              readOnly
              className="commentInput"
              placeholder={placeHolderTxt}
            />
          )}

          {!hideSent ? (
            <>
             {sendCommentBtnLoader ? (
                    <div className="h-5 w-5 theme-color">
                        <Loadingicon />
                    </div>
                ) : <>{
                  <img
                  role="button"
                  src={sent_ico}
                  onClick={() => sendComment(replyCommentId)}
                  width={18}
                  height={18}
                />
                }</>}
            </>
            
          ) : null}
        </div>
      </div>
      {hideSent ? (
      <Modal
      show={showCreatePostModal}
      onHide={handleCreatePostModal}
      
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">Create Post</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {showMessage ? (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    {message}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                ) : null}
                {/* <div className={styles.postImage}>
                  <div
                    className={
                      (styles.postedbycontainer,
                      "d-flex col-12 px-0 align-items-center")
                    }
                  >
                    <div style={{minWidth:40,height:40}}>
                      <img
                        alt="postedByImage"
                        src={
                          user?.profile_image ||
                          require(`../../../assets/images/profile_placeholder.png`)
                        }
                        width={40}
                        height={40}
                        className={styles.imgcircle}
                      />
                    </div>
                    <div className="ms-3">
                      <div className={styles.postedbyname}>
                        {user.name || ""}
                      </div>
                    </div>
                  </div>
                </div> */}
                <input
                  type="text"
                  className="form-control mb-3 border-0s mt-2 pb-5"
                  style={{border:'1px solid #eaeee9'}}
                  onChange={(e) => setFeedText(e.target.value)}
                  required
                  ref={createPostInput}
                  placeholder={`What's on your mind, ${
                    user?.name || ""
                  }?`}
                />
                {                
                postType==='image'?
                <><div
                  className="image-drop-zone text-center py-3 rounded"
                  role="button"
                  style={{
                    background: "rgb(248 248 248)",
                    boxShadow: "0px 0px 2px rgb(0 0 0 / 20%)",
                  }}
                  onClick={() => browseImage()}
                >
                  <img
                    src={require("../../../assets/images/pick_image.png")}
                    width={23}
                    height={23}
                    layout="fixed"
                    className="mx-auto my-4"
                  />
                  
                  <p style={{wordBreak:'break-word', padding:'0 1rem'}}>
                  {/* /Video */}
                    <strong>{choosenImageName || "Choose Video/Image"}</strong>
                  </p>
                </div>
                <input
                  type="file"
                  
                  id="choosepostfile"
                  style={{ opacity: 0, height: 0 }}
                  onChange={(e) => {
                    chooseImage(e);
                  }}
                />
                </>:null
}
                <button
                  type="button"
                  disabled={isLoading ? true : false}
                  className="btn btn-block w-100 btn-warning custom-theme-button py-2 justify-content-center d-flex align-items-center"
                  onClick={() => handleCreatePost()}
                >
                  Post{" "}
                  {isLoading ? (
                    <CircularProgress
                      size={15}
                      color={"inherit"}
                      className="ms-2"
                    />
                  ) : null}
                </button>
      </Modal.Body>
      <Modal.Footer>
        {/* <button
          type="button"
          disabled={isLoading ? true : false}
          className="btn btn-warning custom-theme-button d-flex align-items-center"
          onClick={() => reportFeed()}
        >
          Submit{" "}
          {isLoading ? (
            <CircularProgress size={15} color={"inherit"} className="ms-2" />
          ) : null}
        </button> */}
      </Modal.Footer>
    </Modal>
        // <div
        //   className="modal fade"
        //   id="createPostPopup"
        //   tabIndex="-1"
        //   aria-labelledby="exampleModalLabel"
        //   aria-hidden="true"
        // >
        //   <div className="modal-dialog modal-dialog-centered">
        //     <div className="modal-content ">
        //       <div className="modal-header">
        //         <h5 className="modal-title" id="exampleModalLabel">
        //           Create Post
        //         </h5>
        //         <button
        //           type="button"
        //           className="btn-close"
        //           data-bs-dismiss="modal"
        //           aria-label="Close"
        //         ></button>
        //       </div>
        //       <div className="modal-body px-4 py-5">
        //         {showMessage ? (
        //           <div
        //             className="alert alert-success alert-dismissible fade show"
        //             role="alert"
        //           >
        //             {message}
        //             <button
        //               type="button"
        //               className="btn-close"
        //               data-bs-dismiss="alert"
        //               aria-label="Close"
        //             ></button>
        //           </div>
        //         ) : null}
        //         <div className={styles.postImage}>
        //           <div
        //             className={
        //               (styles.postedbycontainer,
        //               "d-flex col-12 px-0 align-items-center")
        //             }
        //           >
        //             <div>
        //               <img
        //                 alt="postedByImage"
        //                 src={
        //                   user?.profile_image ||
        //                   placeholderImages.userPicPlaceholder
        //                 }
        //                 width={40}
        //                 height={40}
        //                 className={styles.imgcircle}
        //               />
        //             </div>
        //             <div className="ms-3">
        //               <div className={styles.postedbyname}>
        //                 {user.name || ""}
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //         <input
        //           type="text"
        //           className="form-control mb-3 border-0 mt-5 pb-5"
        //           onChange={(e) => setFeedText(e.target.value)}
        //           required
        //           ref={createPostInput}
        //           placeholder={`What's on your mind, ${
        //             user?.name || ""
        //           }?`}
        //         />
        //         <div
        //           className="image-drop-zone text-center py-3 rounded"
        //           role="button"
        //           style={{
        //             background: "rgb(248 248 248)",
        //             boxShadow: "0px 0px 2px rgb(0 0 0 / 20%)",
        //           }}
        //           onClick={() => browseImage()}
        //         >
        //           <img
        //             src="/pick_image.png"
        //             width={23}
        //             height={23}
        //             layout="fixed"
        //           />
        //           <p>
        //             <strong>{choosenImageName || "Choose Image/Video"}</strong>
        //           </p>
        //         </div>
        //         <input
        //           type="file"
        //           multiple
        //           id="choosepostfile"
        //           style={{ opacity: 0, height: 0 }}
        //           onChange={(e) => {
        //             chooseImage(e);
        //           }}
        //         />
        //         <button
        //           type="button"
        //           disabled={isLoading ? true : false}
        //           className="btn btn-block w-100 btn-warning custom-theme-button py-2 justify-content-center d-flex align-items-center"
        //           onClick={() => handleCreatePost()}
        //         >
        //           Post{" "}
        //           {isLoading ? (
        //             <CircularProgress
        //               size={15}
        //               color={"inherit"}
        //               className="ms-2"
        //             />
        //           ) : null}
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      ) : null}
    </>
  );
}
