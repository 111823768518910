import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js';
import { NavLink, useParams } from 'react-router-dom';
import { ERROR_SOMETHING_WENT_WRONG } from '../../../../utils/utils';
import { Loadingicon } from '../../../../AppIcons';
import { getExamResult } from '../../../../api/exam';
import { useAuth } from '../../../../Context/AuthContext';
import { toast } from 'react-toastify';
import SectionDescription from '../../../common/sectionDescription';
import Chart from "react-apexcharts";
// Register the necessary Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const ExamResult = () => {
  const params = useParams();
  const { globalToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [totalRecordFound, setTotalRecordFound] = useState([]);
  const [examData, setExamData] = useState(null);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    handleExamsResult();
  }, []);

  


  const handleExamsResult = async () => {
    const examData = await getExamResult({ token: globalToken , id : params?.examID });
    if (examData?.success) {
      setExamData(examData?.examResults);
      const correctAnswers = examData?.statistics?.correct_answers;
      const incorrectAnswers = examData?.statistics?.wrong_answers;
      const notAttempted = examData?.statistics?.not_answered;
      setTotalRecordFound(examData?.examResults?.length)
      setData({
        ...examData,
        notAttempted,
        correctAnswers,
        incorrectAnswers,
      });
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG)
  }
  setLoading(false);
  };
    
  // Chart data
  // const doughnutData = {
  //   labels: ["Not Attempted Questions", "Correct Answers", "Incorrect Answers"],
  //   datasets: [
  //     {
  //       data: [
  //         data?.notAttempted,
  //         data?.correctAnswers,
  //         data?.incorrectAnswers,
  //       ],
  //       backgroundColor: ["#C4C4C4", "#28a745", "#FF5C5C"],
  //       hoverBackgroundColor: ["#C4C4C4", "#28a745", "#FF5C5C"],
  //     },
  //   ],
  // };
  const performanceSeries = [
    data?.notAttempted || 0,
    data?.correctAnswers || 0,
    data?.incorrectAnswers || 0,
  ];
  // Chart options
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'bottom',
  //       display:false
        
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (context) {
  //           let label = context.label || '';
  //           let value = context.raw;
  //           label += `: ${value}`;
  //           return label;
  //         },
  //       },
  //     },
  //   },
  // };

  const performanceOptions = {
    labels: ["Not Attempted Questions", "Correct Answers", "Incorrect Answers"],
    colors: [
     "#FF4C4C", "#4CFF99", "#FF4C9F"
    ],
    stroke: {
      width: 1,
      show: true,
      colors: ["#ffffff"],
    },
    legend: {
      show: true,
      position: "bottom",
      fontSize: "12px",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name: {
              color: "#64748B",
            },
            value: {
              show: true,
              color: "#3A4252",
              fontSize: "22px",
              fontWeight: "600",
            },
            total: {
              show: true,
              color: "#64748B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "10px",
        fontWeight: "bold",
        colors: ["#fff"],
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        color: "#000",
        opacity: 0.25,
      },
      formatter: (val, opts) => {
        const index = opts.seriesIndex;
        return performanceSeries[index]; 
      },
    },
    tooltip: {
      enabled: true,
    },
  };

  return (
    <>
    <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex justify-content-between mb-3">
          <nav
            aria-label="breadcrumb"
            className="pt-0 px-0 pb-0 breadcrumb-card"
          >
            <ol
              className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea"
              style={{ display: "flex", flexWrap: "nowrap" }}
            >
              <li className={`breadcrumb-item capitalize rest-bread`}>
                <NavLink
                  to="/"
                  style={{ fontSize: 14, textDecoration: "none" }}
                >
                  Home
                </NavLink>
              </li>
              <li className={`breadcrumb-item capitalize rest-bread`}>
                <NavLink
                  to="/exams"
                  style={{ fontSize: 14, textDecoration: "none" }}
                >
                  Exams
                </NavLink>
              </li>
              <li
                className={`breadcrumb-item capitalize active clamping ellipsis`}
              >
                Result
              </li>
            </ol>
          </nav>
        </div>        
      </div>
    {loading ? (
        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
          <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="card mb-3">
            <div className="card-body">
            <SectionDescription title={`<strong>${data?.exam?.title}</strong>`} subTitle={`Total ${totalRecordFound} Test Attempted`} />
            </div>
          </div>

          <div className='row'>
              <div className='col-md-6 order-2 order-md-1'>
              <div className="card mt-2 mb-3">
              <div className="card-body">
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, margin: 0 }}>
                {examData?.map((exam, index) =>{ 
                const getOrdinalSuffix = (num) => {
                  const suffixes = ["th", "st", "nd", "rd"];
                  const value = num % 100;
                  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
                };
                const attemptNumber = index + 1;
                return (
                  <div className="col-md-12 " key={`${index}`}>
                    <div
                      className="mb-3"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '15px',
                        borderRadius: '8px',
                        width: '100%',
                        border: '1px solid #ddd',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '15px',
                            backgroundColor: '#f9f9f9',
                            borderRadius: '8px',
                            fontFamily: "'Roboto', sans-serif",
                          }}
                        >
                          {/* Column 1: Marks Details */}
                          <div style={{ flex: 1, marginRight: '20px' }}>
                            <p style={{ margin: '10px 0', fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
                              Total Marks:
                              <span style={{ marginLeft: '8px', color: '#007BFF' }}>{exam.total_marks}</span>
                            </p>
                            <p style={{ margin: '10px 0', fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
                              Marks Obtained:
                              <span style={{ marginLeft: '8px', color: '#28A745' }}>{exam.marks_obtained}</span>
                            </p>
                          </div>
      
                          {/* Column 2: Performance Details */}
                          <div style={{ flex: 1, textAlign: 'left' }}>
                            <p style={{ margin: '10px 0', fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
                              Percentage:
                              <span style={{ marginLeft: '8px', color: '#FFC107' }}>{exam.percentage}%</span>
                            </p>
                            <p
                              style={{
                                margin: '10px 0',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                              }}
                            >
                              Exam Status:
                              <span style={{ marginLeft: '8px', color: exam.exam_status == 'pass' ? '#28A745' : '#DC3545' }} className='capitalize'>{exam.exam_status}</span>
                            </p>
                            
                          </div>
                          
                        </div>
                        <small
                  style={{
                    color: '#555',
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    display:'block'
                  }}
                  className='mt-3'
                >
                  {attemptNumber}{getOrdinalSuffix(index + 1)} Attempt On:
                  <span style={{ marginLeft: '8px'}}>
                    {new Date(exam.updated_at).toLocaleDateString()}
                  </span>
                </small>
                      </div>
                    </div>
                  </div>
                )})}
              </ul>
              
              </div>
            </div>

              
              </div>
              <div className='col-md-6 order-1 order-md-2'>
                  <div className='card card-exam-result-pie'>
                    <div className='card-body'>
                    {/* <div className="d-flex align-items-center justify-content-between">
                                <SectionDescription title={`<strong>Performance</strong>`} subTitle={``} />
                            </div>
                            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /> */}
              <div style={{width:isMobile?'100%':'100%',margin:'0 auto'}}>
              {/* <Doughnut data={doughnutData} options={options} /> */}
              <Chart
                options={performanceOptions}
                series={performanceSeries}
                type="donut"
                height={300}
                width={'100%'}
              />
              </div>
                    </div>
                  </div>

              </div>
              
            </div>
       
      
      </>
      
    
    )}
    </>
  );
};

export default ExamResult;
