import { useEffect, useState } from "react";
import {changePassword, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik, useFormik } from "formik";
import { useAuth } from "../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { onNewTicketRaise, onProfileVerification, onRaisedTicketsList } from "../../../api/AuthService";
import notFound from '../../../assets/images/not_found_new.png';

import { formatDate, moduleNames, moduleSearchType, moduleTypes } from "../../../utils/utils";
import Modal from "../../common/Modal";
import { useNavigate } from "react-router-dom";
function RaiseTicket() {
    const [loading, setLoading] = useState(false);
    

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        message: Yup.string().required("Message is required"),
        subject: Yup.string().required("Subject is required")
    });

    const { user, globalToken } = useAuth();
    const navigate = useNavigate();
    const handleNewTicketGenerate = async (values) => {  
      
        setLoading(true)    
        const res = await onNewTicketRaise({...values, token:globalToken});
     
        if(res?.success){
            toast.success(res?.message);
            navigate('/help-and-support')
        }else{
            // toast.error(res?.message) 
        }
        setLoading(false)    
    }

   
     
    const formData = [
        {
            id: 'module',
            title: 'Module',
            type: 'text',
            name: 'module',
            placeholder: 'eg. Question Bank, Interview Question, Fullform etc.',
            required: false,
            isSelect:true
        },
        {
            id: 'subject',
            title: 'Subject',
            type: 'text',
            name: 'subject',
            placeholder: 'Subject',
            required: true,
        },
        {
            id: 'message',
            title: 'Message',
            type: 'text',
            name: 'message',
            placeholder: 'Message',
            required: true,
        }
    ]

    return (
        <>
            <BreadCrumbs />
          
            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                    <div className="px-3">
                  
                    <div className="row">
                        
                    <Formik    
                                                initialValues={{
                                                    message: '',
                                                    subject: ''
                                                  }}                                           
                                                validationSchema={DisplayingErrorMessagesSchema}
                                                onSubmit={(values) => {       
                                                                                    
                                                   handleNewTicketGenerate(values)
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setFieldValue,
                                                    handleSubmit,
                                                }) => (
                                                    <form className="" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            {
                                                                formData?.map(fieldMeta => (
                                                                    <div className={`${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                                        <label
                                                                            htmlFor={fieldMeta?.id}
                                                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                                                        >
                                                                            {fieldMeta?.title}

                                                                            {
                                                                                fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                            }
                                                                        </label>
                                                                        <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                                        {   fieldMeta?.isSelect?<>
                                                                            <select
                                                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-4"
                                                                                name={fieldMeta?.name}
                                                                                id={fieldMeta?.id}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                            >
                                                                                {
                                                                                    moduleTypes?.map((m, index)=>(
                                                                                        <option key={'m'+index} value={m.label} >{m?.label}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </>
                                                                           :fieldMeta?.id=="message"?
                                                                          <><textarea  onChange={handleChange}
                                                                          onBlur={handleBlur} name={fieldMeta?.name} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"></textarea>
                                                                             <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                        </div>
                                                                                    </>
                                                                           : <>
                                                                                       <div className="position-relative"> 
                                                                                        <input
                                                                                           
                                                                                            type={fieldMeta?.type}
                                                                                            name={fieldMeta?.name}
                                                                                            autoComplete={'off'}
                                                                                            id={fieldMeta?.name}
                                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                            placeholder={fieldMeta?.title}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            value={values[fieldMeta?.name]}
                                                                                        />
                                                                                       
                                                                                        </div>
                                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className="col-md-2">
                                                                <button
                                                                type="submit"
                                                                disabled={loading}
                                                                className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                                                >
                                                                {loading ? (
                                                                    <div className="h-5 w-5">
                                                                    <Loadingicon />
                                                                    </div>
                                                                ) : (
                                                                    "Submit"
                                                                )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}

                                        </Formik>
                    </div>
                    </div>
                      
                    </>
                )
                }
            </div>
            
        </>
    );
}
export default RaiseTicket;
