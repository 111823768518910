import { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import './navigation.css';
import { Link } from "react-router-dom";
import howItWorkIcon from '../../../../assets/images/ico/how_it_works_o.png';

function TaskNavigationTab({tabType}) {
   
    return (
        <>
        <section className="tabs_boxs tabs_main_box px-0 d-md-block d-none position-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col">
                    <div className="card">
                      <Link to={'/gigs/my-campaign'} className={`box py-2 ${tabType=='mycampaign'?'active':''}`}>
                        <div className="tab_page_link">
                          <span><i className="fa fa-stream"></i></span>
                          <h3 className="box_title pt-0">My Campaign</h3>
                        </div>
                     </Link>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="card">
                    <Link to={'/gigs/create-campaign'} className={`box py-2 ${tabType=='createcampaign'?'active':''}`}>
                        <div  className="tab_page_link">
                          <span><i className="fa-regular fa-pen-to-square"></i></span>
                          <h3 className="box_title pt-0">Create Campaign</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card">
                    <Link to={'/gigs/tasks'} className={`box py-2 ${tabType=='tasks'?'active':''}`}>
                        <div className="tab_page_link">
                          <span><i className="fa-solid fa-list-ul"></i></span>
                          <h3 className="box_title pt-0">Task</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col tour-step-5">
                    <div className="card">
                    <Link to={'/gigs/review-task'} className={`box py-2 ${tabType=='reviewtask'?'active':''}`}>
                        <div className="tab_page_link">
                          <span><i className="fa fa-check-double"></i></span>
                          <h3 className="box_title pt-0">Review Task</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="col-md-2">
                    <div className="card">
                    <Link to={'/gigs/submit-proof'} className={`box ${tabType=='submitproof'?'active':''} }`}>
                        <div  className="tab_page_link">
                          <span><i className="fa-solid fa-list-ul"></i></span>
                          <h3 className="box_title">Submit Proof</h3>
                        </div>
                      </Link>
                    </div>
                  </div> */}
                
                  <div className="col">
                    <div className="card">
                      <Link to={'/gigs/my-task'} className={`box py-2 ${tabType=='acceptedtask'?'active':''} }`}>
                        <div className="tab_page_link">
                          <span><i className="fa-solid fa-tasks"></i></span>
                          <h3 className="box_title pt-0">My Task</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card">
                      <Link to={'/gigs/favourite-campaigns'} className={`box py-2 ${tabType=='favCampaign'?'active':''} `}>
                        <div className="tab_page_link">
                          <span>
                          <i className="fa-regular fa-heart"></i>
                            </span>
                          <h3 className="box_title pt-0">Favourite Campaigns</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card">
                      <Link to={'/gigs/favourite-employers'} className={`box py-2 ${tabType=='favEmployer'?'active':''} }`}>
                        <div className="tab_page_link">
                          <span><i className="fa fa-user-tag"></i></span>
                          <h3 className="box_title pt-0">Favourite Employers</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link to="/gigs/how-it-works" title="How It Works?" style={{position:'absolute',right:10, top:0,bottom:0,margin:'auto 0',display:'flex', alignItems:'center'}}><img src={howItWorkIcon} width={50} height={50}/></Link> */}
            </section>
        </>
    );
}
export default TaskNavigationTab;
