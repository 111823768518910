// src/components/InstructionPage.js
import React, { useEffect, useRef, useState } from 'react';
import { Loadingicon } from '../../../../AppIcons';
import { getExamInstructions } from '../../../../api/exam';
import { toast } from 'react-toastify';
import { ERROR_SOMETHING_WENT_WRONG } from '../../../../utils/utils';
import { useNavigate, useParams } from 'react-router-dom';

const ExamInstructionPage = () => {
  const [instructions, setInstructions] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const params = useParams();

  const startExamButtonRef = useRef();

  useEffect(() => {
    if (params?.examSlug) {
        fetchExamInstructions(params?.examSlug);
    }
  }, []);

  const fetchExamInstructions = async (examSlug) => {
    const resExamsIns = await getExamInstructions({ examSlug });
    if (resExamsIns.active_class) {
        console.log("Hello, ", resExamsIns)
        setInstructions(resExamsIns)
        setLoading(false);
  } else {
    toast.error(ERROR_SOMETHING_WENT_WRONG);
}
}

  const modifyAnchorTags = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const anchorTags = doc.querySelectorAll('a');
    anchorTags.forEach(tag => {
        tag.setAttribute('target', '_blank');
    });
    return doc.documentElement.innerHTML;
};

const handleStartExamClick = () => {
  navigation(`/exams/start-exam/${params?.examSlug}`, {
    state: { ref: startExamButtonRef }
  });
};


  return (
    <>
    {loading ? (
          <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
            <div className="h-10 w-10 theme-color">
              <Loadingicon />
            </div>
          </div>
        ) : (
            <>
            <div
            style={{
                listStylePosition: 'inside',
                marginLeft: '0',
                paddingLeft: '0'
            }}
            className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                __html: modifyAnchorTags(`<h1 style= "text-align: center;font-size: 1.6rem;
    font-weight: bold;" class="main-title">${instructions?.title}</h1><hr style="border-top:1px solid #ccc"/><h2>${instructions?.instruction_title}</h2>${instructions?.instruction_data.replace(/&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/g, '&middot;&nbsp;')
                    .replace(/&nbsp;/g, ' ')
                    .trim()}`)
            }}></div>
            <hr style={{borderTop:'1px solid #ccc'}}/>
            <div className="d-flex justify-content-center mt-3">
                            <button
                              className="btn btn-primary"
                              style={{
                                width: "80%",
                                height: "50px",
                                fontSize: "20px",
                              }}
                              onClick={handleStartExamClick}
                            >
                              Start Exam
                            </button>
                          </div>
            </>
        )}
    </>
  );
;
}

export default ExamInstructionPage;
