import React, { useEffect, useState } from "react";
import CameraAlt from "@material-ui/icons/CameraAlt";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
import CheckIcon from '@material-ui/icons/Check';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import AlbumOutlinedIcon from '@material-ui/icons/AlbumOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import CompareArrowsOutlinedIcon from '@material-ui/icons/CompareArrowsOutlined';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Mic from "@material-ui/icons/Mic";
import Home from "@material-ui/icons/Home";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import ListAlt from "@material-ui/icons/ListAlt";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useCookies } from "react-cookie";
import { CDN_BASE_URL, headerIcoUrls, nFormatter } from "../../../utils/utils";
import { useAuth } from "../../../Context/AuthContext";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { GetDataAuth } from "../../../api/feeds/GetDataAuth";
import { useFeeds } from "../../../Context/FeedsContext";
import { PostDataAuth } from "../../../api/feeds/PostDataAuth";
import { PostData } from "../../../api/feeds/PostData";
import { createWorker } from "tesseract.js";
import { CircularProgress } from "@material-ui/core";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import { useSearch } from "../../../Context/SearchContext";
import { AccountBalanceOutlined, HelpOutline, ListAltOutlined, InfoOutlined, PaymentOutlined, PolicyOutlined, ReceiptOutlined, RemoveCircleOutline } from "@material-ui/icons";
import { deleteAccount, getAllUnreadNotifications, getUserToken } from "../../../api/OurService";
import { Loadingicon } from "../../../AppIcons";
import { toast } from "react-toastify";
import SearchHeader from "../../globalsearch/searchHeader";

export default function HeaderAfterLogin({
  handleNavigation,
  sidebarLists,
  showHideLeftBar,
  showProfileMenu,
  setShowProfileMenu,
  handleCustomModal,
  showHideProfileMenu,
}) {
  const params = useParams();
  const location = useLocation();
  const paramLength = Object.keys(params).length;
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [deleteAccountBtnLoader, setDeleteAccountBtnLoader] = useState(false);
  const [imageVoiceLoader, setImageVoiceLoader] = useState(false);
  const [showOnlySearch, setShowOnlySearch] = useState(false);
  const navigation = useNavigate();
  const [voiceDetectModal, setVoiceDetectModal] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [searchTerm, setSearchTerm] = useState();
  const isMobile = window.innerWidth <= 768;
  const {
    listData,
    setListData,
    currentPage,
    setCurrentPage,
    searchValue,
    setSearchValue,
  } = useSearch();

  const {
    setMyFav,
    setModalOpen,
    setFeeds,
    setMyFeeds,
    setSearch,
    loading,
    setLoading,
    feeds,
  } = useFeeds();
  const { handleLogout, user, gigToken, unreadNotifications, setUnreadNotifications, setGigtoken, isHeader, globalToken } = useAuth();
  const [hideTopHeader, setHideTopHeader] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const [placeholder, setPlaceholder] = useState("Search Feeds...");
  const [ocr, setOcr] = useState("");
  const [imageData, setImageData] = useState(null);
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const searchBox = useRef();
  const navigate = useNavigate();

  const showHideNotifiationMenu = () => {
    setIsNotificationDropdown(!isNotificationDropdown)
  }
  const convertImageToText = async () => {
    searchBox?.current?.focus();
    if (!imageData) return;
    setImageVoiceLoader(true);

    const worker = await createWorker({
      logger: (m) => {
        // console.log(m);
      },
    });

    await worker.load();
    await worker.loadLanguage("eng");
    await worker.initialize("eng");
    const {
      data: { text },
    } = await worker.recognize(imageData);
    setSearchWord(text);
    setImageVoiceLoader(false);
  };

  const handleAccountDelete = async () => {

    setDeleteAccountBtnLoader(true)
    const deleteAccountRes = await deleteAccount({ token: globalToken, tuteehubId: user?.tuteehub_id });
    // console.log(deleteAccountRes)
    if (deleteAccountRes?.success) {
      openHideDeleteAccountConfirmationModal();
      toast.success(deleteAccountRes?.message);
      handleLogout();

    } else {
      toast.error(deleteAccountRes?.message);
    }
    setDeleteAccountBtnLoader(false)
  }

  const handleVoiceDetectModal = () => {
    setVoiceDetectModal(!voiceDetectModal);
    SpeechRecognition.startListening();
    setTimeout(() => {
      SpeechRecognition.stopListening();
    }, 3000);

    setTimeout(() => {
      setSearchWord(transcript);
      searchBox?.current?.focus();
      setVoiceDetectModal(false);
    }, 4000);
  };

  // const _allFeed = async (pageNumber) => {
  //   if (
  //     localStorage.getItem("userDetails") &&
  //     localStorage.getItem("___fTkn")
  //   ) {
  //     const token = localStorage.getItem("___fTkn");
  //     await setApiToken(token);
  //     const allFeeds = await GetDataAuth(
  //       `api/fd/get/feed/list/via-authentic-user?page=${pageNumber}`,
  //       token
  //     );
  //     if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
  //       const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
  //       setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
  //     } else {
  //       setFeeds(allFeeds);
  //     }
  //   } else {
  //     const allFeeds = await GetData(`api/fd/get/feed/list?page=${pageNumber}`);
  //     if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
  //       const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
  //       setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
  //     } else {
  //       setFeeds(allFeeds);
  //     }
  //   }
  //   setLoading(false)
  // };
  const myAccountRef = useRef(null)
  const notificationRef = useRef(null);
  const myAccountDropdownRef = useRef(null)
  const myNotiDropdownRef = useRef(null);
  const handleBodyClick = (event) => {
    const clickedElement = event.target;
    if (
      myAccountRef?.current?.contains(clickedElement) ||
      (myAccountDropdownRef.current && myAccountDropdownRef?.current?.contains(clickedElement))
    ) { } else { }

  };

  const openHideDeleteAccountConfirmationModal = () => {
    setIsDeleteAccountOpen(!isDeleteAccountOpen);
  }


  useEffect(() => {
    if (!gigToken?.token) {
      // alert(1)
      // generateShortTaskToken();
    }

    document.body.addEventListener('click', handleBodyClick);
    document.addEventListener('click', function (event) {
      if (!event.target?.classList?.contains('account-toggle')) {
        if (!event.target.closest('.account-toggle')) {
          setShowProfileMenu(false);
        }
      }
      if (!event.target?.classList?.contains('noti-toggle')) {
        if (!event.target.closest('.noti-toggle')) {
          setIsNotificationDropdown(false);
        }
      }
    });

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  // useEffect(() => {
  // if (!params?.qName) {
  //   setSearchValue("");
  // } else {
  //   setCurrentPage(1);
  //   setListData([]);
  // }
  // setSearchWord("");
  // handlePlaceholder();
  // localStorage.removeItem("feedDetail");
  // }, [navigation]);

  // useEffect(() => {
  //   convertImageToText();
  // }, [imageData]);

  // function handleImageChange(e) {
  //   const file = e.target.files[0];
  //   if (!file) return;
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const imageDataUri = reader.result;
  //     // console.log({ imageDataUri });
  //     setImageData(imageDataUri);
  //   };
  //   reader.readAsDataURL(file);
  // }

  const _favFeedList = async () => {

    setMyFeeds(false);
    setSearch(false);
    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const favListDataReturn = await GetDataAuth(
        `feed-favourite/list`,
        globalToken
      );

      if (favListDataReturn?.success) {
        setMyFav(true);
        setFeeds(favListDataReturn);
        localStorage.removeItem("feedDetail");
      } else {
        setMyFav(false);
        setModalOpen(true);
      }
    } else {
      setMyFav(false);
      setModalOpen(true);
    }

    setLoading(false);
    navigation("/feeds");
  };

  // const handlePlaceholder = () => {

  //   const queryParams = new URLSearchParams(location.search);
  //   if(queryParams?.get('searchTerm'))
  //   setSearchWord(queryParams?.get('searchTerm'));

  //   if (paramLength === 0 || params?.slug) {
  //     setPlaceholder("Search Feeds...");
  //   }
  //   if (
  //     params?.name === "question-Bank" ||
  //     (params?.name === "question-Bank" &&
  //       (params?.catName || params?.subCatName || params?.qName))
  //   ) {
  //     setPlaceholder("Search Solved Questions Across Any Subject Or Topic...");
  //   }

  //   if (
  //     location?.pathname.includes('/corporates')
  //   ) {
  //     setPlaceholder("Search Companies Across Any Categories ...");
  //   }

  //   if (
  //     location?.pathname.includes('/forum')
  //   ) {
  //     setPlaceholder("Search Questions");
  //   }

  //   if (
  //     location?.pathname.includes('/gigs') ||
  //     (location?.pathname.includes('/gigs') &&
  //       (params?.catName || params?.subCatName || params?.qName))
  //   ) {
  //     setPlaceholder("What's on your mind?");
  //   }

  //   if (
  //     location?.pathname.includes('/lyrics')
  //   ) {
  //     setPlaceholder("Search Lyrics By Artist, Lyricist Or Album ...");
  //   }
  //   if (
  //     location?.pathname.includes('/exams')
  //   ) {
  //     setPlaceholder("Search Exams Across Any Subject Or Topic ...");
  //   }

  //   if (
  //     location?.pathname.includes('/quotes')
  //   ) {
  //     setPlaceholder("Search Quotes on any Topic or by any Author ...");
  //   }

  //   if (
  //     location?.pathname.includes('/educators')
  //   ) {
  //     setPlaceholder("Search Educators Across Any Categories ...");
  //   }

  //   if (
  //     location?.pathname.includes('/blogs')
  //   ) {
  //     setPlaceholder("Search Blogs");
  //   }

  //   if (
  //     location?.pathname.includes('/career-news')
  //   ) {
  //     const currentYear = new Date().getFullYear();
  //     setPlaceholder(`Search for Top Exams, Job Alerts, Syllabus, Pattern, Admit Card, Result News of ${currentYear}`);
  //   }
  //   if (
  //     params?.name === "interview-questions" ||
  //     (params?.name === "interview-questions" &&
  //       (params?.catName || params?.subCatName || params?.qName))
  //   ) {
  //     setPlaceholder("Find Answers to Job Interview Questions...");
  //   }
  //   if (
  //     params?.name === "fullforms" ||
  //     (params?.name === "fullforms" &&
  //       (params?.catName || params?.subCatName || params?.qName))
  //   ) {
  //     setPlaceholder("Search Full Forms Across Any Categories...");
  //   }
  //   if (
  //     params?.name === "dictionary" ||
  //     (params?.name === "dictionary" &&
  //       (params?.catName || params?.subCatName || params?.qName))
  //   ) {
  //     setPlaceholder("Search Dictionary/Word Meaning Across Any Categories...");
  //   }
  //   if (
  //     location?.pathname.includes('/tutorials') ||
  //     (location?.pathname.includes('/tutorials') &&
  //       (params?.catName || params?.subCatName || params?.qName))
  //   ) {
  //     setPlaceholder("Search Tutorial");
  //   }
  // };


  const searchFeeds = async (searchText) => {
    setSearch(true);
    // resetTranscript();
    // setImageVoiceLoader(true);
    navigation("/feeds");
    setMyFeeds(false);
    setMyFav(false);
    setLoading(true);
    let returnSearchResult = null;
    // const apiToken = localStorage.getItem("___fTkn");

    if (globalToken) {
      returnSearchResult = await PostDataAuth(
        `search/feed/list/via-authentic-user`,
        globalToken,
        { keyword: searchText }
      );
    } else {
      returnSearchResult = await PostData(`search/feed/list`, "", {
        keyword: searchText,
      });
    }
    if (searchText === "") {
      const pageNumber = 1;
      const allFeeds = await GetDataAuth(
        `get/feed/list/via-authentic-user?page=${pageNumber}`,
        globalToken
      );
      if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
        const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
        setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
      } else {
        setFeeds(allFeeds);
      }
      setImageVoiceLoader(false);
    } else {
      setFeeds(returnSearchResult);
      setImageVoiceLoader(false);
    }
    setSearch(true);
    setLoading(false);
    localStorage.removeItem("feedDetail");
  };

  const handleMyFeeds = async () => {

    setMyFav(false);
    setSearch(false);

    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const returnMyFeeds = await GetDataAuth(`feed/my`, globalToken);
      if (returnMyFeeds?.success) {
        setFeeds(returnMyFeeds);
        setMyFeeds(true);
        localStorage.removeItem("feedDetail");
      } else {
        setMyFeeds(true);
      }
    } else {
      console.log("");
    }
    setLoading(false);
    navigation("/feeds");
  };

  // const showHideMoreMenu = () => {
  //   setShowMoreMenu(!showMoreMenu);
  // };

  // const handleSearchOnClick = (searchValue) => {

  //   if (location?.pathname.includes('/career-news')) {
  //       setSearchValue(searchValue);
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`career-news/search-news?searchTerm=${searchValue}`)
  //   }
  //   if (location?.pathname.includes('/exams')) {
  //     setSearchValue(searchValue);
  //     setCurrentPage(1);
  //     setListData([]);
  //     navigate(`exams/search-exams?searchTerm=${searchValue}`)
  // }
  //   if (location?.pathname.includes('/corporates')) {
  //       setSearchValue(searchValue);
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`corporates/search-companies?searchTerm=${searchValue}`)
  //   }
  //   if (location?.pathname.includes('/blogs')) {
  //     setSearchValue(searchValue);
  //     setCurrentPage(1);
  //     setListData([]);
  //     navigate(`blogs/search-blogs?searchTerm=${searchValue}`)
  // }
  // if (location?.pathname.includes('/educators')) {
  //   setSearchValue(searchValue);
  //   setCurrentPage(1);
  //   setListData([]);
  //   navigate(`educators/search-educators?searchTerm=${searchValue}`)
  // }
  // if (location?.pathname.includes('/tutorials')) {
  //   setSearchValue(searchValue);
  //   setCurrentPage(1);
  //   setListData([]);
  //   navigate(`tutorials/search-tutorials?searchTerm=${searchValue}`)
  // }
  // if (location?.pathname.includes('/forum')) {
  //   setSearchValue(searchValue);
  //   setCurrentPage(1);
  //   setListData([]);
  //   navigate(`forum/search-forum?searchTerm=${searchValue}`)
  // }
  // if (location?.pathname.includes('/lyrics')) {
  //   setSearchValue(searchValue);
  //   setCurrentPage(1);
  //   setListData([]);
  //   navigate(`lyrics/search-album?searchTerm=${searchValue}`)
  // }
  // if (location?.pathname.includes('/quotes')) {
  //   setSearchValue(searchValue);
  //   setCurrentPage(1);
  //   setListData([]);
  //   navigate(`quotes/search-topics?searchTerm=${searchValue}`)
  // }
  // }

  // const handleSearchOnKeypup = (e) => {
  //   if (params?.name && !location?.pathname.includes('/career-news')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value);
  //       setCurrentPage(1);
  //       setListData([]);
  //     }
  //     return;
  //   }
  //   if (location?.pathname.includes('/career-news')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'c');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`career-news/search-news?searchTerm=${e.target.value}`)
  //     }
  //   }
  //   if (location?.pathname.includes('/exams')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'c');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`exams/search-exams?searchTerm=${e.target.value}`)
  //     }
  //   }

  //   if (location?.pathname.includes('/corporates')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'c');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`corporates/search-companies?searchTerm=${e.target.value}`)
  //     }
  //   }

  //   if (location?.pathname.includes('/blogs')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'b');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`blogs/search-blogs?searchTerm=${e.target.value}`)
  //     }
  //   }

  //   if (location?.pathname.includes('/educators')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'e');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`educators/search-educators?searchTerm=${e.target.value}`)
  //     }
  //   }

  //   if (location?.pathname.includes('/tutorials')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 't');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`tutorials/search-tutorials?searchTerm=${e.target.value}`)
  //     }
  //   }
  //   if (location?.pathname.includes('/forum')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'f');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`forum/search-forum?searchTerm=${e.target.value}`)
  //     }
  //   }
  //   if (location?.pathname.includes('/lyrics')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'l');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`lyrics/search-album?searchTerm=${e.target.value}`)
  //     }
  //   }
  //   if (location?.pathname.includes('/quotes')) {
  //     if (e.key === "Enter") {
  //       setSearchValue(e.target.value || 'q');
  //       setCurrentPage(1);
  //       setListData([]);
  //       navigate(`quotes/search-topics?searchTerm=${e.target.value}`)
  //     }
  //   }

  //   if ((paramLength === 0 || params?.slug) && (location?.pathname.includes('/feeds') || location?.pathname=='/')) {      
  //     setSearchWord(e.target.value);
  //     if (e.key === "Enter") {
  //       searchFeeds(e.target.value);
  //     }
  //   }
  // };



  const handleNotificationClick = (notification) => {
    localStorage.setItem('noti', JSON.stringify(notification));
    setIsNotificationDropdown(false)
    navigate('/gigs/notifications');
  }

  const getNotificationCount = () => {
    let nCount = 0;
    if (unreadNotifications?.notifications && unreadNotifications?.notifications?.length > 0) {
      nCount = unreadNotifications?.notifications.filter((f) => f.viewed == 0)?.length;
    }
    return nCount;
  }

  const toggleDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown); // Toggle the dropdown state

  };

  return (
    <>
      <div className={`d-flex align-items-center justify-content-between px-3 py-2 sticky-head sticky-top bg-white ${location?.pathname.includes('/exams/start-exam') ? 'start-exam-main' : ''}`} style={{ opacity: isHeader ? 1 : 0 }}>
        <span className="d-flex align-items-center flex-1">
          <div style={{ width: 43, height: 43 }} className="logo-container">
            <Link to={"/"}>
              <img
                src={require(`../../../assets/images/logo_other_platform.webp`)}
                alt="logo.png"
                width={43}
                height={43}
                role="button"
                layout="fixed"
              />
            </Link>
          </div>

          {/* added searhbar inside */}
          {
            isMobile && showOnlySearch ? (<SearchHeader searchFeeds={searchFeeds} />) : !isMobile?<SearchHeader searchFeeds={searchFeeds} />:null
          }
          

          {/* added searchbar inside */}
        </span>

        <div className="d-flex align-items-center flex-11 lost-flex-1 justify-content-end">

          <i className={`fa ${showOnlySearch?'fa-times':'fa-search me-1'} px-2  d-md-none d-block`} onClick={()=>setShowOnlySearch(!showOnlySearch)} style={{color:'#6a7b8b'}}></i>
          {
            isMobile && showOnlySearch?null:(<><svg
              className="gb_We show-features d-md-none d-block me-0 me-md-2"
              style={{ width: 24, fill: "#6a7b8b" }}
              onClick={() => showHideLeftBar()}
              role="button"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
            </svg>
            {/* <Link href="https://tuteehub.com/how-it-works">
              <a
                target={"_blank"}
                className="text-black-50 theme-color d-md-block d-none text-decoration-none mx-2 fsize-12"
              >
                How It Works
              </a>
            </Link> */}
            {/* {!user ? (
              <div className="d-md-block d-none">
                <Link href="https://tuteehub.com/multiuser-register">
                  <a
                    target={"_blank"}
                    className="text-black-50 theme-color text-decoration-none mx-2 fsize-12"
                  >
                    Sign Up
                  </a>
                </Link>
                <span
                  className="theme-color text-decoration-none mx-2 fsize-12 sign-in-btn rounded"
                  role="button"
                  onClick={()=>setModalOpen(!modalOpen)}
                >
                  Sign In
                </span>
              </div>
            ) : null} */}
            {/* <Link href="https://play.google.com/store/apps/details?id=com.tuteehub.babble"><a target="_blank"><CommentOutlined className="me-3" style={{opacity:0.5}}/></a></Link> */}
            {/* <svg
              className="gb_We show-features d-md-none d-block"
              style={{ width: 30, fill: "#ccc" }}
              onClick={() => showHideLeftBar()}
              role="button"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
            </svg>
            {!user ? (
              <Person
                className="d-block d-md-none"
                style={{ marginLeft: 5, color: "#ccc" }}
                onClick={()=>setModalOpen(!modalOpen)}
              />
            ) : null} */}
            {hideTopHeader ? (<>
              <div className="btn-group">
                <div className="d-flex align-item-center justify-content-between">
                  {/* <div className="d-md-flex d-none align-items-center me-2">
                   <button onClick={()=>navigation('/offers')} className="btn offer-btn justify-content-center px-4 me-2 d-flex align-items-center border-0 btn-primarys w-100 btn-sm credit-btn">       <img src={require('../../../assets/images/discount.png')} width={20} height={20} />Offers</button>
              
              </div> */}
                  <div className="d-md-flex d-none w-100 align-items-center">
  
                    <button onClick={() => { navigation('/credit-history'); window.location.reload(); }} className="btn justify-content-center px-md-3 px-2 me-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">
                      <img src={require('../../../assets/images/star_white.png')} width={20} height={20} />
                      {nFormatter(user?.credits)} Credits</button>
                  </div>
                  <div className="position-relative">
                    <button className="btn btn-default dropdown-toggle notification-dropdown" onClick={() => { navigate('/inbox') }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa fa-envelope" style={{color:'#6a7b8b'}}></i>
                      {/* <span className="badge">{0}</span> */}
                    </button>
                  </div>
                  <div className="dropdown">
                    {/* {
                location?.pathname!=='/gigs/notifications'?  */}
                    <button className="btn btn-default px-md-3 px-2 dropdown-toggle noti-toggle  notification-dropdown" onClick={toggleDropdown} type="button" id="dropdownMenuButton1" aria-expanded={isNotificationDropdown ? 'true' : 'false'} >
                      <i className="fa fa-bell" style={{color:'#6a7b8b'}}></i>
                      <span className="badge">{getNotificationCount() || 0}</span>
                    </button>
                    {/* :null
                } */}
  
                    {
                      isNotificationDropdown ?
                        <ul className="profile-dropdowns mw-300 mt-3 p-0" style={{ maxHeight: 'calc(100vh - 15vh)', overflow: 'auto' }}>
                          {
                            unreadNotifications?.notifications?.length ? unreadNotifications?.notifications.filter((f) => f.viewed == 0).map((notification, index) => (
                              <li className="group-title p-0 n-title m-0" key={'unread-notifcation' + index}>
                                <a onClick={() => handleNotificationClick(notification)} className="d-flex py-2 px-3 align-items-center">
                                  <NotificationsIcon
                                    role="button"
                                    className="me-2"
                                    style={{ color: "#ccc", width: 18, height: 18, marginTop: 2 }}
                                  />{" "}
                                  {notification?.notification_text}</a>
                              </li>
                            )) : null
                          }
  
  
                          <li className="group-title p-0 n-title m-0 p-0">
                            <hr className="m-0" />
                            <a onClick={() => { setIsNotificationDropdown(false); navigate('/gigs/notifications'); }} className="w-100 btn py-2 btn-outline btn-default text-center p-0 text-theme-color">
                              View All
                            </a>
                          </li>
                        </ul> : null
                    }
  
                  </div>
                </div>
                <button
                  ref={myAccountRef}
                  type="button"
                  className="btn btn-transparent account-toggle pe-md-3 pe-0 d-flex align-items-center dropdown-toggle outline-0 shadow-0"
                  onClick={() => showHideProfileMenu()}
                  style={{ outline: "none", boxShadow: "none" }}
                >
  
                  <img
                    src={
                      user?.profile_image ? user?.profile_image : require(`../../../assets/images/profile_placeholder.png`)
                    }
                    width={28}
                    height={28}
                    className="siderbarIco"
                    layout="fixed"
                    style={{ borderRadius: 100, width: 28, height: 28 }}
                  />{" "}
                  <span className="ms-2 text-capitalize d-none d-md-block">
                    {user?.name}
                  </span>
                  { }
                </button>
  
                {showProfileMenu ? (
                  <ul ref={myAccountDropdownRef} className="profile-dropdowns" style={{ maxHeight: 'calc(100vh - 15vh)', overflow: 'auto' }}>
                    {/* <li className="d-md-none d-block mb-2">
                    <div className="d-flex w-100 align-items-center">
                    <button onClick={()=>navigation('/offers')} className="btn offer-btn btn-block justify-content-center px-4 me-md-2 d-flex align-items-center border-0 btn-primarys w-100 btn-sm credit-btn">       <img src={require('../../../assets/images/discount.png')} width={20} height={20} />Offers</button>
              
                    </div>
                    </li> */}
                    <li className="d-md-none d-block">
                      <div className="d-flex w-100 align-items-center">
                        <button onClick={() => navigation('/credit-history')} className="btn justify-content-center px-3 me-md-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">
                          <img src={require('../../../assets/images/star_white.png')} width={20} height={20} />
                          {nFormatter(user?.credits)} Credits</button>
                      </div>
  
                    </li>
                    <li className="group-title">My Accounts</li>
                    <li>
                      <button
                        className="dropdown-item py-1 my-1 ms-2"
                        type="button"
                        onClick={() => {
                          // navigation("myprofile");
                          navigate(`/my-profile`)
                          showHideProfileMenu();
                        }}
                      >
                        <PermIdentityOutlinedIcon style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }} /> My Profile
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={() => {
                          handleMyFeeds();
                          showHideProfileMenu();
                        }}
                      >
                        <ListAlt
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                        />{" "}
                        My Feeds
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item py-1 my-1 ms-2"
                        type="button"
                        onClick={() => {
                          navigate(`/my-favourites`)
                          showHideProfileMenu();
                        }}
                      >
                        <FavoriteBorder style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }} /> My Favourites
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item py-1 my-1 ms-2"
                        type="button"
                        onClick={() => {
                          _favFeedList();
                          showHideProfileMenu();
                        }}
                      >
                        <FavoriteBorder style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }} /> My Favourites Feeds
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={() => {
                          navigation('profile-verification')
                          window.location.reload();
                          showHideProfileMenu();
                        }}
                      >
                        <CheckIcon
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                        />{" "}
                        Profile Verification
                      </button>
                      {/* <a
                        className="dropdown-item py-1 my-1 ms-2"
                        type="button"
                        target="_blank"
                        href="https://showtalentapp.tuteehub.com"
                      >
                        <CheckIcon style={{ color: "#ccc",width:18,height:18,marginTop:-2 }} /> Profile
                        Verification
                      </a> */}
                    </li>
                    <li>
                      <button
                        className="dropdown-item py-1 my-1 ms-2"
                        type="button"
                        onClick={() => {
                          navigation("weekly-leaderboard");
                          window.location.reload();
                          showHideProfileMenu();
                        }}
                      >
                        <FlagOutlinedIcon style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }} /> Leaderboard
                      </button>
                    </li>
                    <div className="d-none">
                      <li>
                        <button
                          className="dropdown-item py-1 my-1 ms-2"
                          type="button"
                          onClick={() => {
                            navigation("payment-details");
                            showHideProfileMenu();
                          }}
                        >
                          <PaymentOutlined style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }} /> Payment Details
                        </button>
                      </li>
  
                      <li>
                        <button
                          className="dropdown-item py-1 my-1 ms-2"
                          type="button"
                          onClick={() => {
                            navigation('withdrawal')
                            window.location.reload();
                            showHideProfileMenu();
                          }}
                        >
                          <MarkunreadMailboxOutlinedIcon style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }} /> Withdrawal
                          Money
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          onClick={() => {
                            navigation('transaction-history');
                            window.location.reload();
                            showHideProfileMenu();
                          }}
                        >
                          <CompareArrowsOutlinedIcon
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Transaction History
                        </button>
                      </li>
                      <li className="group-title">Credit Management</li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          onClick={() => {
                            navigation('purchase-credits')
                            window.location.reload();
                            showHideProfileMenu();
                          }}
                        >
                          <AlbumOutlinedIcon
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Purchase Credits
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          onClick={() => {
                            navigation('credit-history')
                            window.location.reload();
                            showHideProfileMenu();
                          }}
                        >
                          <ReceiptOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Credit History
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          onClick={() => {
                            navigation('credit-purchase-history')
                            window.location.reload();
                            showHideProfileMenu();
                          }}
                        >
                          <CardMembershipOutlinedIcon
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Credit Purchase History
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          onClick={() => {
                            navigation('claim-credits')
                            window.location.reload();
                            showHideProfileMenu();
                          }}
                        >
                          <CardGiftcardOutlinedIcon
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Claim Credits
                        </button>
                      </li>
                    </div>
                    <div className="d-none">
                      <li className="group-title">Gigs</li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          onClick={() => {
                            navigation('/gigs/tasks')
                          }}
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        // href={"/gigs/tasks"}
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Tasks
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          // href={"/gigs/create-campaign"}
                          onClick={() => {
                            navigation('/gigs/create-campaign')
                          }}
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Create Campaign
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          // href={"/gigs/my-campaign"}
                          onClick={() => {
                            navigation('/gigs/my-campaign')
                          }}
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          My Campaign
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          // href={"/gigs/accepted-task"}
                          onClick={() => {
                            navigation('/gigs/my-task')
                          }}
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          My Task
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          // href={"/gigs/review-task"}
                          onClick={() => {
                            navigation('/gigs/review-task')
                          }}
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Review Task
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          // href={"/gigs/favourite-tasks"}
                          onClick={() => {
                            navigation('/gigs/favourite-campaigns')
                          }}
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Favourite Campaigns
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          // href={"/gigs/favourite-employers"}
                          onClick={() => {
                            navigation('/gigs/favourite-employers')
                          }}
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Favourite Employers
                        </button>
                      </li>
  
                      {/* <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        href={"/gigs/tasks"}
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Tasks
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        href={"/gigs/create-campaign"}
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Create Campaign
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        href={"/gigs/my-campaign"}
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        My Campaign
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        href={"/gigs/accepted-task"}
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Accepted Task
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        href={"/gigs/review-task"}
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Review Task
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        href={"/gigs/favourite-tasks"}
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Favourite Tasks
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        href={"/gigs/favourite-employers"}
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Favourite Employers
                      </a>
                    </li> */}
                      {/* <li>
                      <button
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={()=>
                          navigate("/offers")
                        }
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Offers<sup className="new-text">New</sup>
                      </button>
                    </li> */}
                      {/* <li>
                      <button
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={()=>
                          navigate("/offers/my-offers")
                        }
                      >
                        <ListAltOutlined
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        My Offers
                      </button>
                    </li> */}
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          // href={"/surveys"}
                          onClick={() =>
                            navigate("/surveys")
                          }
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Surveys<sup className="new-text">New</sup>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          onClick={() =>
                            navigate("/surveys/my-surveys")
                          }
                        >
                          <ListAltOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          My Surveys
                        </button>
                      </li>
                    </div>
                    <li className="group-title">Settings & Privacy</li>
                    <div className="d-none">
                      <li>
                        <a
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          target="_blank"
                          href="https://tuteehub.com/about-us"
                        >
                          <InfoOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          About Us
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          target="_blank"
                          href="https://tuteehub.com/privacy-policy"
                        >
                          <PolicyOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Privacy Privacy
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          target="_blank"
                          href="https://tuteehub.com/terms-and-condition"
                        >
                          <PolicyOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item py-1 my-1"
                          type="button"
                          // onClick={() => {
                          //   handleMyFeeds();
                          //   showHideProfileMenu();
                          // }}
                          target="_blank"
                          href="https://tuteehub.com/cancellation-and-refund-policy"
                        >
                          <PolicyOutlined
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Cancellation & Refund Policy
                        </a>
                      </li>
                    </div>
                    <li>
                      <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={() => {
                          openHideDeleteAccountConfirmationModal()
                        }}
                      // target="_blank"
                      // href="https://tuteehub.com/terms-and-condition"
                      >
                        <RemoveCircleOutline
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                        />{" "}
                        Delete Account
                      </a>
                    </li>
                    {/* <li>
                    <a
                        className="dropdown-item py-1 my-1"
                        type="button"
                        // onClick={() => {
                        //   handleMyFeeds();
                        //   showHideProfileMenu();
                        // }}
                        target="_blank"
                        href="https://showtalentapp.tuteehub.com"
                      >
                        <LiveHelpOutlinedIcon
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        FAQ
                      </a>
                    </li> */}
                    <div className="d-none">
                      <li className="group-title">Help & Support</li>
                      <li>
                        <button
                          className="dropdown-item py-1 my-1"
                          type="button"
                          onClick={() => {
                            navigation('help-and-support')
                            window.location.reload();
                            showHideProfileMenu();
                          }}
                        >
                          <HelpOutline
                            role="button"
                            className="ms-2"
                            style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                          />{" "}
                          Help & Support
                        </button>
  
  
                      </li>
                    </div>
                    <li className="group-title">
                      <hr className="my-2" />
                    </li>
                    {/* <li>
                    <Link
                        className="dropdown-item py-1 my-1 ms-2"
                        type="button"
                        to={'finance'}
                      >
                        <AccountBalanceWalletOutlinedIcon style={{ color: "#ccc" }} /> Finance
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        className="dropdown-item py-1 my-1 ms-2"
                        type="button"
                        to={'plans'}
                      >
                        <MoneyOutlinedIcon style={{ color: "#ccc" }} /> Plans
                      </Link>
                    </li> */}
                    <li>
                      <button
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={() => handleLogout()}
                      >
                        <PowerSettingsNew
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc", width: 18, height: 18, marginTop: -2 }}
                        />{" "}
                        Logout
                      </button>
                    </li>
                  </ul>
                ) : null}
              </div>
  
            </>
            ) : null}</>)
          }
          
        </div>
      </div>

      <Modal
        show={voiceDetectModal}
        onHide={handleVoiceDetectModal}
        size="sm"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Report</h5>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <img src={require("../../../assets/images/listening.gif")} />
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            type="button"
            disabled={isLoading ? true : false}
            className="btn btn-warning custom-theme-button d-flex align-items-center"
            onClick={() => reportFeed()}
          >
            Submit{" "}
            {isLoading ? (
              <CircularProgress size={15} color={"inherit"} className="ms-2" />
            ) : null}
          </button>
        </Modal.Footer> */}
      </Modal>


      <Modal
        show={isDeleteAccountOpen}
        onHide={openHideDeleteAccountConfirmationModal}
        size="md"
        centered
      // closeModal={openHideDeleteAccountConfirmationModal}
      // setIsOpen={setIsDeleteAccountOpen}
      // isOpen={isDeleteAccountOpen}
      // callBack={openHideDeleteAccountConfirmationModal}
      // title={'Delete Account'}
      // modalFooter={true}
      // isMaxW={true}
      >
        <Modal.Body>
          <p className="text-capitalize">
            <h5><strong>Confirm Account Deletion</strong></h5> <br />Deleting your TuteeHub account is irreversible. All your data will be permanently lost. Do you wish to proceed?
          </p>
          <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
            <button
              type="button"
              className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
              onClick={() => { openHideDeleteAccountConfirmationModal() }}
            >
              No
            </button>
            <button

              type="submit"
              disabled={deleteAccountBtnLoader}
              className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
              onClick={() => handleAccountDelete()}
            >
              {deleteAccountBtnLoader ? (
                <div className="h-5 w-5">
                  <Loadingicon />
                </div>
              ) : 'Yes'
              }
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
