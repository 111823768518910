import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getBranchDetail, getNewsByPattern, getNewsDetailPatternWise, getSearchedData } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import BranchDetailList from '../common/branchDetailList';
import NewsListItem from '../common/newsList';


function NewsList() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const [branchId, setBranchId] = useState();
    const [streamId, setStreamId] = useState();
    const [isSearch, setIsSearch] = useState(false);    
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const [searchTerm, setSearchTerm] = useState();
    const [newsPattern, setNewsPattern] = useState();

    const navigation = useNavigate();
    const params = useParams();

    useEffect(() => {
        if(params?.branchId && params?.streamId && params?.newsPattern){
            setBranchId(params?.branchId);
            setStreamId(params?.streamId);
            setNewsPattern(params?.newsPattern);
            handleFetchAllNewsByPattern(1, params?.streamId, params?.branchId, params?.newsPattern);
        }
        else if(params?.newsId && params?.patternSlug && params?.sCat && params?.nSlug)
        {
            handleFetchNewsDetailWithPattern(params?.sCat, params?.patternSlug);
        }
        else{
            handleFetchAllNewsByPattern(1, 0, 0, 'latest-news');
        }
        
    }, [])

    // fetch news with pattern slug
    const handleFetchNewsDetailWithPattern = async (sCatId, slug) => {        
        const resNewsByPatternWise = await getNewsDetailPatternWise({ token: globalToken, subCatId:sCatId, slug:slug });
        if (resNewsByPatternWise?.contents) {
            // console.log('newsbypattern', resNewsByPattern);
            setIsNotFound(false)
            resNewsByPatternWise?.contents?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resNewsByPatternWise?.contents?.data?.length && pageNo == 1) {
                setNewsList(resNewsByPatternWise?.contents?.data);
            } else if (resNewsByPatternWise?.contents?.data?.length && pageNo !== 1) {
                setNewsList([...newsList, ...resNewsByPatternWise?.contents?.data]);
            } else {
                if (pageNo == 1) {
                    setNewsList([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // fetch all news by pattern
    const handleFetchAllNewsByPattern = async (pageNo, streamId, branchId, newsPattern) => {
        const resNewsByPattern = await getNewsByPattern({ token:globalToken, pageNo, streamId, branchId, newsPattern });
        if (resNewsByPattern?.success) {
            // console.log('newsbypattern', resNewsByPattern);
            setIsNotFound(false)
            resNewsByPattern?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resNewsByPattern?.data?.length && pageNo == 1) {
                setNewsList(resNewsByPattern?.data);
            } else if (resNewsByPattern?.data?.length && pageNo !== 1) {
                setNewsList([...newsList, ...resNewsByPattern?.data]);
            } else {
                if (pageNo == 1) {
                    setNewsList([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more news data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;        
        if (searchTerm?.length) {
            searchNews(nxtPage, 'news', searchTerm, 'loadMore');
        } else {
            handleFetchAllNewsByPattern(nxtPage, streamId, branchId, newsPattern);
        }
        setPageNo(nxtPage);
    }

    
    // const popularCareerCats = [
    //     {
    //         name: "Latest Govt Jobs",
    //         value: "Latest Govt Jobs",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Govt Jobs By Qualification",
    //         value: "Govt Jobs By Qualification",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Govt Jobs By Location",
    //         value: "Govt Jobs By Location",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Latest Private Jobs",
    //         value: "Latest Private Jobs",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Private Jobs By Qualification",
    //         value: "Private Jobs By Qualification",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Private Jobs By Location",
    //         value: "Private Jobs By Location",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Latest Internships",
    //         value: "Latest Internships",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Internships By Education",
    //         value: "Internships By Education",
    //         redirectUrl: "#"
    //     },
    //     {
    //         name: "Internships By Location",
    //         value: "Internships By Location",
    //         redirectUrl: "#"
    //     }
    // ];


    const popularCareerCats = [
        {
            name: "About",
            value: "about",
            redirectUrl: `/upcoming-about`
        },
        {
            name: "Eligibility",
            value: "eligibility",
            redirectUrl: `/upcoming-eligibility`
        },
        {
            name: "Dates",
            value: "dates",
            redirectUrl: `/upcoming-dates`
        },
        {
            name: "Pattern",
            value: "pattern",
            redirectUrl: `/upcoming-pattern`
        },
        {
            name: "Syllabus",
            value: "syllabus",
            redirectUrl: `/upcoming-syllabus`
        },
        {
            name: "Answer Keys",
            value: "answerkeys",
            redirectUrl: `/upcoming-answerkeys`
        },
        {
            name: "Results",
            value: "results",
            redirectUrl: `/upcoming-results`
        },
        {
            name: "Cutoff",
            value: "cutoff",
            redirectUrl: `/upcoming-cutofo`
        },
        {
            name: "Admit Card",
            value: "admitcard",
            redirectUrl: `/upcoming-admitcard`
        },
        {
            name: "Admit Card",
            value: "admitcard",
            redirectUrl: `/upcoming-other`
        }
    ];

    
    const handleSearchNews = (e) => {
        const searchTxt = e.target.value;
        setPageNo(1);
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setLoading(true);
            setIsSearch(false);
            handleFetchAllNewsByPattern(1, streamId, branchId, newsPattern);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            searchNews(1, 'news', searchTxt);
        }
    }

    const searchNews = async (pNo, type, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const resSearchNews = await getSearchedData({ pageNo: pNo, type, keyword });
        setIsSearch(false);

        if (resSearchNews?.success) {
            setTotalRecordFound(resSearchNews?.total || 0)
            setIsNotFound(false)
            resSearchNews?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchNews?.data?.length && pNo == 1) {
                setNewsList(resSearchNews?.data);
            } else if (resSearchNews?.data?.length && pNo !== 1) {

                setNewsList([...newsList, ...resSearchNews?.data]);
            } else {
                if (pageNo == 1) {
                    setNewsList([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }
    return (<>
        {/* <BreadCrumbs /> */}
        <div className='d-flex align-items-center justify-content-between'>
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>                    
                    <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={'/career-news/top-10-trending-branches-exams'} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: `top 10 trending branches exams` }}
                            />                       
                    </li>
                    {
                        params?.nSlug?null: <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={`/career-news/top-10-trending-branches-exams/${params?.streamId}/${params?.branchId}/${params?.branchSlug}`} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: params?.branchSlug || params?.nSlug}}
                            />                       
                    </li>
                    }
                   
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(params?.newsPattern || params?.nSlug+'-'+params?.patternSlug)}
                    </li>
                    
      </ol>
    </nav>
            {/* <div className="search-boxs px-2 d-flex align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchNews} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search News" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchNews(1, 'news', searchTerm)}></i>
            </div> */}
        </div>
        
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Latest News on upcoming Exam dates, eligibility, application form, syllabus, admit card, results and pattern for Session ${yearSatra()}</strong>`} subTitle={`Read latest news on popular Public / Private Sector Job exams, University/College/Degree/Course Entrance Exams, other Competition exams and job alerts in ${yearSatra()}. Practice for Banking, Railways, Defense, Civil Services entrance and more in Question Bank. Prepare for any job interview with Interview Questions`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <>
                        {
                            newsList?.map((newsData, sIndex) => (                             
                                <NewsListItem
                                key={sIndex} 
                                stripBottom={params?.nSlug?true:false}
                                newsData={newsData}
                                patternLinks={popularCareerCats}  
                                type="newsbypattern" 
                                redirectionFn={(e)=>{e.preventDefault();e.stopPropagation();navigation(`/career-news/news/${newsData?.id}/${newsData?.slug}`)}}
                                />
                            ))
                        }

                    </>
                    }
                </div>
                
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                   
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default NewsList;
