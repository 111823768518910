import React from 'react'
import {  nFormatter } from '../../../../utils/utils';
import corporateThumbPlaceholder from '../../../../assets/images/corporate_thumb.png';

export default function CorporateIndustryList({ content, boxSize='null', mH=null, redirectionFn, type, thumbPrefix=`thcn/category/thumb/`, sType=null }) {
    return (
        <>
        <div className={`${boxSize} mb-2 cursor-pointer hover-c-change`} onClick={()=>redirectionFn()} title={content?.name}>
            <div className={`col-md-12 col-12 py-0 col-sm-12 left-angle-border card_box ${mH?'mh-119':'mh-93'}`}>
                <div className='d-flex align-items-center' style={{maxWidth:'60%'}}>
                <span className="books_icon bg-transparent px-3">                    
                   <img src={corporateThumbPlaceholder}  width="80" height="80" style={{minWidth:50,minHeight:50}} className="feature_images rounded  overflow-hidden" alt={content?.name} />      

                </span>
                <span className="w-100 d-flex flex-column">
                    <h6 className="m-0 pr-3 ellipsis-text lquestion capitalize" title={content?.name}>
                        {content?.name || content?.keyword}
                    </h6>
                        {
                            content?.companies_count ?
                            <span className="span pb-1 color-gray text-capitalize fsize-11 c-a5">  
                          
                           {nFormatter(content?.companies_count || 0)} Companies                          
                        </span> : null
                        }
                </span>
                </div>
                <i className='fa-solid fa-arrow-right-long me-2 text-black-50' style={{opacity:0.5}}></i>
            </div>
        </div>
        </>
    )
}
