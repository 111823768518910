import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getAllBranches, getSearchedData } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import StreamBranchList from '../common/streambranchlist';
import { useNavigate, useParams } from 'react-router-dom'; 
import { useSearch } from '../../../../Context/SearchContext';
import { isArray } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

function NewsBranches() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false); 
    const [scrollLoading, setScrollLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const [allBranches, setAllBranches] = useState([]);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false); 
    
    
    const [streamId, setStreamId] = useState(0);
    const {searchValue} = useSearch();
    
    const params = useParams();

    const navigation = useNavigate();

    useEffect(() => {
        if(params?.streamId){
            setStreamId(params?.streamId);            
            handleFetchAllBranches(1, params?.streamId);
        }else{
            handleFetchAllBranches(1, 0);
        }
        
    }, [])
   

    // fetch all branches
    const handleFetchAllBranches = async (pageNo, streamId) => {
        const resAllBranches = await getAllBranches({ pageNo, streamId });
        if (resAllBranches?.success) {
            console.log('branches', resAllBranches?.data);
            setIsNotFound(false)
            resAllBranches?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllBranches?.data?.length && pageNo == 1) {
                setAllBranches(resAllBranches?.data);
            } else if (resAllBranches?.data?.length && pageNo !== 1) {
                setAllBranches([...allBranches, ...resAllBranches?.data]);
            } else {
                if (pageNo == 1) {
                    setAllBranches([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
        setScrollLoading(false);
    }

    // load more streams data
    const loadMore = () => {
        setScrollLoading(true);
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        if (searchTerm?.length) {
            searchBranch(nxtPage, 'branch', searchTerm, 'loadMore');
        } else {
            handleFetchAllBranches(nxtPage, streamId);
        }
        
        setPageNo(nxtPage);
    }

    const popularCareerCats = [
        {
            name: "Latest Govt Jobs",
            value: "Latest Govt Jobs",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Qualification",
            value: "Govt Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Location",
            value: "Govt Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Private Jobs",
            value: "Latest Private Jobs",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Qualification",
            value: "Private Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Location",
            value: "Private Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Internships",
            value: "Latest Internships",
            redirectUrl: "#"
        },
        {
            name: "Internships By Education",
            value: "Internships By Education",
            redirectUrl: "#"
        },
        {
            name: "Internships By Location",
            value: "Internships By Location",
            redirectUrl: "#"
        }
    ];

    const handleSearchBranch = (e) => {
        const searchTxt = e.target.value;
        setPageNo(1);
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false);
            handleFetchAllBranches(1, streamId);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            searchBranch(1, 'branch', searchTxt);
        }
    }

    const searchBranch = async (pNo, type, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const resSearchBranch = await getSearchedData({ pageNo: pNo, type, keyword });
        setIsSearch(true);

        if (resSearchBranch?.success) {
            // console.log('streams', resSearchBranch?.data);
            setTotalRecordFound(resSearchBranch?.total || 0)
            setIsNotFound(false)
            resSearchBranch?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchBranch?.data?.length && pNo == 1) {
                setAllBranches(resSearchBranch?.data);
            } else if (resSearchBranch?.data?.length && pNo !== 1) {
                setAllBranches([...allBranches, ...resSearchBranch?.data]);
            } else {
                if (pageNo == 1) {
                    setAllBranches([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }

    return (<>
         <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            {/* <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchBranch} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Branch" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchBranch(1, 'branch', searchTerm)}></i>
            </div> */}
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<> <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={params?.streamId?`<strong>Latest Alerts! ${slugToNormal(params?.streamSlug)} Exams, Syllabus, Pattern, Results and Jobs For Session  ${yearSatra()}</strong>`:`<strong>Top 10 Trending Branches Exams For Session ${yearSatra()}</strong>`} subTitle={`Read 100+ news items on all career branches covering latest updates on all competitive, Public / Private Sector Job and entrance exams in ${yearSatra()}. Practice for all career entrance and job exams in Question Bank. Prepare for any job interview with Interview Questions.`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                   
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                                <InfiniteScroll
                                    dataLength={allBranches?.length}
                                    next={() =>loadMore()}
                                    hasMore={showLoadMore}
                                    loader={scrollLoading && (
                                    <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                                        <div className="h-10 w-10">
                                        <Loadingicon />
                                        </div>
                                    </div>
                                    )}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={90}
                                    refreshFunction={() => {
                                    setPageNo(1);
                                    setAllBranches([]);
                                    }}
                                    scrollableTarget={"qBank"}
                                    className={"relative"}
                                >
                                    <div className="py-8 grid pr-2 p-md-4 px-3 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom" style={{ overflow: "auto" }} id="qBank">
                                    {isArray(allBranches) && allBranches.map((stream, sIndex) => (<>
                                        <StreamBranchList stream={stream} key={sIndex} boxSize='col-md-12' type="branches" redirectionFn={()=>navigation(`/career-news/top-10-trending-branches-exams/${stream?.parent_id}/${stream?.id}/${stream?.slug}`)}/>
                                    </>
                                    ))}
                                    </div>
                                </InfiniteScroll>
                        {/* {
                            allBranches?.map((stream, sIndex) => (
                                <StreamBranchList stream={stream} key={sIndex} boxSize='col-md-3' type="branches" redirectionFn={()=>navigation(`/career-news/top-10-trending-branches-exams/${stream?.parent_id}/${stream?.id}/${stream?.slug}`)}/>
                            ))
                        } */}

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    
                      <PopularCareerCategories catsList={popularCareerCats} title={'Popular Categories'}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default NewsBranches;
