import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
// import StarRatings from 'react-star-ratings';
import { useAuth } from '../../../../Context/AuthContext';
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    placeholderImages,
    CDN_BASE_URL,
    fetchUserInfo,
    viewRecentVisits
} from '../../../../utils/utils';
import {
    bookMarkExam,
    likeUnlikeDislike,
    writeReviewExam
} from '../../../../api/exam';
import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import LyricsComments from '../../../common/comments/lyricsComments';
import styles from "../../../../assets/css/styles/Home.module.css";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import { getBlogDetails } from '../../../../api/blog';
import BlogsComments from '../../../common/comments/blogsComments';
import * as Yup from "yup";
import StarRatings from 'react-star-ratings';
import { Formik } from 'formik';
import { getExamDetails } from '../../../../api/exam';
import './TabSection.css';

function ExamDetail() {
    const { globalToken, setIsEmailVerifyCover, user, setUser, setFinance } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [examDetailData, setExamDetailData] = useState([]);
    const [posterImageBaseURL, setPosterImageBaseURL] = useState();
    const [examSlug, setExamSlug] = useState();
    const [isLocationChange, setIsLocationChange] = useState(false);
    const[nextPrevLoader, setNextPrevLoader] = useState(false);
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const [isLiked, setIsLiked] = useState(false);
    const [isFav, setIsFav] = useState(0);
    const [avgRating, setAvgRating] = useState(0);
    const [isDisliked, setIsDisliked] = useState(false);
    const[ratingLoading, setRatingLoading] = useState(false);
    const[reportLoading, setReportLoading] = useState(false);
    const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);
    const [reportVal, setReportVal] = useState(0);
    const [ratingVal, setRatingVal] = useState(0);
    const [cat, setCat] = useState('');
    const [sCat, setSCat] = useState('');
    const [activeTab, setActiveTab] = useState('About');

    const iconShareRef = useRef(null);
    const shareRef = useRef(null);
    
    const DisplayingErrorMessagesSchemaRating = Yup.object().shape({
        rating: Yup.string().required("Rating is required"),
        reviews: Yup.string().required("Review is required")

    });
    const DisplayingErrorMessagesSchemaReport = Yup.object().shape({
        report: Yup.string().required("Report message is required")

    });

    // Fetches lyrics details when component mounts or params change
    useEffect(() => {
        if (params?.examSlug) {
            setExamSlug(params?.examSlug);
            handleFetchExamDetail(params?.examSlug);
            viewRecentVisits(globalToken);
        }
    }, []);

    useEffect(() => {
        if (params?.examSlug) {
            setExamSlug(params?.examSlug);
            handleFetchExamDetail(params?.examSlug);
        }
    }, [isLocationChange]);


    const openHideRatingModal = () =>{
        if(isOpenRatingModal){
            setRatingVal(0)
        }
        setIsOpenRatingModal(!isOpenRatingModal);
    }

    const openHideReportModal = () =>{
        setIsOpenReportModal(!isOpenReportModal);
    }

    const changeRating = (newRating) => {
        setRatingVal(newRating);
      };
    const handleReviewRating = async (values) =>{
        setRatingLoading(true);
        const resReviewRating = await writeReviewExam({token:globalToken, examId:examDetailData?.id, reviews:values?.reviews, rating:values?.rating});
        // alert(JSON.stringify(resReviewRating))
        // console.log(resReviewRating?.id)
        if(resReviewRating?.success){
             handleFetchExamDetail(examDetailData.slug);
            toast.success('Review & Rating given');
            openHideRatingModal(false);
        }else {
          if(resReviewRating?.status==5){
            toast.error(resReviewRating?.errors);
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
        }else{
          toast.error(resReviewRating?.errors || ERROR_SOMETHING_WENT_WRONG);
        }
      }
        setRatingLoading(false); 
    }

    const handleReport = async (values) =>{
        // setReportLoading(true);
        // // const resReviewRating = await reportAgainstBlog({token:globalToken, articleId:examDetailData?.id, report:values?.report});
        // // alert(JSON.stringify(resReviewRating))
        // if(resReviewRating?.success){
        //      handleFetchExamDetail(examDetailData.id);
        //     toast.success('Report registered');
        //     openHideReportModal(false);
        // }else{
        //     toast.error(ERROR_SOMETHING_WENT_WRONG)
        // }
        // setReportLoading(false); 
    }

    // Handles fetching lyrics details by lyrics ID
    const handleFetchExamDetail = async (examSlug) => {
        const resExamsDetail = await getExamDetails({ token : globalToken, slug : examSlug });
        setIsLiked(resExamsDetail?.exam?.isUserLike);
        setIsDisliked(resExamsDetail?.exam?.isUserDisLike);
        setIsFav(resExamsDetail?.exam?.is_favourite);
        setAvgRating(resExamsDetail?.exam?.avg_rating);
        setLoading(false);
        setLoadingLoadMore(false);
        if (resExamsDetail?.isExam) {
            setCat(resExamsDetail?.subject)
            setSCat(resExamsDetail?.topic)
            console.log('blog detail', resExamsDetail);
            setIsNotFound(false);
            if (resExamsDetail?.exam) {
                setExamDetailData(resExamsDetail?.exam);
            } else {
                setExamDetailData({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false);
    }

    const tabContentHTML = {
        About: `<h2>ABOUT</h2>${examDetailData?.about} ${examDetailData?.description}`,
        Syllabus: `<h2>SYLLABUS</h2>${examDetailData?.syllabus}`,
        Pattern: `<h2>PATTERN</h2>${examDetailData?.pattern}`,
        Important_Dates: `<h2>IMPORTANT DATES</h2>${examDetailData?.imp_dates}`,
      };

    // Handles adding/removing lyrics from favorites
    const FavouriteButton = ({ is_fav_camp }) => {
        const [favBtnLoading, setFavBtnLoading] = useState(false);

        const handleExamFav = async () => {
            setFavBtnLoading(true);
            const token = globalToken;
            const res = await bookMarkExam({ token: token, examId: examDetailData.id, favourite: is_fav_camp ? '0' : '1' });
            if (res?.success) {
              console.log("res", res)
                handleFetchExamDetail(examDetailData.slug);
            }
            setFavBtnLoading(false);
        }

        return (
            <button
                type="button"
                disabled={favBtnLoading}
                onClick={handleExamFav}
                className="btn bg-white p-0 d-flex btn-like-circular"
                style={{ border: 'none', boxShadow: 'none' }}
            >
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : (
                    <>
                        {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
                    </>
                )}
            </button>
        );
    }

    // Handles sharing the lyrics
    const InlineShare = ({ examsData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                  <span ref={shareRef}>
                    <Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubExams}/detail/${examsData?.slug}`,
                            quote: `${examsData?.description}`,
                            hashing: `#tuteehub`,
                            title: `${examsData?.title}`,
                            id:examsData?.id,
                            slug:examsData?.slug
                        }}
                        isCustomPos={true}
                        isShare={isShareActive} setIsShare={setIsShareActive} iconShareRef={iconShareRef} shareRef={shareRef}
                        refetchDetails={handleFetchExamDetail}
                    />
                    </span>
                ) : null}
                <span
                    ref={iconShareRef}
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

    // Handles upvoting or downvoting the lyrics
    const handleLikeUnlikeDislike = async (voteType, type) => {
        // console.log(voteType)
        if (type === 'dLike') setDownvoteBtnLoader(true);
        else setUpvoteBtnLoader(true);
        const resLikeUnlike = await likeUnlikeDislike({ token:globalToken, examId: examDetailData.id, voteType });
        if (resLikeUnlike?.success) {
            handleFetchExamDetail(examDetailData.slug);
            toast.success(resLikeUnlike?.data?.message);
            fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
        }else {
          if(resLikeUnlike?.status==5){
            toast.error(resLikeUnlike?.errors);
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
        }else{
          toast.error(resLikeUnlike?.errors || "Something went wrong!");
        }
      }
      setDownvoteBtnLoader(false)
      setUpvoteBtnLoader(false);
    }



    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };

    const modifyAnchorTagsSelf = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_self');
        });
        return doc.documentElement.innerHTML;
    };

    return (
      <>
        {loading ? (
          <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
            <div className="h-10 w-10 theme-color">
              <Loadingicon />
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between mb-3">
              <nav
                aria-label="breadcrumb"
                className="pt-0 px-0 pb-0 breadcrumb-card"
              >
                <ol
                  className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea"
                  style={{ display: "flex", flexWrap: "nowrap" }}
                >
                  <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink
                      to="/"
                      style={{ fontSize: 14, textDecoration: "none" }}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink
                      to="/exams"
                      style={{ fontSize: 14, textDecoration: "none" }}
                    >
                      Exams
                    </NavLink>
                  </li>
                  <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink
                      to={`/exams/${cat?.slug}`}
                      style={{ fontSize: 14, textDecoration: "none" }}
                    >
                      {cat?.subject_title}
                    </NavLink>
                  </li>
                  <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink
                      to={`/exams/topic/${sCat?.slug}`}
                      style={{ fontSize: 14, textDecoration: "none" }}
                    >
                      {sCat?.topic_name}
                    </NavLink>
                  </li>
                  <li
                    className={`breadcrumb-item capitalize active clamping ellipsis`}
                  >
                    {slugToNormal(params.examSlug)}
                  </li>
                </ol>
              </nav>
              {/* <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/my-blogs')}
                            >
                                My Blogs
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/post-a-blog')}
                            >
                            Post A Blog
                            </button>                    
                    </div> */}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                  <div className="px-0">
                    <div className="flex-column d-flex flex-md-row justify-content-between">
                      <div className="mr-md-5 pr-md-5 flex-1">
                        <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                          <strong>{examDetailData?.title}</strong>
                          <span className="d-flex gap-3 align-items-center">
                            <FavouriteButton is_fav_camp={isFav} />
                            <InlineShare
                              examsData={examDetailData}
                              isShareActive={isShareActive}
                              setIsShareActive={setIsShareActive}
                            />
                          </span>
                        </h1>
                        <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">
                          <span className="text-black-50 gig-table items-center d-md-flex">
                            <small style={{ marginTop: 1, float: "left" }}>
                              <StarRatings
                                rating={avgRating || 0}
                                starRatedColor="#F08645"
                                changeRating={null}
                                numberOfStars={5}
                                starDimension="15px"
                                starSpacing="0"
                                name="rating"
                              />
                            </small>
                            <span className="big-dots"></span>
                            <span>
                              {examDetailData?.total_rating} {examDetailData?.total_rating >1 ? "ratings" : "rating"}
                            </span>
                            {/* <a className="a-hover cursor-pointer text-black-50" onClick={()=>alert(1)}>{lyricsDetailData.album?.name}</a> */}
                            <span className="big-dots"></span>
                            {/* <a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/blogs/${examDetailData?.cat?.slug}`)}>{examDetailData?.cat?.name}</a>
                                            <span className="big-dots"></span>
                                             <a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/blogs/topic/${examDetailData?.scat?.slug}`)}>{examDetailData?.scat?.name}</a>
                                             <span className="big-dots"></span> */}
                            <span>
                              {nFormatter(examDetailData?.views)} views
                            </span>
                            
                            <span className="big-dots"></span>
                            <span>{examDetailData?.likes_count} up votes</span>
                            {upvoteBtnLoader ? (
                              <span className="custom-loader-small ms-2 theme-color">
                                <Loadingicon />
                              </span>
                            ) : (
                              <>
                                <i
                                  className={`fas fa-thumbs-up cursor-pointer ms-2 ${
                                    isLiked && "text-themecolor"
                                  }`}
                                  onClick={() =>
                                    handleLikeUnlikeDislike(
                                      isLiked ? "unlike" : "like",
                                      "lke"
                                    )
                                  }
                                ></i>{" "}
                              </>
                            )}
                            <span className="big-dots"></span>
                            <span>
                              {examDetailData?.dislikes_count} down votes
                            </span>
                            {downvoteBtnLoader ? (
                              <span className="custom-loader-small ms-2 theme-color">
                                <Loadingicon />
                              </span>
                            ) : (
                              <>
                                <i
                                  className={`fas fa-thumbs-down cursor-pointer ms-2 ${
                                    isDisliked && "text-themecolor"
                                  }`}
                                  onClick={() =>
                                    handleLikeUnlikeDislike(
                                      isDisliked ? "remove" : "dislike",
                                      "dLike"
                                    )
                                  }
                                ></i>{" "}
                              </>
                            )}
                            <span className="big-dots"></span>
                            <span>
                              {nFormatter(examDetailData?.share_count)} shares
                            </span>
                            <span className="big-dots"></span>
                            <span>
                              {convertDateFormat(
                                examDetailData?.posting_date ||
                                  examDetailData?.created_at
                              )}
                            </span>
                          </span>
                          {/* <span className="text-black-50">Published on {convertDateFormat(examDetailData?.posting_date)}</span> */}
                        </div>
                        <div className="col-span-12 px-md-3 px-0 question-box question-box-card py-2 mt-3 mb-3 mb-1">
                          <button
                            className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                            onClick={() => {
                              if(user?.email_verified==1){
                                openHideRatingModal()
                              }else{
                                toast.error('Please verify your email');
                                setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                              }
                            }}
                          >
                            Write A Review
                          </button>
                          {/* <button
                                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                onClick={() => openHideReportModal()}
                                            >
                                                Report
                                            </button> */}
                          {/* <a
                                                download
                                                href={`${isHindiNews?'https://careernews.tuteehub.com/download-upcoming-hindi-news':'https://careernews.tuteehub.com/download-upcoming-career-news/latest-scheme-exam-news.pdf'}`}
                                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                onClick={() => alert('PDF Download')}
                                            >
                                                Download As PDF
                                            </a> */}
                        </div>
                        <hr className="heading-hrs mb-0 opacity-5" />
                        <div className="thumbnail-containers rounded w-100">
                          {" "}
                          {examDetailData?.image_url ? (
                            <img
                              src={examDetailData?.image_url}
                              className="rounded lazyload w-100 h-auto"
                              alt="thumbnail-containers"
                            />
                          ) : (
                            <img
                              src={placeholderImages}
                              className="rounded lazyload w-100 h-auto"
                              alt="thumbnail-containers"
                            />
                          )}
                          <div class="stats-container">
                            <div class="stat-item">
                              <div class="stat-title">TOTAL QUESTIONS</div>
                              <div class="stat-value">{examDetailData?.total_questions}</div>
                            </div>
                            <div class="stat-item">
                              <div class="stat-title">TOTAL MARKS</div>
                              <div class="stat-value">{examDetailData?.total_marks}</div>
                            </div>
                            <div class="stat-item">
                              <div class="stat-title">CUT-OFFS</div>
                              <div class="stat-value">{examDetailData?.pass_percentage}%</div>
                            </div>
                            <div class="stat-item">
                              <div class="stat-title">ALLOTED TIME</div>
                              <div class="stat-value">{examDetailData?.duration} Minutes</div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mt-3">
                            <button
                              className="btn btn-primary"
                              style={{
                                width: "60%",
                                height: "50px",
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                if(user?.email_verified==1){
                                  navigation(
                                    `/exams/take-exam/${examDetailData?.slug}`
                                  );
                                }else{
                                  toast.error('Please verify your email');
                                  setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                }                               
                              }}
                            >
                              Start Exam
                            </button>
                          </div>
                        </div>

                        <div>
                          {/* Tab Container */}
                          <div className="exam-tab-container">
                            {Object.keys(tabContentHTML).map((tab) => (
                              <div
                                key={tab}
                                className={`tab ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                onClick={() => setActiveTab(tab)}
                              >
                                {tab}
                              </div>
                            ))}
                          </div>

                          {/* Content Area with HTML Rendering */}
                          <div
                            className="tab-content w-100 text-black-60 mt-4 leads cn-desc"
                            dangerouslySetInnerHTML={{
                              __html: modifyAnchorTags(
                                tabContentHTML[activeTab]
                                  ?.replace(/<p>&nbsp;<\/p>/g, "")
                                  .trim()
                              ),
                            }}
                          ></div>
                        </div>
                        {/* <hr/>
                                        <div className="flex justify-between px-8 mb-3">
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor: !qDetail?.previous_question_id && !nextPrevIds(qDetail?.previous_url) ? '#ccc' : '#ff7200' }}
                                    disabled={(!qDetail?.previous_question_id && !nextPrevIds(qDetail?.previous_url)) || btnLoader}
                                    onClick={() => navigation()}
                                >Previous</button>
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor: !qDetail?.previous_question_id && !nextPrevIds(qDetail?.next_url) ? '#ccc' : '#ff7200' }}
                                    disabled={(!qDetail?.next_question_id && !nextPrevIds(qDetail?.next_url)) || btnLoader}
                                    onClick={() => }
                                >Next</button>
                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {examDetailData?.faq ? (
                  <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                    <div className="heading-main position-relative my-3 overflow-hidden">
                      <h5 className="text-left bg-white pr-3 b">
                        <strong>FAQ</strong>
                      </h5>
                      <hr className="heading-hr mb-0" />
                    </div>
                    {JSON.parse(examDetailData?.faq)?.map((fq, i) => (
                      <div key={i + "faq"} className="faq-item">
                        <h6>
                          <strong>{fq?.question}</strong>
                        </h6>
                        <p dangerouslySetInnerHTML={{ __html: fq?.answer }}></p>
                        <hr style={{ borderColor: "#ccc" }} />
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className="flex justify-between mt-4">
                  <button
                    id="nextprevbtn"
                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                    style={{
                      backgroundColor: !examDetailData?.previous_url
                        ? "#ccc"
                        : "#ff7200",
                    }}
                    disabled={examDetailData?.previous_url ? false : true}
                    onClick={() => {
                      navigation(
                        `/exams/detail/${examDetailData?.previous_url?.slug}`
                      );
                      setIsLocationChange(!isLocationChange);
                    }}
                  >
                    {nextPrevLoader ? (
                      <div className="h-5 w-5 mr-2">
                        <Loadingicon />
                      </div>
                    ) : (
                      <>Previous</>
                    )}
                  </button>
                  <button
                    id="nextprevbtn"
                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                    style={{
                      backgroundColor: !examDetailData?.next_url
                        ? "#ccc"
                        : "#ff7200",
                    }}
                    disabled={examDetailData?.next_url ? false : true}
                    onClick={() => {
                      navigation(
                        `/exams/detail/${examDetailData?.next_url?.slug}`
                      );
                      setIsLocationChange(!isLocationChange);
                    }}
                  >
                    {nextPrevLoader ? (
                      <div className="h-5 w-5 mr-2">
                        <Loadingicon />
                      </div>
                    ) : (
                      <>Next</>
                    )}
                  </button>
                </div>

                {/* <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({examDetailData?.comments_count})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <BlogsComments cData={examDetailData} handleSubmitComment={handleSubmitComment}/>
                        </div> */}
                {/* :null
                        } */}
              </div>
              <div className="col-md-12">
                <div className="col-md-12 my-4 w-full  border-none child-menu   card-body">
                  {/* <SimilarNews newsSlug={examDetailData?.slug} patternLinks={patternLinks} redirectionFn={()=>alert(1)} stripBottom={false}/> */}
                </div>
              </div>
            </div>
          </>
        )}

        <Modal
          closeModal={openHideRatingModal}
          setIsOpen={setIsOpenRatingModal}
          isOpen={isOpenRatingModal}
          callBack={openHideRatingModal}
          title={"Write A Review"}
          modalFooter={true}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{
              reviews: null,
              rating: null,
            }}
            validationSchema={DisplayingErrorMessagesSchemaRating}
            onSubmit={(values) => {
              if (values) {
                handleReviewRating(values);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <form className="" onSubmit={handleSubmit}>
                <div className="mb-2">
                  <textarea
                    disabled={false}
                    type={"text"}
                    name={"reviews"}
                    autoComplete={"off"}
                    id={"reviews"}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder={"Write A Review"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={5}

                    // value={}
                  ></textarea>
                  <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                    {touched["reviews"] &&
                      errors["reviews"] &&
                      errors["reviews"]}
                  </div>
                </div>
                <div className="mb-3 text-center">
                  <StarRatings
                    rating={ratingVal}
                    starRatedColor="#F08645"
                    starHoverColor="rgb(253 160 104)"
                    changeRating={(newRating) => {
                      changeRating(newRating);
                      setFieldValue("rating", newRating);
                    }}
                    numberOfStars={5}
                    starDimension="35px"
                    starSpacing="5px"
                    name="rating"
                  />
                  <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                    {touched["rating"] && errors["rating"] && errors["rating"]}
                  </div>
                </div>
                <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
                  {/* <button
                    type="button"
                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                    onClick={() => {
                      openHideRatingModal();
                    }}
                  >
                    Cancel
                  </button> */}
                  <button
                    type="submit"
                    disabled={ratingLoading}
                    className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                    // onClick={()=>handleAcceptRejectCancel()}
                  >
                    {ratingLoading ? (
                      <div className="h-5 w-5">
                        <Loadingicon />
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal>

        {/* modal for report */}

        <Modal
          closeModal={openHideReportModal}
          setIsOpen={setIsOpenReportModal}
          isOpen={isOpenReportModal}
          callBack={openHideReportModal}
          title={"Report Against Company"}
          modalFooter={true}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{
              report: null,
            }}
            validationSchema={DisplayingErrorMessagesSchemaReport}
            onSubmit={(values) => {
              if (values) {
                handleReport(values);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <form className="" onSubmit={handleSubmit}>
                <div className="mb-2">
                  <textarea
                    disabled={false}
                    type={"text"}
                    name={"report"}
                    autoComplete={"off"}
                    id={"report"}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder={"Write message"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={5}

                    // value={}
                  ></textarea>
                  <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                    {touched["report"] && errors["report"] && errors["report"]}
                  </div>
                </div>

                <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
                  <button
                    type="button"
                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                    onClick={() => {
                      openHideReportModal();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={reportLoading}
                    className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                    // onClick={()=>handleAcceptRejectCancel()}
                  >
                    {reportLoading ? (
                      <div className="h-5 w-5">
                        <Loadingicon />
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      </>
    );
}


export default ExamDetail;
