import React from 'react'
import notFound from '../../../assets/images/not_found_new.png';


const NotFound = ({message}) => {
    return (
        <div className="flex-column not-found d-flex text-center">
            <img src={notFound} className="notFoundImg" />
            <span style={{ opacity: 0.7 }}>{message}</span>
        </div>
    )
}
export default NotFound;
