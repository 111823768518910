import React from 'react'
import { CDN_BASE_URL, nFormatter } from '../../../../utils/utils';
import lyricThumbPlaceholder from '../../../../assets/images/lyric_thumb.png';

export default function laDataArtistList({ laData, mH = false, boxSize = null, redirectionFn, type, thumbPrefix = type=='lyricist'?`showtalent/images/lyrics/lyricists/thumbs/`:`showtalent/images/lyrics/artists/thumbs/`, sType = null }) {
    return (

        <div className={`${boxSize} mb-2 cursor-pointer hover-c-change`} onClick={() => redirectionFn()} title={laData?.name}>
           
         
            <div className={`col-md-12 col-12 py-0 col-sm-12 left-angle-border card_box ${mH ? 'mh-119' : 'mh-93'}`}>
                <div className='d-flex align-items-center' style={{maxWidth:'90%'}}>
                    <span className="books_icon bg-transparent px-3" style={{minWidth:50,minHeight:50}}>
                        {
                        // CDN_BASE_URL + thumbPrefix + laData?.image
                            laData?.image ? <img alt={laData?.name} className="thumbnail rounded" style={{minWidth:50,minHeight:50}} width="80" height="80" src={lyricThumbPlaceholder} /> : <img src={lyricThumbPlaceholder} width="80" height="80" style={{minWidth:50,minHeight:50}} className="feature_images rounded  overflow-hidden" alt={laData?.name} />
                        }
                    </span>
                    <span className="w-100 overflow-hidden d-flex flex-column">
                        <h6 className="m-0 pr-3 ellipsis-text lquestion" title={laData?.name}>
                            {laData?.name}
                        </h6>
                        <span className="span pb-1 color-gray text-capitalize fsize-11 c-a5">
                            { <>{nFormatter(laData?.lyric_count)} Lyrics
                                </>
                            }
                        </span>
                    </span>
                </div>
                <i className='fa-solid fa-arrow-right-long me-2 text-black-50' style={{ opacity: 0.5 }}></i>
            </div>
        </div>
    )
}
