import React from 'react';

const Controls = ({ onSaveAndNext, onClearResponse, onMarkForReview, onSubmitTest, isFullscreen, isLastQ=false }) => (
  <div className={`controls-footer ${isFullscreen ? 'fullscreen' : ''}`}>
    <div className="left-controls">
      <button className="clear-response-btn" onClick={onClearResponse}>Clear Response</button>
      <button className="mark-for-review-btn" onClick={onMarkForReview}>Mark for Review & Next</button>
    </div>
    <div className="right-controls">
      <button className="save-next-btn" onClick={onSaveAndNext}>
        Save & Next <span className="arrow">→</span>
      </button>
    </div>
  </div>
);

export default Controls;
