import {  EX_BASE_URL } from "../../utils/utils";

export const getExamCategory = (params) => {
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/list?page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getExamSubCategory = (params) => {
    const { pageNo, catId } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {      
        method: "GET",
        headers: myHeaders,
    };
    const url = catId?`${EX_BASE_URL}exams/subject/${catId}?page=${pageNo}`:`${EX_BASE_URL}exams/subject?page=${pageNo}`;
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getExamListing = (params) => {
    const { pageNo, subcatId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/topic/${subcatId}?page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getSearchedData = (params) => { 
    const { keyword } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {      
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/global-search?keyword=${keyword}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const getSearchedList = (params) => { 
    const { page , keyword } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let formData = new FormData();
    formData.append('keyword', keyword);
    // formData.append('type', type);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${EX_BASE_URL}exams/search-list?page=${page}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const getExamDetails = (params) => { 
    const { token, slug } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {      
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/mocktest/${slug}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const getFavouriteExams = (params) => { 
    const { token, pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {      
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/favourite/exam?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const likeUnlikeDislike = (params) => {
    const { examId, voteType, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    // formData.append('user_id', user_id);
    formData.append('voteType', voteType)
    formData.append('exam_id', examId);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    return fetch(`${EX_BASE_URL}exams/vote`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const bookMarkExam = (params) => { 
    const { examId, favourite, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('exam_id', examId);
    formData.append('favourite', favourite);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${EX_BASE_URL}exams/favourite/add`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const writeReviewExam = (params) => {
    const { examId, reviews, rating, token } = params;
    // alert(JSON.stringify(params))
    // return;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("exam_id", examId);
    formData.append("description", reviews);
    formData.append("rating", rating);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${EX_BASE_URL}exams/save-rating`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
  };

  export const getExamInstructions = (params) => {
    const { examSlug } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/quiz/take-exam/${examSlug}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getExamData = (params) => {
    const { token, examId } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/start/${examId}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const submitExam = (params) => {
    const {answersPayload, token, examSlug } = params;
    // alert(JSON.stringify(params))
    // return;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
  Object.entries(answersPayload).forEach(([key, value]) => {
    formData.append(key, value);
  });
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${EX_BASE_URL}exams/finish-exam/${examSlug}`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
  };

  export const getMyExamData = (params) => {
    const { token, startDate, endDate } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let url = `${EX_BASE_URL}exams/user-performance`; 
    if (startDate && endDate) {
        url += `?start_date=${startDate}&end_date=${endDate}`;
    }
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getExamResult = (params) => {
    const { token, id } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${EX_BASE_URL}exams/user-exam-results/${id}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};