import { useEffect, useState } from "react";
import { useNavigate, useNavigation, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { getAllTaskNotifications, getAllUnreadNotifications, markAsReadNotification } from "../../../../api/OurService";
import notFound from '../../../../assets/images/not_found_new.png';
import { formatDate, slugify } from "../../../../utils/utils";
import Modal from "../../../common/Modal";



function ShortTaskNotifications() {
    const [loading, setLoading] = useState(true);
    const [moreLoader, setMoreLoader] = useState(false);
    const [allNotifications, setAllNotifications] = useState([]);
    const [allNotificationResponse, setAllNotificationResponse] = useState({});
    const [isOpenNotificationDetail, setIsOpenNotificationDetail] = useState(false);
    const [choosenNotification, setChoosenNotification] = useState();
    const [preSelectedNotificationId, setPreSelectedNotificationId] = useState();

    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate()
    const { user, gigToken, setUnreadNotifications, globalToken } = useAuth();

    useEffect(() => { 
        if(localStorage?.getItem('noti')){
            setChoosenNotification(JSON.parse(localStorage?.getItem('noti')));
            handleNotificationDetail(JSON.parse(localStorage?.getItem('noti')));
        }
        fetchAllNotifications(pageNumber) 
    
    }, [])


    const fetchUnreadNotification = async () => {
        const res = await getAllUnreadNotifications({token:globalToken});      
        if(res?.success){
            setUnreadNotifications(res?.data);
        }else{

        }
    }

    const openHideNotificationDetailModal = () => {
        setIsOpenNotificationDetail(!isOpenNotificationDetail);
    }
    const fetchAllNotifications = async (pNumber) =>{

        const res = await getAllTaskNotifications({page:pNumber, token:globalToken});
        // console.log(res)
        if(res?.success){
            setAllNotificationResponse(res?.data?.data);
            if(pNumber!==pageNumber){
                setAllNotifications([...allNotifications, ...res?.data?.data])
                setPageNumber(pNumber);
            }else{
                setAllNotifications(res?.data?.data)
            }
        }
        setMoreLoader(false);
        setLoading(false);
    }

    const loadMoreNotifications = () =>{
        setMoreLoader(true)
        const nxtPage = pageNumber + 1;
        fetchAllNotifications(nxtPage);
    }

    const handleNotificationDetail = async (choosenNoti) =>{
        if(choosenNoti?.viewed==0){
        const res = await markAsReadNotification({token:globalToken, notification_id:choosenNoti?.id});
        }
        // console.log(choosenNoti)
        openHideNotificationDetailModal();
        fetchAllNotifications(pageNumber); 
        fetchUnreadNotification();
        localStorage.removeItem('noti')
    }

    const customTitle = (n, t) => {
        if (!t) return null; // Handle case where 't' is undefined or null
    
        const splitTitle = t.split('of campaign');
       
        const hasSecondPart = splitTitle.length > 1; // Check if split resulted in two parts
        const url = n?.url;
        const urlParams = new URLSearchParams(new URL(url).search);
        const taskId = urlParams.get('task_id');
        return (
            <>
                {splitTitle[0]} of campaign{' '}
                {hasSecondPart && <Link to={`/gigs/review-task/${slugify(splitTitle[1])}-${taskId}`}>{splitTitle[1]}</Link>}
            </>
        );
    };
    return (
        <>
            <BreadCrumbs />

            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="px-3">
                            
                            <div className="row">

                                <div className="col-md-12">

                                    {
                                        allNotifications?.length ? allNotifications?.map((notification, index) => (
                                            <div key={'sNoti'+index} className="card mb-3 d-flex w-100 flex-row justify-content-between align-items-center p-3 shadow top-leader-board-container" style={{ borderColor: '#f0f0f0' }}>
                                                <div className="me-3">
                                                    <i className="fa fa-bell" style={{color:'#ccc'}}></i>
                                                </div>
                                                <div className="flex-1">
                                                    <h6 className="mb-0"><a onClick={()=>{setChoosenNotification(notification);handleNotificationDetail(notification)}} style={{cursor:'pointer'}} className="text-theme-color text-black">{notification?.notification_text}</a></h6>
                                                    <small className="text-black-50">{formatDate(notification?.created_at)}{notification?.viewed==0?<span className="label-warning"> | unread</span>:null}</small>                                                
                                                </div>

                                            </div>
                                        )) : (
                                            <div className="flex-column not-found d-flex text-center">
                                                <img src={notFound} className="notFoundImg" />
                                                <span style={{ opacity: 0.7 }}>No notifications</span>
                                            </div>
                                        )
                                    }
                                    {
                                        allNotificationResponse?.next_page_url ?
                                            <div className="text-center w-100 mt-4">
                                                <button id="loadMoreBtn" disabled={moreLoader} type="button" onClick={() => loadMoreNotifications()} className="btn text-white m-auto d-flex justify-content-center">
                                                    {moreLoader ? (
                                                        <div className="h-5 w-5">
                                                            <Loadingicon />
                                                        </div>
                                                    ) : (
                                                        "Load More"
                                                    )}
                                                </button>
                                            </div> : null
                                    }


                                </div>
                            </div>
                        </div>
                    </>
                )
                }
            </div>

            <Modal
                closeModal={openHideNotificationDetailModal}
                setIsOpen={setIsOpenNotificationDetail}
                isOpen={isOpenNotificationDetail}
                callBack={openHideNotificationDetailModal}
                title={''}
                modalFooter={true}
            >
              <div className="mt-5 w-100 d-block">{customTitle(choosenNotification, choosenNotification?.notification_text)}</div>
              <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
                    <button
                      type="button"
                      className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                      onClick={()=>{openHideNotificationDetailModal()}}
                    >
                      Ok
                    </button>
                   
              </div>
            </Modal>
        </>
    );
}
export default ShortTaskNotifications;
