import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTutoirialListing } from '../../../../api/tutorial';
import CompanyListItem from '../common/companylist';
import { getCompaniesByTypeWise, getSearchedData } from '../../../../api/corporate';
import { useSearch } from '../../../../Context/SearchContext';
function CorporateCompaniesList() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [typeWiseSlug, setTypeWiseSlug] = useState();
    const [filterType, setFilterType] = useState();
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const location = useLocation();
    const paramDatas = new URLSearchParams(location.search);
    const { searchValue } = useSearch();
    const navigation = useNavigate();

    const params = useParams();

    useEffect(() => {
        // const searchTerm = paramDatas?.get('searchTerm');
        // if(searchTerm){
        //     searchCompany(1, searchTerm);
        //     setSearchTerm(searchTerm);
        // } else 
        if (params?.typeWiseSlug && params?.filterType) {
            setTypeWiseSlug(params?.typeWiseSlug);
            setFilterType(params?.filterType);
            handleCompaniesByTypeWise(1, params?.filterType, params?.typeWiseSlug);
        }
    }, [])

    // useEffect(() => {
              
    //     const searchTerm = paramDatas?.get('searchTerm');
    //     if(searchTerm){
    //         searchCompany(1, searchTerm);
    //         setSearchTerm(searchTerm);
    //     }else{
    //         setSearchTerm('');
    //         setIsSearch(false)
    //         if (filterType && typeWiseSlug) {
    //         handleCompaniesByTypeWise(1, filterType, typeWiseSlug);
    //         }
    //     }
            
    // }, [searchValue])

    // fetch all companies by types(industry, state, city, locality, type)
    const handleCompaniesByTypeWise = async (pageNo, fType, tSlug) => {
        const resAllCompanyByTypeWise = await getCompaniesByTypeWise({ pageNo: pageNo, fType:fType, typeWiseSlug: tSlug });
        // console.log(resAllCompanyByTypeWise);
        if (resAllCompanyByTypeWise?.success) {
            setIsNotFound(false)
              resAllCompanyByTypeWise?.corporates?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            console.log(resAllCompanyByTypeWise);
            if (resAllCompanyByTypeWise?.corporates?.data?.length && pageNo == 1) {
                
                setAllContent(resAllCompanyByTypeWise?.corporates?.data);
            } else if (resAllCompanyByTypeWise?.corporates?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllCompanyByTypeWise?.corporates?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more blog by subcat data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
       
        if (searchTerm?.length) {
            searchCompany(nxtPage, searchTerm, 'loadMore');
        }else{
            handleCompaniesByTypeWise(nxtPage, filterType, typeWiseSlug);
        }
        setPageNo(nxtPage);
    }

    
    const handleSearchCompany = (e) => {
        const searchTxt = e.target.value;
        setPageNo(1);
        setSearchTerm(searchTxt);
       
        if (searchTxt == '') {   
            setIsSearch(false);           
            setLoading(true);      
            console.log(1)    
            handleCompaniesByTypeWise(1, filterType, typeWiseSlug);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(false);
            searchCompany(1, searchTxt);
        }
    }

    const searchCompany = async (pNo, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const resSearchCompany = await getSearchedData({ pageNo: pNo, keyword });
        setIsSearch(true);

        if (resSearchCompany?.success) {
            setTotalRecordFound(resSearchCompany?.data?.corporates?.total || 0)
            setIsNotFound(false)
            resSearchCompany?.data?.corporates?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchCompany?.data?.corporates?.data?.length && pNo == 1) {
                setAllContent(resSearchCompany?.data?.corporates?.data);
            } else if (resSearchCompany?.data?.corporates?.data?.length && pNo !== 1) {
                setAllContent([...allContent, ...resSearchCompany?.data?.corporates?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }
    return (<>
        {/* <BreadCrumbs /> */}
        <div className='d-flex align-items-center justify-content-between'>
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/corporates' style={{ fontSize: 14, textDecoration: 'none' }}>Corporates</NavLink>
                    </li>
                <li
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                        {searchTerm?slugToNormal(`Search Companies for '${searchTerm}'`) : slugToNormal(params.typeWiseSlug)}
                    </li>
                </ol>
            </nav>
            {/* <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={(e)=>handleSearchCompany(e)} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Company" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchCompany(1, searchTerm)}></i>
            </div> */}
        </div>
        
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 ${slugToNormal(typeWiseSlug)} Companies Added in ${yearSatra()}</strong>`} subTitle={`Explore ${slugToNormal(typeWiseSlug)} Corporate Directory as a rich source of business information across every location across the country. In the following listing you will find company profiles, commercial registration information and more on Indian companies, all registered as ${slugToNormal(typeWiseSlug)}.`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row row-mob'>
                        {
                            allContent?.map((content, sIndex) => (
                                <CompanyListItem companyData={content} isSearch={isSearch?true:false} filterType={filterType} redirectionFn={() => navigation(`/corporates/company/${content?.slug}-${content?.id}`)} key={isSearch?sIndex+'search':sIndex+'main'} stripBottom={true} />
                                ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>

    </>)
}

export default CorporateCompaniesList;