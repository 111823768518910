import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Loadingicon } from "../../AppIcons";

export default function Modal({
  closeModal,
  setIsOpen,
  isOpen,
  children,
  callBack,
  title,
  disabled,
  loading,
  modalFooter,
  isMaxW,
  isMaxL=false,
  isMaxximum=false
}) {
   
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto" style={{top:60}}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full ${isMaxL?'max-1300':!isMaxW ? 'max-w-md' : isMaxximum?'max-50':'max-w-xl'}  transform overflow-hidden rounded-2xl bg-white p-md-6 p-3 text-left align-middle shadow-xl transition-all`}>
                <button
                    type="button"
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                    onClick={closeModal}
                    style={{fontSize:'1.7rem'}}
                  >
                    <span className="sr-only">Close</span>
                    &times; {/* HTML entity for the cross symbol */}
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900 mb-4 pr-3 pr-md-0"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">{children}</div>
                  {
                    !modalFooter? 
                    <div className="mt-4 modal-footer gap-2 mt-10 flex justify-end">
                    {/* <button
                      type="button"
                      className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                      onClick={closeModal}
                    >
                      Cancel
                    </button> */}
                    <button
                      type="button"
                      className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                      onClick={callBack}
                      disabled={disabled}
                    >
                      {loading ? (
                        <div className="h-5 w-5">
                          <Loadingicon />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>:null
                  }
                 
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
