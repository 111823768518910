import { isArray, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { getQuestions, globalSearchQuestion } from "../../../api/OurService";
import { Hearticon, Loadingicon } from "../../../AppIcons";
import { useAuth } from "../../../Context/AuthContext";
import BreadCrumbs from "../../common/BreadCrumbs";
import Nodata from "../../common/NoData";
import { parseQuestions, stripHtmlTags } from "../../../utils/utils";
import { useSearch } from "../../../Context/SearchContext";

function QuestionsSubCategories(props) {
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { listData, setListData, searchValue, setSearchValue } = useSearch();
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const { qToken, iQToken, ffToken, dcToken, globalToken } = useAuth();
  const { state } = useLocation();

  useEffect(() => {
    return () => localStorage.setItem('feedDetail', 'feedDetail');
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setListData([]);
    if (!state) {
      navigate("/");
    } else {
      getBankList(true);
    }
  }, [searchValue]);

  useEffect(() => {
    if (currentPage > 1) {
      getBankList();
    }
  }, [currentPage]);

  const getBankList = async (isFirstLoad = false) => {
    

    const token = globalToken;
    if (!!state) {
      if (isFirstLoad) {
        setLoading(true);
        setInitialLoad(true);
      }
      setScrollLoading(true);
      try {
        const res = (state?.comingFrom || searchValue)
          ? await getQuestions(state?.catId, currentPage, token, state?.moduleName)         
          : await getQuestions(state?.catId, currentPage, token, state?.moduleName);  // await globalSearchQuestion("question", searchValue, token, currentPage, state?.moduleName)

        // const newData = (state?.comingFrom || searchValue) ? (res?.data?.data || res?.data) : res?.data?.data;
        const newData = res?.data?.data || res?.data;
        // console.log(newData);
        setListData(prevData => (currentPage === 1 ? newData : [...prevData, ...newData]));

        if (newData.length < ((state?.comingFrom || searchValue) ? 10 : 30)) {
          setHasMore(false);
        }

        setScrollLoading(false);
      } catch (error) {
        toast.error(error.message || "Something went wrong!");
      } finally {
        setLoading(false);
        setInitialLoad(false);
      }
    }
  };

  const handleListItemLink = (id, isFavouriteByUser, question, slug) => {
    setSearchValue('');
    const strippedQuestion = stripHtmlTags(question);
    const qSlug = strippedQuestion.toLowerCase().replace(/ /g, '-');
    const { topicName, moduleName, subid, catId } = state;
    const isFavourite = !isEmpty(isFavouriteByUser);

    
    navigate(`${slug || qSlug+'-'+id}`, {
      state: { catId, qId: id, topicName, isFavourite, moduleName, subid },
    });
  };

  return (
    <>
       <div className="d-flex align-items-center justify-content-between mb-2">
    <BreadCrumbs />
    {/* <div className="search-boxs px-2 d-flex w-50 align-items-center border rounded bg-white d-flex justify-content-between" style={{marginBottom:'1rem'}}><input type="text" autocomplete="chrome-off" className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Solved Questions Across Any Subject Or Topic..." value=""/>
      <i className="fa fa-search opacity-5"></i>
    </div> */}
    </div>
      {/* <div className="d-flex align-items-start justify-content-between d-md-none">
        <h2 className="heading-star pl-0 flex items-center text-left cursor-pointer" style={{ color: "#021442" }} dangerouslySetInnerHTML={{ __html: state?.topicName?.replaceAll("-", " ") }} />
        <button className="basic-btn mr-0 mt-0 mb-3 flex items-center fav-btn" onClick={() => navigate(`/${state?.moduleName}/favourite-questions`)}>
          <div className="mr-1 pt-1">
            <Hearticon />
          </div>
        </button>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 graph-card-box text-center pt-0 d-none d-md-grid">
        <div className="col-span-12 mt-md-4 mb-3 lg:col-span-6 text-card flex items-center">
          <h2 className="heading-star pl-0 flex items-center text-left cursor-pointer" style={{ color: "#021442" }} dangerouslySetInnerHTML={{ __html: state?.topicName?.replaceAll("-", " ") }} />
        </div>
        <div className="col-span-6 mx-0 flex justify-end services-title-h flex-nowrap items-center">
          <button className="basic-btn mr-0 flex items-center fav-btn" onClick={() => navigate(`/${state?.moduleName}/favourite-questions`)}>
            <div className="mr-1 pt-1">
              <Hearticon />
            </div>
            <span>Favourite Questions</span>
          </button>
        </div>
      </div> */}
      <div className="mb-1 pt-2 px-2 grid grid-cols-1 group-card card-body gap-4">
        {loading ? (
          <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div>
        ) : isEmpty(listData) && !initialLoad ? (
          <div className="flex justify-center mt-5">
            <Nodata />
          </div>
        ) : (
          <div className="col-span-9 py-2 w-full card-body border-none child-menu">
            <ol className="child-content pl-0">
              <InfiniteScroll
                dataLength={listData?.length}
                next={() => setCurrentPage(prevPage => prevPage + 1)}
                hasMore={hasMore}
                loader={scrollLoading && (
                  <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                    <div className="h-10 w-10">
                      <Loadingicon />
                    </div>
                  </div>
                )}
                refreshFunction={() => {
                  setCurrentPage(1);
                  setListData([]);
                 
                  getBankList(true);
                }}
                scrollableTarget={"qlist"}
                pullDownToRefresh
                pullDownToRefreshThreshold={90}
                className="relative"
              >
                <div style={{ overflow: "auto" }} id="qlist">
                  {isArray(listData) && listData.map((item, index) => (
                    // !isEmpty(searchValue) ? (
                      <li
                        className={`item-list ${index === 0 ? "active" : ""} cursor-pointer`}
                        onClick={() => handleListItemLink(item.id, item?.is_favourite_by_user, item?.question || item?.name, item?.slug)}
                        key={`${item.id}-${index}`}
                      >
                        {console.log('d', item)}
                        <div className="number-cy">
                          <span>{index + 1}</span>
                        </div>
                        {parseQuestions(item?.question || item?.name)}
                      </li>
                    // )
                    //  : (
                    //   <div
                    //     className="px-3 py-3 my-1 mx-2 child-box cursor-pointer bg-white shadow rounded-2xl flex justify-content-center align-items-center text-center"
                    //     onClick={() => handleListItemLink(item.id, '', item?.question || item?.name, item?.slug)}
                    //     key={`${item.id}-${index}`}
                    //   >
                       
                    //     <div className="text-xl category-text text-blue lineelip3" dangerouslySetInnerHTML={{ __html: item?.name }} />
                    //   </div>
                    // )
                  ))}
                </div>
              </InfiniteScroll>
            </ol>
          </div>
        )}
      </div>
    </>
  );
}

export default QuestionsSubCategories;
