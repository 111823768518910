import React, { memo } from 'react';
// import { Editor } from '@tinymce/tinymce-react';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { registerLicense } from '@syncfusion/ej2-base';
import { EDITOR_KEY } from '../../../../utils/utils';
// Register your Syncfusion license key
registerLicense(EDITOR_KEY);

const FAQItem = memo(({faqIndex, setFieldValue, handleMinusClick, faq, setFaqContainers, TINY_EDITOR_KEY, moderateEditorRef, fq}) => {

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode','CreateTable', 'FullScreen', '|', 'Undo', 'Redo']
    };
    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    };

    return (
       
                <div className="faqs-container bg-gray p-md-3 py-3 px-0 mb-3 rounded position-relative">
                    {
                        faqIndex>0?<button className="btn-plus" type="button" onClick={()=>handleMinusClick(faqIndex)}><i className="fa fa-minus"></i></button>:null
                    }                
                    <div className="faq-box border rounded w-100 p-4">
                        <label htmlFor={'faq-question'}
                            className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                        >
                            Question {faqIndex + 1}
                        </label>
                        <input
                            disabled={false}
                            type={'text'}
                            name={`faqQuestion-${faqIndex + 1}`}
                            autoComplete={'off'}
                            id={`faqQuestion-${faqIndex}`}
                            className="bg-gray-50 border mb-4 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            placeholder={`Question ${faqIndex + 1}`}
                            onChange={(e) => {
                                const newFaqs = [...faq];
                                newFaqs[faqIndex].question = e.target.value;
                                setFaqContainers(newFaqs);
                            }}
                            value={fq?.question}
                        />
                        <label htmlFor={'faq-question'}
                            className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                        >
                            Answer { faqIndex + 1 }
                        </label>
                        {/* <Editor
                            apiKey={TINY_EDITOR_KEY}
                            onInit={(evt, editor) => moderateEditorRef.current = editor}
                            init={{
                                height: '200',
                                auto_focus: true,
                                menubar: false,
                                plugins: 'hr lists table textcolor code link image',
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',
    
                                file_picker_types: 'image',
                                file_picker_callback: function (cb, value, meta) {
                                    var input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', 'image/*');
                                    input.onchange = () => {
                                        const file = input.files[0];
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            const base64 = reader.result.split(',')[1];
                                            cb('data:image/jpeg;base64,' + base64);
                                        };
    
                                        reader.readAsDataURL(file);
                                    };
    
                                    input.click();
                                },
                                anchor_top: false,
                                anchor_bottom: false,
                                branding: false,
                                loading: true
                            }}
                            value={fq?.answer}
                            onEditorChange={(content, editor) => {
                                setFieldValue(`answer-${faqIndex + 1}`, content);
                                const newFaqs = [...faq];
                                newFaqs[faqIndex].answer = content;
                                setFaqContainers(newFaqs);
                            }}
                        /> */}
                       
                        <RichTextEditorComponent height={250} toolbarSettings={toolbarSettings}
                        quickToolbarSettings={quickToolbarSettings}
                                                             id={'faq'+faqIndex}
                                                             value={fq?.answer || ''}
                                                             change={(content) => {
                                                                setFieldValue(`answer-${faqIndex + 1}`, content.value); // Update Formik value
                                                                const newFaqs = [...faq];
                                                                newFaqs[faqIndex].answer = content.value;
                                                                setFaqContainers(newFaqs);
                                                            }}
                                                             placeholder="Enter answer here">
                                                               {/* <span dangerouslySetInnerHTML={{__html:fq?.answer}}></span> */}
      <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]}/>
    </RichTextEditorComponent>
                    </div>                                                
                </div>
            )
});

export default FAQItem;
