import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSearch } from '../../../../Context/SearchContext';
import { getSearchedList } from '../../../../api/exam';
import ExamListItem from '../common/examslist';
function ExamsListSearch() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [topic, setTopic] = useState(null);
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const location = useLocation();
    const paramDatas = new URLSearchParams(location.search);
    const navigation = useNavigate();
    const { searchValue } = useSearch();

    const params = useParams();

    useEffect(() => {
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchExam(1, searchTerm);
            setSearchTerm(searchTerm);
        } 
    }, [])
    useEffect(() => {              
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchExam(1, searchTerm);
            setSearchTerm(searchTerm);
        }else{
            setSearchTerm('');
            navigation('/exams');
        }
            
    }, [searchValue])

    


    //load more blog by subcat data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        searchExam(nxtPage, searchTerm, 'loadMore');      
        setPageNo(nxtPage);
    }



   

    const searchExam = async (pNo, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        !loadMore && setPageNo(1);
        const  resSearchExams = await getSearchedList({ page: pNo, keyword });
        setIsSearch(true);

        if ( resSearchExams?.success) {
            console.log(resSearchExams)
            setTopic(resSearchExams?.topic)
            setTotalRecordFound( resSearchExams?.exams?.total || 0)
            setIsNotFound(false)
             resSearchExams?.exams?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if ( resSearchExams?.exams?.data?.length && pNo == 1) {
                setAllContent( resSearchExams?.exams?.data);
            } else if ( resSearchExams?.exams?.data?.length && pNo !== 1) {
                setAllContent([...allContent, ... resSearchExams?.exams?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }





    return (<>
       
        <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />           
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found </h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <ExamListItem
                                key={sIndex}
                                allExams = {allContent}
                                topic={topic}
                                examData={content}
                                redirectionFn={() => navigation(`/exams/detail/${content?.slug}`)}
                                />
                                ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default ExamsListSearch;