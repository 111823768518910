import React, { useEffect, useState } from 'react';
import Users from '../babbleusers';
import VerifyEmail from '../../verify';
import { useVerifyUserStatus } from '../../../Context/VerifyStatusContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import notFound from '../../../assets/images/not_found_new.png';
import { getShowtalentNotifications, getShowtalentToken } from '../../../api/OurService';
import { CDN_BASE_URL, formatDate } from '../../../utils/utils';
import AdminNotifications from '../adminNotification';
import { useAuth } from '../../../Context/AuthContext';
// import Users from '../../users';
//popularUsers
export default function Footer({popularUsers, handleCustomModal}) {
  // const { isEmailVerified } = useVerifyUserStatus();
  const { user } = useAuth();
  // const isEmailVerified = user?.isemail_verified==1?true:false;
  
  const navigate = useNavigate();
  const location = useLocation();
  const[middleContentWidth, setMiddleContentWidth] = useState(100);
  const[showtalentToken, setShowtalentToken] = useState(null);
  // Extract the pathname from the location object
  const currentPath = location.pathname;
  const [allShowtalentNotifications, setAllShowtalentNotifications] = useState();
  // Check if the current path is '/ask-me-anything'
  const isAskAnythingRoute = currentPath === '/ask-me-anything';
  const[showHidePeople, setShowHidePeople] = useState(false);

  useEffect(()=>{
    handleTabChange('notifications');
    fetchShowtalentToken();
        const mContentEle = document.querySelector('.middle-content');
        const mCWidth = mContentEle.clientWidth;
        setMiddleContentWidth(mCWidth)
  },[])


  const fetchShowtalentToken = async () =>{
      const showTalentTokenResponse = await getShowtalentToken({method:'get-api-token', tuteehubId:user?.tuteehub_id});
      if(showTalentTokenResponse?.response?.original?.status==1){
        return showTalentTokenResponse?.response?.original?.data?.token;
      }
  }

  const handleTabChange = async (key) => {
    if (key === "notifications") {
      const  sToken = await fetchShowtalentToken();
    if(sToken){
        let showtalentNotificationResponse = await getShowtalentNotifications({token: sToken, method:'get_notification', language:'en'});
       
        if(showtalentNotificationResponse?.response?.status == 1){
          setAllShowtalentNotifications(showtalentNotificationResponse?.response?.result);
        }
      }
    }
  };
  return (
   <><div className='py-3'>
      <p className='text-center text-black-50'>&#169; 2019 - {new Date().getFullYear()} TuteeHUB, All Rights Reserved.</p>
    </div>

<div className={`peoples-section d-md-none d-block ${!showHidePeople?'hide':'show'}`}>
<Users popularUsers={popularUsers}/>
<br/>
<br/>
<br/>

</div>

{
  user?.email_verified==0?<VerifyEmail/>:null
}
{/* {
  !isAskAnythingRoute?<div className="chat-icon" id="chat-icon" onClick={()=>navigate('/ask-me-anything')}>
  <span id="img-ask"><img src="https://www.tuteehub.com/tuteehub/images/ask.png" alt="AskAnything"/></span><span id="close-ask" style={{display:'none'}}><i className="fas fa-close" id="close-ask-icon" style={{backgroundColor:"#ff7200"}}></i></span> <span>Ask Anything</span>
</div>:null
} */}


{/* following modal */}
<div className="custom-modal bg-white hide" id="inbox-modal" style={{ width: middleContentWidth -50 }}>
            <div className="header-custom-modal px-4 py-3 w-100 d-flex align-items-center justify-content-between border-bottom">
                <div className="heading">
                    <h5 className="m-0"><strong>Inbox</strong></h5>
                </div> 
                {/*<div className="custom-close-modal">
                    <button type="button" onClick={()=>handleCustomModal()}><i className="fa fa-times"></i></button>
                </div>*/}
            </div>
            <div className="body-custom-modal p-4 w-100">
                <Tabs defaultActiveKey="messages" id="inbox-tab" variant="pills" justify  onSelect={handleTabChange}>
                  <Tab eventKey="messages" title="Messages">
                    <div className='content-tab my-4'>
                          <div className="vdo-module-notfound">
                              <img src={notFound} className="notFoundImg"/>
                              <p className="color-gray text-center">No any message</p>
                          </div>
                    </div>
                  </Tab>
                  <Tab eventKey="notifications" title={`Notifications(${allShowtalentNotifications?.unreadNotificationCount || 0})`}>
                  <div className='content-tab my-4'>
                    
                    {
                      allShowtalentNotifications?.data?.length?allShowtalentNotifications?.data.map((notification, index)=>(
                        <div key={'noti'+index} className="users-list-item px-3 w-100 rounded d-flex align-items-center py-2 justify-content-between">
                        <div className="d-flex w-100 gap-2 align-items-center">
                            <div className="user-pic">
                                {
                                    notification?.sender?.image!==''?<img src={`${CDN_BASE_URL}${notification?.sender?.image}`} style={{width:40,height:40,borderRadius:100}}/>:<img src={require('../../../assets/images/profile_placeholder.png')}/>
                                }
                                
                            </div>
                            <div className='flex-1'>
                            <h6 className="m-0 text-capitalize">{notification?.sender?.name}</h6>
                            <div className='d-flex justify-content-between'>
                            <small className="color-gray mb-0 small">{notification?.text}</small>
                            <small className="color-gray mb-0 small">{formatDate(notification?.created_at)}</small>
                            </div>
                           
                            </div>
                        </div>
                        {/* {
                          publicUserDetails?.tuteehub_id == user?.tuteehub_id?
                        <div className="meta-right">
                            {
                                following?.status==0? <button className="btn justify-content-center px-2 py-1 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn" onClick={()=>handleFollowProfile(following?.id)}>FOLLOW <i className="fab fa-plus"></i></button>: <button className="btn justify-content-center px-2 py-1 d-flex align-items-center border-0 btn-primarys w-100 btn-sm credit-btns credit-btn-default" onClick={()=>handleUnFollowProfile(following?.usr_id)}>FOLLOWING</button>
                            }
                        </div>:null

} */}

                    </div>
                      )):
                      <div className="vdo-module-notfound">
                          <img src={notFound} className="notFoundImg"/>
                          <p className="color-gray text-center">No any notifcation</p>
                      </div>
                    }
                       {
                         allShowtalentNotifications?.data?.length?<button type="button" onClick={() => navigate('/inbox')} className={`d-block text-center mt-4 btn text-white  load-more-custom noti-load-more`}>
                         View All
                     </button>  :null
                         
                       }
                    </div>
                  </Tab>
                
                </Tabs>
            </div>
        </div>
        {/* following modal END */}

</>
  )
}
