import React, { useEffect, useRef, useState } from 'react';
import { moduleSearchType } from '../../utils/utils';
import {useLocation, useNavigate } from 'react-router-dom';
import { useSearch } from '../../Context/SearchContext';
import { CircularProgress } from '@material-ui/core';


function SearchHeader({searchFeeds}) {
    const { sModuleType, setSModuleType, searchValue, setSearchValue, setSearchLoading, searchLoading } = useSearch();
    const navigation = useNavigate();
    const [searchWord, setSearchWord] = useState("")
    const searchBox = useRef(null); 
    const location = useLocation();
    const [placeholder, setPlaceholder] = useState(moduleSearchType[0]?.placeholder);

 

    // fetch all search
    const handleSearchOnClick = async () => {
        if(sModuleType?.value == 'fd'){
            searchFeeds(searchWord);
            return;
        }
        setSearchValue(searchWord);
        setSearchLoading(true);
        navigation('global-search');
    }



    const handleFilterChange = (e) => {        
        const selectedOption = e.target.options[e.target.selectedIndex]; 
        const fValue = JSON.parse(selectedOption.getAttribute('data-value')); 
        setPlaceholder(fValue?.placeholder);
        setSModuleType(fValue);
        setSearchValue("");
        setSearchWord("");
    }

    const handleSearchOnKeypup = (e) => {
        if(e.key==='Enter'){
            handleSearchOnClick();
        }
    }

    return (
        // <div className='flex'>
        
        <div className="search-box flex-1 mx-md-3 mx-2 px-2 d-flex align-items-center">
        <div className='position-relative'>
        <select className='form-control m-select' disabled={searchLoading} style={{ maxWidth: 190 }} onChange={handleFilterChange} value={sModuleType?.value}>
            {
                moduleSearchType?.map((fData, i) => <option key={i + 'ftype'} data-value={JSON.stringify(fData)} value={fData?.value}>{fData?.label}</option>)
            }
        </select>
        <i className='fa fa-angle-down position-absolute right-3 top-3.5 opacity-40'></i>
        </div>
         
            <input
            data-label="Use at least 3 characters for better results"
            type="text"
            ref={searchBox}
            disabled={searchLoading}
            onKeyUp={(e) => handleSearchOnKeypup(e)}
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            autoComplete="chrome-off"
            className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0"
            placeholder={placeholder}
            />
           { searchWord?.length<3 && 
                <small className='tooltip-search'>Use at least 3 characters for better results.</small>
            }
            {searchLoading ? (
            <CircularProgress
                size={15}
                style={{ color: "#ff7200" }}            
                className="ms-2"
            />
            ) : <i
            className="fa fa-search opacity-1 cursor-pointer"
            style={{ color: "#ff7200", fontSize: '20px' }}
            onClick={() => handleSearchOnClick(searchWord)}
        ></i>}
                    {/* </div> */}
    </div>)
}

export default SearchHeader;
