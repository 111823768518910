import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UseLocalStorage from "../Hooks/useLocalStorage";
import useLocalStorage from "../Hooks/useLocalStorage";
import { useCookies } from "react-cookie";
import { decryptLocalData, encryptLocalData } from "../utils/utils";


const AuthContext = React.createContext({});

const AuthProvider = (props) => {
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies([
    "___gtkn"
  ]);


  const [user, setUser] = useLocalStorage("userDetails", null);

  const [finance, setFinance] = useLocalStorage("____fi", null);
  // const [globalToken, setGlobalToken] = useLocalStorage("___gtkn", null); // Updated to use local storage
  const [globalToken, setGlobalToken] = useState(() => {
    const encryptedToken = cookies["___gtkn"];
    return encryptedToken;
    // return encryptedToken ? decryptLocalData(encryptedToken) : null;
  }); // Updated to use local storage
  const [qToken, setQtoken] = useLocalStorage("questionBankToken", null);
  const [reloadNotificaton, setReloadNotificaton] = useState(0);
  const [iQToken, setIQtoken] = useLocalStorage("InterviewQuestionToken", null);
  const [ffToken, setFFToken] = useLocalStorage("FullFormToken", null);
  const [dcToken, setDcToken] = useLocalStorage("DictionaryToken", null);
  const [gigToken, setGigtoken] = useLocalStorage("gigsToken", null);
  const [unreadNotifications, setUnreadNotifications] = useLocalStorage("unreadNotifications", null);
  const [isHeader, setIsHeader] = useLocalStorage("isHeader", true);
  const [isEmailVerifyCover, setIsEmailVerifyCover] = useLocalStorage("isEmailVerifyCover", true);
  const [showStrip, setShowStrip] = useLocalStorage("showStrip", true);
  const [askMeAnythingToken, setAskMeAnythingToken] = useState();
  const [assistantAndThreadKey, setAssistantAndThreadKey] = useState();
  const [blogToken, setBlogToken] = useLocalStorage("blogToken", null);
  const [tutorialToken, setTutorialToken] = useLocalStorage("tutorialToken", null);
  const [forumToken, setForumToken] = useLocalStorage("forumToken", null);
  const [examToken, setExamToken] = useLocalStorage("examToken", null);
  const [dashboardToken, setDashboardToken] = useLocalStorage("dashboardToken", null);


  useEffect(() => {
    if(globalToken){
      setCookie("___gtkn", globalToken, { path: '/', maxAge: 3600 });
    }
    
  }, [globalToken]);

  // useEffect(() => {
  //   if (globalToken) {
  //     const encryptedToken = encryptLocalData(globalToken);
  //     setCookie("___gtkn", encryptedToken, { path: "/", maxAge: 3600 });
  //   }
  // }, [globalToken, setCookie]);

  function handleLogout() {
    localStorage.clear();
    removeCookie("___gtkn", { path: '/' });
    // Clear the state
    setUser(null);
    setReloadNotificaton(null);
    setIsHeader(true);
    setQtoken(null);
    setBlogToken(null);
    setTutorialToken(null);
    setGigtoken(null);
    setIQtoken(null);
    setGlobalToken(null);
    setShowStrip(null);
    setFFToken(null);
    setForumToken(null);
    setDcToken(null);
    setFinance(null);
    setIsEmailVerifyCover(null);
    setUnreadNotifications(null);
    setAssistantAndThreadKey(null);
    setAskMeAnythingToken(null);
    setExamToken(null);
    setDashboardToken(null);
    navigate('/');
    // window.location.href = '/';
    // navigate('/login');
  }
  const authContextValue = {
    user,
    setUser,
    qToken,
    isHeader,
    globalToken,
    blogToken,
    tutorialToken,
    setGlobalToken,
    setIsHeader,
    setQtoken,
    showStrip,
    setShowStrip,
    setReloadNotificaton,
    reloadNotificaton,
    setDcToken,
    unreadNotifications,
    setUnreadNotifications,
    dcToken,
    setBlogToken,
    setTutorialToken,
    setFinance,
    finance,
    gigToken,
    setGigtoken,
    isEmailVerifyCover,
    setIsEmailVerifyCover,
    handleLogout,
    setAssistantAndThreadKey,
    assistantAndThreadKey,
    iQToken,
    ffToken,
    setFFToken,
    setIQtoken,
    setForumToken,
    forumToken,
    setAskMeAnythingToken,
    askMeAnythingToken,
    examToken,
    setExamToken,
    setDashboardToken,
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
