import React, { useEffect, useRef, useState } from "react";
import SectionDescription from "../../../common/sectionDescription";
import BreadCrumbs from "../../../common/BreadCrumbs";
import {
  ERROR_SOMETHING_WENT_WRONG,
  slugToNormal,
  yearSatra,
} from "../../../../utils/utils";
import { toast } from "react-toastify";
import notFound from "../../../../assets/images/not_found_new.png";
import { Loadingicon } from "../../../../AppIcons";
import ViewAllButton from "../../../common/viewallbutton";
import { useNavigate, useParams } from "react-router-dom";
import { getForumSubject } from "../../../../api/forum";
import ForumSubjectTopicItem from "../common/subjecttopic";
import { isArray } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

function ForumSubject() {
  const [pageNo, setPageNo] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [allSubject, setAllSubject] = useState([]);
  const [allFilteredContent, setAllFilteredContent] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [totalRecordFound, setTotalRecordFound] = useState(0);
  const searchBox = useRef(null);

  const params = useParams();

  const navigation = useNavigate();

  useEffect(() => {
    handleFetchAllForumSubject(1);
  }, []);

  // fetch all subject
  const handleFetchAllForumSubject = async (pageNo) => {
    const resAllSubjects = await getForumSubject({ pageNo });
    if (resAllSubjects?.success) {
      const forumCat = resAllSubjects?.data?.category;
      console.log("subjects", forumCat?.data);
      setIsNotFound(false);
      forumCat?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
      if (forumCat?.data.length && pageNo == 1) {
        let sortedData = forumCat.data.sort((a, b) => b.qa_count - a.qa_count);
        setAllSubject(sortedData);
        setAllFilteredContent(sortedData);
      } else if (forumCat?.data?.length && pageNo !== 1) {
        let sortedData = [...allSubject, ...forumCat.data].sort((a, b) => b.qa_count - a.qa_count); // Combine and sort
      
        setAllSubject(sortedData);
        setAllFilteredContent(sortedData);
      } else {
        if (pageNo == 1) {
          setAllSubject([]);
          setAllFilteredContent([]);
          setIsNotFound(true);
        }
      }
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
    }

    setLoading(false);
    setLoadingLoadMore(false);
    setScrollLoading(false);
  };

  // load more streams data
  const loadMore = () => {
    setScrollLoading(true);
    setLoadingLoadMore(true);
    const nxtPage = pageNo + 1;
    handleFetchAllForumSubject(nxtPage);
    setPageNo(nxtPage);
    searchBox.current.value = "";
    setIsSearch(false);
  };

  const searchCategory = (pageNo, searchTxt) => {
    setIsSearch(true);
    const fCategory = allSubject?.filter((al, index) =>
      al.name.toLowerCase().includes(searchTxt.toLowerCase())
    );
    setAllFilteredContent(fCategory);
    setTotalRecordFound(fCategory?.length);
    if (searchTerm?.length && !fCategory?.length) {
      setIsNotFound(true);
    } else {
      setIsNotFound(false);
    }
  };

  const handleSearchCategory = (e) => {
    const searchTxt = e.target.value;
    setSearchTerm(searchTxt);
    if (searchTxt == "") {
      setIsSearch(false);
      handleFetchAllForumSubject(1);
      return;
    }
    if (searchTxt !== "" && e.key === "Enter") {
      setIsSearch(true);
      searchCategory(pageNo, searchTxt);
    }
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <BreadCrumbs />
        {/* <div
          className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between"
          style={{ marginBottom: "1rem" }}
        >
          <input
            type="text"
            autocomplete="chrome-off"
            onKeyUp={handleSearchCategory}
            className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0"
            placeholder={`Search Category`}
            ref={searchBox}
          />
          <i
            className="fa fa-search opacity-1 cursor-pointer"
            style={{ color: "#ff7200" }}
            onClick={() => searchCategory(1, searchTerm)}
          ></i>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
            <div className="d-md-flex align-items-center justify-content-between">
              <SectionDescription
                title={`<strong>Join the Conversation : Top Knowledge Sharing Discussion Forums in ${yearSatra()}</strong>`}
                subTitle={`At TuteeHub , we believe that knowledge is best nurtured through active engagement and sharing. Our Forum Discussions are designed to foster a supportive environment where you can connect, learn, and network with peers who are equally passionate. Join us in building a thriving community that empowers each member to grow and excel.`}
              />
              <div className="btn-group question-box question-box-card mw-220">
                <button
                  className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white"
                  style={{ backgroundColor: "#ff7200" }}
                  onClick={() => navigation("/forum/my-forum")}
                >
                  My Forum
                </button>
                <button
                  className="btn-soft cursor-pointer hover:bg-themecolor text-white"
                  style={{ backgroundColor: "#ff7200" }}
                  onClick={() => navigation("/forum/post-a-question")}
                >
                  Post A Question
                </button>
              </div>
            </div>

            <hr
              style={{ borderColor: "#ccc", marginBottom: 0 }}
              className="mb-4"
            />

            {isSearch ? (
              <>
                <h6 className="text-black-50s label label-default w-100 py-2">
                  Search Result For '{searchTerm}' : {totalRecordFound} Result
                  Found
                </h6>
                <hr style={{ borderColor: "#f0f0f0", marginTop: 0 }} />
              </>
            ) : null}

            {loading ? (
              <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                  <div className="h-10 w-10">
                    <Loadingicon />
                  </div>
                </div>
              </div>
            ) : isNotFound ? (
              <div className="flex-column not-found d-flex text-center">
                <img src={notFound} className="notFoundImg" />
                <span style={{ opacity: 0.7 }}>No data found</span>
              </div>
            ) : (
              <div className="row">
                {/* {allFilteredContent?.map((subject, sIndex) => (
                  <ForumSubjectTopicItem
                    content={subject}
                    key={sIndex}
                    boxSize="col-md-3"
                    type="subject"
                    redirectionFn={() => navigation(`/forum/${subject?.slug}`)}
                  />
                ))} */}

                <InfiniteScroll
                  dataLength={allFilteredContent?.length}
                  next={() =>loadMore()}
                  hasMore={showLoadMore}
                  loader={scrollLoading && (
                  <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                      <div className="h-10 w-10">
                      <Loadingicon />
                      </div>
                  </div>
                  )}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={90}
                  refreshFunction={() => {
                  setPageNo(1);
                  setAllFilteredContent([]);
                  }}
                  scrollableTarget={"qBank"}
                  className={"relative"}
              >
                  <div className="py-8 grid pr-2  p-md-4 px-3 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom" style={{ overflow: "auto" }} id="qBank">
                  {isArray(allFilteredContent) && allFilteredContent.map((subject, sIndex) => (<>
                    <ForumSubjectTopicItem
                      content={subject}
                      key={sIndex}
                      boxSize="col-md-12"
                      type="subject"
                      redirectionFn={() => navigation(`/forum/${subject?.slug}`)}
                    />
                  </>
                  ))}
                  </div>
              </InfiniteScroll>
              </div>
            )}
          </div>
          {/* {showLoadMore ? (
            <ViewAllButton
              disabled={false}
              redirectionUrl={"/"}
              handleClickFn={loadMore}
              btnText={"Load More"}
              type={"button"}
              btnClass={"loadButton card-btn-1"}
              loading={loadingLoadMore}
            />
          ) : null} */}
        </div>
        {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    
                      <PopularCareerCategories catsList={popularCareerCats} title={'Popular Categories'}/>
                </div>
            </div> */}
      </div>
    </>
  );
}

export default ForumSubject;
