import axios from "axios";
import { BASE_URL, BLOGS_BASE_URL, BLOGS_BASE_URL_V1, CAREER_NEWS_BASE_URL, CORPORATES_BASE_URL, DC_BASE_URL, EDUCATORS_BASE_URL, EX_BASE_URL, FF_BASE_URL, FORUM_BASE_URL, GIGS_BASE_URL, INTBANK_BASE_URL, LYRICS_BASE_URL, QBANK_BASE_URL, QUOTES_BASE_URL, QUOTES_BASE_URL_V1, TUTORIALS_BASE_URL } from "../../utils/utils";
import { toast } from "react-toastify";

export const getGlobalSearchResult = (params) => { 
    const { type, query, mType, pageNo } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    
    let requestOptions = {
      method: "GET",
      headers: myHeaders
    };  
    // const url = mType=='qb'? `${QBANK_BASE_URL}qb/search-questions${type?'?'+pageNo:''}`:null;
  //   const url = mType === 'qb'
  // ? `${QBANK_BASE_URL}${mType}/search-questions${
  //     (query || type) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && type) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}` : ''
  //   }`:mType === 'iq'?`${INTBANK_BASE_URL}${mType}/search-questions${
  //     (query || type) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && type) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}` : ''
  //   }`
  const url = mType === 'qb'
  ? `${QBANK_BASE_URL}${mType}/search-questions${
      (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
    }`
  :mType === 'exams'? `${EX_BASE_URL}${mType}/search-questions${
      (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
    }` 
  :mType === 'ff'? `${FF_BASE_URL}${mType}/search-questions${
      (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
    }`
  :mType === 'dc'? `${DC_BASE_URL}${mType}/search-questions${
      (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
    }`
  :mType === 'gigs'? `${GIGS_BASE_URL}api/${mType}/V2/gigs-search${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'blogs'? `${BLOGS_BASE_URL}${mType}/search-blogs${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'lyr'? `${LYRICS_BASE_URL}lyrics-search${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'sht'? `${QUOTES_BASE_URL}quotes-search${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'tutorials'? `${TUTORIALS_BASE_URL}${mType}/search-tutorials${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'forum'? `${FORUM_BASE_URL}${mType}/search-forum${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'cn'? `${CAREER_NEWS_BASE_URL}search-news${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'corporates'? `${CORPORATES_BASE_URL}${mType}/corporate-search${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  :mType === 'educators'? `${EDUCATORS_BASE_URL}${mType}/educator-search${
    (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
  }`
  : mType === 'iq'
    ? `${INTBANK_BASE_URL}${mType}/search-questions${
        (query || type || pageNo) ? `?${query ? `query=${encodeURIComponent(query)}` : ''}${(query && (type || pageNo)) ? '&' : ''}${type ? `type=${encodeURIComponent(type)}` : ''}${(query || type) && pageNo ? '&' : ''}${pageNo ? `page=${encodeURIComponent(pageNo)}` : ''}` : ''
      }`
    : '';

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};