import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { Loadingicon } from "../../../../AppIcons";
import { convertDateFormatOnly, limitSubtitle, stripTags, yearSatra } from "../../../../utils/utils";
import { getSimilarNews } from "../../../../api/careernews";
import { useAuth } from "../../../../Context/AuthContext";
import careerThumbPlaceholder from '../../../../assets/images/c_thumb.png';
// import careerThumbPlaceholder from '../../../../assets/images/feeds_default_placeholder.webp';
import { Link, useNavigate } from 'react-router-dom';

function SimilarNews({
  newsSlug,
  patternLinks,
  redirectionFn,
  stripBottom
}) {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hashMore, setHasMore] = useState(true);
  const { globalToken } = useAuth();
  const token = globalToken;

  useEffect(() => {
    handleSimilarNewsList();
  }, [currentPage]);

  useEffect(()=>{
    return ()=> localStorage.setItem('feedDetail', 'feedDetail')
  },[])

  const handleSimilarNewsList = async () => {
    setLoading(true);
    setScrollLoading(true);
    
    try {
      const resSimilarNews = await getSimilarNews({newsSlug, token:globalToken});
      // console.log('similar news', res)
      setNewsData([...newsData, ...resSimilarNews?.similarNewsContents?.data]);
     
      if (resSimilarNews?.similarNewsContents?.data?.length < 30) {
        setHasMore(false);
      }
      setScrollLoading(false);
    } catch (error) {
      // toast.error(error.message || "Something went wrong!");
    } finally {
      setLoading(false);
      setScrollLoading(false);
    }
  };

  return (
    <div className="question-box" id="question-box">
      {!isEmpty(newsData) ? (        
        <div className="card-header-bar header1 px-8 py-3 mb-4">
        <h5 className="theme-orange-color m-0 fsize-14">
        Similar Career News For The Session {yearSatra()}</h5>
      </div>
      ) : null}
    <div className="px-8 qblistmost">
      <InfiniteScroll
        dataLength={newsData?.length}
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hashMore}
        loader={
          scrollLoading ? (
            <div className="h-10 w-10 mx-auto theme-color my-3">
            <Loadingicon />
          </div>
          ) : null
        }
        refreshFunction={() => {}}
        pullDownToRefresh
        pullDownToRefreshThreshold={90}
      >
        <div id="mViewQa" className="pr-2">
          {!isEmpty(newsData)
            ? newsData?.map((item) => (
                <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`}  onClick={(e) => {navigation(`/career-news/news/${item?.id}/${item?.slug}`);window.location.reload();}} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-flex align-items-center p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers mr-3">
                    {/* {
                        newsData?.file_name ?
                        <img
                        src={
                            CDN_BASE_URL +
                            `${thumbPrefix+newsData?.file_name}`
                        }
                        width="90"
                        height="90"
                        className="rounded bg-gray border"
                        alt="Thumbnail"
                    />
                            :  */}
                            <img src={`${careerThumbPlaceholder}`} width="50" height="50" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    {/* } */}

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5"><strong>{item?.title}</strong> {

                        }</span>
                    </h6>
                    <span className='w-100 d-flex align-items-center'>
                    {

                        true?<><small><a onClick={(e) => {e.stopPropagation();e.preventDefault();navigation(`/career-news/top-10-trending-careers-streams/${item?.cat?.id}/${item?.cat?.slug}`)}} className="theme-a-light cursor-pointer">{item?.cat?.name}</a></small>
                        <span className="big-dots"></span>
                        <small><a onClick={(e) => {e.stopPropagation();e.preventDefault(); navigation(`/career-news/top-10-trending-branches-exams/${item?.cat?.id}/${item?.scat?.id}/${item?.scat?.slug}`)}} className="theme-a-light cursor-pointer">{item?.scat?.name}</a></small>
                        <span className="big-dots"></span></>:null
                    }
                    
                    <span className='span px-1 color-gray text-capitalize fsize-11 c-a5'>{convertDateFormatOnly(item?.createdAt)}</span>
                    </span>
                    <span className="mb-0 mt-1 color-gray fsize-meta desc-camp w-100 d-block" >{limitSubtitle(stripTags(item?.description), 290) }</span>
                    {
                        stripBottom?<> <div> <hr style={{borderColor:"#ccc",marginTop:10,marginBottom:3}} /></div>
                        <div class="">
                            {
                                patternLinks?.length?patternLinks?.map((patternLink, pLIndex) =>(<small>
                                    <Link key={pLIndex} onClick={(e)=>{e.preventDefault();e.stopPropagation();navigation(`/career-news/news/${item?.id}/${item?.scat?.id}/${item?.scat?.slug}${patternLink?.redirectUrl}`);window.location.reload()}} className='color-blue-hover'>
                                        {patternLink?.name}
                                    </Link>
                                    {
                                        pLIndex < patternLinks?.length-1?<span className="big-dots"></span>:null
                                        
                                    }
                                   </small> 
                                )):null
                            }
                           
                        </div></>:null
                    }
                   
                </div>
                
            </div>
        </div>
              ))
            : null}
        </div>
      </InfiniteScroll>
      </div>
    </div>
  );
}
export default SimilarNews;
