import React from 'react';
import { MathJaxContext, MathJax } from 'better-react-mathjax'

const symbolMap = {
  '≈í¬µ‚Äö√á√Ö': 'ε₁',
  '≈í¬µ‚Äö√á√á': 'ε₂',
  // Add more mappings if needed
};

function replaceSymbols(str) {
  if (typeof str !== 'string') return '';
  return str.replace(/≈í¬µ‚Äö√á√Ö|≈í¬µ‚Äö√á√á/g, match => symbolMap[match] || match);
}

function MixedContent({ content }) {
  const processedContent = replaceSymbols(content);
  
  if (!processedContent) {
    return null; // or return some placeholder content
  }

  return (
    <MathJaxContext>
      <MathJax inline dynamic><span  dangerouslySetInnerHTML={{__html:processedContent}}></span> </MathJax>
    </MathJaxContext>
  );
}

export default MixedContent;