import axios from "axios";
import { QBANK_BASE_URL, INTBANK_BASE_URL, FEEDS_API_Basepath, FF_BASE_URL, DC_BASE_URL, BASE_URL, SHORT_TASK_BASE_URL, SHOWTALENT_BASE_URL, CPA_LEAD_OFFERWALL_URL, DASHBOARD_URL, BASE_URL_OLD, FORUM_BASE_URL, BLOGS_BASE_URL, TUTORIALS_BASE_URL } from "../utils/utils";
import { toast } from "react-toastify";
// import { axiosReq as axios } from "./axios";

const url = (moduleName) => {
  return moduleName == "question-Bank"
    ? `${QBANK_BASE_URL}qb`
    : moduleName == "fullforms"
      ? `${FF_BASE_URL}ff`
      : moduleName == "dictionary"
        ? `${FF_BASE_URL}dc`
        : `${INTBANK_BASE_URL}iq`;
};

const idname = (moduleName) => {
  return moduleName === "question-Bank"
    ? "questionbankId"
    : moduleName === "fullforms"
      ? "fullformQuestionId"
      : moduleName === "dictionary"
        ? "dictionaryQuestionId"
        : "interviewquestionId";
};

export const getUserToken = (data, moduleName) => {
  
  const baseURL =
    (moduleName.startsWith("/gigs") || moduleName === "gigs")?SHORT_TASK_BASE_URL:moduleName === "interview-questions" ? INTBANK_BASE_URL:moduleName === "question-Bank" ? QBANK_BASE_URL : moduleName === "fullforms" ? FF_BASE_URL : moduleName === "dictionary" ? DC_BASE_URL : moduleName === "/" || moduleName === "feeds" ? FEEDS_API_Basepath + 'api/fd/' : BASE_URL;
  let myHeaders = new Headers();
  if(baseURL==BASE_URL) return;
  myHeaders.append("Accept", "*/*");
  let formdata = new FormData();
  formdata.append("tuteehubId", data);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  // console.log(`${baseURL}user/api-token`)
  // https://feeds-api.tuteehub.com/api/fd
  return fetch(`${baseURL}user/api-token`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};


export const getModuleUserIdBySecretToken = (token , moduleName) => {  
  const baseURL =
    (moduleName.startsWith("/gigs") || moduleName === "gigs")?SHORT_TASK_BASE_URL+'V2/':moduleName === "question-Bank" ? QBANK_BASE_URL : moduleName === "tutorials" ? TUTORIALS_BASE_URL : moduleName === "blogs" ? BLOGS_BASE_URL : moduleName === "fullforms" ? FF_BASE_URL : moduleName === "forum" ? FORUM_BASE_URL: moduleName === "dictionary" ? DC_BASE_URL : moduleName === "/" || moduleName === "feeds" ? FEEDS_API_Basepath + '' : INTBANK_BASE_URL;
  let myHeaders = new Headers();  
  myHeaders.append("Accept", "*/*");  
  myHeaders.append("Authorization", `Bearer ${token}`);
  let requestOptions = {
    method: "POST",
    headers: myHeaders
  };
  return fetch(`${baseURL}user/secret-token`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      localStorage.clear();
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));
};


export const onBuyCoins = (data) => {

  const baseURL = BASE_URL;
  let myHeaders = new Headers();

  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("tuteehub_id", data?.tuteehub_id);
  formdata.append("tax_applicable", data?.tax_applicable);
  formdata.append("payment_gateway_charge", data?.payment_gateway_charge);
  formdata.append("final_amount", data?.final_amount);
  formdata.append("amount", data?.amount);
  formdata.append("credits", data?.credits);
  formdata.append("transaction_id", data?.transaction_id);
  formdata.append("status", 'success');
  formdata.append("platform_name", data?.platform_name);
  formdata.append("platfrom_txn_id", data?.platfrom_txn_id);
  formdata.append("ip", data?.ip);
  formdata.append("description", data?.description);
  formdata.append("user_agent", data?.user_agent);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}save-coin-purchase-txn`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};

export const getServiceList = (currentPage, moduleName) => {
  const baseURL = url(moduleName);
  return axios.get(`${baseURL}/get-subject/list?page=${currentPage}`);
};

export const globalSearchQuestion = (
  type,
  value,
  token,
  currentPage,
  moduleName
) => {
  const baseURL = url(moduleName);
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append("type", type);
  formdata.append("keyword", value);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(
    `${baseURL}/get-global-search/list?page=${currentPage}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};
export const getQBankCatList = (id, currentPage, moduleName) => {
  const baseURL = url(moduleName);
  return axios.get(`${baseURL}/get-topic/list/${id}?page=${currentPage}`);
};

export const getQuestions = (id, currentPage, token, moduleName) => {
  const baseURL = url(moduleName);

  return axios.get(
    `${baseURL}/get-question/list/via-authentic-user/${id}?page=${currentPage}`,
    {
      headers: {
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    }
  );
};


export const getCreditHistory = (module, tuteehubId, pageNo) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehubId);
  formdata.append("module", module);
  formdata.append("page", pageNo);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}credit-history`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};



export const assitantIdGenerate = (params) => { 
  const { tuteehub_id, apiKey } = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("OpenAI-Beta", "assistants=v1");
  let formdata = new FormData();
  formdata.append("apiKey", apiKey);
  formdata.append("tuteehub_d", tuteehub_id);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${baseURL}create-assistant`, requestOptions)
    .then((response) =>{
      if (!response.ok) {
        toast.error('Network Failure')
      }
      response.text()
    })
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const deleteAccount = (params) => { 
  const { tuteehubId, token } = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append("tuteehubId", tuteehubId);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${baseURL}user/deactivate-account`, requestOptions)
  .then((response) => response.json())
  .catch((error) => console.log("error", error));

};

export const getConfigs = (params) => { 
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(`${baseURL}get-coin-conversion`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const assistantIdGet = (params) => { 
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${baseURL}get-assistant?tuteehub_id=${params?.tuteehub_id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const gigsTransactions = (params) => { 
  const {currentPage, token} = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${baseURL}V2/transaction-history?page=${currentPage}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getGigsCreditStats = (params) => { 
  const {token} = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${baseURL}V2/get-earning-info`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const withdrawalConfig = () => {   
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${baseURL}get-withdrawal-commission`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};


export const getPlansPromotions = (params) => { 
  const {token, type} = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${baseURL}V2/get-plans?type=${type}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));
};

export const getSpecificPlanList = (params) => { 
  const {token, type} = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${baseURL}V2/get-plan-type?type=${type}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));
};

export const getFeaturedAndHighlightedPlans = (params) => { 
  const {token, type} = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${baseURL}V2/get-topup?type=${type}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));
};


export const addCampaignToFeatured = (params) => { 
  const {token, campaign_id, topup_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', campaign_id);
  formData.append('topup_id', topup_id);

  const url = `${baseURL}V2/campaign-featured`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const addCampaignToHightlighted = (params) => { 
  const {token, campaign_id, topup_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', campaign_id);
  formData.append('topup_id', topup_id);

  const url = `${baseURL}V2/campaign-highlight`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));
};

export const buyShortTaskPlan = (params) => { 
  const {token, campaign_id, plan_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', campaign_id);
  formData.append('plan_id', plan_id);

  const url = `${baseURL}V2/buy-plans`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));
};

export const buyShortTaskPromotion = (params) => { 
  const {token, campaign_id, promotion_id, frequency } = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', campaign_id);
  formData.append('promotion_id', promotion_id);
  formData.append('frequency', frequency);

  const url = `${baseURL}V2/buy-promotions`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // toast.error('You have already logged in on another system with the same account');
        // Clear all local storage
        localStorage.clear();
        // Redirect to login page
        setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
        
        return;
      }
      return response.json()})
    .catch((error) => console.log("error", error));
};



export const gigsCreateTaskSession = (params) => { 
  const {token, task_id} = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${baseURL}get-task-id?task_id=${task_id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getTaskCategories = (params) => { 
  const baseURL = SHORT_TASK_BASE_URL;
  const { page } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${params?.token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  let queryString = "";
  if (page) queryString += `&page=${page}`;

  const url = `${baseURL}category?${queryString.slice(1)}`;

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getTaskSubCategories = (params) => { 
  const {catId, token, page, all } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  let queryString = "";
  if (catId) queryString += `&id=${catId}`;
  if (all) queryString += `&all=${1}`;
  if (page && !all) queryString += `&page=${page}`;

  const url = `${baseURL}subcategory?${queryString.slice(1)}`;
  // console.log(url)
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getTaskList = (params) => { 
  // const {cat_id, scat_id, title, token, pageNumber } = params;
  const {status, dateFrom, dateTo, title, token, pageNumber, cat_id, scat_id, user_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let queryString = "";
  if (status) queryString += `&status=${status}`;
  if (dateFrom) queryString += `&dateFrom=${dateFrom}`;
  if (dateTo) queryString += `&dateTo=${dateTo}`;
  if (title) queryString += `&title=${title}`;
  if (cat_id) queryString += `&cat_id=${cat_id}`;
  if (scat_id) queryString += `&scat_id=${scat_id}`;
  if (user_id) queryString += `&user_id=${user_id}`;
  if (pageNumber) queryString += `&page=${pageNumber}`;
  

  const url = `${baseURL}V2/get-campaign?${queryString.slice(1)}`;
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const getFavouriteTasks = (params) => { 
  const {token, user_id, pNumber } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${baseURL}V2/get-fav-campaign-new?user_id=${user_id}&page=${pNumber}`;  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};


export const getSocialOptionsList = () => { 
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const url = `${baseURL}get-social-list`;  
  let requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getFavouriteEmployers = (params) => { 
  const {token, pNumber } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${baseURL}V2/get-fav-taskuser?page=${pNumber}`;  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getAllUnreadNotifications = (params) => { 
  const {token } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let queryString = "";

  const url = `${baseURL}V2/get-notifications?${queryString?.slice(1)}`;
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getAllTaskNotifications = (params) => { 
  const {token, page } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let queryString = "";
  if (page) queryString += `&page=${page}`;
  const url = `${baseURL}V2/get-all-notification?${queryString?.slice(1)}`;
  
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const markAsReadNotification = (params) => { 
  const {token, notification_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('notification_id', notification_id);

  const url = `${baseURL}V2/read-notifications`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getPublicUserProfile = (params) => { 
  const { tuteehub_id, page} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formData = new FormData();
  formData.append('tuteehub_id', tuteehub_id);

  const url = `${baseURL}user/public-profile?page=${page}`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getFollowersList = (params) => { 
  const { per_page, token, page_no} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let formData = new FormData();
  formData.append('per_page', per_page);
  formData.append('page_no', page_no);

  const url = `${baseURL}user/get-followers`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getFollowingList = (params) => { 
  const { per_page, token, page_no} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let formData = new FormData();
  formData.append('per_page', per_page);
  formData.append('page_no', page_no);

  const url = `${baseURL}user/get-followings`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};


export const rejectFollower = (params) => { 
  const { follower_id, status, token} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let formData = new FormData();
  formData.append('follower_id', follower_id);
  formData.append('status', status);

  const url = `${baseURL}user/update-follow-request`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const followProfile = (params) => { 
  const { user_id, token} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('user_id', user_id);

  const url = `${baseURL}user/follow-now`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const unFollowProfile = (params) => { 
  const { user_id, token} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('user_id', user_id);

  const url = `${baseURL}user/unfollow-now`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getMyCampaign = (params) => { 
  const {status, dateFrom, dateTo, title, token, pageNumber, cat_id, scat_id } = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let queryString = "";
  if (status || status ==0) queryString += `&status=${status}`;
  if (dateFrom) queryString += `&dateFrom=${dateFrom}`;
  if (dateTo) queryString += `&dateTo=${dateTo}`;
  if (title) queryString += `&title=${title}`;
  if (cat_id) queryString += `&cat_id=${cat_id}`;
  if (scat_id) queryString += `&scat_id=${scat_id}`;
  if (pageNumber) queryString += `&page=${pageNumber}`;
 
  const url = `${baseURL}V2/my-campaign?${queryString.slice(1)}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const getAcceptedTasks = (params) => { 
  const {status, dateFrom, dateTo, title, token, pageNumber, campaign_id, filterCampaign } = params;
 
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let queryString = "";
  if (status || status==0) queryString += `&status=${status}`;
  if (dateFrom) queryString += `&dateFrom=${dateFrom}`; 
  if (dateTo) queryString += `&dateTo=${dateTo}`;
  if (title) queryString += `&title=${title}`;
  if (campaign_id) queryString += `&campaign_id=${campaign_id}`;
  if (filterCampaign) queryString += `&filterCampaign=${filterCampaign}`;
  if (pageNumber) queryString += `&page=${pageNumber}`;

  const url = `${baseURL}V2/task-list?${queryString.slice(1)}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const getReviewTasks = (params) => { 
  // const {token, pageNumber } = params;  
  const {status, dateFrom, dateTo, title, token, pageNumber, campaign_id, filterCampaign } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let queryString = "";
  // if (pageNumber) queryString += `&page=${pageNumber}`;
  if (status || status==0) queryString += `&status=${status}`;
  if (dateFrom) queryString += `&dateFrom=${dateFrom}`; 
  if (dateTo) queryString += `&dateTo=${dateTo}`;
  if (title) queryString += `&title=${title}`;
  if (campaign_id) queryString += `&campaign_id=${campaign_id}`;
  if (filterCampaign) queryString += `&filterCampaign=${filterCampaign}`;
  if (pageNumber) queryString += `&page=${pageNumber}`;

  const url = `${baseURL}V2/review-task?${queryString.slice(1)}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (!filterCampaign){
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
  }
    return response.json()})
    .catch((error) => console.log("error", error));

};


export const getTaskDetail = (params) => { 
  const {token, task_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}V2/campaign-detail/${task_id}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getSystemNotifications = (params) => { 
  const {token } = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}user/is-system-notification-available`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));


};

export const getOfferConfig = () => { 
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  const url = `${baseURL}offers-configuration-data`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getAllCountries = () => { 
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const url = `https://restcountries.com/v3.1/all`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .then(data => {
      if (data && data.length > 0) {
        // Sort the array of country data alphabetically by country name
        const sortedCountries = data.sort((a, b) => {
            return a.name.common.localeCompare(b.name.common);
        });
        
        return sortedCountries; // This will log the sorted array of countries
    } else {
        console.error('No data available');
    }
  })
    .catch((error) => console.log("error", error));

};



export const getAllOffers = (params) => {   
  const { token, offerQueryParams} = params;
  // console.log(offerQueryParams);
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  // myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}user/get-cpa-leads-no-auth?${offerQueryParams}`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));


};

export const getAllCPAGripOffers = (params) => {   
  const { token, offerQueryParams} = params;
  // console.log(offerQueryParams);
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}user/get-cpaGripOffers?${offerQueryParams}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));


};

export const initiatedOffers = (params) => {   
  const { token, pNo} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}user/initiated-offers-list?page_no=${pNo}`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));


};

export const initiatedSurveys = (params) => {   
  const { token, pNo} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}user/initiated-surveys-list?page_no=${pNo}`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));


};

export const initiateOffer = (params) => { 
  
  const { token, initiateOfferParams} = params;
 
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}user/initiate-offer`;
  let formData = new FormData();
  formData.append('campaign_id', initiateOfferParams?.campaign_id);
  formData.append('campaign_name', initiateOfferParams?.campaign_name);
  formData.append('campaign_credits', initiateOfferParams?.campaign_credits);
  formData.append('campaign_image', initiateOfferParams?.campaign_image);
  formData.append('payout_currency', initiateOfferParams?.payout_currency);
  formData.append('amount', initiateOfferParams?.amount);
  formData.append('campaign_description', initiateOfferParams?.campaign_description);
  formData.append('campaign_category', initiateOfferParams?.campaign_category);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };

  
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));


};

export const initiateSurvey = (params) => {   
  const { token, initiateSurveyParams} = params; 
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${baseURL}user/initiate-survey`;

  let formData = new FormData();
  formData.append('survey_id', initiateSurveyParams?.survey_id);
  formData.append('survey_image', initiateSurveyParams?.survey_image);
  formData.append('survey_min_length', initiateSurveyParams?.survey_min_length);
  formData.append('survey_credits', initiateSurveyParams?.survey_credits);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const fetchIpAddress = () => {
  return fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
}

export const getAllSurveys = (params) => { 
  
  const { token, surveyQueryParams} = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const url = `${baseURL}user/get-cpx-surveys?${surveyQueryParams}`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));


};


export const getShowtalentToken = (params) => { 
  const {method, tuteehubId } = params;
  const baseURL = SHOWTALENT_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  const url = `${baseURL}v2/services`;
  let formData = new FormData();
  formData.append('method', method);
  formData.append('tuteehubId', tuteehubId);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const markedAsReadNotification = (params) => { 
  const {method, id } = params;
  const baseURL = SHOWTALENT_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  const url = `${baseURL}v2/services`;
  let formData = new FormData();
  formData.append('method', method);
  formData.append('id', id);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getShowtalentNotifications = (params) => { 
  const {method, language, token } = params;
  const baseURL = SHOWTALENT_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}v2/services`;
  let formData = new FormData();
  formData.append('method', method);
  formData.append('language', language);
  formData.append('per_page', 1000);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getCountries = (params) => { 
  const {token } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}get-country`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const getGigsConfigurations = (params) => { 
  const {token, conf, cat_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}V2/get-configuration?conf=${conf}&cat_id=${cat_id}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const startTask = (params) => { 
  const {token, task_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', task_id);

  const url = `${baseURL}V2/start-task`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const referToAdmin = (params) => { 
  const {token, task_id, campaign_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', campaign_id);
  formData.append('task_id', task_id);

  const url = `${baseURL}V2/referred-to-admin`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const favTask = (params) => { 
  const {token, task_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', task_id);

  const url = `${baseURL}V2/add-campaign-to-fav`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};
export const unFavTask = (params) => { 
  const {token, task_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}V2/remove-fav-campaign?campaign_id=${task_id}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};


export const favUser = (params) => { 
  const {token, user_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('fav_user_id', user_id);

  const url = `${baseURL}V2/add-user-to-fav`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};
export const unFavUser = (params) => { 
  const {token, user_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}V2/remove-fav-taskuser?fav_user_id=${user_id}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const campaignStatusChange = (params) => { 
  const {token, campaign_id, status } = params;
  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', campaign_id);
  formData.append('status', status);

  const url = `${baseURL}V2/campaign-active-inactive`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};

export const generateCampaignAuto = (params) => { 
  const {token, campaign_id } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = `${baseURL}V2/get-campaign-id?campaign_id=${campaign_id}`;
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const createCampaign = (params, type) => { 
  const {token, createCampaignParams } = params;
 
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
 
  // myHeaders.append("Accept", "application/json");
  // myHeaders.append("Content-Type", "multipart/form-data");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('title', createCampaignParams?.title);
  formData.append('description', createCampaignParams?.description);
  // formData.append('moderator_notes', createCampaignParams?.moderator_notes);
  formData.append('proof_req_desc', createCampaignParams?.proof_desc);
  formData.append('per_task_credit', createCampaignParams?.per_task_cost);
  formData.append('proof_requirement', createCampaignParams?.proof_requirement);
  formData.append('cat_id', createCampaignParams?.cat_id);
  formData.append('subcat_id', createCampaignParams?.scat_id);
  formData.append('user_id', createCampaignParams?.user_id);
  formData.append('frequency_id', createCampaignParams?.frequency_id);
  formData.append('time_interval', createCampaignParams?.time_interval);
  if(type=='edit'){
    formData.append('id', createCampaignParams?.id);
  }
  if(createCampaignParams?.country_ids?.length){
  createCampaignParams?.country_ids.forEach(countryId => {
    formData.append('country_ids[]', countryId);
  });
}
  if(createCampaignParams?.task_thumbnails){
    formData.append('thumbnails', createCampaignParams?.task_thumbnails);
  }  
  formData.append('age', createCampaignParams?.age);
  formData.append('gender', createCampaignParams?.gender);
  // formData.append('country_id', createCampaignParams?.country_id);

  // formData.append('operating_system', createCampaignParams?.operating_system);
  // formData.append('browser', createCampaignParams?.browser);
  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  // }
  // return;
  const url = `${baseURL}V2/create-campaign`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};


export const submitProof = (params) => { 
  const {token, submitProofParams } = params;  
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
 
  // myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', submitProofParams?.taskId);
  formData.append('task_id', submitProofParams?.campId);
  formData.append('concern', submitProofParams?.concern);
  formData.append('proofs', submitProofParams?.proofs);
  if(submitProofParams?.type){    
  formData.append('type', submitProofParams?.type);
  }


  const url = `${baseURL}V2/submit-proof`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const addCreditsToTask = (params) => { 
  const {token, campaign_id, credits } = params;
  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('campaign_id', campaign_id);
  formData.append('credits', credits);

  const url = `${baseURL}V2/add-credits-to-campaign`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};


export const acceptRejectCancel = (params) => { 
  const {token, taskId, action, region, comment } = params;

  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append('taskId', taskId);
  formData.append('action', action);
  if(region)
  {    
  formData.append('region', region);
  }
  if(comment)
  {    
  formData.append('comment', comment);
  }

  const url = `${baseURL}V2/task-approve-reject`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};


export const handleReviewRatingTask = (params) => { 
  const {token, review, rating,  user_id, campaign_id, task_id } = params;

  const baseURL = SHORT_TASK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  if(task_id){
  formData.append('task_id', task_id);
  }
  formData.append('review', review);
  formData.append('user_id', user_id);
  formData.append('rating', rating);
  if(campaign_id){
  formData.append('campaign_id', campaign_id);
  }
  
  const url = `${baseURL}V2/review-rating`;
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };
  return fetch(`${url}`, requestOptions)
  .then((response) => {
    if (response.status === 401) {
      // toast.error('You have already logged in on another system with the same account');
      // Clear all local storage
      localStorage.clear();
      // Redirect to login page
      setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
      
      return;
    }
    return response.json()})
    .catch((error) => console.log("error", error));

};


export const threadsByAssistId = (params) => { 
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  const {tuteehub_id, assistant_id} = params;
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("assistant_id", assistant_id);
  formdata.append("tuteehub_id", tuteehub_id);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };

  return fetch(`${baseURL}get-threads`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

};

export const createThread = (params) => { 
  const { assistant_id, apiKey } = params;

  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("OpenAI-Beta", "assistants=v1");
  let formdata = new FormData();
  formdata.append("apiKey", apiKey);
  formdata.append("assistant_id", assistant_id);
  formdata.append("user_type", 2);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${baseURL}create-thread`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const fetchAskMeAnythingApiKey = () => {
    const baseURL = BASE_URL;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
  
    return fetch(`${baseURL}getapiKey`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
};



export const askQuestion = (params) => {
  // alert(JSON.stringify(params))
  const { assistant_id, thread_id, tuteehubId, module, prompt, apiKey } = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("assistant_id", assistant_id);
  formdata.append("thread_id", thread_id);
  formdata.append("module", module);
  formdata.append("prompt", prompt);
  formdata.append("apiKey", apiKey);
  formdata.append("tuteehubId", tuteehubId);
  // formdata.append("module", module);
  // formdata.append("page", pageNo);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${baseURL}ask-question`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};


export const askMeAnythingHistory = (params) => {
  // alert(JSON.stringify(params))
  const {threadId, apiKey } = params;
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("OpenAI-Beta", "assistants=v1");
  let formdata = new FormData();
  formdata.append("threadId", threadId);
  formdata.append("apiKey", apiKey);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${baseURL}get-messages`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};


export const getLeaderBoardList = (module) => {
  const baseURL = BASE_URL;
  // const baseURL = module=='showtalent'?BASE_URL_OLD:BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  // formdata.append("module", module);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    // body: formdata,
  };
  return fetch(`${baseURL}credit-users`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const getUserInfo = (tuteehubId, token) => {

  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  // myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehubId);
  // formdata.append("key", token);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };

  return fetch(`${baseURL}user/get-user-info`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};



export const changePassword = (data, token) => {
    const baseURL = BASE_URL;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestBody = JSON.stringify({
      current_password: data?.current_password,
      new_password: data?.new_password,
      confirm_password: data?.confirm_password,
    }); 
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: requestBody
    };
  
  
    return fetch(`${baseURL}user/change-password`, requestOptions)
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => console.log("error", error));
  };

// export const changePassword = (data, token) =>{
//   return axios.post(`${BASE_URL}user/change-password`, data);
// }


export const getWithdrawalList = (tuteehubId) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehubId);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}get-withdrawal-requests-list`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const getPlansList = () => {
  const baseURL = 'https://showtalent.tuteehub.com/api/';
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("method", 'get_coin_plans');
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}v2/services`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const getAccountDetail = (tuteehubId) => {
  const url = `${BASE_URL}view-bank-account-details`;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehubId);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const onAddBankDetails = (data) => {
  const url = `${BASE_URL}save-bank-account-details`;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const formdata = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formdata.append(key, value);
  });
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const onUpdateBankDetails = (data) => {
  const url = `${BASE_URL}update-bank-account-details`;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const formdata = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formdata.append(key, value);
  });
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${url}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};




export const sendWithdrawalRequest = (params) => {
  const baseURL = BASE_URL;
  const { tuteehub_id, redeemed_coins, amount, module } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehub_id);
  formdata.append("redeemed_coins", redeemed_coins);
  formdata.append("amount", amount);
  formdata.append("module", module);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}send-withdrawal-request`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const getCoinPurchaseHistoryList = (tuteehubId) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  // myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehubId);
  formdata.append("per_page", 1000);
  formdata.append("page_no", 1);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}get-coin-purchase-txn-list`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const onRedeemQuizCredits = (tuteehubId, redeemCode, token) => {

  const baseURL = QBANK_BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehubId);
  formdata.append("code", redeemCode);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}qb/redeem-credit`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const onRedeemCodeWithTH = (redeemCode, token) => {

  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append("redeem_code", redeemCode);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}user/redeem-notification-code`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};


export const getTransactionHistory = (tuteehubId) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formdata = new FormData();
  formdata.append("tuteehub_id", tuteehubId);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}get-coin-purchase-txn-list`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));

};

export const getFavQuestions = (token, moduleName, page) => {

  const baseURL = url(moduleName);
  return axios.get(`${baseURL}/question-favourite/list?page=${page}`, {
    headers: {
      Authorization: "Bearer " + token, //the token is a variable which holds the token
    },
  });
};

export const getQuestionsDetails = (id, token, moduleName) => {
  const baseURL = url(moduleName);
  
  return axios.get(`${baseURL}/get-question/detail/via-authentic-user/${id}`, {
    headers: {
      Authorization: "Bearer " + token, //the token is a variable which holds the token
    },
  });
};

export const postQuestionComment = (
  qId,
  value,
  commentId,
  token,
  moduleName
) => {
  const baseURL = url(moduleName);
  const id = idname(moduleName);
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append(id, qId);
  formdata.append("comment", value);
  formdata.append("commentId", commentId);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}/question-comment`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};

export const postFavouriteQuestion = (qId, value, token, moduleName) => {
  const baseURL = url(moduleName);
  const id = moduleName === "question-Bank" ? "questionId" : moduleName === "fullforms" ? "fullformQuestionId" : moduleName === "dictionary" ? "dictionaryQuestionId" : "interviewquestionId";
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append(id, qId);
  formdata.append("favourite", value);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}/question-favourite/add`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};

export const postQuestionRating = (qId, value, token, moduleName) => {
  const baseURL = url(moduleName);
  const id = idname(moduleName);
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append(id, qId);
  formdata.append("rating", value);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}/question-rating`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};

export const postQuestionLikedislike = (qId, value, token, moduleName) => {
  const baseURL = url(moduleName);
  const id = idname(moduleName);
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append(id, qId);
  formdata.append("voteType", value);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };
  return fetch(`${baseURL}/question-vote`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};

export const postQuestionReport = (qId, value, token, moduleName) => {

  const baseURL = url(moduleName);
  const id = idname(moduleName);
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formdata = new FormData();
  formdata.append(id, qId);
  formdata.append("report_message", value);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };

  return fetch(`${baseURL}/question-report`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};

export const mostViewedQuestions = (id, token, currentPage, moduleName) => {
  const baseURL = url(moduleName);
  return axios.get(
    `${baseURL}/question-most/${id}/viewed?page=${currentPage}`,
    {
      headers: {
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    }
  );
};
export const handleShareIncrease = (baseURL, type, shareContentId) => {
   
      return axios.get(`${baseURL}/update-count?id=${shareContentId}&type=${type}`)
        // .then((response) => response.json())
        // .catch((error) => console.log("error", error)); 
}