import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { useAuth } from "../../../../Context/AuthContext";
import { toast } from "react-toastify";
import { getExamResult, getMyExamData } from "../../../../api/exam";
import { ERROR_SOMETHING_WENT_WRONG } from "../../../../utils/utils";
import { Loadingicon } from "../../../../AppIcons";

import notFound from '../../../../assets/images/not_found_new.png';
import './result.css'
import { FaClock, FaPercentage, FaCheckCircle, FaTimesCircle, FaListAlt, FaCalendarAlt } from 'react-icons/fa'; // Import icons
import SectionDescription from "../../../common/sectionDescription";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const MyExams = () => {
  const { globalToken } = useAuth();
  const [data, setData] = useState(null);
  const [subjectData, setSubjectData] = useState(null);
  const [subject, setSubject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [filteredExams, setFilteredExams] = useState([]);
  const [totalRecordFound, setTotalRecordFound] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [examsTableData, setExamsTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [subjectTableData, setSubjectTableData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [isDataVisible, setIsDataVisible] = useState(true);
  const navigation = useNavigate();
  const isMobile = window.innerWidth <= 768;

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    handleExamsResult(currentPage);
  }, [currentPage]);

  const handleExamsResult = async (page = 1) => {
    const resExamResult = await getMyExamData({ token: globalToken });
    if (resExamResult?.success) {
      const subjectWisePerformance = resExamResult?.data?.original?.subject_wise_performance;
      const updatedSubjectData = subjectWisePerformance.map((subject) => {
        const correctAnswersSubject = subject.correct_answers;
      const incorrectAnswersSubject = subject.incorrect_answers;
      const totalQuestionsSubject = subject.total_questions;

      const totalAttemptedSubject = correctAnswersSubject + incorrectAnswersSubject;
      const performanceSubject = totalAttemptedSubject ? Math.round((correctAnswersSubject / totalAttemptedSubject) * 100) : 0;
      const accuracySubject = totalQuestionsSubject ? (correctAnswersSubject / totalQuestionsSubject) * 100 : 0;

      return {
        ...subject,
        performance: performanceSubject,
        accuracy: accuracySubject,
      };
      });
      setSubjectData(updatedSubjectData);

      const examTableData = resExamResult?.data?.original?.all_exams;
      const resultsPerPage = 10;
      const totalPages = Math.ceil(examTableData.length / resultsPerPage);
      setTotalPages(totalPages);
      const currentResults = examTableData
        ?.slice()
        .reverse()
        .slice(
          (currentPage - 1) * resultsPerPage,
          currentPage * resultsPerPage
        );
      setSubjectTableData(examTableData)
      setExamsTableData(currentResults);

      const examData = resExamResult?.data?.original?.overall_statistics;
      // Extract values from the response
      const correctAnswers = examData.correct_answers;
      const incorrectAnswers = examData.incorrect_answers;
      const totalQuestions = examData.total_questions;
  
      // Calculate Performance and Accuracy
      const totalAttempted = correctAnswers + incorrectAnswers;
      const performance = totalAttempted ? ((correctAnswers / totalAttempted) * 100).toFixed(2) : 0;
      const accuracy = totalQuestions ? ((correctAnswers / totalQuestions) * 100).toFixed(2) : 0;
  
      // Set the calculated data
      setData({
        ...examData,
        performance,
        accuracy,
      });
    } else {
        toast.error(ERROR_SOMETHING_WENT_WRONG)
    }
    setLoading(false);
  };

  const handleSubjectSelect = (subjectName) => {
    setSelectedSubject(subjectName);
    if (!subjectName) {
      setSubject(subjectData)
      setFilteredExams([]);
      setIsTableVisible(true);
      handleExamsResult();
      return;
    }
    setIsTableVisible(false);
    const examsForSelectedSubject = subjectData
    .filter((subject) => subject.subject_title === subjectName);
    setFilteredExams(examsForSelectedSubject[0]?.exam_details);
  setTotalRecordFound(examsForSelectedSubject[0]?.exam_details?.length)
  setData(examsForSelectedSubject[0]);
  setSubject(Object.values(examsForSelectedSubject));
};

  const doughnutData = {
    labels: ["Not Attempted Questions", "Correct Answers", "Incorrect Answers"],
    datasets: [
      {
        data: [
          data?.not_attempted,
          data?.correct_answers,
          data?.incorrect_answers,
        ],
        backgroundColor: ["#C4C4C4", "#28a745", "#FF5C5C"],
        hoverBackgroundColor: ["#C4C4C4", "#28a745", "#FF5C5C"],
      },
    ],
    datalabels: {
      enabled: isMobile?true:false,
    }
  };
  const barData = {
    labels: (subject || subjectData)?.map((item) => item.subject_title),
    datasets: [
      {
        label: "Performance %",
        data: (subject || subjectData)?.map((item) => item.performance),
        backgroundColor: "#4A90E2",
        hoverBackgroundColor: "#4A90E2",
      },
      {
        label: "Accuracy %",
        data: (subject || subjectData)?.map((item) => item.accuracy),
        backgroundColor: "#F5A623",
        hoverBackgroundColor: "#F5A623",
      },
    ],
  };
  const handleDateFilter = async () => {
    if (startDate && endDate) {
      const filteredByDate = await getMyExamData({ token: globalToken, startDate: startDate, endDate: endDate });
      setTotalRecordFound(filteredByDate?.data?.total_records);
      setFilteredExams(filteredByDate?.data?.results);
      setIsTableVisible(false);
      setIsDataVisible(false);
    }
  };
  const handleClearFilter = () => {
    setStartDate(''); // Clear start date
    setEndDate(''); // Clear end date
    // Optionally reset data or state to original values
    setFilteredExams([]);
    setIsDataVisible(true); // Clear
    setIsTableVisible(true);
    handleExamsResult();
  };

  const barOptions = {
    plugins: {
      tooltip: {
        enabled: true, // Tooltips only appear on hover
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const roundedValue = parseFloat(value).toFixed(2); // Round to 2 decimal places
            const formattedValue = roundedValue.endsWith(".00") 
              ? parseInt(roundedValue) 
              : roundedValue; // Remove ".00" if present
            return `${context.dataset.label}: ${formattedValue}`; // Return formatted tooltip content
          },
        },        
      },
      datalabels: {
        display: false, // Ensures no data labels are shown on bars
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const doughnutOptions = {
    plugins: {
      legend: {
        display: true, // Show the legend
        position: 'bottom',
        labels: {
          padding: 30, // Add space between the Doughnut chart and legend items
        }
       
      },
      dataLabels: {
        enabled: isMobile?true:false,
        style: {
          fontSize: "10px",
          fontWeight: "bold",
          colors: ["#fff"],
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 2,
          color: "#000",
          opacity: 0.25,
        },
        formatter: (val, opts) => {
          const index = opts.seriesIndex;
          return doughnutData[index]; 
        },
      },
    },
    hover: {
      mode: null, // Disable hover interactions (no scale or color change)
    },
    elements: {
      arc: {
        hoverBackgroundColor: null, // Keep original background color on hover
        hoverBorderColor: null, // Keep original border color on hover
        hoverBorderWidth: null, // Prevent border width change on hover
        hoverOffset: 0, // Prevent arc scaling on hover
      },
    },
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex justify-content-between mb-3">
          <nav
            aria-label="breadcrumb"
            className="pt-0 px-0 pb-0 breadcrumb-card"
          >
            <ol
              className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea"
              style={{ display: "flex", flexWrap: "nowrap" }}
            >
              <li className={`breadcrumb-item capitalize rest-bread`}>
                <NavLink
                  to="/"
                  style={{ fontSize: 14, textDecoration: "none" }}
                >
                  Home
                </NavLink>
              </li>
              <li className={`breadcrumb-item capitalize rest-bread`}>
                <NavLink
                  to="/exams"
                  style={{ fontSize: 14, textDecoration: "none" }}
                >
                  Exams
                </NavLink>
              </li>
              <li
                className={`breadcrumb-item capitalize active clamping ellipsis`}
              >
                My Exams
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {loading ? (
        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
          <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="card mt-2 mb-3">
            <div className="card-body">
            <div className="d-md-flex filter-result-exam justify-content-between align-items-center">
            {/* Left: My Performance */}
            <h1 className="mb-0"></h1>

            {/* Right: Filters */}
            <div className="d-md-flex align-items-center">
              {/* Filter by Subject */}
              <select
                className="form-select me-3"
                style={{ width: "200px", height: "40px" }}
                value={selectedSubject}
                onChange={(e) => handleSubjectSelect(e.target.value)}
              >
                <option value="">All Subjects</option>
                {subjectData?.map((subject) => (
                  <option
                    key={subject.subject_title}
                    value={subject.subject_title}
                  >
                    {subject.subject_title}
                  </option>
                ))}
              </select>

              {/* Date Filter Dropdown */}
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  style={{
                    borderColor: 'rgb(222, 226, 230)',
                    minHeight: '41px',
                    background: 'none',
                    color: '#333',
                  }}
                  type="button"
                  id="dateFilterDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-filter me-2"></i>
                  Date
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dateFilterDropdown"
                >
                  <li>
                    <div className="d-flex justify-content-between p-3 gap-2">
                      <div>
                        <label htmlFor="startDate">Start Date:</label>
                        <input
                          type="date"
                          id="startDate"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <label htmlFor="endDate">End Date:</label>
                        <input
                          type="date"
                          id="endDate"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex justify-content-between px-3 py-2">
                      <button
                        className="btn btn-primary btn-sm me-2"
                        onClick={handleDateFilter}
                      >
                        Apply Date Filter
                      </button>
                      <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={handleClearFilter}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
            </div>
          </div>
          

          {
            <>
            {console.log(isDataVisible , filteredExams?.length, isTableVisible)}
            {
              (!isDataVisible && !filteredExams?.length && !isTableVisible)?<div className="card"><div className="card-body">
                 <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div></div></div>:<>
              {isDataVisible && (
                <>
                  <div className="row">
                    {/* Card for Doughnut Chart */}
                    <div className="col-md-6 mb-md-0 mb-4">
                      <div
                        className="card h-100 px-md-4"
                      >
                        <div className="card-body">
                        {
                          <><div className="d-flex align-items-center justify-content-between">
                            <SectionDescription title={`<strong>Exam Performance Overview to Date</strong>`} subTitle={``} />
                        </div>

                        <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>
                        }
                          <div>
                              <Doughnut data={doughnutData} options={doughnutOptions}/>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                    {/* Card for Performance & Accuracy */}
                    <div className="col-md-6">
                      <div
                        className="card h-100 px-md-4"
                      >
                        {/* Flex Container for Card Body */}
                        <div className="card-body d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between">
                            <SectionDescription title={`<strong>Performance & Accuracy</strong>`} subTitle={``} />
                        </div>

                        <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                          {/* Performance Section */}
                          <p className="card-title mb-2"><strong>Performance</strong></p>
                          <div className="progress mb-3">
                            <div
                              className="progress-bar"
                              style={{ width: `${data?.performance}%`, background:'#9575CD' }}
                            >
                              {data?.performance}%
                            </div>
                          </div>
                          <p
                            className="text-muted"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Performance measures the percentage of correct
                            answers out of the total questions attempted.
                          </p>
                          {/* Accuracy Section */}
                          <p className="card-title mb-2"><strong>Accuracy</strong></p>
                          <div className="progress mb-3">
                            <div
                              className="progress-bar bg-warning"
                              style={{ width: `${data?.accuracy}%` }}
                            >
                              {data?.accuracy}%
                            </div>
                          </div>
                          <p
                            className="text-muted"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Accuracy measures the percentage of correct answers
                            out of the total questions in the exam.
                          </p>
                          {/* Statistics Section */}
                          <div className="mt-2 mb-2">
                          <p className="card-title mb-2"><strong>Statistics</strong></p>
                            <div className="d-flex align-items-center justify-between w-100 mb-2 statstistics">
                              <span>                                
                               <i className="bi bi-calendar2-check-fill text-success me-2"></i>
                                Exams Taken                              
                              </span>
                              <strong>{data?.total_exams_taken || data?.total_exams}</strong>
                            </div>
                            <div className="d-flex align-items-center justify-between w-100 mb-2 statstistics">
                              
                              <span>
                              <i className="bi bi-trophy-fill text-warning me-2"></i>
                                Highest Score                                
                              </span>
                              <strong>{data?.overall_highest_score ||
                                  data?.highest_marks}</strong>
                            </div>
                            <div className="d-flex align-items-center justify-between w-100 mb-2 statstistics">
                              
                              <span><i className="bi bi-bar-chart-line-fill text-primary me-2"></i> Average Score                          
                              </span>
                              <strong>{data?.overall_average_score ||
                                  data?.average_score}</strong>
                            </div>
                          </div>

                          {/* Note Section Positioned at the Bottom */}
                          <small className="text-muted mt-auto">
                            <hr />
                            Note: More questions attempted with higher Accuracy
                            will lead to higher Performance. Clearing UPSC
                            Prelims requires a performance of 50–55%.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                   className="row mt-4"
                    // style={{
                    //   marginTop: "20px",
                    //   display: "flex",
                    //   justifyContent: "space-evenly",
                    //   gap: "20px",
                    //   backgroundColor: "#f9f9f9",
                    //   paddingTop: "20px",
                    //   borderRadius: "8px",
                    //   border: "1px solid #ddd",
                    // }}
                  >
                    <div className="col-md-6 mb-md-0 mb-4">
                        <div
                            className="card h-100 px-md-4"
                          >
                            <div className="card-body d-flex flex-column">
                            <div className="d-flex align-items-center justify-content-between">
                                <SectionDescription title={`<strong>Performance Summary</strong>`} subTitle={``} />
                            </div>
                            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                            <div className="d-flex align-items-center justify-between w-100 mb-2 statstistics">
                              
                                <span>Correct Answers                         
                              </span>
                                <strong>{data?.correct_answers || 0}</strong>
                            </div>
                            <div className="d-flex align-items-center justify-between w-100 mb-2 statstistics">
                              
                              <span>Attempted                        
                            </span>
                              <strong>{data.correct_answers + data.incorrect_answers || 0}</strong>
                          </div>
                          </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div
                            className="card h-100 px-md-4"
                          >
                            <div className="card-body d-flex flex-column">
                            <div className="d-flex align-items-center justify-content-between">
                                <SectionDescription title={`<strong>Accuracy Summary</strong>`} subTitle={``} />
                            </div>
                            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                            <div className="d-flex align-items-center justify-between w-100 mb-2 statstistics">
                              
                                <span>Correct Answers                         
                              </span>
                                <strong>{data?.correct_answers || 0}</strong>
                            </div>
                            <div className="d-flex align-items-center justify-between w-100 mb-2 statstistics">
                              
                              <span>Attempted                        
                            </span>
                              <strong>{data.total_questions || 0}</strong>
                          </div>
                          </div>
                        </div>
                    </div>
                

                    
                    {/* Performance Summary */}
                    {/* <div style={{ textAlign: "center" }}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          marginBottom: "15px",
                        }}
                      >
                        Performance Summary :
                      </h6>
                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          <h6 style={{ color: "#4A90E2", fontWeight: "bold" }}>
                            Correct Answers
                          </h6>
                          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                            {data?.correct_answers || 0}
                          </p>
                        </div>
                        <div
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          <h6 style={{ color: "#F5A623", fontWeight: "bold" }}>
                            Attempted
                          </h6>
                          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                            {data.correct_answers + data.incorrect_answers || 0}
                          </p>
                        </div>
                      </div>
                    </div> */}

                    {/* Accuracy Summary */}
                    {/* <div style={{ textAlign: "center" }}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          marginBottom: "15px",
                        }}
                      >
                        Accuracy Summary :
                      </h6>
                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          <h6 style={{ color: "#4A90E2", fontWeight: "bold" }}>
                            Correct Answers
                          </h6>
                          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                            {data.correct_answers || 0}
                          </p>
                        </div>
                        <div
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          <h6 style={{ color: "#F5A623", fontWeight: "bold" }}>
                            Total Questions
                          </h6>
                          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                            {data.total_questions || 0}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-4">
                    <div className="card">
                      <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                                <SectionDescription title={`<strong>Subjectwise Analysis</strong>`} subTitle={``} />
                            </div>
                            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                        {/* <div style={{height:200,width:'100%'}}> */}
                        <Bar data={barData} options={barOptions} />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {filteredExams?.length ? (
                <>
                  <div className="mt-4">
                    {/* <h5>
                      Exams for{" "}
                      {selectedSubject
                        ? selectedSubject
                        : startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : "All Subjects or All Dates"}
                      : {totalRecordFound} in Total
                    </h5>
                    <hr /> */}
                    <ul className="p-0">
                      {filteredExams?.map((exam, index) => {
                        const getOrdinalSuffix = (num) => {
                          const suffixes = ["th", "st", "nd", "rd"];
                          const value = num % 100;
                          return (
                            suffixes[(value - 20) % 10] ||
                            suffixes[value] ||
                            suffixes[0]
                          );
                        };
                        const attemptNumber = index + 1;
                        return (
                          <div
                            className="col-md-12 px-0"
                            key={`${exam.exam_title}-${index}`}
                          >
                            <div className="card mb-3">
                                <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between">
                                    <SectionDescription title={`<strong> Exams for
                      ${selectedSubject
                        ? selectedSubject
                        : startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : "All Subjects or All Dates"}
                      : ${totalRecordFound} in Total</strong>`} subTitle={``} />
                                </div>
                               </div>
                            </div>
                            <div
                              className="card mb-3"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "15px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                width: "100%",
                                border: "1px solid #ddd",
                              }}
                            >
                              {/* Title Section */}
                              
                              <div className="rounded" style={{flex: 1,padding:'1rem', background:'#f9f9f9'}}>
                                <h5
                                  className="card-title"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    marginBottom: "10px",
                                    color: "#333",
                                  }}
                                >
                                  {exam.exam_title}
                                  <hr />
                                </h5>
                                {/* Two Columns for Details */}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    padding: "15px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "8px",
                                    fontFamily: "'Roboto', sans-serif",
                                  }}
                                >
                                  {/* Column 1: Marks Details */}
                                  <div style={{ flex: 1, marginRight: "20px" }}>
                                    <p
                                      style={{
                                        margin: "10px 0",
                                        fontSize: "1rem",
                                        color: "#333",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Total Marks:
                                      <span
                                        style={{
                                          marginLeft: "8px",
                                          color: "#007BFF",
                                        }}
                                      >
                                        {exam.total_marks}
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        margin: "10px 0",
                                        fontSize: "1rem",
                                        color: "#333",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Marks Obtained:
                                      <span
                                        style={{
                                          marginLeft: "8px",
                                          color: "#28A745",
                                        }}
                                      >
                                        {exam.marks_obtained}
                                      </span>
                                    </p>
                                  </div>

                                  {/* Column 2: Performance Details */}
                                  <div style={{ flex: 1, textAlign: "left" }}>
                                    <p
                                      style={{
                                        margin: "10px 0",
                                        fontSize: "1rem",
                                        color: "#333",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Percentage:
                                      <span
                                        style={{
                                          marginLeft: "8px",
                                          color: "#FFC107",
                                        }}
                                      >
                                        {exam.percentage}%
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        margin: "10px 0",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Exam Status:
                                      <span className="capitalize" style={{ marginLeft: "8px", color:exam.exam_status == 'pass' ? '#28A745' : '#DC3545' }}>
                                        {exam.exam_status}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    margin: "10px 0",
                                    fontSize: "1rem",
                                    color: "#555",
                                    fontWeight: "normal",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {attemptNumber}
                                  {getOrdinalSuffix(index + 1)} Attempt On:
                                  <span
                                    style={{
                                      marginLeft: "8px",
                                      color: "#007BFF",
                                    }}
                                  >
                                    {new Date(exam.date).toLocaleDateString()}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </>
              ) : null}

              {isTableVisible && (
                <div className="mt-4">
                  <div className="card">
                    <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                                <SectionDescription title={`<strong>Detailed Exam Result</strong>`} subTitle={``} />
                            </div>
                            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                      {/* Table starts here */}
                      <div className="table-responsive">
                      <table className="table c-table table-bordered table-striped">
                       
                        <thead
                          style={{
                            backgroundColor: "gray !important", // Grayish background for header
                            fontWeight: "bold !important",
                          }}
                        >
                          <tr>
                            <th>ID</th>
                            <th>Exam Date</th>
                            <th>Exam Name</th>
                            <th>Subject</th>
                            <th>Topic</th>
                            <th>Total Marks</th>
                            <th>Marks Obtained</th>
                            <th>Percentage</th>
                            {/* <th>Credits</th> */}
                            <th>Exam Status</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#ffffff" }}>
                          {" "}
                          {/* White background for data */}
                         
                          {examsTableData
                            ?.slice()
                            .reverse()
                            .map((exam, index) => (
                              <tr key={index}>
                                <td>{exam.id}</td>
                                <td>
                                  {new Date(exam.updated_at).toLocaleString()}
                                </td>
                                <td onClick={()=>navigation(`/exams/detail/${exam.exam?.slug}`)} className="cursor-pointer"><span className="theme-hover-color ">{exam.exam?.title}</span></td>
                                <td>{exam.exam?.subject?.subject_title}</td>
                                <td>{exam.exam?.topic?.topic_name}</td>
                                <td>{exam.total_marks}</td>
                                <td>{exam.marks_obtained}</td>
                                <td>{exam.percentage}%</td>
                                {/* <td>{exam.credits}</td> */}
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    color:exam.exam_status == 'pass' ? '#28A745' : '#DC3545'
                                  }}
                                >
                                  {exam.exam_status}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <button
                          className="btn btn-light"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <i className="fa fa-angle-left"></i>
                        </button>
                        <span className="color-gray">
                          Page {currentPage} of {totalPages}
                        </span>
                        <button
                          className="btn btn-light"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              </>
            }
              
            </>
          }
        </div>
      )}
    </>
  );
};

export default MyExams;
