import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
// import StarRatings from 'react-star-ratings';
import { useAuth } from '../../../../Context/AuthContext';
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    placeholderImages,
    CDN_BASE_URL,
    fetchUserInfo,
    viewRecentVisits
} from '../../../../utils/utils';
import {
    blogsCommentAndReply,
    bookMarkBlog,
    likeUnlikeDislike,
    reportAgainstBlog,
    writeReviewBlog
} from '../../../../api/blog';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import ViewAllButton from '../../../common/viewallbutton';
import CommonComments from '../../../common/comments/commonComments';
import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import LyricsComments from '../../../common/comments/lyricsComments';
import styles from "../../../../assets/css/styles/Home.module.css";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import { getBlogDetails } from '../../../../api/blog';
import BlogsComments from '../../../common/comments/blogsComments';
import * as Yup from "yup";
import StarRatings from 'react-star-ratings';
import { Formik } from 'formik';

function BlogDetail() {
    const { globalToken, blogToken, user, setUser, setFinance, setIsEmailVerifyCover } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [blogDetailData, setBlogDetailData] = useState([]);
    const [posterImageBaseURL, setPosterImageBaseURL] = useState();
    const [blogId, setBlogId] = useState();
    const [blogSlug, setBlogSlug] = useState();
    const [isLocationChange, setIsLocationChange] = useState(false);
    const [nextPrevLoader, setNextPrevLoader] = useState(false);
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const iconShareRef = useRef(null);
    const shareRef = useRef(null);
    const currentPath = location.pathname;
    const [isLiked, setIsLiked] = useState(false);
    const [isFav, setIsFav] = useState(false);
    const [avgRating, setAvgRating] = useState(0);
    const [isDisliked, setIsDisliked] = useState(false);
    const [ratingLoading, setRatingLoading] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);

    const [reportVal, setReportVal] = useState(0);
    const [ratingVal, setRatingVal] = useState(0);

    const DisplayingErrorMessagesSchemaRating = Yup.object().shape({
        rating: Yup.string().required("Rating is required"),
        reviews: Yup.string().required("Review is required")

    });
    const DisplayingErrorMessagesSchemaReport = Yup.object().shape({
        report: Yup.string().required("Report message is required")

    });

    // Fetches lyrics details when component mounts or params change
    // useEffect(() => {

    //     if (params?.blogSlug) {
    //         const bId = (params?.blogSlug).split('-').pop();
    //         setBlogSlug(params?.blogSlug);
    //         setBlogId(bId)
    //         handleFetchBlogDetail(bId);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (params?.blogSlug) {
    //         const bId = (params?.blogSlug).split('-').pop();
    //         setBlogSlug(params?.blogSlug);
    //         setBlogId(bId)
    //         handleFetchBlogDetail(bId);
    //     }
    // }, [isLocationChange]);

    useEffect(() => {
        if (params?.blogSlug) {
            const slugSplit = params.blogSlug.split('-');
            let bId = slugSplit.pop();
            if (isNaN(bId) || !bId) {
                setLoading(false);
                setIsNotFound(true);
                return;
            }

            setBlogSlug(params.blogSlug);
            setBlogId(bId);
            handleFetchBlogDetail(bId);
            viewRecentVisits(globalToken);
        }
    }, []);

    useEffect(() => {
        if (params?.blogSlug) {
            const slugSplit = params.blogSlug.split('-');
            let bId = slugSplit.pop();

            if (isNaN(bId) || !bId) {
                setLoading(false);
                setIsNotFound(true);
                return;
            }

            setBlogSlug(params.blogSlug);
            setBlogId(bId);
            handleFetchBlogDetail(bId);
        }
    }, [isLocationChange]);


    const openHideRatingModal = () => {
        if (isOpenRatingModal) {
            setRatingVal(0)
        }
        setIsOpenRatingModal(!isOpenRatingModal);
    }

    const openHideReportModal = () => {
        setIsOpenReportModal(!isOpenReportModal);
    }

    const changeRating = (newRating) => {
        setRatingVal(newRating);
    };
    const handleReviewRating = async (values) => {
        setRatingLoading(true);
        const resReviewRating = await writeReviewBlog({ token: globalToken, articleId: blogDetailData?.id, reviews: values?.reviews, rating: values?.rating });
        // alert(JSON.stringify(resReviewRating))
        // console.log(resReviewRating?.id)
        if (resReviewRating?.success) {
            handleFetchBlogDetail(blogDetailData.id);
            toast.success('Thanks for your feedback!');
            openHideRatingModal(false);
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setRatingLoading(false);
    }

    const handleReport = async (values) => {
        setReportLoading(true);
        const resReviewRating = await reportAgainstBlog({ token: globalToken, articleId: blogDetailData?.id, report: values?.report });
        // alert(JSON.stringify(resReviewRating))
        if (resReviewRating?.success) {
            handleFetchBlogDetail(blogDetailData.id);
            toast.success('Report registered');
            openHideReportModal(false);
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setReportLoading(false);
    }

    // Handles fetching lyrics details by lyrics ID
    const handleFetchBlogDetail = async (bId) => {

        const resBlogsDetail = await getBlogDetails({ token: globalToken, blogId: bId });
        // console.log('blog detail', resBlogsDetail);
        setIsLiked(resBlogsDetail?.data?.is_like);
        setIsDisliked(resBlogsDetail?.data?.is_dislike);
        setIsFav(resBlogsDetail?.data?.is_favourite);
        setAvgRating(resBlogsDetail?.data?.avg_rating);
        setLoading(false);
        setLoadingLoadMore(false);
        if (resBlogsDetail?.success) {
            setIsNotFound(false);
            if (resBlogsDetail?.data?.details) {
                setBlogDetailData(resBlogsDetail?.data?.details);
            } else {
                setBlogDetailData({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false);
    }

    // Handles adding/removing lyrics from favorites
    const FavouriteButton = ({ is_fav_camp }) => {

        const [favBtnLoading, setFavBtnLoading] = useState(false);

        const handleBlogFav = async () => {
            setFavBtnLoading(true);
            const token = globalToken;
            const res = await bookMarkBlog({ token: token, articleId: blogDetailData.id, favourite: is_fav_camp ? 'unfav' : 'fav' });
            if (res?.success) {
                handleFetchBlogDetail(blogDetailData.id);
            }
            setFavBtnLoading(false);
        }

        return (
            <button
                type="button"
                disabled={favBtnLoading}
                onClick={handleBlogFav}
                className="btn bg-white p-0 d-flex btn-like-circular"
                style={{ border: 'none', boxShadow: 'none' }}
            >
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : (
                    <>
                        {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
                    </>
                )}
            </button>
        );
    }

    // Handles sharing the lyrics
    const InlineShare = ({ blogsData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                    <span ref={shareRef}><Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubBlogs}/detail/${blogsData?.slug}`,
                            quote: `${blogsData?.description}`,
                            hashing: `#tuteehub`,
                            title: `${blogsData?.title}`,
                            id: blogsData?.id
                        }}
                        isCustomPos={true}
                        refetchDetails={handleFetchBlogDetail}
                        isShare={isShareActive}
                        setIsShare={setIsShareActive}
                        iconShareRef={iconShareRef}
                        shareRef={shareRef}
                    /></span>
                ) : null}
                <span
                    ref={iconShareRef}
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

    // Handles upvoting or downvoting the lyrics
    const handleLikeUnlikeDislike = async (voteType, type) => {
        if (type === 'dLike') setDownvoteBtnLoader(true);
        else setUpvoteBtnLoader(true);
        const resLikeUnlike = await likeUnlikeDislike({ token: globalToken, articleId: blogDetailData.id, voteType });
        if (resLikeUnlike?.success) {
            fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
            handleFetchBlogDetail(blogDetailData.id);
            toast.success(resLikeUnlike?.data?.message);
        }
    }

    // Handles submitting comments and replies
    const handleSubmitComment = async (commentId, setComment, comment, isReply, commentLoader, setIsReplyShow) => {

        if (user?.email_verified == 0) {

            toast.error('Please verify your email');
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
            return;
        }

        commentLoader(true);
        try {
            const resCommentCommit = await blogsCommentAndReply({
                token: globalToken,
                blogId: blogDetailData?.id,
                commentId: isReply ? commentId : 0,
                comment,
            });
            if (resCommentCommit?.success) {
                fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
                setIsReplyShow(false);
                toast.success(resCommentCommit?.message);
                handleFetchBlogDetail(blogDetailData?.id);
            }
            setComment('');
        } catch (error) {
            toast.error('Error sending comment');
        } finally {
            commentLoader(false);
        }
    }

    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };

    const modifyAnchorTagsSelf = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_self');
        });
        return doc.documentElement.innerHTML;
    };

    return (
        <>
            {loading ? (
                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                        <Loadingicon />
                    </div>
                </div>
            ) : <>
                <div className='d-md-flex justify-content-between mb-3'>
                    <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/blogs' style={{ fontSize: 14, textDecoration: 'none' }}>Blogs</NavLink>
                            </li>
                            {
                                isNotFound ? null : <> <li className={`breadcrumb-item capitalize rest-bread`}>
                                    <NavLink to={`/blogs/${blogDetailData?.cat?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{blogDetailData?.cat?.name}</NavLink>
                                </li>
                                    <li className={`breadcrumb-item capitalize rest-bread`}>
                                        <NavLink to={`/blogs/topic/${blogDetailData?.scat?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{blogDetailData?.scat?.name}</NavLink>
                                    </li></>
                            }

                            <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(params.blogSlug)}
                            </li>
                        </ol>
                    </nav>
                    <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                        <button
                            className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{ backgroundColor: '#ff7200' }}
                            onClick={() => navigation('/blogs/my-blogs')}
                        >
                            My Blogs
                        </button>
                        <button
                            className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{ backgroundColor: '#ff7200' }}
                            onClick={() => navigation('/blogs/post-a-blog')}
                        >
                            Post A Blog
                        </button>
                    </div>
                </div>
                {
                    isNotFound ?
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                            <div className="flex-column not-found d-flex text-center min-h-full" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div></div> :
                        <div className="row">
                            <div className="col-md-12">
                                <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                    <div className="px-0">
                                        <div className="flex-column d-flex flex-md-row justify-content-between">

                                            <div className="mr-md-5 pr-md-5 flex-1">
                                                <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                                    <strong>{blogDetailData?.title}</strong>
                                                    <span className="d-flex gap-3 align-items-center">
                                                        <FavouriteButton is_fav_camp={isFav} />
                                                        <InlineShare blogsData={blogDetailData} isShareActive={isShareActive} setIsShareActive={setIsShareActive} />
                                                    </span>
                                                </h1>
                                                <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">

                                                    <span className="text-black-50 gig-table items-center d-md-flex">
                                                        <small style={{ marginTop: 1, float: 'left' }}>
                                                            <StarRatings
                                                                rating={avgRating || 0}
                                                                starRatedColor="#F08645"
                                                                changeRating={null}
                                                                numberOfStars={5}
                                                                starDimension="15px"
                                                                starSpacing="0"
                                                                name="rating"
                                                            /></small>
                                                        {/* <a className="a-hover cursor-pointer text-black-50" onClick={()=>alert(1)}>{lyricsDetailData.album?.name}</a> */}
                                                        <span className="big-dots"></span>
                                                        {/* <a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/blogs/${blogDetailData?.cat?.slug}`)}>{blogDetailData?.cat?.name}</a>
                                            <span className="big-dots"></span>
                                             <a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/blogs/topic/${blogDetailData?.scat?.slug}`)}>{blogDetailData?.scat?.name}</a>
                                             <span className="big-dots"></span> */}
                                                        <span>{nFormatter(blogDetailData?.views)} views</span>
                                                        <span className="big-dots"></span>
                                                        <span>{blogDetailData?.comments_count} comments</span>
                                                        <span className="big-dots"></span>
                                                        <span>{blogDetailData?.alikes_count} up votes</span>
                                                        {
                                                            blogToken?.id !== blogDetailData?.user_id ? <>
                                                                {
                                                                    upvoteBtnLoader ? <span className="custom-loader-small ms-2 theme-color">
                                                                        <Loadingicon />
                                                                    </span> : <><i
                                                                        className={`fas fa-thumbs-up cursor-pointer ms-2 ${isLiked && "text-themecolor"
                                                                            }`}
                                                                        onClick={() => {
                                                                            if (user?.email_verified == 1) {
                                                                                handleLikeUnlikeDislike(isLiked ? "unlike" : "like", 'lke')
                                                                            } else {
                                                                                toast.error('Please verify your email');
                                                                                setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                            }
                                                                        }

                                                                        }
                                                                    ></i>{" "}</>
                                                                }
                                                            </> : null
                                                        }

                                                        <span className="big-dots"></span>
                                                        <span>{blogDetailData?.adislikes_count} down votes</span>

                                                        {
                                                            blogToken?.id !== blogDetailData?.user_id ? <>
                                                                {
                                                                    downvoteBtnLoader ? <span className="custom-loader-small ms-2 theme-color">
                                                                        <Loadingicon />
                                                                    </span> : <><i
                                                                        className={`fas fa-thumbs-down cursor-pointer ms-2 ${isDisliked && "text-themecolor"
                                                                            }`}
                                                                        onClick={() => {
                                                                            if (user?.email_verified == 1) {
                                                                                handleLikeUnlikeDislike(isDisliked ? "rmdislike" : "dislike", 'dLike')
                                                                            } else {
                                                                                toast.error('Please verify your email');
                                                                                setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                            }
                                                                        }
                                                                        }
                                                                    ></i>{" "}</>
                                                                }
                                                            </> : null
                                                        }

                                                        <span className="big-dots"></span>
                                                        <span>{nFormatter(blogDetailData?.share_count)} shares</span>
                                                        <span className='big-dots'></span>
                                                        <span>{convertDateFormat(blogDetailData?.posting_date || blogDetailData?.created_at)}</span>
                                                    </span>
                                                    {/* <span className="text-black-50">Published on {convertDateFormat(blogDetailData?.posting_date)}</span> */}

                                                </div>
                                                {
                                                    blogToken?.id !== blogDetailData?.user_id ? <div className="col-span-12 px-0 question-box question-box-card py-2 mt-3 mb-3 mb-1">

                                                        <button
                                                            className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                            onClick={() => {
                                                                if (user?.email_verified == 1) {
                                                                    openHideRatingModal()
                                                                } else {
                                                                    toast.error('Please verify your email');
                                                                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                }
                                                            }
                                                            }
                                                        >
                                                            Write A Review
                                                        </button>
                                                        <button
                                                            className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                            onClick={() => {
                                                                if (user?.email_verified == 1) {
                                                                    openHideReportModal()
                                                                } else {
                                                                    toast.error('Please verify your email');
                                                                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                }
                                                            }
                                                            }
                                                        >
                                                            Report
                                                        </button>
                                                        {/* <a
                                                download
                                                href={`${isHindiNews?'https://careernews.tuteehub.com/download-upcoming-hindi-news':'https://careernews.tuteehub.com/download-upcoming-career-news/latest-scheme-exam-news.pdf'}`}
                                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                onClick={() => alert('PDF Download')}
                                            >
                                                Download As PDF
                                            </a> */}
                                                    </div> : null
                                                }

                                                <hr className="heading-hrs mb-0 opacity-5" />
                                                <div className="thumbnail-containers rounded w-100">                                            {
                                                    blogDetailData?.image ? <img src={CDN_BASE_URL + blogDetailData?.image} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' /> : null
                                                    // <img src={require(placeholderImages?.postImagePlaceholder)} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' />
                                                }

                                                </div>

                                                <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                                    __html: modifyAnchorTags(`${blogDetailData?.description?.replace(/<p>&nbsp;<\/p>/g, '').trim()}`)
                                                }}></div>
                                                {/* <hr/>
                                        <div className="flex justify-between px-8 mb-3">
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor: !qDetail?.previous_question_id && !nextPrevIds(qDetail?.previous_url) ? '#ccc' : '#ff7200' }}
                                    disabled={(!qDetail?.previous_question_id && !nextPrevIds(qDetail?.previous_url)) || btnLoader}
                                    onClick={() => navigation()}
                                >Previous</button>
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor: !qDetail?.previous_question_id && !nextPrevIds(qDetail?.next_url) ? '#ccc' : '#ff7200' }}
                                    disabled={(!qDetail?.next_question_id && !nextPrevIds(qDetail?.next_url)) || btnLoader}
                                    onClick={() => }
                                >Next</button>
                                </div> */}
                                            </div>

                                        </div>


                                    </div>
                                </div>
                                {
                                    (blogDetailData?.faq) ? <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                                        <div className="heading-main position-relative my-3 overflow-hidden">
                                            <h5 className="text-left bg-white pr-3 b"><strong>FAQ</strong></h5>
                                            <hr className="heading-hr mb-0" />
                                        </div>
                                        {
                                            JSON.parse(blogDetailData?.faq)?.map((fq, i) => (<div key={i + 'faq'} className='faq-item'>
                                                <h6><strong>{fq?.question}</strong></h6>
                                                <p dangerouslySetInnerHTML={{ __html: fq?.answer }}></p>
                                                <hr style={{ borderColor: '#ccc' }} />
                                            </div>))
                                        }
                                    </div> : null
                                }

                                <div className="flex justify-between mt-4">
                                    <button id="nextprevbtn"
                                        className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                        style={{ backgroundColor: !blogDetailData?.prevUrl ? '#ccc' : '#ff7200' }}
                                        disabled={blogDetailData?.prevUrl ? false : true}
                                        onClick={() => { navigation(`/blogs/detail/${blogDetailData?.prevUrl?.slug}-${blogDetailData?.prevUrl?.id}`); setIsLocationChange(!isLocationChange);viewRecentVisits(globalToken); }}
                                    >
                                        {
                                            nextPrevLoader ? <div className="h-5 w-5 mr-2">
                                                <Loadingicon />
                                            </div> : <>Previous</>
                                        }

                                    </button>
                                    <button id="nextprevbtn"
                                        className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                        style={{ backgroundColor: !blogDetailData?.nextUrl ? '#ccc' : '#ff7200' }}
                                        disabled={blogDetailData?.nextUrl ? false : true}
                                        onClick={() => { navigation(`/blogs/detail/${blogDetailData?.nextUrl?.slug}-${blogDetailData?.nextUrl?.id}`); setIsLocationChange(!isLocationChange);viewRecentVisits(globalToken); }}
                                    >
                                        {
                                            nextPrevLoader ? <div className="h-5 w-5 mr-2">
                                                <Loadingicon />
                                            </div> : <>Next</>
                                        }
                                    </button>
                                </div>

                                <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body mt-4">
                                    <div className="heading-main position-relative my-3 overflow-hidden">
                                        <h5 className="text-left bg-white pr-3 b"><strong>Comments({blogDetailData?.comments_count})</strong></h5>
                                        <hr className="heading-hr mb-0" />
                                    </div>
                                    <BlogsComments cData={blogDetailData} handleSubmitComment={handleSubmitComment} />
                                </div>
                                {/* :null
                        } */}

                            </div>
                            <div className='col-md-12'>
                                <div className="col-md-12 my-4 w-full  border-none child-menu   card-body">
                                    {/* <SimilarNews newsSlug={blogDetailData?.slug} patternLinks={patternLinks} redirectionFn={()=>alert(1)} stripBottom={false}/> */}
                                </div>
                            </div>
                        </div>
                }
            </>
            }

            <Modal
                closeModal={openHideRatingModal}
                setIsOpen={setIsOpenRatingModal}
                isOpen={isOpenRatingModal}
                callBack={openHideRatingModal}
                title={'Write A Review'}
                modalFooter={true}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        reviews: null,
                        rating: null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaRating}
                    onSubmit={(values) => {
                        if (values) {
                            handleReviewRating(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>

                            <div className="mb-2">
                                <textarea
                                    disabled={false}
                                    type={'text'}
                                    name={'reviews'}
                                    autoComplete={'off'}
                                    id={'reviews'}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={'Write A Review'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    rows={5}

                                // value={}
                                ></textarea>
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                    {touched['reviews'] && errors['reviews'] && errors['reviews']}
                                </div>
                            </div>
                            <div className="mb-3 text-center">

                                <StarRatings
                                    rating={ratingVal}
                                    starRatedColor="#F08645"
                                    starHoverColor="rgb(253 160 104)"
                                    changeRating={(newRating) => { changeRating(newRating); setFieldValue('rating', newRating) }}
                                    numberOfStars={5}
                                    starDimension="35px"
                                    starSpacing="5px"
                                    name="rating"
                                />
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                    {touched['rating'] && errors['rating'] && errors['rating']}
                                </div>
                            </div>
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end">
                                {/* <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideRatingModal();}}
                                    >
                                    Cancel
                                    </button> */}
                                <button
                                    type="submit"
                                    disabled={ratingLoading}
                                    className="bg-themecolor text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                // onClick={()=>handleAcceptRejectCancel()}

                                >
                                    {ratingLoading ? (
                                        <div className="h-5 w-5">
                                            <Loadingicon />
                                        </div>
                                    ) : 'Submit'
                                    }
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>

            </Modal>


            {/* modal for report */}


            <Modal
                closeModal={openHideReportModal}
                setIsOpen={setIsOpenReportModal}
                isOpen={isOpenReportModal}
                callBack={openHideReportModal}
                title={'Report Against Company'}
                modalFooter={true}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        report: null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaReport}
                    onSubmit={(values) => {
                        if (values) {
                            handleReport(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>

                            <div className="mb-2">
                                <textarea
                                    disabled={false}
                                    type={'text'}
                                    name={'report'}
                                    autoComplete={'off'}
                                    id={'report'}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={'Write message'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    rows={5}

                                // value={}
                                ></textarea>
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                    {touched['report'] && errors['report'] && errors['report']}
                                </div>
                            </div>

                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end">
                                {/* <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideReportModal()}}
                                    >
                                    Cancel
                                    </button> */}
                                <button
                                    type="submit"
                                    disabled={reportLoading}
                                    className="bg-themecolor text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                // onClick={()=>handleAcceptRejectCancel()}

                                >
                                    {reportLoading ? (
                                        <div className="h-5 w-5">
                                            <Loadingicon />
                                        </div>
                                    ) : 'Submit'
                                    }
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>

            </Modal>
        </>
    );
}


export default BlogDetail;
