import { BASE_URL, BLOGS_BASE_URL } from "../../utils/utils";

export const getOverviewData = (params) => {
    const { query, startDate, endDate } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let url = `https://www.tuteehub.com/api/get-all-platform-counts`;
    if (startDate && endDate) {
        url += `?startdate =${startDate}&enddate=${endDate}`;
    } else if (query) {
        url += `?this_month=${query}`
    }
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getSystemStats = (params) =>{
    const { url, filter, lib } = params;
    let myHeaders = new Headers();
    let requestOptions = {
        method : 'GET',
        headers : myHeaders
    }
    let fetchUrl = `${url}?module=${lib}&this_month=${filter?.this_month}`
    return fetch(fetchUrl, requestOptions)
    .then((response)=> response.json())
    .catch((error) => console.log("error", error))
}

export const getMyPosts = (params) =>{
    const { filter, module, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method : 'GET',
        headers : myHeaders
    }

    let fetchUrl = `${BLOGS_BASE_URL}blogs/get-last-post?module=${module}&this_month=all`
    return fetch(fetchUrl, requestOptions)
    .then((response)=> response.json())
    .catch((error) => console.log("error", error))
}

export const getRecentVisits = (params) =>{
    const { token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method : 'GET',
        headers : myHeaders
    }

    let fetchUrl = `${BASE_URL}user/recent-visits`
    return fetch(fetchUrl, requestOptions)
    .then((response)=> response.json())
    .catch((error) => console.log("error", error))
}

export const getCreditStats = (params) =>{
    const { token, this_month, module } = params;
    // alert(JSON.stringify(params))
    // return;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method : 'GET',
        headers : myHeaders
    }
    let fetchUrl = `${BASE_URL}user/finance-report?this_month=${this_month}&module=${module}`
    return fetch(fetchUrl, requestOptions)
    .then((response)=> response.json())
    .catch((error) => console.log("error", error))
}

export const saveRecentVisits = (params) =>{
    const { token, recent_visits } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("recent_visits", recent_visits);
    let requestOptions = {
        method : 'POST',
        headers : myHeaders,
        body:formData
    }

    let fetchUrl = `${BASE_URL}user/recent-visits`
    return fetch(fetchUrl, requestOptions)
    .then((response)=> response.json())
    .catch((error) => console.log("error", error))
}