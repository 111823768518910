import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CustomSelect from '../customselect';
import { getGigsConfigurations, getTaskCategories } from '../../../api/OurService';
import { Formik } from 'formik';
import * as Yup from "yup";
import ReactDatePicker from 'react-datepicker';
import { convertDateFormatForApi } from '../../../utils/utils';
import { Loadingicon } from '../../../AppIcons';
import { useAuth } from '../../../Context/AuthContext';

function CustomFilter({isCat, isSubCat, isTitle, isStatus, isCampaign=false, campaignDropdownValues=null, handleSearchFilter, isDate, setStartDate, setEndDate, setTitle, setFilterStatus, setFilterCat=null, setFilterSCat=null, startDate, endDate, filterStatus, filterCat=null, filterSCat=null, title, setFilterCatName=null, setFilterSubCatName=null, setIsRemoval, setIsFilterActive, campaign_id=null, setFilterCampaign=null, defaultStatusOption=null, defaultCampaignOption=null, filterBtnLoading=false}) {
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [isSubCatLoading, setIsSubCatLoading] = useState(false);
    const [catFormatted, setCatFormatted] = useState([]);
    const [subCatFormatted, setSubCatFormatted] = useState([]);
    const [isSubCatRerendered, setIsSubCatRerendered] = useState(false);
    const { globalToken } = useAuth();

    const [choosenDefaultSubCat, setChoosenDefaultSubCat] = useState();

    const DisplayingErrorMessagesSchema = Yup.object().shape({

       
    });
   

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {
       
        setIsRemoval(false)
        if(type=='cat'){
            // setChoosenDefaultSubCat(null)
            if(!value){
                setFilterSCat(null);
            }
            handleCategoryListing('subcat', setFieldValue, value || null, null);
            setFilterCat(value || null);
            setFieldValue('cat_id', value || null);
            setFilterCatName(label);
        }if(type=='subcat'){
            setFilterSCat(value || null);
            setFieldValue('scat_id', value || null)
            setFilterSubCatName(label)
        }
        if(type=='campaign_id'){
            setFieldValue('campaign_id', value || null)
            setFilterCampaign({label:label, value:value})
            console.log(type)
        }
        if(type=='status'){
            
            setFilterStatus(value);
        }
    }

    const handleCategoryListing = async (conf, sFValue, catId=null, comingFrom) => {
        
        setIsCatLoading(true)
        // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
        const res = await getGigsConfigurations({ token: globalToken, conf: conf,cat_id:catId });
        // console.log(res)
        if(conf=='cat'){
            const categoryData = res?.data?.category?.map(option => ({
                value: option.id,
                label: option.name
            })) || [];
            setCatFormatted(categoryData);
        }
        if(conf=='subcat'){
            setSubCatFormatted([]);
            const subcategoryData = res?.data?.subcategory?.map(option => ({
                value: option.id,
                label: option.name
            })) || [];  
            setSubCatFormatted(subcategoryData);
            if(!comingFrom){
            // setChoosenDefaultSubCat(subcategoryData[0]);
            // setFilterSCat(subcategoryData[0]?.value);
            // setFilterSubCatName(subcategoryData[0]?.label);
            }
            setIsSubCatLoading(false);  
        }
        setIsCatLoading(false);
    }

    const  taskStutusEnum = [
        {
            value:0,
            label:'Pending'
        },
        {
            value:1,
            label:'Approved'
        },
        {
            value:2,
            label:'Running'
        },
        {
            value:3,
            label:'Paused'
        },{
            value:4,
            label:'Inactive'
        },{
            value:5,
            label:'Rejected'
        }
    ]

    const  myTaskStutusEnum = [
        {
            value:0,
            label:'In Progress'
        },
        {
            value:1,
            label:'In Review'
        },
        {
            value:2,
            label:'Completed'
        },
        {
            value:3,
            label:'Rejected'
        },{
            value:4,
            label:'Expired'
        },{
            value:5,
            label:'Disputed'
        },{
            value:6,
            label:'Reffered to Admin'
        },{
            value:7,
            label:'Cancelled'
        }
    ]
    

    useEffect(()=>{
        // console.log(campaignDropdownValues)
        if(!isCampaign && isCat)
        handleCategoryListing('cat',null,  null, 'sc');
    },[])

    const handleFilter = (values) =>{
       
       
        setIsFilterActive(true)
        let formattedValue = {...values, fromDate:convertDateFormatForApi(values.fromDate), toDate:convertDateFormatForApi(values.toDate)}
        // console.log(formattedValue);
        if(isCampaign){     
            // alert(1)
            // console.log('dddd');      
            handleSearchFilter(formattedValue?.campaign_id || null, formattedValue?.fromDate || null, formattedValue?.toDate || null, formattedValue?.status, 1, null, 'filter')
        }else{
           
            handleSearchFilter(formattedValue?.cat_id || null, formattedValue?.scat_id || null, formattedValue?.fromDate || null, formattedValue?.toDate || null, (formattedValue?.status || formattedValue?.status==0)?formattedValue?.status: null, formattedValue?.title || null, 1, 'filter')
        }
        
    }
    return (
        <>
        <Formik
            enableReinitialize={true}
            initialValues={isCampaign?{
                campaign_id:campaign_id,
                fromDate:startDate,
                toDate:endDate,
                status:filterStatus
            }:{
                title:title,
                cat_id:filterCat,
                scat_id:filterSCat,
                fromDate:startDate,
                toDate:endDate,
                status:filterStatus
            }}
            // validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={(values) => {

                handleFilter(values)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
            }) => (
                <form className="" onSubmit={handleSubmit}>
        <div className="d-flex flex-md-row flex-column gap-2 w-100 position-relative" style={{zIndex:2}}>
            {
                isCampaign && campaignDropdownValues?.length?<>
                <div className="flex-1 position-relative">
                    <CustomSelect name={'campaign_id'} options={campaignDropdownValues} 
                        handleCustomSelectOnChange={handleCustomSelectOnChange}
                        type={'campaign_id'}
                        isSelectLoading={false}
                        setFieldValue={setFieldValue}
                        isClearable={true}
                        defaultOption={defaultCampaignOption}
                        placeholder={'Select Campaign'} />
                </div>
                </>:null
            }
            {
                isCat && catFormatted?.length ?
                <div className="flex-1 position-relative">
                    <CustomSelect name={'cat_id'} options={catFormatted} 
                        handleCustomSelectOnChange={handleCustomSelectOnChange}
                        type={'cat'}
                        setFieldValue={setFieldValue}
                        isSelectLoading={isCatLoading}
                        isClearable={true}
                        placeholder={'Category'} /> 
                </div>
                :null
            }
            {
                // && choosenDefaultSubCat
                isSubCat && subCatFormatted?.length?
                <div className="flex-1 position-relative">
                    <CustomSelect name={'scat_id'} options={subCatFormatted} 
                        handleCustomSelectOnChange={handleCustomSelectOnChange}
                        type={'subcat'}
                        defaultOption={filterSCat?choosenDefaultSubCat:null}
                        isSelectLoading={isCatLoading}
                        setFieldValue={setFieldValue}
                        isClearable={true}
                        placeholder={'Sub Category'} /> 
                </div>
                :null
            }
            {
                isDate?<>
                <div className="flex-1 position-relative">
                <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                name={'fromDate'}
                onChange={(date) => {
                    // if(!endDate){
                    //     setEndDate(date)
                    //     setFieldValue('toDate', date)
                    // }
                    setStartDate(date)
                    setFieldValue('fromDate', date)
                }
            }
                placeholderText="Start Date"
                isClearable
                />
                </div>
                <div className="flex-1 position-relative">
                <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                name={'toDate'}
                minDate={startDate}
                onChange={(date) => {
                    if(!startDate){
                        setStartDate(date)
                        setFieldValue('fromDate', date)
                    }
                    setEndDate(date)
                    setFieldValue('toDate', date)
                }}
                placeholderText="End Date"
                isClearable
                />
                </div>
                </>:null
            }
            {
                isStatus && !isCampaign?<>
                <div className="flex-1 position-relative">
                    <CustomSelect name={'status'} options={taskStutusEnum} 
                        handleCustomSelectOnChange={handleCustomSelectOnChange}
                        type={'status'}
                        isSelectLoading={false}
                        setFieldValue={setFieldValue}
                        isClearable={true}
                        placeholder={'Select Status'} />
                </div>
                </>:isStatus && isCampaign?<>
                <div className="flex-1 position-relative">
                    <CustomSelect name={'status'} options={myTaskStutusEnum} 
                        handleCustomSelectOnChange={handleCustomSelectOnChange}
                        type={'status'}
                        isSelectLoading={false}
                        setFieldValue={setFieldValue}
                        isClearable={true}                        
                        defaultOption={defaultStatusOption}                      
                        placeholder={'Select Status'} />
                </div>
                </>:null
            }
            
           
        </div>
        {
                isTitle?<>
                <div className="w-100 position-relative mt-2">
                    <input type="search" name={'title'} onChange={(e) =>setTitle(e.target.value)} placeholder="Search Campaign by Title" className="form-control mb-0 " />
                </div>
                </>:null
            }
            <button type='submit' disabled={filterBtnLoading} className='btn credit-btnbtn justify-content-end float-right px-md-3 px-2 align-items-center mt-2 border-0 btn-primary btn-sm credit-btn'>
            {filterBtnLoading ? (
                                                        <div className="h-5 w-5">
                                                            <Loadingicon />
                                                        </div>
                                                    ) : (
                                                        <><i className='fa fa-filter me-2'></i> Filter</>
                                                    )}
                </button>
        </form> 
            )}
        </Formik>
        </>
    )
}

export default CustomFilter;
