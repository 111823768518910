import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
import CustomSelect from "../../../common/customselect";
import { getForumSubject, getForumTopic, postAForumQuestion } from "../../../../api/forum";
import { CDN_BASE_URL, EDITOR_KEY, ERROR_SOMETHING_WENT_WRONG, slugToNormal, TINY_EDITOR_KEY } from "../../../../utils/utils";
import { useSearch } from "../../../../Context/SearchContext";
import { getBlogCategory, getBlogSubCategory, postBlog } from "../../../../api/blog";
import { Editor } from "@tinymce/tinymce-react";
import { getTutorialCategory, postTutorial } from "../../../../api/tutorial";
import FAQItem from "../faq";

import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { registerLicense } from '@syncfusion/ej2-base';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import addImage from '../../../../assets/images/add-image.png';
import Modal from "../../../common/Modal";

// Register your Syncfusion license key
registerLicense(EDITOR_KEY);

function EditTutorial() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [tagId, setTagId] = useState(null);
    const [isTagLoading, setIsTagLoading] = useState(false);
    const { globalToken } = useAuth();
    const [tagFormatted, setTagFormatted] = useState([]);
    const [defaultTag, setDefaultTag] = useState([]);
    const [choosenTutorial, setChoosenTutorial] = useState(JSON.parse(localStorage.getItem('t__e') || '{}'));
    const [loadCustomDropDown, setLoadCustomDropDown] = useState(false);
    const location = useLocation()
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [submitAction, setSubmitAction] = useState();
    const [draftBtnLoading, setDraftBtnLoading] = useState(false);
    const [faqContainers, setFaqContainers] = useState([{ question: '', answer: '' }]);

    const [isCoverPicCropModalOpen, setIsCoverPicCropModalOpen] = useState(false);
    const chooseCoverPicRef = useRef(null);
    const [coverImage, setCoverImage] = useState(null);
    const [image, setImage] = useState(null);
    const [croppedCoverImage, setCroppedCoverImage] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const cropperRef = useRef(null);

    
    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode','CreateTable', 'FullScreen', '|', 'Undo', 'Redo']
    };
    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    };

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        about: Yup.string().required("About Author is required"),
        tag: Yup.array().min(1, "Choose at least one tag").required("Tags are required")
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (choosenTutorial) {
            if(choosenTutorial?.image){
                setCroppedCoverImage(CDN_BASE_URL + '' + choosenTutorial?.image);
            }
            setFaqContainers(choosenTutorial?.faq?.length?JSON.parse(choosenTutorial?.faq):[{ question: '', answer: '' }]);
            if(choosenTutorial?.tag_list?.length){                
                let cData = choosenTutorial?.tag_list?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];
                setDefaultTag(cData);
            }
            
            handleFetchAllTutorialTags(1);
        }
        return () => {
            localStorage.removeItem('t__e');
        }

    }, [])

    const handlePlusClick = () => {
        // Add a new FAQ container and expand its size
        setFaqContainers([...faqContainers, { question: '', answer: '' }]);
    };


    const handleMinusClick = (index) => {
        // Remove the relevant FAQ container
        setFaqContainers(faqContainers.filter((_, i) => i !== index));
    };

    const handleFetchAllTutorialTags = async (pageNo) => {
        const resAllTutorialTag = await getTutorialCategory({ pageNo: pageNo });
        if (resAllTutorialTag?.success) {
            const tutorialTag = resAllTutorialTag?.data?.data;
            if (tutorialTag?.length) {
                const cData = tutorialTag?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];


                setTagFormatted(cData);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }


        setLoading(false);
    }

    useEffect(()=>{
        setLoadCustomDropDown(true);
    },[defaultTag])


    

    const formData = [
        {
            id: 'title',
            title: 'Title',
            type: 'text',
            name: 'title',
            placeholder: 'Blog Title',
            required: true,
            editable: true
        },
        {
            id: 'tag',
            title: 'Tag',
            type: 'text',
            name: 'tag',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
            isTag: true
        },
        {
            id: 'about',
            title: 'About Author',
            type: 'text',
            name: 'about',
            placeholder: 'About author',
            required: true,
            editable: true,
            width: true
        },
        {
            id: 'description',
            title: 'Description',
            type: 'text',
            name: 'description',
            placeholder: 'Description',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handleUpdateTutorial = async (values, type) => {
        type=='save'?setSubmitBtnLoading(true):setDraftBtnLoading(true);
        const postParams = { token: globalToken, faq:faqContainers, title: values?.title, description: values?.description, tag: values?.tag, type: type, image: image, about: values?.about, id: choosenTutorial?.id };
        const formData = new FormData();
        if(image){
            formData.append('image', image);
        }
        // { token: globalToken, faq:faqContainers, title: values?.title, description: values?.description, tag: values?.tag, type: type, image: values?.image, about: values?.about, id: choosenTutorial?.id }
        const resPostTutorial = await postTutorial(postParams, formData);

        if (resPostTutorial?.success) {
            toast.success(resPostTutorial?.data?.message || 'Tutorial updated successfully.');
            navigate('/tutorials/my-tutorials')
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setSubmitBtnLoading(false)
        setDraftBtnLoading(false)
    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {
        if (type == 'tag') {
            if (value) {
                // console.log('v', value)
                setTagId(value);
                setFieldValue('tag', value);
                findChoosenTag(value, tagFormatted)
            } else {
                setTagId(null);
                setFieldValue('tag', null);
            }
        }

    }


    const findChoosenTag = (choosenTag, cData) => { 
        // const cTag = cData?.filter(sc => sc?.value == choosenTag);  
        // console.log(choosenTag)   
        setDefaultTag(choosenTag);
    }

    const closeCoverPicCropModal = () => {
        setIsCoverPicCropModalOpen(!isCoverPicCropModalOpen);
    }
    const handleCropProfilePic = () => {

    }

    const handleImageChange = (event) => {

        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (allowedTypes.includes(file.type)) {
                const reader = new FileReader();
                reader.onload = () => {
                    setCoverImage(reader.result);
                };
                reader.readAsDataURL(file);
                setIsCoverPicCropModalOpen(true);

            } else {
                alert('Please choose a valid image file.');
            }
        }
        event.target.value = '';
    };

    const handleUserPicChange = () => {
        chooseCoverPicRef.current.click();
    };

     const getOriginalImageSize = () => {
                    // Ensure coverImage is defined and valid
                    if (!coverImage) {
                        console.error("coverImage is not defined or invalid.");
                        return;
                    }
                
                    // Create a new Image object
                    const img = new window.Image();
                
                    // Set the src to the base64-encoded coverImage
                    img.src = coverImage;
                
                    // When the image loads, access its natural width and height
                    img.onload = () => {
                        const originalWidth = img.naturalWidth;
                        const originalHeight = img.naturalHeight;
                        console.log(`Original Size: ${originalWidth}x${originalHeight}`);
                
                        // Example of what you might want to do with the image
                        const timestamp = new Date().getTime();
                        const fileName = `original-image-${timestamp}.jpg`;
                
                        // You can skip the fetch if you don't need to reprocess the base64 image
                        // If you want to create a new File object from the base64 string:
                        const byteCharacters = atob(coverImage.split(',')[1]); // Decode the base64 string
                        const byteArrays = [];
                
                        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                            const slice = byteCharacters.slice(offset, offset + 1024);
                            const byteNumbers = new Array(slice.length);
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                            }
                            byteArrays.push(new Uint8Array(byteNumbers));
                        }
                
                        const blob = new Blob(byteArrays, { type: 'image/jpeg' });
                        const originalFile = new File([blob], fileName, { type: 'image/jpeg' });
                
                        setCroppedCoverImage(URL.createObjectURL(blob));
                        setImage(originalFile);
                        closeCoverPicCropModal();
                    };
                
                    // Handle any errors that occur while loading the image
                    img.onerror = (error) => {
                        console.error("Failed to load image:", error);
                    };
                };

    const getCroppedData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                const timestamp = new Date().getTime();
                const fileName = `cropped-image-${timestamp}.jpg`;
                const croppedFile = new File([blob], fileName, { type: 'image/jpeg' });
               setCroppedCoverImage(URL.createObjectURL(blob));
                setImage(croppedFile);
                closeCoverPicCropModal();
            }, 'image/jpeg', 0.5);
        }
    }

    const removeImg = () => {
        setCroppedCoverImage(null);
        setImage(null);
        setCoverImage(null);
    }

    return (
        <>
            {/* <BreadCrumbs /> */}
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>Tutorials</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/tutorials/my-tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>My Tutorials</NavLink>
                    </li>
                    {
                        choosenTutorial ? <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                            {choosenTutorial?.title}
                        </li> : null
                    }

                    <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                        Edit
                    </li>
                </ol>
            </nav>
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">

                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                title: choosenTutorial?.title,
                                description: choosenTutorial?.description,
                                tag: [{label:choosenTutorial?.tag_list?.name, value:choosenTutorial?.tag_list?.id}],
                                about: choosenTutorial?.about
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {
                                if (values){
                                    if (submitAction === 'post') {
                                        handleUpdateTutorial(values, 'save') // Call save function
                                    } else if (submitAction === 'draft') {
                                        handleUpdateTutorial(values,'draft') // Call draft function
                                    }
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map((fieldMeta, _i) => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>
                                                                {/* <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    initialValue={fieldMeta?.name == "description" ? choosenTutorial?.description : choosenTutorial?.about}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                /> */}

                                                                <RichTextEditorComponent height={350} toolbarSettings={toolbarSettings}
                                                                    quickToolbarSettings={quickToolbarSettings}
                                                                    id={'editorContent'+_i}
                                                                    value={fieldMeta?.name == "description" ? choosenTutorial?.description || '' : choosenTutorial?.about || ''}
                                                                    change={(e) => {
                                                                    setFieldValue(fieldMeta?.id, e.value); // Update Formik value
                                                                    }}
                                                                    placeholder={fieldMeta?.title=='Description'?"Write description here":"Write about author"}>
                                                                        {/* <div dangerouslySetInnerHTML={{__html:fieldMeta?.name == "description" ? choosenTutorial?.description : choosenTutorial?.about}}></div> */}
                                                                    <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]}/>
                                                                </RichTextEditorComponent>
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : fieldMeta.isTag ?
                                                                <div className="w-100" style={{zIndex:100, position:'relative'}}>
                                                                   
                                                                    <div className="d-block w-100">
                                                                        {
                                                                            loadCustomDropDown && <CustomSelect options={tagFormatted}
                                                                                defaultOption={defaultTag}
                                                                                isMultiple={true} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'tag'} isSelectLoading={isTagLoading} placeholder={'Choose among tags'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : <>
                                                                    <input
                                                                        disabled={!fieldMeta?.editable}
                                                                        type={fieldMeta?.type}
                                                                        name={fieldMeta?.id}
                                                                        autoComplete={'off'}
                                                                        id={fieldMeta?.name}
                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                        placeholder={fieldMeta?.title}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values[fieldMeta?.id] || ""}
                                                                    />
                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </>}

                                                </div>
                                            ))
                                        }
                                        {/* {
                                            choosenTutorial?.image ? <div className="col-md-1 mb-3">
                                                <div className="image-container rounded">
                                                    <img src={CDN_BASE_URL + '' + choosenTutorial?.image} alt="blog image" className="rounded" />

                                                </div>
                                            </div> : ''
                                        } */}

                                        <div className="col-md-11 mb-3">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500" >Set Blog Image (Supported Formats JPG,PNG,BMP,WEBP)</label>
                                            {/* <input type="file" onChange={(event) => {
                                                setFieldValue('image', event.currentTarget.files[0]);
                                            }}
                                                onBlur={handleBlur}
                                                className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                name="image" /> */}
                                            <input
                                                type="file"
                                                id="chooseProfilePic"
                                                ref={chooseCoverPicRef}
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e)}
                                            />
                                            <div className="relative inline-block">
                                                {/* {
                                                    croppedCoverImage?<button className="btn btn-default remove-img-btn" onClick={()=>removeImg()} type="button"><i className="fa fa-times"></i></button>:null
                                                } */}
                                                
                                            <button onClick={() => handleUserPicChange()}  className="btn btn-outline" style={{border:'1px solid #f0f0f0'}} type="button">
                                                {
                                                    croppedCoverImage ? <img src={croppedCoverImage} alt="Cover Image" className="h-10 rounded" /> : <img src={addImage} alt="Add Image" className="h-10 w-10 add-img-placeholder" />
                                                }
                                                
                                            </button>
                                            </div>
                                        </div>

                                        <label htmlFor={'faq'}
                                                    className={`block mb-2 text-sm font-medium position-relative text-gray-900 dark:text-gray-500`}
                                                >
                                                    FAQ
                                                    <button className="btn-plus" type="button" onClick={()=> handlePlusClick()}><i className="fa fa-plus"></i></button>
                                                </label>
                                                <div className="col-md-12">                                            {
                                                        faqContainers?.length?faqContainers?.map((fq, index)=>(
                                                            <FAQItem key={fq.id} TINY_EDITOR_KEY={TINY_EDITOR_KEY} moderateEditorRef={moderateEditorRef} faq={faqContainers} fq={fq} setFaqContainers={setFaqContainers} faqIndex={index} handleMinusClick={handleMinusClick} setFieldValue={setFieldValue}/>
                                                        )):null
                                                    }                                                    
                                                </div>

                                    </div>
                                    <div className="col-md-5 d-flex gap-2 mt-3">
                                        
                                        <button
                                            type="submit"
                                            disabled={submitBtnLoading}
                                            onClick={() => setSubmitAction('post')}
                                            className="mt-2 w-fulls text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-md-5 px-3 py-2.5 text-center flex justify-center"
                                        >
                                            {submitBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={draftBtnLoading}
                                            onClick={() => setSubmitAction('draft')}
                                            className="mt-2 w-fulls text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-md-5 px-3 py-2.5 text-center flex justify-center"
                                        >
                                            {draftBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Save As Draft"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }
{/* modal for tutorial banner cropper */}
<Modal
                closeModal={closeCoverPicCropModal}
                setIsOpen={setIsCoverPicCropModalOpen}
                isOpen={isCoverPicCropModalOpen}
                callBack={handleCropProfilePic}
                title="Crop Tutorial Pic"
                modalFooter={true}
                isMaxL={true}
            >
                <Cropper
                    src={coverImage}
                    style={{ height: 450, width: '100%' }}
                    initialAspectRatio={4 / 2}
                    aspectRatio={4 / 2}
                    guides={true}
                    ref={cropperRef}
                    // minCropBoxWidth={1200} 
                    // minCropBoxHeight={450} 
                    dragMode="move"
                />
                <div className="mt-4 modal-footer gap-2 mt-10 flex justify-end">
                     <button
                        type="button"
                        className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                        onClick={() => getOriginalImageSize()}
                    >
                        {
                            imageLoading ? <div className="h-5 w-5 m-auto">
                                <Loadingicon />
                            </div> : <span>Skip</span>
                        }
                    </button>
                    <button
                        type="button"
                        className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                        onClick={() => getCroppedData()}
                    >
                        {
                            imageLoading ? <div className="h-5 w-5 m-auto">
                                <Loadingicon />
                            </div> : <span>Crop</span>
                        }
                    </button>
                </div>

            </Modal>
        </>
    );
}
export default EditTutorial;
