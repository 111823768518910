import React from 'react'
import { CDN_BASE_URL, convertDateFormat, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import careerThumbPlaceholder from '../../../../assets/images/feeds_default_placeholder.webp';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import { toast } from 'react-toastify';

export default function ExamListItem({ topic, examData, thumbPrefix = 'tuteehub/online_exam/exam/', redirectionFn, type }) {
    const navigation = useNavigate();
    const { user, setIsEmailVerifyCover } = useAuth();
    // console.log(examData)
    return (
        <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`} onClick={(e) => redirectionFn(e)} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-md-flex ex-container-list align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers mr-3">
                    {
                        examData?.image ?
                            <img
                                src={
                                    CDN_BASE_URL +
                                    `${thumbPrefix + examData?.image}`
                                }
                                width="200"
                                height="90"
                                className="rounded bg-gray border"
                                alt="Thumbnail"
                            />
                            : <img src={`${careerThumbPlaceholder}`} width="90" height="90" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    }

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5"><strong>{examData?.title}</strong>
                            <div
                                className="badge badge-pill badge-success"
                                style={{
                                    backgroundColor: '#28a745', // Green background
                                    color: 'white', // White text color
                                    fontSize: '12px', // Smaller font size
                                    padding: '2px 8px',
                                    borderRadius: '12px', // Rounded corners
                                }}
                            >
                                Free
                            </div>
                        </span>
                    </h6>
                    <span className='w-100 d-md-flex align-items-center'>


                        <><small><a onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigation(`/exams/${topic?.subject?.slug}`) }} className="theme-a-light cursor-pointer">{topic?.subject?.subject_title}</a></small>
                            <span className="big-dots"></span>
                            <small><a onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigation(`/exams/topic/${topic?.slug}`) }} className="theme-a-light cursor-pointer">{topic?.topic_name}</a></small>
                        </>

                        <span className="big-dots"></span>
                        <span className='span color-gray text-capitalize fsize-11 c-a5'>{convertDateFormatOnly(topic?.created_at || examData?.created_at)}</span>
                    </span>
                    <div className='m-0 color-gray fsize-13 d-flex w-100 justify-content-between align-items-center'>
                        <span>
                            <span className='color-333' >Total Questions :</span> {examData?.total_questions}
                        </span>
                    </div>
                    {/* <div className="w-100 text-black-50 text-capitalize mt-1 mb-0 d-md-flexs d-inline-block  align-items-center">
                        <small>{nFormatter(examData?.news_count || 0)} News dangerouslySetInnerHTML={{__html:`${examData?.about}`}}</small>
                    </div> */}
                    <p className="mb-0 mt-1 color-gray fsize-meta desc-camp" >{limitSubtitle(stripTags(examData?.about || examData?.description || examData?.other), 290)}</p>
                    <div className="d-flex justify-content-end mt-2">
                        {examData?.is_attempted?.length ? (
                            <button
                                className="btn offer-btn text-white border-0 mt-2"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent redirect when clicking the button
                                    navigation(`/exams/result/${examData.id}`); // Redirect to result page
                                }}
                                style={{ backgroundColor: 'blue', borderColor: '#6c757d', marginRight: '20px' }}
                            >
                                View Result
                            </button>
                        ) : null}
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                if (user?.email_verified == 0) {
                                    toast.error('Please verify your email');
                                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200);
                                    return;
                                } else {
                                    navigation(
                                        `/exams/take-exam/${examData?.slug}`
                                    );
                                }

                            }}
                            className="btn btn-primary mt-2"
                            style={{ backgroundColor: '#ff7200', borderColor: '#ff7200' }}
                        >
                            Start Exam
                        </button>

                    </div>
                </div>

            </div>
        </div>
    )
}
