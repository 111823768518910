import { useEffect, useState } from "react";
import {changePassword, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik, useFormik } from "formik";
import { useAuth } from "../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { onProfileVerification, onRaisedTicketsList } from "../../../api/AuthService";
import notFound from '../../../assets/images/not_found_new.png';

import { formatDate } from "../../../utils/utils";
import Modal from "../../common/Modal";
import { useNavigate } from "react-router-dom";
function HelpAndSupport() {
    const [loading, setLoading] = useState(false);
   
    const { user } = useAuth();
    const[raiseTickets, setRaisedTickets ] = useState([]);
    const[isRaiseTicketOpen, setIsRaiseTicketOpen] = useState(false);
    const navigate= useNavigate()

    const handleRaisedTicketsList = async () => {  
        setLoading(true)    
        const res = await onRaisedTicketsList({token:user?.access_token});
       
        if(res?.success){
            setRaisedTickets(res?.data?.data)
        }else{
            // toast.error(res?.message) 
        }
        setLoading(false)    
    }

    useEffect(()=>{
        handleRaisedTicketsList();
    },[])
    const formData = [
        {
            id: 'module',
            title: 'Module Name',
            type: 'text',
            name: 'module',
            placeholder: 'eg. Question Bank, Interview Question, Fullform etc.',
            required: false,
        },
        {
            id: 'subject',
            title: 'Subject',
            type: 'text',
            name: 'subject',
            placeholder: 'Subject',
            required: true,
        },
        {
            id: 'message',
            title: 'Message',
            type: 'text',
            name: 'message',
            placeholder: 'Message',
            required: true,
        }
    ]

    return (
        <>
            <BreadCrumbs />
          
            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                    <div className="px-3">
                    <div className="row">
                        
                        <div className="col-md-12 mb-4">
                    <button  type="button" onClick={()=> navigate("/raise-ticket")} className="btn bg-theme text-white float-right" style={{backgroundColor:'#ff7200'}}>Raise Ticket</button>
                    </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-md-12">
                        
                            {
                                raiseTickets?.length?raiseTickets?.map(ticket=>(
                                    <div className="card mb-3 d-flex w-100 flex-md-row flex-column justify-content-between align-items-center p-3 shadow top-leader-board-container" style={{borderColor:'#f0f0f0'}}>
                                    <div>
                                        <h6 className="mb-0">{ticket?.subject}</h6>
                                        <small className="text-black-50">{ticket?.id} <span className="big-dots"></span> {ticket?.module} <span className="big-dots"></span> {formatDate(ticket?.created_at)}<span className="big-dots"></span><span className={`d-md-none ${ticket?.status=='Pending'?'text-warning':'text-success'}`}>{ticket?.status}</span></small>
                                        
                                        <p className="mb-0 mt-2 text-black-60">{ticket?.message}</p>
                                    </div>
                                    <div style={{minWidth:100,textAlign:'right'}} className="d-none d-md-block">
                                        {
                                            ticket?.status=='Pending'?  <span className="label-warning text-warning">{ticket?.status}</span>:  <span className="label-warning text-success">{ticket?.status}</span>
                                        }
                                      
                                    </div>
                                       
                                </div>
                                )):(
                                    <div className="flex-column not-found d-flex text-center">
                                        <img src={notFound} className="notFoundImg" />
                                        <span style={{ opacity: 0.7 }}>Ticket yet to raise</span>
                                    </div>
                                )
                            }
                           
                           
                        </div>
                    </div>
                    </div>
                        {/* <div className="px-3">
                            <div className="row">
                               
                                <div className="col-md-9">
                                            <Formik    
                                                initialValues={{
                                                    id_type: 'Aadhaar',
                                                    id_photo: '',
                                                    selfie: '',
                                                  }}                                           
                                                validationSchema={DisplayingErrorMessagesSchema}
                                                onSubmit={(values) => {
                                                   handleProfileVerification(values)
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setFieldValue,
                                                    handleSubmit,
                                                }) => (
                                                    <form className="" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            {
                                                                formData?.map(fieldMeta => (
                                                                    <div className={`${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                                        <label
                                                                            htmlFor={fieldMeta?.id}
                                                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                                                        >
                                                                            {fieldMeta?.title}

                                                                            {
                                                                                fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                            }
                                                                        </label>
                                                                        <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                                        {
                                                                           fieldMeta?.id=="id_type"?
                                                                           <select name="id_type" onChange={handleChange} onBlur={handleBlur} className="mb-4 form-controls bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                            <option value="Aadhaar">Aadhaar</option>
                                                                            <option value="Voter Card">Voter Card</option>
                                                                            <option value="Pan Card">Pan Card</option>
                                                                            <option value="Passport">Passport</option>
                                                                           </select>
                                                                           : <>
                                                                                       <div className="position-relative"> 
                                                                                        <input
                                                                                            onChange={(e) => handleFileChange(fieldMeta.name, e, setFieldValue)}
                                                                                            type={fieldMeta?.type}
                                                                                            name={fieldMeta?.name}
                                                                                            autoComplete={'off'}
                                                                                            id={fieldMeta?.name}
                                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                            placeholder={fieldMeta?.title}
                                                                                            // onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            // value={values[fieldMeta?.name]}
                                                                                        />
                                                                                       
                                                                                        </div>
                                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className="col-md-2">
                                                                <button
                                                                type="submit"
                                                                disabled={loading}
                                                                className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                                                >
                                                                {loading ? (
                                                                    <div className="h-5 w-5">
                                                                    <Loadingicon />
                                                                    </div>
                                                                ) : (
                                                                    "Submit"
                                                                )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}

                                        </Formik>
                                </div>
                            </div>
                        </div> */}
                    </>
                )
                }
            </div>
            
        </>
    );
}
export default HelpAndSupport;
