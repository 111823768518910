import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { Formik, useFormik } from "formik";
import { useAuth } from "../../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';

import { Link, useNavigate, useParams } from "react-router-dom";
import { createCampaign, submitProof } from "../../../../api/OurService";
import { EXTRA_MSG, SHORT_TASK_ASSETS_BASE_URL, calculateAverageRating, convertDateFormat } from "../../../../utils/utils";
import StarRatings from "react-star-ratings";


function SubmitTaskProof() {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const isMounted = useRef(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [task, setTask] = useState(null);
    const [isMoreDetail, setIsMoreDetail] = useState(false);

    const navigate = useNavigate()
    const { gigToken, globalToken, setIsEmailVerifyCover } = useAuth();

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        proofs: Yup.string().required("Proof is required"),
        concern: Yup.string().required("Task proof description is required")
    });



    useEffect(() => {
        // handleTaskCatList();
        // setTask(JSON.parse(localStorage.getItem('forProof')));
      
        const storedData = localStorage.getItem('forProof');
        if (storedData) {
            // console.log(storedData);
            setTask(JSON.parse(storedData));
        }

    }, [])



    const handleSubmitProof = async (values) => {
        setBtnLoading(true)
        if (task?.tId) {
            // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
            const submitProofParams = task?.type ? {
                ...values,
                taskId: task?.tId,
                campId: task?.id,
                type: task?.type
            } : {
                ...values,
                taskId: task?.tId,
                campId: task?.id
            }
          
            const res = await submitProof({ token: globalToken, submitProofParams });
            setFirstLoading(false);
            if (res?.success) {
                if(res?.message?.status==4){
                    toast.success(res?.message?.message+' '+ EXTRA_MSG);
                }
                localStorage.removeItem('forProof');
                toast.success(res?.message);
                navigate('/gigs/my-task')
            } else {
                if(res?.status==5){
                    toast.error(res?.errors);
                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                }else{
                    toast.error(res?.errors)
                }
                // toast.error(res?.errors)
            }
        } else {
            toast.error('Task Missing');
        }
        setBtnLoading(false)
    }




    const formData = [
        {
            id: 'proofs',
            title: 'Upload Proof',
            type: 'text',
            name: 'proofs',
            placeholder: 'Upload Proof',
            required: true,
            editable: true
        },
        {
            id: 'concern',
            title: 'Task Proof Description',
            // type: 'text',
            name: 'concern',
            placeholder: 'Provide a brief description of the proof submitted for the task - e.g Screenshot of Browser history attached',
            required: true,
            editable: true,
            // width: true
        }
    ]

    return (
        <>
            <BreadCrumbs />

            <div className=" py-4 px-4 grid grid-cols-1 group-card card-body gap-4">
                {firstLoading || loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                    {
                        task?.type?<div className="alert alert-info mb-0">
                            <i className="fa fa-info-circle me-2"></i> To raise a dispute against this task, you need to resubmit proof with the following details.
                        </div>:null
                    }
                    <div className="d-flex flex-md-row flex-column gap-4">
                        
                        <div className="flex-1 w-100">
                                    
                            <div className="card">
                                <div className="card-body shadow">
                                   
                                    
                                    <div className="card">
                                        <div className="card-body shadows" style={{ background: '#ececec' }}>

                                            
                                            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto">

                                                <div className="thumbnail-container mr-3">
                                                    {
                                                        task?.thumbnail ?
                                                            <img src={SHORT_TASK_ASSETS_BASE_URL + (task?.thumbnail)} width="70" height="70" className="rounded bg-gray" />
                                                            :
                                                            <span className="placeholder-thumbnail">
                                                                <svg className="MuiSvgIcon-root ms-0" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="button" style={{ height: 68, width: 68 }}>
                                                                    <path d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"></path>
                                                                </svg>
                                                            </span>
                                                    }
                                                </div>
                                                <div className="flex-1">
                                                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                                                        <strong>{task?.title}</strong>

                                                    </h6>


                                                    <div className="w-100 text-black-50 text-capitalize mt-0 mb-0 d-md-flexs d-inline-block  align-items-center">

                                                        <small><span className="text-gray-400">{task?.cTask?.cat?.name}</span></small>
                                                        <span className="big-dots"></span>
                                                        <small><span className="text-gray-400">{task?.cTask?.scat?.name}</span></small>

                                                    </div>
                                                    <div className="w-100 text-black-50 text-capitalize mt-0 mb-0 d-md-flexs d-inline-block  align-items-center">

                                                        <small>Posted On {convertDateFormat(task?.cTask?.created_at)}</small>

                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <p className="mb-0 mt-3 color-gray desc-camp" dangerouslySetInnerHTML={{ __html: task?.subtitle }}>
                                    </p>
                                    <p className="mb-0 mt-3 color-gray desc-camp">
                                        <strong>Proof Requirement</strong><br />
                                        <span className="color-gray d-flex d-md-inline-block flex-column flex-md-row" dangerouslySetInnerHTML={{__html:task?.cTask?.proof_requirement}}>
                                            {/* {} */}
                                        </span>
                                    </p>

                                    <p className="mb-0 mt-3 color-gray desc-camp">
                                        <strong>Proof Description</strong><br />
                                        <span className="color-gray d-flex d-md-inline-block flex-column flex-md-row" dangerouslySetInnerHTML={{__html:task?.cTask?.proof_req_desc}}>
                                            {/* {} */}
                                        </span>
                                    </p>

                                    <div className="important-info-container my-3 shadows px-3s py-2">
                                        <div onClick={()=>setIsMoreDetail(!isMoreDetail)} className="more-detail-header cursor-pointer py-2 d-flex align-items-center justify-content-betwee theme-color gap-1">
                                        <span>{!isMoreDetail?`Show More`:'Show Less'}</span>
                                        {
                                            isMoreDetail?<i className="fa fa-angle-up"></i>:<i className="fa fa-angle-down"></i>
                                        }
                                        
                                        </div>
                                        {
                                            isMoreDetail?<>
                                            <div className="imp-items d-flex py-1  my-1 align-items-cener justify-content-between">
                                            <div className="d-flex gap-1 align-items-center color-gray">
                                                {
                                                    gigToken?.id == task?.cTask?.user_id ? 'Per Task Credits' : 'Earn Credits'
                                                }
                                            </div>
                                            <div className="color-gray">
                                                <strong>{task?.cTask?.per_task_credit}</strong>
                                            </div>
                                        </div>
                                       
                                        {task?.cTask?.time_interval && (
                                            <div className="imp-items d-flex py-1  my-1 align-items-cener justify-content-between">
                                                <div className="d-flex gap-1 align-items-center color-gray">
                                                    Task Completion Time
                                                </div>
                                                <div className="color-gray">
                                                    <strong>{task?.cTask?.time_interval} Mins</strong>
                                                </div>
                                            </div>)
                                        }
                                        <div className="imp-items d-flex py-1  my-1 align-items-cener justify-content-between">
                                            <div className="d-flex gap-1 align-items-center color-gray">
                                                All Tasks
                                            </div>
                                            <div className="color-gray">
                                                <strong>{task?.cTask?.available_task + task?.cTask?.inprogress_task + task?.cTask?.rejected_task + task?.cTask?.approve_task}</strong>
                                            </div>
                                        </div>
                                        <div className="imp-items d-flex py-1  my-1 align-items-cener justify-content-between">
                                            <div className="d-flex gap-1 align-items-center color-gray">
                                                Available Tasks
                                            </div>
                                            <div className="color-gray">
                                                <strong>{task?.cTask?.available_task}</strong>
                                            </div>
                                        </div>

                                        <div className=" imp-items d-flex flex-column py-1 my-1 align-items-cener justify-content-between">
                                            <div className="d-flex gap-1 align-items-center  color-gray">
                                                Who Can Do?
                                            </div>
                                            <div className="color-gray">
                                                <strong className="text-capitlize">{task?.cTask?.gender?task?.cTask?.gender?.replace(/\b\w/g, char => char.toUpperCase()):'All'} & Age Between {task?.cTask?.age} Years</strong>
                                            </div>
                                        </div>
                                        {/* <div className="imp-items d-flex flex-column py-1 my-1 align-items-cener justify-content-between">
                                            <div className="d-flex gap-1 align-items-center color-gray">
                                                Country
                                            </div>
                                            <div className="color-gray">
                                                <strong className="text-capitalize"> {task?.cTask?.campaigncountry?.map((country, index) => (
                                                    index === 0 ? country?.countryname : `, ${country?.countryname}`
                                                ))}</strong>
                                            </div>
                                        </div> */}
                                        </>:null
                                        }
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                proofs: null,
                                concern: ""
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {

                                handleSubmitProof(values)
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <div className="card">
                                <div className="card-body shadow">
                                <form className="" onSubmit={handleSubmit} style={{position:'sticky', top:70}}>
                                    <div className="row">
                                        {
                                            formData?.map(fieldMeta => (
                                                <div className={`${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>

                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                                    >
                                                        {fieldMeta?.title}
                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {/* <input type="text" autoComplete="off" name="fake-email" style={{ display: 'none' }} /> */}
                                                    {
                                                        fieldMeta.id == 'proofs' ?
                                                            <>
                                                                <div className="col-md-12 mb-3">
                                                                    <input type="file" multiple onChange={(event) => {
                                                                        const file = event.currentTarget.files[0];
                                                                        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

                                                                        if (file && allowedTypes.includes(file.type)) {
                                                                            setFieldValue('proofs', file);
                                                                        } else {
                                                                            alert('Allowed file formats: JPEG, JPG, PNG');
                                                                            event.currentTarget.value = '';
                                                                        }
                                                                        // setFieldValue('proofs', event.currentTarget.files[0]);
                                                                    }}
                                                                        onBlur={handleBlur}
                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                        name="proofs" />
                                                                    <small className="text-black-50">Note: Allowed file formats: JPEG, JPG, PNG</small>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>

                                                                </div>
                                                            </>
                                                            : <>
                                                                <textarea
                                                                    name={fieldMeta?.name}
                                                                    value={values[fieldMeta?.id] || ""}
                                                                    id={fieldMeta?.name} rows={6}
                                                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                    placeholder={fieldMeta?.placeholder}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                // value={""}
                                                                ></textarea>
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                    }


                                                </div>
                                            ))
                                        }

                                    </div>
                                    {/* </div> */}
                                    <div className="col-md-3">
                                        <button
                                            type="submit"
                                            disabled={btnLoading}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center flex justify-center"
                                        >
                                            {btnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit Proof"
                                            )}
                                        </button>
                                    </div>
                                </form>
                                </div>
                                </div>
                                
                            )}

                        </Formik>


                    </div>
                    </>
                )
                }
            </div>

        </>
    );
}
export default SubmitTaskProof;
