import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getEducatorsByTypeWise, getSearchedData } from '../../../../api/educator';
import EducatorListItem from '../common/educatorlist';
import { useSearch } from '../../../../Context/SearchContext';
function EducatorsList() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [typeWiseSlug, setTypeWiseSlug] = useState();
    const [filterType, setFilterType] = useState();
    const navigation = useNavigate();
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const location = useLocation();
    const paramDatas = new URLSearchParams(location.search);
    const { searchValue } = useSearch();
    const params = useParams();

    useEffect(() => {
        const searchTerm = paramDatas?.get('searchTerm');
        if (searchTerm) {
            searchEducator(1, searchTerm);
            setSearchTerm(searchTerm);
        } else if (params?.typeWiseSlug && params?.filterType) {
            setTypeWiseSlug(params?.typeWiseSlug);
            setFilterType(params?.filterType);
            handleEducatorsByTypeWise(1, params?.filterType, params?.typeWiseSlug);
        }
    }, [])

    useEffect(() => {

        const searchTerm = paramDatas?.get('searchTerm');
        if (searchTerm) {
            searchEducator(1, searchTerm);
            setSearchTerm(searchTerm);
        } else {
            setSearchTerm('');
            setIsSearch(false)
            if (filterType && typeWiseSlug) {
                handleEducatorsByTypeWise(1, filterType, typeWiseSlug);
            }
        }

    }, [searchValue])

    // fetch all educators by types(industry, state, city, locality, type)
    const handleEducatorsByTypeWise = async (pageNo, fType, tSlug) => {
        const resAllEducatorByTypeWise = await getEducatorsByTypeWise({ pageNo: pageNo, fType: fType, typeWiseSlug: tSlug });
        // console.log(resAllEducatorByTypeWise);

        if (resAllEducatorByTypeWise?.success) {
            const resData = params?.filterType == 'types' ? resAllEducatorByTypeWise?.specializations : resAllEducatorByTypeWise?.educators;
            setIsNotFound(false)
            resData?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resData?.data?.length && pageNo == 1) {

                setAllContent(resData?.data);
            } else if (resData?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resData?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more educators by type wise data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;

        if (searchTerm?.length) {
            searchEducator(nxtPage, searchTerm, 'loadMore');
        } else {
            handleEducatorsByTypeWise(nxtPage, filterType, typeWiseSlug);
        }
        setPageNo(nxtPage);
    }

    const handlesearchEducator = (e) => {
        const searchTxt = e.target.value;
        setPageNo(1);
        setSearchTerm(searchTxt);

        if (searchTxt == '') {
            setIsSearch(false);
            setLoading(true);
            handleEducatorsByTypeWise(1, filterType, typeWiseSlug);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(false);
            searchEducator(1, searchTxt);
        }
    }

    const searchEducator = async (pNo, keyword, loadMore = null) => {
        !loadMore && setLoading(true);
        const resSearchEducators = await getSearchedData({ pageNo: pNo, keyword });
        setIsSearch(true);

        if (resSearchEducators?.success) {
            setTotalRecordFound(resSearchEducators?.data?.educators?.total || 0)
            setIsNotFound(false)
            resSearchEducators?.data?.educators?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchEducators?.data?.educators?.data?.length && pNo == 1) {
                setAllContent(resSearchEducators?.data?.educators?.data);
            } else if (resSearchEducators?.data?.educators?.data?.length && pNo !== 1) {
                setAllContent([...allContent, ...resSearchEducators?.data?.educators?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }

    return (<>
        {/* <BreadCrumbs /> */}
        <div className='d-flex align-items-center justify-content-between'>
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/educators' style={{ fontSize: 14, textDecoration: 'none' }}>Educators</NavLink>
                    </li>
                    <li
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                        {searchTerm ? slugToNormal(`Search Educators for '${searchTerm}'`) : slugToNormal(params.typeWiseSlug)}
                    </li>
                </ol>
            </nav>
            {/* <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handlesearchEducator} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Educator" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchEducator(1, searchTerm)}></i>
            </div> */}
        </div>

        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch ? <><div className="d-flex align-items-center justify-content-between">
                            <SectionDescription title={`<strong>Top 10 ${slugToNormal(typeWiseSlug)} Colleges/Universities/Institutes in India ${yearSatra()}: Discover Fees, Courses, Admission, Placements</strong>`} subTitle={`Find list of all top colleges in ${slugToNormal(typeWiseSlug)}. Check Fees, Admission process, and Placements. This list will help you in making decisions regarding the right educator selection for your career.`} />
                        </div>

                            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></> : null
                    }

                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <EducatorListItem educatorData={content} filterType={filterType} isSearch={isSearch ? true : false} redirectionFn={() => navigation(`/educators/university/${content?.slug}`)} key={sIndex} stripBottom={true} />
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>

    </>)
}

export default EducatorsList;