import React, { useState, useContext } from "react";
const SearchContext = React.createContext({});

const SearchProvider = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [sModuleType, setSModuleType] = useState({ label: 'Question Bank', value: 'qb' });
  const [searchLoading, setSearchLoading] = useState(false);
  const searchContextValue = {
    searchValue,
    setSearchLoading,
    searchLoading,
    sModuleType,
    setSModuleType,
    setSearchValue,
    currentPage,
    setCurrentPage,
    listData,
    setListData
  };

  return <SearchContext.Provider value={searchContextValue} {...props} />;
};

const useSearch = () => useContext(SearchContext);

export { SearchProvider, useSearch };
