import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { useState } from "react";
import { useEffect } from "react";
import { Loadingicon } from "../../../AppIcons";
import { getCreditStats, getMyPosts, getOverviewData, getRecentVisits, getSystemStats } from "../../../api/dashboard";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import trophy from "../../../assets/images/trophy.gif";
import dGraph from "../../../assets/images/d-graph.webp";
import uGraph from "../../../assets/images/u-graph.webp";

import Chart from "react-apexcharts";
import { useAuth } from "../../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  BASE_URL,
  BLOGS_BASE_URL,
  CAREER_NEWS_BASE_URL,
  convertSlugToTitle,
  EX_BASE_URL,
  FF_BASE_URL,
  formatDate,
  INTBANK_BASE_URL,
  nFormatter,
  QBANK_BASE_URL,
  QUOTES_BASE_URL,
  stripHtmlTags,
  TUTORIALS_BASE_URL,
  WORK_BASE_URL,
} from "../../../utils/utils";
import engagementRate from "../../../assets/images/engagement.png";
import earncredit from "../../../assets/images/profit.png";
import spendcredit from "../../../assets/images/cash-flow.png";
import cashwithdrawal from "../../../assets/images/cash-withdrawal.png";
import totalCredits from "../../../assets/images/star_n.png";
import { set } from "lodash";
import NotFound from "../../common/notfound";
import { onRecentActivityList } from "../../../api/AuthService";
import { getTransactionHistory } from "../../../api/OurService";
// import e from 'express';

ChartJS.register(
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  ChartDataLabels
);
function FinalDashboard() {
  const [data, setData] = useState(null);
  const { user, globalToken } = useAuth();
  const [selectedOptions, setSelectedOptions] = useState("cevscs");
  const [selectedModules, setSelectedModules] = useState([
    "Learn",
    "Work",
    "Earn",
    "Community",
  ]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [customRange, setCustomRange] = useState({ start: "", end: "" });
  const [isChartLoaded, setChartLoaded] = useState(false);
  const navigation = useNavigate();
  const [filterSystemStats, setFilterSystemStats] = useState({
    module: "work",
    library: "all",
    this_month: "today",
  });
  const [filterMyPosts, setFilterMyPosts] = useState({library:'forum', this_month:'today'});
  const [selectMyPostLibrary, setSelectMyPostLibrary] = useState("forum");
  const [selectedCreditsLibrary, setSelectedCreditsLibrary] = useState("tuteehub");
  const [filterCreditStats, setFilterCreditStats] = useState({library:'tuteehub', this_month:'today'});
  const [isSectionLoading, setIsSectionLoading] = useState(true);
  const [isSectionLoadingRecentVisit, setIsSectionLoadingRecentVisit] = useState(true);
  const [isSectionLoadingRecentActivity, setIsSectionLoadingRecentActivity] = useState(true);
  const [isSectionLoadingTransactionHitory, setIsSectionLoadingTransactionHitory] = useState(true);
  const [isSectionLoadingCreditStats, setIsSectionLoadingCreditStats] = useState(true);
  const [learnData, setLearnData] = useState({});
  const [workData, setWorkData] = useState({});
  const [earnData, setEarnData] = useState({});
  const [communityData, setCommunityData] = useState({});
  const [myPostData, setMyPostData] = useState({});
  const [myRecentVisitData, setMyRecentVisitData] = useState([]);
  const [myRecentActivity, setMyRecentActivity] = useState([]);
  const [recentTransactionData, setRecentTransactionData] = useState([]);
  const [creditStatsData, setCreditStatsData] = useState({});
  const myPostModuleFilter = [
    {
      label: "Blogs",
      value: "blogs",
    },{
      label: "Tutorials",
      value: "tutorial",
    },{
      label: "Forum",
      value: "forum",
    }
  ]
  const moduleFilter = [
    {
      label: "Learn",
      value: "learn",
    },
    {
      label: "Work",
      value: "work",
    },
    {
      label: "Earn",
      value: "earn",
    },
    {
      label: "Community",
      value: "community",
    },
  ];
  const dateFilter = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 Days", value: "weekly" },
    { label: "Last 30 Days", value: "monthly" },
    { label: "Last 12 Months", value: "yearly" },
    { label: "All Time", value: "all" },
  ];
  const [choosenSysDateFilter, setChoosenSysDateFilter] = useState(
    dateFilter[0].value
  );

  const sysApiCallDataMap = {
    learn: {
      qb: {
        url: `${QBANK_BASE_URL}qb/get-engagement-counts`,
        key: "qb",
        title: "Questions",
        btnTxt: "Explore",
        direct: () => navigation("/question-Bank"),
        explore: () => navigation("/question-Bank"),
        count: learnData?.questionbank || 0,
        graphTitle: "Questions In Question Bank", // Static title
        graphColor: "#E598A6", // Static color
      },
      ff: {
        url: `${QBANK_BASE_URL}qb/get-engagement-counts`,
        key: "ff",
        title: "Full Forms",
        btnTxt: "Explore",
        direct: () => navigation("/full-forms"),
        explore: () => navigation("/full-forms"),
        count: learnData?.full_forms || 0,
        graphTitle: "Fullforms", // Static title
        graphColor: "#E6C58F", // Static color
      },
      dc: {
        url: `${QBANK_BASE_URL}qb/get-engagement-counts`,
        key: "dc",
        title: "Meanings",
        btnTxt: "Explore",
        direct: () => navigation("/dictionary"),
        explore: () => navigation("/dictionary"),
        count: learnData?.dictionaries || 0,
        graphTitle: "Meanings In Dictionary", // Static title
        graphColor: "#D8A7FF", // Static color
      },
      ex: {
        url: `${QBANK_BASE_URL}qb/get-engagement-counts`,
        key: "ex",
        title: "Exams",
        btnTxt: "Give Exam",
        direct: () => navigation("/exams/topic/general-awareness-12850"),
        explore: () => navigation("/exams"),
        count: learnData?.exams || 0,
        graphTitle: "Exams", // Static title
        graphColor: "#94CFC7", // Static color
      },
      tutorial: {
        url: `${BLOGS_BASE_URL}blogs/get-engagement-counts`,
        key: "tutorial",
        title: "Tutorials",
        btnTxt: "Post Tutorial",
        direct: () => navigation("/tutorials/post-a-tutorial"),
        explore: () => navigation("/tutorials"),
        count: learnData?.tutorial || 0,
        graphTitle: "Tutorials", // Static title
        graphColor: "#99BFDF", // Static color
      },
      article: {
        url: `${BLOGS_BASE_URL}blogs/get-engagement-counts`,
        key: "article",
        title: "Blogs",
        btnTxt: "Post Blog",
        direct: () => navigation("/blogs/post-a-blog"),
        explore: () => navigation("/blogs"),
        count: learnData?.article || 0,
        graphTitle: "Blogs", // Static title
        graphColor: "#BA85DF", // Static color
      },
      educator: {
        url: `${BASE_URL}get-engagement-counts`,
        key: "educator",
        title: "Educators",
        btnTxt: "Discover More",
        direct: () => navigation("/educators"),
        explore: () => navigation("/educators"),
        count: learnData?.educators || 0,
        graphTitle: "Educators", // Static title
        graphColor: "#E58189", // Static color
      },
    },
    work: {
      corp: {
        url: `${BASE_URL}get-engagement-counts`,
        key: "corporate",
        title: "Corporates",
        btnTxt: "Discover More",
        direct: () => navigation("/corporates"),
        explore: () => navigation("/corporates"),
        count: workData?.corporates || 0, // corporates count from data
        graphTitle: "Corporates",
        graphColor: "#D8A7FF", // Example color
      },
      iq: {
        url: `${QBANK_BASE_URL}qb/get-engagement-counts`,
        key: "iq",
        title: "Interview Questions",
        btnTxt: "Explore",
        direct: () => navigation("/interview-questions"),
        explore: () => navigation("/interview-questions"),
        count: workData?.question_interviews || 0, // question_interviews from data
        graphTitle: "Interview Questions",
        graphColor: "#BA85DF", // Example color
      },
      cn: {
        url: `${CAREER_NEWS_BASE_URL}get-engagement-counts`,
        key: "careernews",
        title: "Career News",
        btnTxt: "Read More",
        direct: () => navigation("/career-news"),
        explore: () => navigation("/career-news"),
        count:
          workData?.careernews_content + workData?.career_news_hindi_contents ||
          0, // careernews_content from data
        graphTitle: "Career News",
        graphColor: "#E58189", // Example color
      },
      fm: {
        url: `${BLOGS_BASE_URL}blogs/get-engagement-counts`,
        key: "forum",
        title: "Discussions",
        btnTxt: "Post A Question",
        direct: () => navigation("/forum/post-a-question"),
        explore: () => navigation("/forum"),
        count: workData?.qa_forum || 0, // qa_forum from data
        graphTitle: "Discussions",
        graphColor: "#94CFC7", // Example color
      },
    },
    earn: {
      gig: {
        url: `${CAREER_NEWS_BASE_URL}get-engagement-counts`,
        key: "gigs",
        title: "Campaigns",
        btnTxt: "Post A Campaign",
        direct: () => navigation("/gigs/create-campaign"),
        explore: () => navigation("/gigs"),
        count: earnData?.campaigns || 0,
        graphTitle: "Campaigns",
        graphColor: "#FF5733",
      },
      work: {
        url: `${WORK_BASE_URL}get-all-counts`,
        key: "all",
        title: "Projects & Services",
        btnTxt: "Post A Project",
        direct: "https://work.tuteehub.com",
        explore: "https://work.tuteehub.com/project",
        count:
          (earnData?.project || 0) +
          (earnData?.service || 0) +
          (earnData?.software || 0),
        graphTitle: "Projects & Services",
        graphColor: "#E6C58F",
        url:true
      },
    },
    community: {
      sht: {
        url: `${QUOTES_BASE_URL}get-engagement-counts`,
        key: "videos",
        title: "Videos",
        btnTxt: "Discover Videos",
        direct: "https://showtalent.tuteehub.com/videos",
        explore: "https://showtalent.tuteehub.com/videos",
        count: communityData?.videos || 0,
        graphTitle: "Video Engagements",
        graphColor: "#80C7E0"
      },
      qt: {
        url: `${QUOTES_BASE_URL}get-engagement-counts`,
        key: "quotes",
        title: "Quotes",
        btnTxt: "Read Quotes",
        direct: () => navigation("/quotes"),
        explore: () => navigation("/quotes"),
        count: communityData?.quotes || 0,
        graphTitle: "Quote Engagements",
        graphColor: "#B39DDB",
      },
      lyr: {
        url: `${QUOTES_BASE_URL}get-engagement-counts`,
        key: "lyrics",
        title: "Lyrics",
        btnTxt: "Read Lyrics",
        direct: () => navigation("/lyrics"),
        explore: () => navigation("/lyrics"),
        count: communityData?.lyrics || 0,
        graphTitle: "Lyrics Engagements",
        graphColor: "#A5D6A7",
      },
      fd: {
        url: `${BASE_URL}get-engagement-counts`,
        key: "feed",
        title: "Feeds",
        btnTxt: "Read Feeds",
        direct: () => navigation("/feeds"),
        explore: () => navigation("/feeds"),
        count: communityData?.th_feeds || 0,
        graphTitle: "Feeds",
        graphColor: "#A78BFA"
      },
    },
  };
  const moduleDataLoop = (data, fLoad) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        handleSystemCounts(data[key].url, data[key].key, fLoad);
      }
    }
  };

  const getRandomValue = () => {
    return Math.floor(Math.random() * 100);
  };

  const getRandomSuffix = () => {
    return Math.random() < 0.5 ? "" : "";
  };
  let earnFixedData = { likes: 0, comments: 0, shares: 0, views: 0 };
  const agregatePopularityByModule = (firstLoad = false) => {
    let popularData = {};
    const safeSum = (...values) => values.reduce((acc, val) => acc + (val ?? 0), 0);
    if (filterSystemStats.library === "all") {
      if (filterSystemStats?.module === "learn") {
        popularData = {
          likes: safeSum(
            learnData?.questionbank_response,
            learnData?.article_response,
            learnData?.tutorial_response,
            learnData?.educators_response,
            learnData?.exam_response,
            learnData?.full_form_response,
            learnData?.dictionary_response
          ),
          comments: safeSum(
            learnData?.questionbank_comment,
            learnData?.article_comment,
            learnData?.tutorial_comment,
            learnData?.educator_comments,
            learnData?.exam_comment,
            learnData?.full_form_comment,
            learnData?.dictionary_comment
          ),
          shares: safeSum(
            learnData?.article_share,
            learnData?.tutorial_share,
            learnData?.educators_share,
            learnData?.ex_share,
            learnData?.ff_share,
            learnData?.qb_share,
            learnData?.dc_share
          ),
          views: safeSum(
            learnData?.dc_views,
            learnData?.qb_views,
            learnData?.ff_views,
            learnData?.ex_views,
            learnData?.educator_views,
            learnData?.tutorial_views,
            learnData?.article_views
          ),
        };
        return popularData;
      }
  
      if (filterSystemStats?.module === "work") {
        popularData = {
          likes: safeSum(
            workData?.corporate_response,
            workData?.careernews_response,
            workData?.career_news_hindi_responses,
            workData?.question_interview_response,
            workData?.qa_response
          ),
          comments: safeSum(
            workData?.corporate_comments,
            workData?.careernews_comment,
            workData?.career_news_hindi_comments,
            workData?.question_interview_answer,
            workData?.qa_answers
          ),
          shares: safeSum(
            workData?.qa_share,
            workData?.iq_share,
            workData?.cn_hindi_share,
            workData?.cn_eng_share,
            workData?.corporate_share
          ),
          views: safeSum(
            workData?.qa_views,
            workData?.iq_views,
            workData?.cn_hindi_views,
            workData?.cn_eng_views,
            workData?.corporate_views
          ),
        };
        return popularData;
      }
  
      if (filterSystemStats?.module === "earn") {
        if (firstLoad) {
          popularData = {
            likes: getRandomValue(),
            comments: getRandomValue(),
            shares: getRandomValue(),
            views: getRandomValue(),
          };
          earnFixedData = popularData;
        }
        return earnFixedData;
      }
  
      if (filterSystemStats?.module === "community") {
        popularData = {
          likes: safeSum(
            communityData?.quote_response,
            communityData?.lyric_response,
            communityData?.video_response,
            communityData?.th_feed_likes
          ),
          comments: safeSum(
            communityData?.quote_comment,
            communityData?.lyric_comment,
            communityData?.video_comment,
            communityData?.th_feed_comments
          ),
          shares: safeSum(
            communityData?.quote_share,
            communityData?.lyric_share,
            communityData?.video_share,
            communityData?.feeds_share
          ),
          views: safeSum(
            communityData?.quote_views,
            communityData?.lyric_views,
            communityData?.video_views,
            communityData?.feeds_views
          ),
        };
        return popularData;
      }
    } else {
      const safeGet = (value, defaultValue = 0) => value ?? defaultValue;
      if (filterSystemStats.module == "learn") {
        popularData = {
          likes: {
            questionbank: learnData?.questionbank_response,
            article: learnData?.article_response,
            tutorial: learnData?.tutorial_response,
            educators: learnData?.educators_response,
            exam: learnData?.exam_response,
            fullForm: learnData?.full_form_response,
            dictionary: learnData?.dictionary_response,
          },
          comments: {
            questionbank: learnData?.questionbank_comment,
            article: learnData?.article_comment,
            tutorial: learnData?.tutorial_comment,
            educators: learnData?.educator_comments,
            exam: learnData?.exam_comment,
            fullForm: learnData?.full_form_comment,
            dictionary: learnData?.dictionary_comment,
          },
          shares: {
            questionbank: learnData?.qb_share,
            article: learnData?.article_share,
            tutorial: learnData?.tutorial_share,
            educators: learnData?.educators_share,
            exam: learnData?.ex_share,
            fullForm: learnData?.ff_share,
            dictionary: learnData?.dc_share,
          },
          views: {
            questionbank: learnData?.qb_views,
            article: learnData?.article_views,
            tutorial: learnData?.tutorial_views,
            educators: learnData?.educator_views,
            exam: learnData?.ex_views,
            fullForm: learnData?.ff_views,
            dictionary: learnData?.dc_views,
          },
        };
        return popularData;
      }
      if (filterSystemStats.module == "work") {
        popularData = {
          likes: {
            corporate: workData?.corporate_response,
            careernews: workData?.careernews_response,
            careerNewsHindi: workData?.career_news_hindi_responses,
            questionInterview: workData?.question_interview_response,
            qa: workData?.qa_response,
          },
          comments: {
            corporate: workData?.corporate_comments,
            careernews: workData?.careernews_comment,
            careerNewsHindi: workData?.career_news_hindi_comments,
            questionInterview: workData?.question_interview_answer,
            qa: workData?.qa_answers,
          },
          shares: {
            corporate: workData?.corporate_share,
            careernews: workData?.cn_eng_share,
            careerNewsHindi: workData?.cn_hindi_share,
            questionInterview: workData?.iq_share,
            qa: workData?.qa_share,
          },
          views: {
            corporate: workData?.corporate_views,
            careernews: workData?.cn_eng_views,
            careerNewsHindi: workData?.cn_hindi_views,
            questionInterview: workData?.iq_views,
            qa: workData?.qa_views,
          },
        };
        return popularData;
      }
      if (filterSystemStats.module == "earn") {
        popularData = {
          likes: {
            gigs: getRandomValue(),
            work: getRandomValue(),
          },
          comments: {
            gigs: getRandomValue(),
            work: getRandomValue(),
          },
          shares: {
            gigs: getRandomValue(),
            work: getRandomValue(),
          },
          views: {
            gigs: getRandomValue(),
            work: getRandomValue(),
          },
        };
        // setLearnData({...learnData, ...popularData})
        return popularData;
      }
      if (filterSystemStats.module == "community") {
        popularData = {
          likes: {
            quotes: communityData?.quote_response,
            lyrics: communityData?.lyric_response,
            videos: communityData?.video_response,
            feeds: communityData?.th_feed_likes
          },
          comments: {
            quotes: communityData?.quote_comment,
            lyrics: communityData?.lyric_comment,
            videos: communityData?.video_comment,
            feeds: communityData?.th_feed_comments
          },
          shares: {
            quotes: communityData?.quote_share,
            lyrics: communityData?.lyric_share,
            videos: communityData?.video_share,
            feeds: communityData?.feeds_share
          },
          views: {
            quotes: communityData?.quote_views,
            lyrics: communityData?.lyric_views,
            videos: communityData?.video_views,
            feeds: communityData?.feeds_views
          },
        };
        return popularData;
      }
    }
  };

  useEffect(() => {
    setChartLoaded(true);
    if (filterSystemStats.module) {
      moduleDataLoop(sysApiCallDataMap?.[filterSystemStats?.module], true);
      return;
    }
  }, []);

  // get count sys
  const handleSystemCounts = async (u, lib, isFirstLoad) => {
    const resSysStats = await getSystemStats({
      url: u,
      lib,
      filter: filterSystemStats,
    });

    if (resSysStats?.success && isFirstLoad) {
      const moduleDataHandlers = {
        learn: setLearnData,
        work: setWorkData,
        earn: setEarnData,
        community: setCommunityData,
      };
      const updateState = moduleDataHandlers[filterSystemStats?.module];
      if (updateState) {
        const dataToUpdate =
          lib == "gigs"
            ? resSysStats?.data?.gigs
            : lib == "careernews"
              ? resSysStats?.data?.careernews
              : resSysStats?.data;
        updateState((prevData) => ({ ...prevData, ...dataToUpdate }));
      }
    }
  };

  // handle filter by date/library/module sysstats
  const handleFilterSysStats = (mType, fType, data) => {
    if (mType == "sys" && fType == "date") {
      setChoosenSysDateFilter(data.target.value);
      setFilterSystemStats({
        ...filterSystemStats,
        this_month: data.target.value,
      });
    }
  };
  useEffect(() => {
    // console.log(filterSystemStats?.library);
    if (filterSystemStats.module) {
      moduleDataLoop(sysApiCallDataMap?.[filterSystemStats.module], true);
    }
  }, [filterSystemStats]);

  const earnCreditVsSpentCredits = [
    {
      name: "Earned Credits",
      data: [170, 420, 300, 550, 550, 650, 820],
    },
    {
      name: "Spent Credits",
      data: [320, 300, 650, 400, 750, 650, 600],
    },
  ];
  const creditPurchasedVsWithdrawals = [
    {
      name: "Credits Purchased",
      data: [100, 120, 305, 600, 500, 450, 800],
    },
    {
      name: "Withdrawals",
      data: [420, 280, 600, 350, 690, 650, 500],
    },
  ];

  const creditCamparisonsOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#4936F5", "#EC1F00"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [2, 2],
    },
    grid: {
      show: true,
      borderColor: "#ECEEF2",
      strokeDashArray: 10,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        stops: [0, 90, 100],
        shadeIntensity: 1,
        opacityFrom: 0,
        opacityTo: 0.5,
      },
    },
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      axisTicks: {
        show: false,
        color: "#ECEEF2",
      },
      axisBorder: {
        show: true,
        color: "#D5D9E2",
      },
      labels: {
        show: true,
        style: {
          colors: "#8695AA",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      tickAmount: 5,
      max: 1000,
      min: 0,
      labels: {
        style: {
          colors: "#64748B",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#D5D9E2",
      },
      axisTicks: {
        show: false,
        color: "#ECEEF2",
      },
    },
    legend: {
      show: true,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "left",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
  };

  const moduleSeries = filterSystemStats?.module
    ? Object.values(sysApiCallDataMap?.[filterSystemStats?.module]).map(
      (item) => item.count
    )
    : [10, 20, 30, 11];
  const mLabels = filterSystemStats?.module
    ? Object.values(sysApiCallDataMap?.[filterSystemStats?.module]).map(
      (item) => item.graphTitle
    )
    : [];
  const mColors = filterSystemStats?.module
    ? Object.values(sysApiCallDataMap?.[filterSystemStats?.module]).map(
      (item) => item.graphColor
    )
    : [];

  const popularitySeries =
    filterSystemStats?.library == "all"
      ? [
        agregatePopularityByModule(true).likes,
        agregatePopularityByModule().comments,
        agregatePopularityByModule().shares,
        agregatePopularityByModule().views,
      ]
      : [
        agregatePopularityByModule()?.likes?.[filterSystemStats?.library],
        agregatePopularityByModule()?.comments?.[filterSystemStats?.library],
        agregatePopularityByModule()?.shares?.[filterSystemStats?.library],
        agregatePopularityByModule()?.views?.[filterSystemStats?.library],
      ];

  const popularityByEngagement = {
    labels: ["Likes", "Comments", "Shares", "Views"],
    colors: ["#99BFDF", "#BA85DF", "#E58189", "#E6B172"],
    stroke: {
      width: 1,
      show: true,
      colors: ["#ffffff"],
    },
    legend: {
      show: false,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name: {
              color: "#64748B",
            },
            value: {
              show: true,
              color: "#3A4252",
              fontSize: "22px",
              fontWeight: "600",
            },
            total: {
              show: true,
              color: "#64748B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const activitybyModules = {
    labels: mLabels,
    colors: mColors,
    stroke: {
      width: 1,
      show: true,
      colors: ["#ffffff"],
    },
    legend: {
      show: false,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name: {
              color: "#64748B",
            },
            value: {
              show: true,
              color: "#3A4252",
              fontSize: "22px",
              fontWeight: "600",
            },
            total: {
              show: true,
              color: "#64748B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const learnModule = [
    {
      label: "Question Bank",
      value: "questionbank",
    },
    {
      label: "Full Forms",
      value: "fullForm",
    },
    {
      label: "Dictionary",
      value: "dictionary",
    },
    // {
    //   label:'Courses',
    //   value:'courses'
    // },
    {
      label: "Tutorials",
      value: "tutorial",
    },
    {
      label: "Educators",
      value: "educators",
    },
    {
      label: "Exams",
      value: "exam",
    },
    {
      label: "Blogs",
      value: "article",
    },
    // ,{
    //   label:'Tutoring',
    //   value:'tutoring'
    // }
  ];
  const workModule = [
    {
      label: "Corporates",
      value: "corporate",
    },
    {
      label: "Interview Questions",
      value: "questionInterview",
    },
    {
      label: "Career News",
      value: "careernews",
    },
    // ,{
    //   label:'Tools',
    //   value:'tools'
    // }
    {
      label: "Forum",
      value: "qa",
    },
  ];
  const earnModule = [
    {
      label: "Gigs",
      value: "gigs",
    },
    {
      label: "Work",
      value: "work",
    },
  ];
  const communityModule = [
    {
      label: "Videos",
      value: "videos",
    },
    {
      label: "Lyrics",
      value: "lyrics",
    },
    {
      label: "Quotes",
      value: "quotes",
    },
    {
      label: "Feeds",
      value: "feeds",
    },
  ];

  const keyValueMap = {
    learn: {
      questionbank: "Question Bank",
      fullForm: "Full Forms",
      dictionary: "Dictionary",
      tutorial: "Tutorials",
      educators: "Educators",
      exam: "Exams",
      article: "Blogs",
    },
    work: {
      corporate: "Corporates",
      questionInterview: "Interview Questions",
      careernews: "Career News",
      qa: "Forum",
    },
    earn: {
      gigs: "Gigs",
      work: "Work",
    },
    community: {
      videos: "Videos",
      lyrics: "Lyrics",
      quotes: "Quotes",
      feeds: "Feeds",
    },
  };

  const [moduleOptions, setModuleOptions] = useState([
    ...learnModule,
    ...workModule,
    ...earnModule,
    ...communityModule,
  ]);

  useEffect(() => {
    const moduleMap = {
      Learn: learnModule,
      Work: workModule,
      Earn: earnModule,
      Community: communityModule,
    };

    const selectedOptions = selectedModules.reduce(
      (acc, key) => [...acc, ...moduleMap[key]],
      []
    );

    setModuleOptions(selectedOptions);
  }, [selectedModules]);

  

  const handleOptionChange = async (e) => {
    setLoading(true);
    const value = e.target.value;
    setSelectedOption(value);

    // If custom range is selected, do not immediately send the query
    if (value === "custom") {
      setLoading(false);
      return;
    } else {
    }

    const queryMap = {
      today: "today",
      yesterday: "yesterday",
      "past-7-days": "week",
      "past-30-days": "thirty-days",
      "past-60-days": "sixty-days",
      "past-90-days": "three-months",
      "past-6-months": "six-months",
      "past-12-months": "twelve-months",
      "this-month": "this-month",
      "past-month": "past-month",
    };

    const query = `${queryMap[value]}`;
    const response = await getOverviewData({ query });
    setData(response?.data);
    setLoading(false);
  };

  

  const fetchOverviewData = async () => {
    setLoading(true);
    const response = await getOverviewData({ query: "today" });
    setData(response?.data);
    setLoading(false);
  };
  useEffect(() => {    
    window.scrollTo(0, 0);
    fetchOverviewData();
  }, []);

  const handleSysStateModuleChange = (e) => {
    setFilterSystemStats({ ...filterSystemStats, library: "all", module: e.target.value });
  };

  const handleSysLibraryChange = (e) => {
    setFilterSystemStats({ ...filterSystemStats, library: e.target.value });
  };



  const handleMyPosts = async (module) => {
    setIsSectionLoading(true);
      const resMyPost = await getMyPosts({ module, token: globalToken, filter: filterMyPosts });
      setMyPostData(resMyPost);
      setIsSectionLoading(false);
  };


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (!isFetching && scrollPosition >= 100) {
        setIsFetching(true);
        viewRecentVisits();
        handleMyRecentActivity();
        handleTransactionHitory();
        handleCreditInfo(selectedCreditsLibrary, filterCreditStats?.this_month);
        handleMyPosts(filterMyPosts?.library).finally(() => {
          // setIsFetching(false); 
        });
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFetching]);


  const isAllZero = (series) => {
    return series.every(value => value === 0);
  };

  const viewRecentVisits = async () =>{
    setIsSectionLoadingRecentVisit(true);
        const resRecentVisits = await getRecentVisits({ token: globalToken });
        if(resRecentVisits?.success){
          setMyRecentVisitData(resRecentVisits?.data);
          setIsSectionLoadingRecentVisit(false);
        }
  }


  const handleMyRecentActivity = async () => {  
              setIsSectionLoadingRecentActivity(true)
              const res = await onRecentActivityList({ token: globalToken, choosenModule: '', filterDays:365, perPage:10});
               if (res?.success==200) {
                  setMyRecentActivity(res?.data?.original?.data);
              } else {
                  // toast.error(res?.message)
              }
              setIsSectionLoadingRecentActivity(false);
  };

  const handleTransactionHitory = async () =>{
        setIsSectionLoadingTransactionHitory(true);
        const res = await getTransactionHistory(user?.tuteehub_id);            
        setRecentTransactionData(res?.data?.data);
        setIsSectionLoadingTransactionHitory(false);
  }

  const handleCreditsLibraryChange = (e) =>{
    setSelectedCreditsLibrary(e.target.value);
    handleCreditInfo(e.target.value, filterCreditStats?.this_month);
  }

  const handleCreditInfo= async (module, this_month) => {  
    setIsSectionLoadingCreditStats(true)
    const res = await getCreditStats({ token: globalToken, module:module, this_month:this_month });
     if (res?.status==1) {
      setCreditStatsData(res?.data);
    } else {
        // toast.error(res?.message)
    }
    setIsSectionLoadingCreditStats(false);
};
  return (
    <>
      {loading ? (
        <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
          <div className="h-10 w-10">
            <Loadingicon />
          </div>
        </div>
      ) : (
        <>
          <div className="mb-4 row">
            <div className="col-md-12">
              <div
                className="card"
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                }}
              >
                <div className="row mt-md-0 mt-3">
                  <div className="col-md-4">
                    <div className="d-flex items-center gap-4">
                      <div className="">
                        <img src={trophy} style={{ width: 60 }} />
                      </div>
                      <div className="">
                        <h3
                          style={{ fontSize: "1.125rem", color: "#3c4043" }}
                          className="mb-0"
                        >
                          Welcome Back,{" "}
                          <span className="theme-color">{user?.name}!</span>
                        </h3>
                        <small className="color-gray">
                          Top community builder
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 m-auto">
                    <div style={{ maxWidth: "60%", margin: "0 auto" }}>
                      <div className="d-flex items-center w-100 justify-between mb-2">
                        <label>Profile Completeness</label>
                        <strong className="theme-color">
                          {user?.profile_completed}%
                        </strong>
                      </div>
                      <div className="progress" style={{ height: 6 }}>
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                          role="progressbar"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: "75%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex w-100 gap-2 justify-between">
                      <button
                        className="btn--light mt-3 btn justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary  btn-sm credit-btn"
                        type="button"
                        onClick={() => navigation(`/my-profile`)}
                      >
                        View Profile
                      </button>
                      <button
                        className="mt-3 btn--light  btn justify-content-center px-md-3 px-2 d-flex text-white align-items-center border-0  btn-sm offer-btn"
                        type="button"
                        onClick={() => navigation(`/my-profile/edit`)}
                      >
                        Complete Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className="card"
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                }}
              >
                <div
                className="d-md-flex h-section"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Platform Overview Text */}
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "1.125rem",
                      color: "#3c4043",
                    }}
                  >
                    System Insights & Opportunity
                  </h2>

                  {/* Filter Dropdown */}
                  <div
                    style={{
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <select
                      className="filter-select"
                      value={filterSystemStats.module}
                      onChange={handleSysStateModuleChange}
                    >
                      {moduleFilter?.map((m, _i) => (
                        <option key={_i + "sysM"} value={m.value}>
                          {m.label}
                        </option>
                      ))}
                    </select>

                    <select
                      className="filter-select"
                      value={choosenSysDateFilter}
                      onChange={(e) => handleFilterSysStats("sys", "date", e)}
                    >
                      {dateFilter?.map((f, _i) => (
                        <option key={_i + "sysdfilter"} value={f?.value}>
                          {f.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr style={{ borderColor: "#f0f0f0" }} />
                <div className="mb-4 row">
                  <div className="col-md-5">
                    <div
                      className="card h-100"
                      style={{
                        padding: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                      }}
                    >
                      <hr style={{ borderColor: "#f0f0f0", height:35.2 }} />
                      <div className="col-md-12 px-0 text-center mb-4">
                        {isChartLoaded && (
                          // <></>
                          <Chart
                            options={activitybyModules}
                            series={moduleSeries}
                            type="donut"
                            height={140}
                            width={"100%"}
                          />
                        )}
                        <small className="text-gray-400 w-100 text-center d-block">
                          Overall System Stats by{" "}
                          <span title="Learn, Work, Earn, Community">
                            Module
                          </span>{" "}
                          and Date
                        </small>
                      </div>

                      <div className="row c-row">
                        {filterSystemStats.module &&
                          Object.entries(
                            sysApiCallDataMap?.[filterSystemStats.module]
                          ).map(([key, sstats], _i) => (
                            <div
                              className="col-md-4s my-2"
                              key={_i + "sStats"}
                              title={sstats?.count || 0 + ` ${sstats?.title}`}
                            >
                              <div className="text-center d-flex flex-column align-items-center justify-center stats-my">
                                <h3
                                  style={{
                                    margin: 0,
                                    fontSize: "1.125rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {
                                    sstats?.url? <a
                                    href={sstats.explore}
                                    target="_blank"
                                    className="theme-color"
                                  >
                                    {nFormatter(sstats?.count || 0)}
                                  </a>: <a
                                    href="javascript:;"
                                    className="theme-color"
                                    onClick={() => sstats.explore()}
                                  >
                                    {nFormatter(sstats?.count || 0)}
                                  </a>
                                  }
                                 
                                </h3>
                                <small className="color-gray">
                                  {sstats?.title}
                                </small>
                                <p className="m-0">
                                {
                                    sstats?.url?<a
                                    target="_blank"
                                    className="small-btn small mt-2s"
                                    type="button"
                                    href={sstats?.direct}
                                  >
                                    {sstats?.btnTxt}
                                  </a>:<button
                                    className="small-btn small mt-2s"
                                    type="button"
                                    onClick={() => sstats?.direct()}
                                  >
                                    {sstats?.btnTxt}
                                  </button>
                                }
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7  mt-md-0 mt-4">
                    <div
                      className="card h-100"
                      style={{
                        padding: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          className="w-100"
                        >
                          <select
                            className="filter-select w-100"
                            value={filterSystemStats.library}
                            onChange={handleSysLibraryChange}
                          >
                            <option value="all">All</option>
                            {(filterSystemStats?.module == "learn" &&
                              learnModule.map((m, _i) => (
                                <option key={_i + "learnMS"} value={m.value}>
                                  {m.label}
                                </option>
                              ))) ||
                              (filterSystemStats?.module == "work" &&
                                workModule.map((m, _i) => (
                                  <option key={_i + "workMS"} value={m.value}>
                                    {m.label}
                                  </option>
                                ))) ||
                              (filterSystemStats?.module == "earn" &&
                                earnModule.map((m, _i) => (
                                  <option key={_i + "earnMS"} value={m.value}>
                                    {m.label}
                                  </option>
                                ))) ||
                              (filterSystemStats?.module == "community" &&
                                communityModule.map((m, _i) => (
                                  <option
                                    key={_i + "communityMS"}
                                    value={m.value}
                                  >
                                    {m.label}
                                  </option>
                                )))}
                          </select>
                        </div>
                      </div>
                      <hr style={{ borderColor: "#f0f0f0" }} />
                      <div className="row">
                        {/* <div className="col-md-8">
                          <div className="flex my-3">
                            <div className="transaction-left flex items-center flex-1">
                              <div
                                className="thumb-trans me-2"
                                style={{
                                  background: "rgb(153 191 223 / 50%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-thumbs-up"></i>
                              </div>
                              <div
                                className="flex flex-col"
                                title={`${filterSystemStats?.library == "all"
                                    ? agregatePopularityByModule().likes
                                    : agregatePopularityByModule()?.likes?.[
                                    filterSystemStats?.library
                                    ]
                                  }`}
                              >
                                <p className="m-0">
                                  <strong>
                                    {nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().likes
                                        : agregatePopularityByModule()?.likes?.[
                                        filterSystemStats?.library
                                        ]
                                    )}
                                  </strong>
                                </p>
                                <small className="color-gray">
                                  {filterSystemStats.library == "all"
                                    ? `Likes`
                                    : `Likes on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}
                                </small>
                              </div>
                            </div>
                            <div className="transaction-left flex items-center flex-1 mt-3">
                              <div
                                className="thumb-trans me-2"
                                style={{
                                  background: "rgb(186 133 223 / 46%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-comment"></i>
                              </div>
                              <div
                                className="flex flex-col"
                                title={`${filterSystemStats?.library == "all"
                                    ? agregatePopularityByModule().comments
                                    : agregatePopularityByModule()?.comments?.[
                                    filterSystemStats?.library
                                    ]
                                  }`}
                              >
                                <p className="m-0">
                                  <strong>
                                    {nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().comments
                                        : agregatePopularityByModule()
                                          ?.comments?.[
                                        filterSystemStats?.library
                                        ]
                                    )}
                                  </strong>
                                </p>
                                <small className="color-gray">
                                  {filterSystemStats.library == "all"
                                    ? `Comments`
                                    : `Comments on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="transaction-left flex items-center flex-1">
                              <div
                                className="thumb-trans me-2"
                                style={{
                                  background: "rgb(229 129 137 / 46%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-share-alt"></i>
                              </div>
                              <div
                                className="flex flex-col"
                                title={`${filterSystemStats?.library == "all"
                                    ? agregatePopularityByModule().shares
                                    : agregatePopularityByModule()?.shares?.[
                                    filterSystemStats?.library
                                    ]
                                  }`}
                              >
                                <p className="m-0">
                                  <strong>
                                    {nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().shares
                                        : agregatePopularityByModule()
                                          ?.shares?.[
                                        filterSystemStats?.library
                                        ]
                                    )}
                                  </strong>
                                </p>
                                <small className="color-gray">
                                  {filterSystemStats.library == "all"
                                    ? `Shares`
                                    : `Shares on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}
                                </small>
                              </div>
                            </div>
                            <div className="transaction-left flex items-center flex-1">
                              <div
                                className="thumb-trans me-2"
                                style={{
                                  background: "rgb(230 177 114 / 46%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-eye"></i>
                              </div>
                              <div
                                className="flex flex-col"
                                title={`${filterSystemStats?.library == "all"
                                    ? agregatePopularityByModule().views
                                    : agregatePopularityByModule()?.views?.[
                                    filterSystemStats?.library
                                    ]
                                  }`}
                              >
                                <p className="m-0">
                                  <strong>
                                    {nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().views
                                        : agregatePopularityByModule()?.views?.[
                                        filterSystemStats?.library
                                        ]
                                    )}
                                  </strong>
                                </p>
                                <small className="color-gray">
                                  {filterSystemStats.library == "all"
                                    ? `Views`
                                    : `Views on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-md-12 mb-4">
                          {isChartLoaded && (
                          <span className={`${isAllZero(popularitySeries)?'blurchart':''}`}>
                            <Chart
                            options={popularityByEngagement}
                            series={isAllZero(popularitySeries)?[30, 80, 120, 47]:popularitySeries} 
                            type="donut"
                            height={140}
                            width={'100%'}
                          />
                          </span>
                        )}
                         <small className="text-gray-400 w-100 text-center d-block">
                          Overall System Popularity Stats by{" "}
                          <span>
                            Module, Library
                          </span>{" "}
                          and Date
                        </small>
                        </div>
                      </div>
                      <div className="row mt-2">
                          <div className="col-md-3">
                            <div class="text-center d-flex flex-column align-items-center justify-center stats-my"
                            title={`${filterSystemStats?.library == "all"
                              ? agregatePopularityByModule().likes
                              : agregatePopularityByModule()?.likes?.[
                              filterSystemStats?.library
                              ]
                            }`}>
                            <div
                                className="thumb-trans my-3"
                                style={{
                                  background: "rgb(153 191 223 / 50%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-thumbs-up"></i>
                              </div>
                                <h3 style={{margin: 0,fontSize: '1.125rem', fontWeight: 'bold'}}>
                                <span style={{color:'rgb(153 191 223 / 50%)'}}>{nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().likes
                                        : agregatePopularityByModule()?.likes?.[
                                        filterSystemStats?.library
                                        ]
                                    )}</span></h3>
                                <small class="color-gray"> 
                                  {filterSystemStats.library == "all"
                                    ? `Likes`
                                    : 'Likes' || `Likes on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}
                                </small>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="text-center d-flex flex-column align-items-center justify-center stats-my" title={filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().comments
                                        : agregatePopularityByModule()
                                          ?.comments?.[
                                        filterSystemStats?.library
                                        ]
                                    }>
                            <div
                                className="thumb-trans my-3"
                                style={{
                                  background: "rgb(186 133 223 / 46%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-comment"></i>
                              </div>
                                <h3 style={{margin: 0,fontSize: '1.125rem', fontWeight: 'bold'}}>
                                <span style={{color:'rgb(186 133 223 / 46%)'}}>{nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().comments
                                        : agregatePopularityByModule()
                                          ?.comments?.[
                                        filterSystemStats?.library
                                        ]
                                    )}</span></h3>
                                <small class="color-gray">{filterSystemStats.library == "all"
                                    ? `Comments`
                                    : 'Comments' || `Comments on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}</small>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="text-center d-flex flex-column align-items-center justify-center stats-my" title={filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().shares
                                        : agregatePopularityByModule()
                                          ?.shares?.[
                                        filterSystemStats?.library
                                        ]
                                   }>
                            <div
                                className="thumb-trans my-3"
                                style={{
                                  background: "rgb(229 129 137 / 46%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-share-alt"></i>
                              </div>
                                <h3 style={{margin: 0,fontSize: '1.125rem', fontWeight: 'bold'}}>
                                <span style={{color:'rgb(229 129 137 / 46%)'}}>{nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().shares
                                        : agregatePopularityByModule()
                                          ?.shares?.[
                                        filterSystemStats?.library
                                        ]
                                    )}</span></h3>
                                <small class="color-gray">{filterSystemStats.library == "all"
                                    ? `Shares`
                                    : 'Shares' || `Shares on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}</small>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="text-center d-flex flex-column align-items-center justify-center stats-my" title={
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().views
                                        : agregatePopularityByModule()
                                          ?.views?.[
                                        filterSystemStats?.library
                                        ]
                                    }>
                             <div
                                className="thumb-trans my-3"
                                style={{
                                  background: "rgb(230 177 114 / 46%)",
                                  color: "#fafafa",
                                }}
                              >
                                <i className="fa fa-eye"></i>
                              </div>
                                <h3 style={{margin: 0,fontSize: '1.125rem', fontWeight: 'bold'}}>
                                <span style={{color:'rgb(230 177 114 / 46%)'}}>{nFormatter(
                                      filterSystemStats?.library == "all"
                                        ? agregatePopularityByModule().views
                                        : agregatePopularityByModule()
                                          ?.views?.[
                                        filterSystemStats?.library
                                        ]
                                    )}</span></h3>
                                <small class="color-gray">{filterSystemStats.library == "all"
                                    ? `Views`
                                    : 'Views' || `Views on ${keyValueMap?.[filterSystemStats?.module]?.[filterSystemStats?.library]}`}</small>
                            </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* <div className="col-md-12">
                          <div
                            className="card mt-4 text-center d-flex items-center justify-between"
                            style={{
                              padding: "20px",
                              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                              width: "100%",
                            }}
                          >
                            <img
                              src={engagementRate}
                              width={50}
                              style={{ opacity: 0.4}}
                            />
                            <p
                              className="text-orange-400 mb-0 mt-2"
                              style={{margin: 0,fontSize: '1.125rem', fontWeight: 'bold'}}
                            >
                             {
                                (() => {
                                  const popularity = agregatePopularityByModule();
                                  const library = filterSystemStats?.library;

                                  const comments = library === "all" 
                                    ? popularity?.comments || 0 
                                    : popularity?.comments?.[library] || 0;

                                  const likes = library === "all" 
                                    ? popularity?.likes || 0 
                                    : popularity?.likes?.[library] || 0;

                                  const shares = library === "all" 
                                    ? popularity?.shares || 0 
                                    : popularity?.shares?.[library] || 0;

                                  const views = library === "all" 
                                    ? popularity?.views || 1 
                                    : popularity?.views?.[library] || 1; 

                                  const engagementRate = ((comments + likes + shares) / views) * 100;

                                  return `${engagementRate.toFixed(2)}%`; // Ensure fixed decimal points
                                })()
                              }

                            </p>
                            <small  class="color-gray">Engagement Rate</small>
                          </div>
                        </div> */}

                        {/* Popular Library */}
                        {/* <div className="col-md-4">
    <div
      className="card mt-4 text-center d-flex items-center justify-between"
      style={{
        padding: "20px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        width: "100%",
      }}
    >
      <p className="text-green-400 m-0">Question Bank</p>
      <small>Popular Library</small>
    </div>
  </div> */}

                        {/* Traffic Per Day */}
                        {/* <div className="col-md-4">
    <div
      className="card mt-4  text-center d-flex items-center justify-between"
      style={{
        padding: "20px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        width: "100%",
      }}
    >
      <p className="text-purple-400 m-0">3K</p>
      <small>Traffic Per Day</small>
    </div>
  </div> */}

                        {/* Popular Category */}
                        {/* <div className="col-md-4">
    <div
      className="card mt-4 text-center d-flex items-center justify-between"
      style={{
        padding: "20px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        width: "100%",
      }}
    >
      <p className="text-cyan-400 m-0">Engineering</p>
      <small>Popular Category</small>
    </div>
  </div> */}

                        {/* Earned Credits */}
                        {/* <div className="col-md-4">
                          <div
                            className="card mt-4 text-center d-flex items-center justify-between"
                            style={{
                              padding: "20px",
                              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                              width: "100%",
                            }}
                          >
                            <img
                              src={earncredit}
                              width={80}
                              style={{ opacity: 0.4, marginTop: 20 }}
                            />
                            <p
                              className="text-blue-400 mb-0 mt-4"
                              style={{ fontSize: 25 }}
                            >
                              500
                            </p>
                            <small>Credits Earned</small>
                          </div>
                        </div> */}

                        {/* Spent Credits */}
                        {/* <div className="col-md-4">
                          <div
                            className="card mt-4 text-center d-flex items-center justify-between"
                            style={{
                              padding: "20px",
                              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                              width: "100%",
                            }}
                          >
                            <img
                              src={spendcredit}
                              width={80}
                              style={{ opacity: 0.4, marginTop: 20 }}
                            />
                            <p
                              className="text-red-400 mb-0 mt-4"
                              style={{ fontSize: 25 }}
                            >
                              150
                            </p>
                            <small>Credits Spent</small>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4 d-none">
                    <div
                      className="card h-100"
                      style={{
                        padding: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                      }}
                    >
                      <div
                      className="d-md-flex h-section w-100"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* Platform Overview Text */}
                        <h2
                          style={{
                            margin: 0,
                            fontSize: "1.125rem",
                            color: "#3c4043",
                          }}
                        >
                          Best Learning Path In
                        </h2>

                        {/* Filter Dropdown */}
                        <div
                          style={{
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          className="w-100s"
                        >
                          <select style={{ fontSize: "1rem" }}>
                            {learnModule?.map((m, _i) => (
                              <option key={"m" + _i} value={m?.value}>
                                {m?.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <hr style={{ borderColor: "#f0f0f0" }} />

                      <div className="row mt-4">
                        <div className="col-md-12">
                          <p className="my-2 d-flex gap-1 w-100">
                            <strong> &gt;&gt; </strong>{" "}
                            <span>
                              <b>Algebra</b> is a trending topic with over 10K
                              learners this week. <a href="">Explore It</a>
                            </span>
                          </p>
                          <p className="my-2 d-flex gap-1 w-100">
                            <strong> &gt;&gt; </strong>{" "}
                            <span>
                              Boost your <b>General Knowledge</b> with 3K+
                              questions across 50+ topics.{" "}
                              <a href="">Explore It</a>
                            </span>
                          </p>
                          <p className="my-2 d-flex gap-1 w-100">
                            <strong> &gt;&gt; </strong>{" "}
                            <span>
                              Master the basics of <b>Programming</b>, already
                              explored by 5K+ beginners.{" "}
                              <a href="">Start Learning</a>
                            </span>
                          </p>
                          <p className="my-2 d-flex gap-1 w-100">
                            <strong> &gt;&gt; </strong>{" "}
                            <span>
                              Discover exciting facts in <b>Science</b>, loved
                              by 8K+ learners. <a href="">Dive In</a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 row mt-4">
          
            <div className="col-md-4 mb-md-0 mb-4">
              <div
                className="card flex flex-col"
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  minHeight:'100%'
                }}
              >
                <div
                  className="d-flex h-section no-m"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Platform Overview Text */}
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "1.125rem",
                      color: "#3c4043",
                    }}
                  >
                    My Posts
                  </h2>

                  {/* Filter Dropdown */}
                  <div
                    style={{
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px"
                      // filter: myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?"blur(5px)":'none',
                      // pointerEvents: myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?"none":'auto',
                      // userSelect: myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?"none":'auto',
                    }}
                    className={myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?'blur-c':''}
                  >
                    <select
                      className="filter-select"
                      value={selectMyPostLibrary}
                      onChange={(e) => {handleMyPosts('all');setSelectMyPostLibrary(e.target.value); setFilterMyPosts({...filterMyPosts, library: e.target.value})}}
                    >
                      {/* <option value="all">All</option> */}
                      {myPostModuleFilter?.map((mo, _io) => (
                        <option key={"mypost_" + _io} value={mo.value}>
                          {mo.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr style={{ borderColor: "#f0f0f0" }} />
                {
                  !isSectionLoading && myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?<div className="message-container">
                  <h5>No Posts Yet? Start Your Journey Here!</h5>
                  <p>
                    It looks like you haven't shared anything yet. Begin by
                    creating your first post and contribute to the community!
                  </p>
                  <div className="actions">
                    <button className="btn  btn--light offer-btn w-100 text-white" onClick={()=>navigation('/blogs/post-a-blog')}>
                      Create Your First Blog Post
                    </button>
                    <button className="mt-3 btn  btn--light justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn" onClick={()=>navigation('/forum/post-a-question')}>
                      Start a Discussion Post
                    </button>
                    {/* <a
                      href="/learn-more"
                      className="btn btn-outline theme-color mt-3 btn--light"
                    >
                      Learn How to Post Effectively
                    </a> */}
                  </div>
                </div>:null
                }
                
                <div
                  style={{
                    // marginBottom: "20px",
                    filter: myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?"blur(5px) grayscale(100%)":'none',
                    // pointerEvents: myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?"none":'auto',
                    // userSelect: myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?"none":'auto',
                  }}
                  className={`table-responsivse flex flex-col flex-1 ${myPostData && !myPostData?.blogs?.length && !myPostData?.forum?.length && !myPostData?.tutorial?.length?'blur-c':''}`}
                >
                  {
                    myPostData?.[selectMyPostLibrary]?.length && !isSectionLoading?<>
                    <table className="table  w-100 table-bordered rounded c--table-2 flex-1 d-block">
                    {/* <thead>
                      <tr>
                        <th>Name</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {
                        myPostData?.[selectMyPostLibrary]?.map((mp, _i)=>(
                        <tr className="rounded" key={'myPList_'+_i}>
                          <td className="rounded" onClick={()=>{selectMyPostLibrary=='blogs'?navigation(`/blogs/detail/${mp?.slug +'-'+ mp?.id}`):selectMyPostLibrary=='tutorial'?navigation(`/tutorials/detail/${mp?.slug +'-'+ mp?.id}`):navigation(`/forum/${mp?.cat?.slug}/${mp?.scat?.slug}/${mp?.slug +'-'+ mp?.id}`)
                          }}>
                            <p className="cursor-pointer theme-hover-color mb-0">
                              {stripHtmlTags(mp?.title || mp?.question)}
                            </p>
                            <p className="mb-0 text-gray-400 small mt-1">
                            <small className='text-black-50'>
                                {mp?.views || 0} Views
                            </small>
                            <span className="big-dots"></span>
                            <small className='text-black-50'>
                                {mp?.likes_count_count || mp?.alikes_count || mp?.likes_count_count || 0} Likes
                            </small>
                            <span className="big-dots"></span>
                            <small className='text-black-50'>
                                {mp?.comments_count || mp?.answer_count_count || 0} {selectMyPostLibrary=='forum'?'Answers':'Comments'}
                            </small>
                            <span className="big-dots"></span>
                            <small className='text-black-50'>
                                {mp?.share_count || 0} Shares
                            </small>
                            </p>
                          </td>
                        </tr>))
                      }
                      
                    </tbody>
                  </table> <hr style={{ borderColor: "#f0f0f0" }} />
                  <Link to={selectMyPostLibrary=='blogs'?`/blogs/my-blogs`:selectMyPostLibrary=='tutorial'?'/tutorials/my-tutorials':'/forum/my-forum'} className="theme-color">
                    View More
                  </Link></>:isSectionLoading?<div className="w-10 m-auto h-100 d-flex justify-center theme-color items-center"><Loadingicon/></div>:<NotFound message={`No Data Found!`}/>
                  }
                  
                 
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div
                className="card h-100"
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                }}
              >
                <div
                  className="d-md-flex h-section"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Platform Overview Text */}
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "1.125rem",
                      color: "#3c4043",
                    }}
                  >
                    Earning Stats & Opportunities
                  </h2>

                  {/* Filter Dropdown */}
                  <div
                    className="d-md-flex"
                    style={{
                      borderRadius: "5px",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {/* <select className="filter-select">
                      <option value="yesterday">Learn</option>
                      <option value="past-7-days">Work</option>
                      <option value="past-30-days">Earn</option>
                      <option value="past-60-days">Community</option>
                    </select> */}
                    <select
                      className="filter-select mb-md-0 mb-2  w-mob-100"
                      value={selectedCreditsLibrary}
                      onChange={handleCreditsLibraryChange}
                    >
                      <option value="tuteehub">All</option>
                      <option value="questionbank">Question Bank</option>
                      <option value="interviewquestions">Interview Questions</option>
                      <option value="fullforms">FullForms</option>
                      <option value="dictionary">Dictionary</option>
                      <option value="exams">Exams</option>
                      
                      <option value="careernews">Career News</option>
                      <option value="gigs">Gigs</option>

                      <option value="feeds">Feeds</option>

                      <option value="quotes">Quotes</option>
                      <option value="lyrics">Lyrics</option>
                      <option value="showtalent">Videos</option>

                      <option value="blogs">Blogs</option>
                      <option value="forum">Forum</option>
                      <option value="tutorials">Tutorials</option>

                      <option value="educators">Educators</option>
                      <option value="corporates">Corporates</option>

                      <option value="courses">Courses</option>
                      <option value="work">Work</option>


                    </select>
                    <select
                      className="filter-select  w-mob-100"
                      value={filterCreditStats?.this_month}
                      onChange={(e) => {setFilterCreditStats({...filterCreditStats, this_month: e.target.value}); handleCreditInfo(selectedCreditsLibrary, e.target.value); }}
                    >
                      {
                        dateFilter?.map((df, _i)=>(<option key={'dfCredits'+_i} value={df?.value}>{df?.label}</option>))
                      }                      
                    </select>
                  </div>
                </div>
                <hr style={{ borderColor: "#f0f0f0" }} />

                <div className="row credit-row">
                {
                    selectedCreditsLibrary=='tuteehub'?<div className="col">
                    <div
                      className="card mt-4 text-center d-flex items-center justify-between cursor-pointer"
                      style={{
                        padding: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                      }}
                      onClick={()=>navigation('/credit-history')}
                    >
                      <img src={totalCredits} alt="earncredits" width={80} className="mb-3" style={{opacity:0.5}}/>
                      <h3 className="text-orange-400" title={creditStatsData?.totalCredits}>{nFormatter(creditStatsData?.totalCredits || 0)}</h3>
                      <label>Total Credits</label>
                      <small className="color-gray mt-2" style={{fontSize:10}}>All Library Credits</small>
                    </div>
                  </div>:null
}
                  
                  <div className="col">
                    <div
                      className="card mt-4 text-center d-flex items-center justify-between"
                      style={{
                        padding: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                      }}
                    >
                      <img src={earncredit} alt="earncredits" width={80} className="mb-3" style={{opacity:0.5}}/>
                      <h3 className="text-green-400" title={creditStatsData?.earned_sum}>{nFormatter(creditStatsData?.earned_sum || 0)}</h3>
                      <label>Earned Credits</label>
                      <small className="color-gray mt-2" style={{fontSize:10}}>based on filter</small>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="card mt-4 text-center d-flex items-center justify-between"
                      style={{
                        padding: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                      }}
                    >
                      <img src={spendcredit} alt="earncredits" width={80} className="mb-3" style={{opacity:0.5}}/>
                      <h3 className="text-purple-400" title={creditStatsData?.spent_sum}>{nFormatter(creditStatsData?.spent_sum || 0)}</h3>

                      <label>Spent Credits</label>
                      <small className="color-gray mt-2" style={{fontSize:10}}>based on filter</small>
                    </div>
                  </div>
                  {
                    selectedCreditsLibrary=='tuteehub'?<div className="col">
                    <div
                      className="card mt-4 text-center d-flex items-center justify-between cursor-pointer"
                      style={{
                        padding: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        width: "100%",
                      }}
                      onClick={()=>navigation('/withdrawal')}
                    >
                      <img src={cashwithdrawal} alt="earncredits" width={80} className="mb-3" style={{opacity:0.5}}/>
                      <h3 className="text-cyan-400" title={creditStatsData?.withdrawal}>INR {nFormatter(creditStatsData?.withdrawal || 0)}</h3>

                      <label>Withdrawals</label>
                      <small className="color-gray mt-2" style={{fontSize:10}}>All Library Withdrawals</small>
                    </div>
                  </div>:null
                  }
                  
                </div>

                {/* // <FinanceReportChart selectedOptions={selectedOptions} /> */}

                <hr style={{ borderColor: "#f0f0f0" }} />
                <div className="row">
                  <div className="col-md-12">
                    {
                      selectedCreditsLibrary=='questionbank'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Engage with the Question Bank to Earn</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/question-Bank')}>
                              Explore Questions
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Participate in a Quiz Competition & Earn Rewards</td>
                          <td>
                            <a target="_blank" className="btn btn-primary btn-sm btn-action" href={'https://questionbank.tuteehub.com/mcqs/clarkes-model-assumes-a-horizontal-polarized-antenna-4111658'}>
                              Take a Quiz
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>: selectedCreditsLibrary=='interviewquestions'?<table
                      className="table w-100 table-bordered rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Prepare youself for Interview</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/interview-questions')}>
                              Start Preparation
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Participate in a Quiz Competition & Earn Rewards</td>
                          <td>
                            <a className="btn btn-primary btn-sm btn-action" target="_blank" href={'https://interviewquestions.tuteehub.com/qna/which-of-the-following-isare-correct-inorder-traversal-7276'}>
                              Take a Quiz
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='fullforms'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Learn full forms of common abbreviations, expand your knowledge, and earn credits on engagement.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/fullforms')}>
                              Discover FullForms
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Participate in a Quiz Competition & Earn Rewards</td>
                          <td>
                            <a className="btn btn-primary btn-sm btn-action" target="_blank" href={'https://fullforms.tuteehub.com/abbreviation/how-many-natural-numbers-are-there-between-23-5847'}>
                              Take a Quiz
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='dictionary'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Enhance your vocabulary with our comprehensive dictionary and Earn on Engagement</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/dictionary')}>
                              Read Dictionary
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Participate in a Quiz Competition & Earn Rewards</td>
                          <td>
                            <a className="btn btn-primary btn-sm btn-action" target="_blank" href={'https://dictionary.tuteehub.com/meaning/most-experts-feel-that-in-spite-of-possessing-7395'}>
                              Take a Quiz
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='exams'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Prepare for exams with curated resources, engage with content, and earn credits.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/exams')}>
                              Give Exams
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='careernews'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Stay updated with career news, share insights, and earn credits for keeping others informed.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/career-news')}>
                              Explore Careernews
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='gigs'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Find short-term gigs, work flexibly, and earn real rewards for your efforts</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/gigs/tasks')}>
                              Start Task
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Post your campaigns or tasks, connect with skilled individuals, and achieve your goals while earning rewards.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/gigs/create-campaign')}>
                              Advertise Campaign
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='work'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Discover freelance projects, showcase your skills, and earn by completing tasks.</td>
                          <td>
                            <a target="_blank" className="btn btn-primary btn-sm btn-action" href="https://work.tuteehub.com/project">
                            Find Projects
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Access platform-provided services to simplify your work and achieve more.</td>
                          <td>
                          <a target="_blank" className="btn btn-primary btn-sm btn-action" href="https://work.tuteehub.com/service">
                            Explore Services
                            </a>
                          </td>
                        </tr>
                        
                        <tr>
                          <td>Purchase utility software to streamline your workflow and boost productivity.</td>
                          <td>
                          <a target="_blank" className="btn btn-primary btn-sm btn-action" href="https://work.tuteehub.com/software">
                            Buy Software
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='quotes'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Discover inspiring quotes, share your own, and earn credits by engaging with the community.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/quotes')}>
                            Share Quotes
                            </button>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>:selectedCreditsLibrary=='lyrics'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Explore song lyrics, share your favorites, and earn credits by connecting with music lovers.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/lyrics')}>
                            Discover Lyrics
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='showtalent'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                       <tr>
                          <td>Showcase your creativity with videos, engage with others, and earn credits for participation.</td>
                          <td>
                            <a target="_blank" className="btn btn-primary btn-sm btn-action" href="https://showtalent.tuteehub.com/videos">
                              Share Videos
                            </a>
                          </td>
                        </tr>
                       
                      </tbody>
                    </table>:selectedCreditsLibrary=='blogs'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Read insightful blogs, engage with content, and earn credits for meaningful participation.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/blogs')}>
                            Explore Blogs
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Share your thoughts and ideas by posting blogs to connect with the community and earn</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/blogs/post-a-blog')}>
                            Post a Blog
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='tutorials'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Master new topics by engaging with step-by-step tutorials and earn credits.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/tutorials')}>
                              View Tutorials
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Create and share your own tutorials to educate others and build your reputation and earn.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/tutorials/post-a-tutorial')}>
                              Post a Tutorial
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>:selectedCreditsLibrary=='forum'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Join ongoing discussions, share your insights, and connect with like-minded individuals.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/forum')}>
                            Join a Discussion
                            </button>
                          </td>
                        </tr> 
                        <tr>
                          <td>Start a discussion or ask a question to engage with the community and share knowledge.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/forum/post-a-question')}>
                            Post a Discussion
                            </button>
                          </td>
                        </tr> 
                      </tbody>
                    </table>:selectedCreditsLibrary=='courses'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Explore expertly crafted courses, learn new skills, and earn credits as you engage.</td>
                          <td>
                            <a target="_blank" className="btn btn-primary btn-sm btn-action" href="https://courses.tuteehub.com/topics">
                            Enroll Now
                            </a>
                          </td>
                        </tr> 
                      </tbody>
                    </table>:selectedCreditsLibrary=='educators'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Connect with top educators, gain valuable insights, and earn credits while enhancing your learning journey.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/educators')}>
                            Find Educators
                            </button>
                          </td>
                        </tr> 
                      </tbody>
                    </table>:selectedCreditsLibrary=='corporates'?<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Engage with corporate opportunities, explore resources, and earn credits for meaningful participation.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/corporates')}>
                            Explore Corporates
                            </button>
                          </td>
                        </tr> 
                      </tbody>
                    </table>:<table
                      className="  table table-bordered w-100 rounded c--table c2"
                      cellSpacing={10}
                    >
                      <tbody>
                        <tr>
                          <td>Participate in a Quiz Competition & Earn Rewards</td>
                          <td>
                            <a target="_blank" className="btn btn-primary btn-sm btn-action" href={'https://questionbank.tuteehub.com/mcqs/clarkes-model-assumes-a-horizontal-polarized-antenna-4111658'}>
                              Take a Quiz
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Explore expertly crafted courses, learn new skills, and earn credits as you engage.</td>
                          <td>
                            <a target="_blank" className="btn btn-primary btn-sm btn-action" href="https://courses.tuteehub.com/topics">
                            Enroll Now
                            </a>
                          </td>
                        </tr> 
                        <tr>
                          <td>Start a discussion or ask a question to engage with the community and share knowledge.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/forum/post-a-question')}>
                            Post a Question
                            </button>
                          </td>
                        </tr> 
                        <tr>
                          <td>Create and share your own tutorials to educate others and build your reputation and earn.</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/tutorials/post-a-tutorial')}>
                              Post a Tutorial
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>Share your thoughts and ideas by posting blogs to connect with the community and earn</td>
                          <td>
                            <button className="btn btn-primary btn-sm btn-action" onClick={()=>navigation('/blogs/post-a-blog')}>
                            Post a Blog
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 row mt-4">
            
            <div className="col-md-4">
              <div
                className={`card mh-480`}
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height:'100%'
                }}

              >
                <div
                  className="d-md-flex h-section no-m"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Platform Overview Text */}
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "1.125rem",
                      color: "#3c4043",
                    }}
                  >
                    Recent Visits
                  </h2>

                  {/* Filter Dropdown */}
                  <div
                    style={{
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      opacity:0,
                      height:0
                    }}
                    className={!myRecentVisitData?.length?'blur-c':''}
                  >
                    <select
                      className="filter-select"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <option value="all">All</option>
                      {moduleOptions?.map((mo, _io) => (
                        <option key={"recentactivityMo" + _io} value={mo.value}>
                          {mo.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr style={{ borderColor: "#f0f0f0" }} />
                {
                  !myRecentVisitData?.length && <div className="message-container">
                  <h5>No Recent Visits Yet? Start Exploring!</h5>
                  <p>
                    Your recent visits will appear here as you start navigating
                    through tutorials, blogs, and other sections of the
                    platform.
                  </p>
                  <div className="actions">
                    <button className="btn  btn--light offer-btn w-100 text-white" onClick={()=>navigation('/tutorials')}>
                      Explore Tutorials
                    </button>
                    <button className="mt-3 btn  btn--light justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn" onClick={()=>navigation('/blogs')}>
                      Visit the Blog Section
                    </button>
                    <a
                      href="https://www.tuteehub.com" target="_blank"
                      className="btn btn-outline theme-color mt-3 btn--light "
                    >
                      Learn How to Use the Platform
                    </a>
                  </div>
                </div>
                }
                
                <div
                  style={{
                    marginBottom: "20px",
                    // filter: "blur(3px) grayscale(100%)",
                    // pointerEvents: "none",
                    // userSelect: "none",
                  }}
                  className={!myRecentVisitData?.length?'blur-c table-responsive':'table-responsive'}
                >
                  {
                    myRecentVisitData?.length && !isSectionLoadingRecentVisit?<><table className="  table table-bordered w-100 rounded c--table c--table-2">
                    
                    <tbody>
                    {
                        myRecentVisitData?.map((rv, _i)=>(
                        <tr className="rounded" key={'myPList_'+_i}>
                          <td className="rounded">
                            <Link to={rv?.recent_visits}>
                            <p className="cursor-pointer theme-hover-color mb-0 break-all">
                              {convertSlugToTitle(stripHtmlTags(rv?.recent_visits))}
                            </p>
                            <p className="mb-0 text-gray-400 small mt-1">
                            <small className='text-black-50'>
                               Last Visited :  {formatDate(rv?.created_at)} 
                            </small>
                            </p>
                            </Link>
                          </td>
                        </tr>))
                      }
                    </tbody>
                  </table>
                 </>:isSectionLoadingRecentVisit?<div className="w-10 m-auto h-100 d-flex justify-center theme-color items-center"><Loadingicon/></div>:<NotFound message={`No Data Found!`}/>
                  }
                  
                </div>
              </div>
            </div>
            <div className="col-md-4 my-4 m-md-0">
              <div
                className="card"
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  minHeight:'100%'
                }}
              >
                <div
                  className="d-md-flex h-section"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Platform Overview Text */}
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "1.125rem",
                      color: "#3c4043",
                    }}
                  >
                    Recent Activities
                  </h2>

                  {/* Filter Dropdown */}
                  <div
                    style={{
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      height:'0px'
                      // filter: "blur(5px)",
                      // pointerEvents: "none",
                      // userSelect: "none",
                    }}
                    className={myRecentActivity && !myRecentActivity?.length?'blur-c':''}
                  >
                    {/* <CustomDropDown setSelectedModules={setSelectedModules} selectedModules={selectedModules}/> */}
                    <select
                      className="filter-select opacity-0 blur-c"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <option value="all">All</option>
                      {moduleOptions?.map((mo, _io) => (
                        <option key={"recentactivityMo" + _io} value={mo.value}>
                          {mo.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr style={{ borderColor: "#f0f0f0" }} />
                {
                  !isSectionLoadingRecentActivity && myRecentActivity && !myRecentActivity?.length?
                <div className="message-container">
                  <h5>Get Started to See Your Activity!</h5>
                  <p>
                    Your recent activity will show up here once you start
                    exploring, engaging, and contributing to the community.
                  </p>
                  <div className="actions">
                    <button className="btn  btn--light offer-btn w-100 text-white" onClick={()=>navigation('/blogs/post-a-blog')}>
                      Create Your First Blog Post
                    </button>
                    <button className="mt-3 btn  btn--light justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn" onClick={()=>navigation('/forum')}>
                      Join Your First Discussion
                    </button>
                    <a
                      href="javascript:void(0)" onClick={()=>navigation('/exams')} 
                      className="btn btn-outline theme-color mt-3 btn--light "
                    >
                      Take Your First Exam
                    </a>
                  </div>
                </div>:null
                }
                <div
                  style={{
                    // marginBottom: "20px",
                    // filter: "blur(3px) grayscale(100%)",
                    // pointerEvents: "none",
                    // userSelect: "none",
                  }}
                  className={myRecentActivity && !myRecentActivity?.length?'blur-c table-responsive flex flex-1 flex-col':'table-responsive  flex flex-1 flex-col'}
                >
                  {
                    myRecentActivity?.length && !isSectionLoadingRecentActivity?<>
                    <table className="  table table-bordered w-100 rounded c--table-2 flex-1 d-inline-table w-100">
                    <tbody>
                      {
                        myRecentActivity?.slice(0, 4)?.map((activityLog, _i)=>(
                        <tr className="rounded" key={'myRA_'+_i}>
                          <td className="rounded py-1" onClick={()=>{alert(1)}}>
                            <p className="cursor-pointer theme-hover-color mb-0">
                              {activityLog?.content_title && activityLog?.content_url?<a className="theme-hover-color" href={activityLog?.content_url} target="_blank">{activityLog?.content_title}</a>:!activityLog?.content_title && activityLog?.content_url?<a  className="theme-hover-color" href={activityLog?.content_url} target="_blank">{convertSlugToTitle(activityLog?.content_url)}</a>:'NA'}
                            </p>
                            <p className="mb-0 text-gray-400 small mt-1">
                            <small className='text-black-50'>
                                {activityLog?.module}
                            </small>
                            <span className="big-dots"></span>
                            <small className='text-black-50'>
                                {formatDate(activityLog?.created_at)}
                            </small>
                            </p>
                            <div className="mt-1 mb-1" style={{borderTop: '1px dashed #f0f0f0'}}> 
                                <p className="card-text m-0 pt-1 capitalize">{activityLog?.action}</p>
                            </div>
                          </td>
                          {/* <td className="rounded" onClick={()=>{alert(1)}}>
                            <p className="cursor-pointer theme-hover-color mb-0">Liked A Forum Post</p>
                          </td> */}
                        </tr>))
                      }
                      
                    </tbody>
                  </table> <hr style={{ borderColor: "#f0f0f0" }} />
                  <Link to={'/my-profile/recent-activity'} className="theme-color">
                    More Activities
                  </Link></>:isSectionLoadingRecentActivity?<div className="w-10 m-auto h-100 d-flex justify-center theme-color items-center"><Loadingicon/></div>:<NotFound message={`No Data Found!`}/>
                  }
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="card h-100"
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  minHeight:'100%'
                }}
              >
                <div
                  className="d-md-flex h-section"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Platform Overview Text */}
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "1.125rem",
                      color: "#3c4043",
                      padding: '0.3rem 0'
                    }}
                  >
                    Recent Transactions
                  </h2>

                  {/* Filter Dropdown */}
                </div>

                <hr style={{ borderColor: "#f0f0f0" }} />
                {
                  !isSectionLoadingTransactionHitory && recentTransactionData && !recentTransactionData.length?
                <div className="message-container">
                  <h5>No Recent Transactions Yet!</h5>
                  <p>
                    Start earning credits by contributing and engaging with the
                    platform. Check out how you can earn credits and track your
                    progress here.
                  </p>
                  <div className="actions">
                    <a target="_blank" href="https://www.tuteehub.com/earn-credits" className="mt-3 btn--light  btn justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">
                      Watch Tutorial: How to Earn Credits
                    </a>
                    <a
                      href="https://www.tuteehub.com/earn-credits" target="_blank"
                      className="btn btn-outline theme-color mt-3 btn--light"
                    >
                      Learn More
                    </a>
                  </div>
                </div>:null
    }
                {/* <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    filter: "blur(3px)",
                    pointerEvents: "none",
                    userSelect: "none",
                  }}
                >
                  <select
                    className="filter-select"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="all">All</option>
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>
                  </select>
                  <select
                    className="filter-select"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (
                    <>
                      <div>
                        <DateRangePicker
                          format="MM/dd/yyyy"
                          character=" – "
                          onChange={(e) => {
                            setCustomRange({
                              start: e[0].toLocaleDateString(),
                              end: e[1].toLocaleDateString(),
                            });
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", height: 35.2 }}>
                        <button
                          className="btn btn-primary btn-sm me-1"
                          style={{
                            padding: "8px 12px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={handleCustomRangeSubmit}
                        >
                          Apply
                        </button>
                      </div>
                    </>
                  )}
                </div> */}
                
                  
                  {recentTransactionData?.length && !isSectionLoadingTransactionHitory ?<><div
                  style={{
                    marginBottom: "20px",
                    // filter: "blur(5px)",
                    // pointerEvents: "none",
                    // userSelect: "none",
                  }}
                  className={`table-responsive flex-1 flex flex-col ${!recentTransactionData?.length?'blur-c':''}`}
                >{ recentTransactionData?.slice(0, 5).map((transaction, index) => (<>
                    <div className="transaction-list flex justify-between items-center  my-3">
                      <div className="transaction-left flex items-center">
                        <div className="thumb-trans me-2">
                          <i className="fa fa-credit-card"></i>
                        </div>
                        <div className="flex flex-col">
                          <p className="m-0">{transaction?.description}</p>
                          <p className="mb-0 text-gray-400 small mt-1">
                            <small className='text-black-50'>
                                {transaction?.transaction_id}
                            </small>
                            <span className="big-dots"></span>
                            <small className='text-black-50'>
                                {formatDate(transaction?.created_at)}
                            </small>
                            </p>
                        </div>
                      </div>
                      <div className="transaction-right">
                        <span
                          className={
                            transaction?.type == "debit ms-3"
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {transaction?.type == "debit"
                            ? "+" + transaction.amount
                            : "-" + transaction.amount}
                        </span>
                      </div>
                    </div>
                   </>
                  ))} </div><hr style={{ borderColor: "#f0f0f0" }} />
                  <Link to={'/transaction-history'} className="theme-color">
                    More Transactions
                  </Link></>
                  :isSectionLoadingTransactionHitory?<div className="w-10 m-auto h-100 d-flex justify-center theme-color items-center"><Loadingicon/></div>:<span className={`${!recentTransactionData?.length?'blur-c':''}`}><NotFound message={`No Data Found!`}/></span>
                }
                
              </div>
            </div>
          </div>

          
          <div className="mt-4 row">
            
            {/* <div className="col-md-8">
              <div
                className="card h-100"
                style={{
                  padding: "20px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Platform Overview Text 
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "1.125rem",
                      color: "#3c4043",
                    }}
                  >
                    My Credits & Transactions Stats
                  </h2>

                </div>
                <hr style={{ borderColor: "#f0f0f0" }} />
                <div className="message-container">
                  <h5>No Credit Activity Yet!</h5>
                  <p>
                    Kickstart your journey by earning credits through
                    contributions or purchasing credits to unlock more features.
                  </p>
                  <div className="actions">
                    <button className="btn btn--light  offer-btn w-100 text-white">
                      Purchase Credits
                    </button>
                    <button className="mt-3 btn btn--light  justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">
                      Watch Video: How to Earn Credits
                    </button>
                    <a
                      href="/learn-more"
                      className="btn btn-outline theme-color mt-3 btn--light "
                    >
                      Learn More About Credit
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    filter: "blur(3px)",
                    pointerEvents: "none",
                    userSelect: "none",
                  }}
                >
                  <select
                    className="filter-select"
                    value={selectedOptions}
                    onChange={(e) => setSelectedOptions(e.target.value)}
                  >
                    <option value="cevscs">
                      Credits Earned Vs Credits Spent
                    </option>
                    <option value="cpvsw">
                      Credits Purchased Vs Withdrawals
                    </option>
                  </select>
                  <select className="filter-select">
                    <option value="yesterday">Learn</option>
                    <option value="past-7-days">Work</option>
                    <option value="past-30-days">Earn</option>
                    <option value="past-60-days">Community</option>
                  </select>
                  <select
                    className="filter-select"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>
                  </select>
                  <select
                    className="filter-select"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Weekly</option>
                    <option value="yesterday">Monthly</option>
                    <option value="this_month">Yearly</option>
                    <option value="last_month">All Time</option>
                  </select>
                </div>
                <hr style={{ borderColor: "#f0f0f0" }} />
                {selectedOptions && isChartLoaded && (
                  <Chart
                    options={creditCamparisonsOptions}
                    series={
                      selectedOptions == "cevscs"
                        ? earnCreditVsSpentCredits
                        : creditPurchasedVsWithdrawals
                    }
                    type="area"
                    height={200}
                    width={"100%"}
                    style={{
                      filter: "blur(5px) grayscale(100%)",
                      pointerEvents: "none",
                      userSelect: "none",
                    }}
                  />
                )}
                <hr style={{ borderColor: "#f0f0f0" }} />
                {selectedOptions == "cevscs" ? (
                  <>
                    {" "}
                    <div
                      className="d-flex items-center justify-between mb-3"
                      style={{
                        filter: "blur(5px)",
                        pointerEvents: "none",
                        userSelect: "none",
                      }}
                    >
                      <div className="flex flex-col">
                        <p className="m-0">Earned Credits</p>
                        <small className="color-gray">
                          <strong className="text-green-500 me-1">
                            <i className="fa fa-long-arrow-up me-1"></i>7%
                          </strong>
                          from last week
                        </small>
                      </div>
                      <strong className="text-green-500">1.5K</strong>
                    </div>
                    <div
                      className="d-flex items-center justify-between mb-3"
                      style={{
                        filter: "blur(5px)",
                        pointerEvents: "none",
                        userSelect: "none",
                      }}
                    >
                      <div className="flex flex-col">
                        <p className="m-0">Spend Credits</p>
                        <small className="color-gray">
                          <strong className="text-red-500 me-1">
                            <i className="fa fa-long-arrow-down me-1"></i>1%
                          </strong>
                          from last week
                        </small>
                      </div>
                      <strong className="text-red-500">-2,000</strong>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex items-center justify-between mb-3"
                      style={{
                        filter: "blur(5px)",
                        pointerEvents: "none",
                        userSelect: "none",
                      }}
                    >
                      <div className="flex flex-col">
                        <p className="m-0">Purchased Credits</p>
                        <small className="color-gray">
                          <strong className="text-green-500 me-1">
                            <i className="fa fa-long-arrow-up me-1"></i>0.75%
                          </strong>
                          from last week
                        </small>
                      </div>
                      <strong className="theme-color">
                        INR 1,000 (500 Credits)
                      </strong>
                    </div>
                    <div
                      className="d-flex items-center justify-between mb-3"
                      style={{
                        filter: "blur(5px)",
                        pointerEvents: "none",
                        userSelect: "none",
                      }}
                    >
                      <div className="flex flex-col">
                        <p className="m-0">Withdrawals</p>
                        <small className="color-gray">
                          <strong className="text-green-500 me-1">
                            <i className="fa fa-long-arrow-up me-1"></i>20%
                          </strong>
                          from last week
                        </small>
                      </div>
                      <strong className="theme-color">50K</strong>
                    </div>
                  </>
                )}
              </div>
            </div> */}
          </div>
        </>
      )}
    </>
  );
}

export default FinalDashboard;
