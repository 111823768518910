import React from 'react';
import { CDN_BASE_URL, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import forumThumpPlaceholder from '../../../../assets/images/forum_thumb.png';
import { Link, useNavigate } from 'react-router-dom';

export default function ForumListItem({ forumData, handleDeleteModal=null,  redirectionFn, isUser=false, isEdit=false }) {
    const navigate = useNavigate();
    const thumbnail = forumThumpPlaceholder;
    const subtitle = forumData?.metaDescription;

    
    return (
        <div
            className="d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer"
            onClick={(e)=>redirectionFn(e)}
            style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}
        >
            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto">
                <div className="thumbnail-containers mr-3">
                    {
                        forumData?.image? <img
                        src={forumData?.image}
                        width="60"
                        height="60"
                        className="rounded bg-gray border"
                        alt="Thumbnail"
                    />:<span style={{width:60, height:60, background:'#ffe8d6',color:'rgb(255 156 78)', fontWeight:'bold', fontSize:'1.2rem'}} className='rounded bolder d-flex align-items-center justify-content-center' >{stripTags(forumData?.question)?.slice(0,3)}</span>
                    }
                   
                    
                </div>

                <div className="flex-1">
                    <h6 className="mb-0 d-md-flex justify-content-between d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5">
                            <strong dangerouslySetInnerHTML={{__html:`${forumData?.question}`}}></strong>
                        </span>                      
                    </h6>

                    <div className="d-md-flex align-items-center mt-1">
                    <small>
                        <a onClick={(e)=>{e.preventDefault();e.stopPropagation();navigate(`/forum/${forumData?.cat?.slug}`);}} className="theme-a-light cursor-pointer">
                            {forumData.cat?.name}
                        </a>
                    </small>
                    <span className="big-dots"></span>
                    <small className='text-black-50'>
                        {forumData?.views || 0} Views
                    </small>
                    <span className="big-dots"></span>
                    <small className='text-black-50'>
                        {forumData?.answerCount || 0} Answers
                    </small>
                    <span className="big-dots"></span>
                    <small className='text-black-50'>
                        {forumData?.likeCounts || 0} Likes
                    </small>
                    <span className="big-dots"></span>
                    <small className='text-black-50'>
                        {forumData?.share_count || 0} Shares
                    </small>
                    </div>
                    { isUser?
                    <div className='d-flex justify-content-between align-items-center w-100'>
                    <div className="d-flex  mt-2 w-100 gap-2 align-items-center mw-200">
                        {/* <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/forum/user/`)}} className=" hover-theme text-black-50"> */}
                                <div className="user-pic">
                                    {
                                        forumData?.user?.image && forumData?.user?.image !== '' ? 
                                        <img src={`${CDN_BASE_URL}showtalent/images/forum/user/thumbs/${forumData?.user?.image}`} width={25} height={25} style={{borderRadius:100}}/>
                                            :
                                            <img src={require('../../../../assets/images/placeholder_big.png')} width={25} height={25} />
                                    }
                                </div>
                            {/* </Link> */}
                            <div className='flex-1'>
                                <small className="m-0">
                                    {/* <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/forum/user/`);}} className=" hover-theme text-black-50"> */}
                                        <em className='text-black-50'>by </em>
                                        <strong className="text-capitalize text-black-50">{forumData?.user?.first_name}{forumData?.user?.last_name?' ' + forumData?.user?.last_name:null}</strong>
                                    {/* </Link> */}
                                </small>
                                {/* </a> */}
                            </div>
                    </div>
                    <small style={{minWidth:150, textAlign:'right'}}><em className='text-black-50'>{forumData?.status==1?'Discussion Active':'Discussion Off'}</em></small>
                    </div>:null
                    }
                    {/* <p className="mb-0 mt-1 color-gray fsize-meta desc-camp">{subtitle}</p> */}
                    
                </div>
                {
                        isEdit?<div className='edit-sec'><button className='btn bg-none text-theme-color' onClick={(e)=>{e.preventDefault();e.stopPropagation();handleDeleteModal(forumData)}} title='Delete' type='button'><i className='fas fa-trash'></i></button><button className='btn bg-none text-theme-color' onClick={(e)=>{localStorage.setItem('f__e', JSON.stringify(forumData));e.preventDefault();e.stopPropagation();navigate('/forum/my-forum/edit-question')}} title='Edit' type='button'><i className='fas fa-pencil'></i></button></div>:null
                       } 
            </div>
        </div>
    );
}
