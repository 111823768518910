import React, { useEffect, useRef, useState } from 'react';
import BreadCrumbs from '../../common/BreadCrumbs';
import { toast } from "react-toastify";
import notFound from '../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../AppIcons';
import ViewAllButton from '../../common/viewallbutton';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../Context/AuthContext';
import Modal from "react-bootstrap/Modal";
import SectionDescription from '../../common/sectionDescription';
import { bookMarkNews, favouriteNews, getBookmarkedNews, getFavouritesNews } from '../../../api/careernews';
import { ERROR_SOMETHING_WENT_WRONG, moduleNames, stripHtmlTags } from '../../../utils/utils';
import { bookMarkLyrics, getFavouritesLyrics } from '../../../api/lyrics';
import { bookMarkQuotes, getFavouritesQuotes } from '../../../api/quotes';
import { bookMarkBlog, getFavouritesBlogs } from '../../../api/blog';
import { bookMarkCorporate, getFavouritesCorporates } from '../../../api/corporate';
import { bookMarkEducator, getFavouritesEducators } from '../../../api/educator';
import { bookMarkTutorial, getFavouritesTutorials } from '../../../api/tutorial';
import FavouriteQuestions from '../questionBank/FavouriteQuestions';
import { getFavQuestions, postFavouriteQuestion } from '../../../api/OurService';
import { getFavouriteExams } from '../../../api/exam';
function MyFavourites() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [tabLoading, setTabLoading] = useState(true);
    const [filterType, setFilterType] = useState('questionbank');
    const navigation = useNavigate();

    const params = useParams();

    const tabItems = [{
        name: 'Question Bank',
        value: 'questionbank'
    }, {
        name: 'Interview Questions',
        value: 'interviewquestions'
    }, {
        name: 'Full Forms',
        value: 'fullforms'
    }, {
        name: 'Dictionary',
        value: 'dictionary'
    }, {
        name: 'Career News',
        value: 'careernews'
    },{
        name: 'Exams',
        value: 'exams'
    }, {
        name: 'Lyrics',
        value: 'lyrics'
    }, {
        name: 'Quotes',
        value: 'quotes'
    }, {
        name: 'Blogs',
        value: 'blogs'
    }, {
        name: 'Tutorials',
        value: 'tutorials'
    }, {
        name: 'Corporates',
        value: 'corporates'
    }, {
        name: 'Educators',
        value: 'educators'
    },
    ]


    useEffect(() => {
        handleFavouritesList('questionbank', 1);
    }, []);

    const handleFavouritesList = async (fType, pageNo) => {
        const moduleMap = {
            questionbank: moduleNames?.qb,
            interviewquestions: moduleNames?.iq,
            fullforms: moduleNames?.ff,
            dictionary: moduleNames?.dic,
        };

        const favouritesMap = {
            tutorials: getFavouritesTutorials,
            educators: getFavouritesEducators,
            corporates: getFavouritesCorporates,
            blogs: getFavouritesBlogs,
            quotes: getFavouritesQuotes,
            careernews: getFavouritesNews,
            lyrics: getFavouritesLyrics,
            questionbank: getFavQuestions,
            interviewquestions: getFavQuestions,
            fullforms: getFavQuestions,
            dictionary: getFavQuestions,
            exams: getFavouriteExams
        };

        const getFavouritesFunction = favouritesMap[fType] || getFavouritesNews;
        const module = moduleMap[fType] || null;

        const resAllMyFavourites = fType === 'questionbank' || fType === 'interviewquestions' || fType === 'fullforms' || fType === 'dictionary'
            ? fType === 'exams'? await getFavouritesFunction({ token: globalToken, pageNo}) :await getFavouritesFunction(globalToken, module, pageNo)
            : await getFavouritesFunction({ pageNo, token: globalToken });
        console.log('exams', resAllMyFavourites);

        if (resAllMyFavourites?.success || resAllMyFavourites?.data?.success) {
            let nData = resAllMyFavourites?.data?.data || resAllMyFavourites?.data;
            setIsNotFound(false);
            setShowLoadMore(!!(resAllMyFavourites?.data?.next_page_url || resAllMyFavourites?.data?.data?.next_page_url));

            if (nData?.length) {
                pageNo === 1 ? setAllContent(nData) : setAllContent(prev => [...prev, ...nData]);
            } else if (pageNo === 1) {
                setAllContent([]);
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }

        setLoading(false);
        setBtnLoading(false);
        setTabLoading(false);
        setLoadingLoadMore(false);
    };


    const loadMore = () => {
        setLoadingLoadMore(true);
        handleFavouritesList(filterType, pageNo + 1);
        setPageNo(prevPageNo => prevPageNo + 1);
    };

    const handleFilterChange = (v) => {
        setPageNo(1);
        setTabLoading(true);
        setFilterType(v);
        setAllContent([]);
        handleFavouritesList(v, 1);
    };


   


    const FavouriteButton = ({ id, mType, type }) => {
        const [favBtnLoading, setFavBtnLoading] = useState(false);

        const removeFromFavourites = async () => {
            setFavBtnLoading(true);
            const bookmarkFunctions = {
                tutorials: () => bookMarkTutorial({ token: globalToken, tutorialId: id, favourite: 'unfav' }),
                educators: () => bookMarkEducator({ token: globalToken, educatorId: id, favourite: 'unfav' }),
                corporates: () => bookMarkCorporate({ token: globalToken, corporateId: id, favourite: 'unfav' }),
                blogs: () => bookMarkBlog({ token: globalToken, articleId: id, favourite: 'unfav' }),
                quotes: () => bookMarkQuotes({ token: globalToken, quoteId: id, favourite: 0 }),
                careernews: () => favouriteNews({ token: globalToken, newsId: id, type, favourite: 0 }),
                lyrics: () => bookMarkLyrics({ token: globalToken, lyricId: id, favourite: 0 }),
                questionbank: () => postFavouriteQuestion(id, 0, globalToken, moduleNames?.qb),
                interviewquestions: () => postFavouriteQuestion(id, 0, globalToken, moduleNames?.iq),
                fullforms: () => postFavouriteQuestion(id, 0, globalToken, moduleNames?.ff),
                dictionary: () => postFavouriteQuestion(id, 0, globalToken, moduleNames?.dic)
            };
            const resRmFavourite = await (bookmarkFunctions[mType] || (() => favouriteNews({ token: globalToken, newsId: id, type, favourite: 0 })))();
            if (resRmFavourite?.success) {
                setPageNo(1);
                handleFavouritesList(filterType, 1);
            }
            setFavBtnLoading(false);
        };

        return (
            <button
                type="button"
                disabled={favBtnLoading}
                title="Remove from Favourites"
                onClick={removeFromFavourites}
                className="btn bg-white p-0 d-flex btn-like-circular"
                style={{ border: 'none', boxShadow: 'none' }}
            >
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : (
                    <i className="fa fa-times heart active"></i>
                )}
            </button>
        );
    };

    return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>My Favourites</strong>`} subTitle={`A collection of valuable resources, insights, and inspiration.`} />

                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>)
                        : <div className='row mx-0'>
                            <div className='scrollable-container'>
                                <ul className="nav nav-pills mb-3 tab-custom" id="pills-tab" role="tablist" style={{
                                    display: "flex",
                                    minWidth: "max-content",
                                    overflow: "auto",
                                    flexDirection: "row"
                                }}>
                                    {
                                        tabItems?.map((tab, itab) => (
                                            <li className="nav-item" key={itab + 'tab'} role="presentation">
                                                <button disabled={tabLoading} className={`nav-link ${filterType == tab?.value ? 'active' : ''}`} id={`pills-${tab?.value}-tab`}
                                                    // data-bs-toggle="pill" 
                                                    // data-bs-target={`#pills-${tab?.value}`} 
                                                    type="button" role="tab" aria-controls={`pills-${tab?.value}`} aria-selected="true" onClick={() => handleFilterChange(tab?.value)}>
                                                    {tab?.name}
                                                
                                                </button>
                                            </li>
                                        ))
                                    }

                                </ul>
                            </div>
                            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                            <div className="tab-content px-0" id="pills-tabContent">
                                <div className={`tab-pane fade show active`}>
                                    {
                                        (!tabLoading && !allContent?.length) ? <div className="flex-column not-found d-flex text-center" >
                                            <img src={notFound} className="notFoundImg" />
                                            <span style={{ opacity: 0.7 }}>No data found</span>
                                        </div> : tabLoading ? (
                                            <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                                <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[200px]">
                                                    <div className="h-10 w-10">
                                                        <Loadingicon />
                                                    </div>
                                                </div>
                                            </div>) : ( filterType == 'questionbank' ||filterType == 'fullforms' || filterType == 'interviewquestions' || filterType == 'dictionary') ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.qustion?.id + filterType + '-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/tutorials/detail/${content?.question?.slug}-${content?.question?.id}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.question?.question)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.question?.id} mType={filterType} type={filterType} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol>: ( filterType == 'exams') ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.exam?.id + filterType + '-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/tutorials/detail/${content?.exam?.slug}-${content?.exam?.id}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.exam?.title)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.exam?.id} mType={filterType} type={filterType} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol> : filterType == 'tutorials' ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.id + 'educator-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/tutorials/detail/${content?.tutorial?.slug}-${content?.tutorial?.id}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.tutorial?.title)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.tutorial_id} mType={'tutorials'} type={'tutorials'} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol> : filterType == 'educators' ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.id + 'educator-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/educators/university/${content?.educator?.slug}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.educator?.listingName)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.educator_id} mType={'educators'} type={'educators'} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol> : filterType == 'corporates' ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.id + 'corporate-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/corporates/company/${content?.corporate?.slug}-${content?.corporate?.id}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.corporate?.company_name)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.corporate_id} mType={'corporates'} type={'corporates'} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol> : filterType == 'blogs' ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.id + 'blog-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/blogs/detail/${content?.article?.slug}-${content?.article?.id}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.article?.title)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.article_id} mType={'blogs'} type={'blogs'} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol> : filterType == 'careernews' ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.id + 'careernews-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/career-news/news/${content?.type == 'hi' && content?.newshindi ? content?.hindi_news_id : content?.news_id}/${content?.type == 'hi' && content?.newshindi ? content?.newshindi?.slug : content?.news?.slug}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {content?.type == 'hi' && content?.newshindi ? stripHtmlTags(content?.newshindi?.title) : stripHtmlTags(content?.news?.title)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.type == 'hi' ? content?.hindi_news_id : content?.news_id} mType={'careernews'} type={content?.type} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol> : filterType == 'quotes' ? <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}
                                                        key={content?.id + 'quote-fav-list'}
                                                    >
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/quotes/detail/${content?.quote?.id}/${content?.quote?.slug}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.quote?.title)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.quote_id} mType={'quotes'} type={'quotes'} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol> : <ol className="child-content pl-0">{
                                                allContent?.map((content, bIndex) => (
                                                    <li
                                                        className={`item-list cursor-pointer align-items-center d-flex justify-content-between`}

                                                        key={content?.id + 'lyric-fav-list'}
                                                    >
                                                        {/* {console.log('dd', allContent)} */}
                                                        <div className='d-flex align-items-center' onClick={() => navigation(`/lyrics/detail/${content?.lyric?.id}/${content?.lyric?.slug}`)}>
                                                            <div className="number-cy">
                                                                <span>{bIndex + 1}</span>
                                                            </div>
                                                            {stripHtmlTags(content?.lyric?.title)}
                                                        </div>
                                                        <div className='ml-3'>
                                                            <FavouriteButton id={content?.lyric_id} mType={'lyrics'} type={'lyrics'} />
                                                        </div>
                                                    </li>

                                                ))
                                            }</ol>
                                    }
                                </div>

                            </div>

                        </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>

        </div>

        {/* delete alert START  */}
        {/* <Modal
        show={showDeleteModal}
        onHide={handleDeleteModal}
        
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        <strong>Hey!</strong> Do you realy want to delete this tutorial?
        <div className="card mt-4">
            <div className="card-body bg-gray running">
                <h6><strong>Tutorial: <span className="color-gray">{choosenTutorial?.title}</span></strong></h6>
            </div>
        </div>
         
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <button
            type="button"
            disabled={btnLoading}
            className="btn btn-warning custom-theme-button d-flex align-items-center"
            onClick={() => handleDeleteTutorial()}
          >
            {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : (
                "Yes"
            )}
            
          </button>
          <button
            type="button"
            className="btn btn-light d-flex align-items-center ms-2"
            onClick={() => handleDeleteModal()}
          >
            No{" "}
          </button>
        </Modal.Footer>
      </Modal> */}
        {/* delete alert popup END  */}
    </>)
}

export default MyFavourites;