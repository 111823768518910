import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate } from 'react-router-dom';
import TutorialCategoryList from '../common/catsubcat';
import { getCorporateIndustries, getCorporateWiseFilter, getSearchedData } from '../../../../api/corporate';
import CorporateIndustryList from '../common/catsubcat';
import { isArray } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
function CorporateIndustries() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [allFilteredContent, setAllFilteredContent] = useState([]);
    const navigation = useNavigate();
    const [filterType, setFilterType] = useState('industry');
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const searchBox = useRef(null);



    useEffect(() => {
        handleFetchAllCorporateIndustries(1, filterType);
    }, [])

    // fetch all industries type
    const handleFetchAllCorporateIndustries = async (pageNo, fType) => {
        const resAllCorporateWiseFilter = await getCorporateWiseFilter({ pageNo: pageNo, fType });
        if (resAllCorporateWiseFilter?.success) {
            setIsNotFound(false);
            
            const hasNextPage = resAllCorporateWiseFilter?.industries?.next_page_url || 
                                resAllCorporateWiseFilter?.states?.next_page_url || 
                                resAllCorporateWiseFilter?.cities?.next_page_url ||
                                resAllCorporateWiseFilter?.cTypes?.next_page_url ||
                                resAllCorporateWiseFilter?.localities?.next_page_url;
                                
            hasNextPage ? setShowLoadMore(true) : setShowLoadMore(false);
            
            const hasData = (resAllCorporateWiseFilter?.industries?.data?.length || 
                             resAllCorporateWiseFilter?.states?.data?.length ||
                             resAllCorporateWiseFilter?.cities?.data?.length ||
                             resAllCorporateWiseFilter?.cTypes?.data?.length ||
                             resAllCorporateWiseFilter?.localities?.data?.length);
            
            if (hasData && pageNo === 1) {
                setAllContent(resAllCorporateWiseFilter?.industries?.data || 
                              resAllCorporateWiseFilter?.states?.data || 
                              resAllCorporateWiseFilter?.cities?.data || 
                              resAllCorporateWiseFilter?.cTypes?.data || 
                              resAllCorporateWiseFilter?.localities?.data);
                setAllFilteredContent(resAllCorporateWiseFilter?.industries?.data || 
                    resAllCorporateWiseFilter?.states?.data || 
                    resAllCorporateWiseFilter?.cities?.data || 
                    resAllCorporateWiseFilter?.cTypes?.data || 
                    resAllCorporateWiseFilter?.localities?.data);
            } else if (hasData && pageNo !== 1) {
                setAllContent([...allContent, ...(resAllCorporateWiseFilter?.industries?.data || 
                                                resAllCorporateWiseFilter?.states?.data || 
                                                resAllCorporateWiseFilter?.cities?.data || 
                                                resAllCorporateWiseFilter?.cTypes?.data || 
                                                resAllCorporateWiseFilter?.localities?.data)]);
                setAllFilteredContent([...allContent, ...(resAllCorporateWiseFilter?.industries?.data || 
                    resAllCorporateWiseFilter?.states?.data || 
                    resAllCorporateWiseFilter?.cities?.data || 
                    resAllCorporateWiseFilter?.cTypes?.data || 
                    resAllCorporateWiseFilter?.localities?.data)]);
            } else {
                if (pageNo === 1) {
                    setAllContent([]);
                    setAllFilteredContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
   

        setLoading(false);
        setLoadingLoadMore(false);
        setScrollLoading(false);

    }


    //load more category data
    const loadMore = () => {
        setScrollLoading(true);
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllCorporateIndustries(nxtPage, filterType);
        setPageNo(nxtPage);
        searchBox.current.value = '';
        setIsSearch(false)
    }

    const handleSearchIndustry = (e) => {
        const searchTxt = e.target.value;
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false); 
            setFilterType(filterType);          
            handleFetchAllCorporateIndustries(1, filterType);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(true);
            searchIndustry(1, searchTxt);
        }
    }

    const searchIndustry = async (pNo, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const res = await getSearchedData({ pageNo: pNo, keyword });
        console.log(res)
        setIsSearch(true);

        if (res?.success) {
            let filteredResults;
            switch (filterType) {
                case 'industry':
                    filteredResults = res?.data?.industries;
                    break;
                case 'state':
                    filteredResults = res?.data?.states;
                    break;
                case 'city':
                    filteredResults = res?.data?.cities;
                    break;
                case 'locality':
                    filteredResults = res?.data?.localities;
                    break;
                case 'type':
                    filteredResults = res?.data?.types;
                    break;
                default:
                    filteredResults = [];
                    break;
            }
            setTotalRecordFound(filteredResults?.length || 0)
            setIsNotFound(false)
            filteredResults?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (filteredResults?.length && pNo == 1) {
                setAllFilteredContent(filteredResults);
            } else if (filteredResults?.length && pNo !== 1) {
                setAllFilteredContent([...allContent, ...filteredResults]);
            } else {
                if (pageNo == 1) {
                    setAllFilteredContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }

    // filter type data
    const filterTypeData = [
        {
            name:'Industry',
            value:'industry',
        },{
            name:'State',
            value:'state'
        },{
            name:'City',
            value:'city'
        },{
            name:'Locality',
            value:'locality'
        },{
            name:'Company Type',
            value:'type'
        }
    ]


    // handle filter change 
    const handleFilterChange = (e) =>{
        setPageNo(1);
        const fValue = e.target.value;
        setFilterType(fValue);
        handleFetchAllCorporateIndustries(1, fValue);
    }

    return (<>
        
        <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            {/* <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchIndustry} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder={`Search ${filterType}`} ref={searchBox}/>
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchIndustry(1, searchTerm)}></i>
            </div> */}
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-md-flex align-items-center justify-content-between">
                        <SectionDescription title={filterType=="type"?`<strong>Top 10 Different Companies Types Added In ${yearSatra()}</strong>`:`<strong>Top 10 Companies ${filterType} Wise Added In ${yearSatra()}</strong>`} subTitle={`Explore TuteeHUB Corporate Directory as a rich source of business information across every location in the country. In the following listing you will find company profiles, commercial registration information and more on millions of Indian companies, all categorized by Industry.`} />
                        <div className='filter-container ms-4 mt-md-0 mt-4' style={{minWidth:200}}>
                                <select className='form-control mw-100'  onChange={handleFilterChange}  value={filterType}>
                                    {
                                        filterTypeData?.map((fData, i)=><option key={i+'ftype'} value={fData?.value}>{fData?.name}</option>)
                                    }
                                    
                                </select>
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>

                                <InfiniteScroll
                                    dataLength={allFilteredContent?.length}
                                    next={() => loadMore()}
                                    hasMore={showLoadMore}
                                    loader={scrollLoading && (
                                    <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                                        <div className="h-10 w-10">
                                        <Loadingicon />
                                        </div>
                                    </div>
                                    )}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={90}
                                    refreshFunction={() => {
                                    setPageNo(1);
                                    setAllFilteredContent([]);
                                    }}
                                    scrollableTarget={"qBank"}
                                    className={"relative"}
                                >
                                    <div className="py-8 grid pr-2 p-md-4 px-3 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom" style={{ overflow: "auto" }} id="qBank">
                                    {isArray(allFilteredContent) && allFilteredContent.map((content, sIndex) => (<>
                                        <CorporateIndustryList content={content} boxSize='col-md-12 mb-3' key={sIndex} type="content" redirectionFn={() => filterType=='type'?navigation(`/corporates/${filterType}/${content?.name}`):navigation(`/corporates/${filterType}/${content?.slug}`)} />
                                    </>
                                    ))}
                                    </div>
                                </InfiniteScroll>
                        {/* {
                            allFilteredContent?.map((content, sIndex) => (
                                <>
                                {console.log(content)}
                                <CorporateIndustryList content={content} boxSize='col-md-3 mb-3' key={sIndex} type="content" redirectionFn={() => navigation(`/corporates/${filterType}/${content?.slug}`)} />
                                </>
                            ))
                        } */}

                    </div>
                    }
                </div>
                {/* {
                    showLoadMore && !isNotFound ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                } */}

            </div>
        </div>

    </>)
}

export default CorporateIndustries;