import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAuth } from '../../../../Context/AuthContext';
import * as Yup from "yup";
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    placeholderImages,
    CDN_BASE_URL,
    convertDateFormatOnly,
    slugify,
    viewRecentVisits
} from '../../../../utils/utils';

import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "../../../../assets/css/styles/Home.module.css";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import StarRatings from 'react-star-ratings';
import { Formik } from 'formik';
import CorporateComments from '../../../common/comments/corporatesComments';
import { getCourseDetail } from '../../../../api/educator';
import ReactPlayer from 'react-player';
import { LightBox } from 'react-lightbox-pack';

function CourseDetail() {
    const { globalToken, user } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);   

    const [btnLoading, setBtnLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [educatorId, setEducatorId] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [educatorDetail, setEducatorDetail] = useState();
   
    // Fetches courses details when component mounts or params change
    useEffect(() => {
        if (params?.educatorSlug) {
            // const  cId = (params?.educatorSlug).split('-').pop();
            setEducatorId(params?.educatorSlug);
            handleFetchCourseDetail(params?.educatorSlug);
            viewRecentVisits(globalToken);
        }

    }, []);

   
    // Handles fetching educator detail
    const handleFetchCourseDetail = async (eSlug) => {
        const resCourseDetail = await getCourseDetail({ token:globalToken, educatorSlug: eSlug });
        setLoading(false);
        setLoadingLoadMore(false);
        if (resCourseDetail?.success) {
            setIsNotFound(false);
            if (resCourseDetail) {
                setEducatorDetail(resCourseDetail?.course);
            } else {
                setEducatorDetail({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
    }


    // Handles sharing the tutorial
    const InlineShare = ({ eduData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                    <Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubEducators}/university/${eduData?.slug}`,
                            quote: `${eduData?.metaDescription}`,
                            hashing: `#tuteehub`,
                            title: `${eduData?.listingName}`,
                        }}
                        isCustomPos={true}
                    />
                ) : null}
                <span
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

   
    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };



    

   
    const tabItems = [{
            name:'Exams',
            value:'exams'
        },{
            name:'Educators',
            value:'educators'
        }
    ]
    return (
        <>
            {loading ? (
                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                        <Loadingicon />
                    </div>
                </div>
            ) : (
                <>
                    <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/educators' style={{ fontSize: 14, textDecoration: 'none' }}>Educators</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/educators/states/${educatorDetail?.educator?.state?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{educatorDetail?.educator?.state_name}</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/educators/cities/${educatorDetail?.educator?.city?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{educatorDetail?.educator?.city_name}</NavLink>
                            </li>
                            {
                                educatorDetail?.educator?.locality_name? <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/educators/localities/${educatorDetail?.educator?.locality?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{educatorDetail?.educator?.locality_name}</NavLink>
                            </li>:null
                            }
                           
                            <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(params.educatorSlug)}
                            </li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className={`col-md-12`}>
                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                <div className="px-0">
                                    <div className="flex-column d-flex flex-md-row justify-content-between">

                                        <div className="mr-md-5 pr-md-5 flex-1">
                                            <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                                <strong>{educatorDetail?.educator?.listingName}</strong>
                                                <span className="d-flex gap-3 align-items-center">
                                                    
                                                    <InlineShare eduData={educatorDetail?.educator} isShareActive={isShareActive} setIsShareActive={setIsShareActive} />
                                                </span>
                                            </h1>
                                            <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">

                                                <span className="text-black-50 gig-table">
                                                    <small style={{ marginTop: 1, float: 'left' }}>

                                                        <span className='star-container me-1'><span><i className='fa fa-star'></i>{educatorDetail?.reviews?.avgRating || 0}</span> based on <strong>{educatorDetail?.reviews?.rating || 0} Reviews</strong></span>
                                                        </small> 
                                                    <span className="big-dots"></span>
                                                    <small>Establishment Year: {convertDateFormatOnly(educatorDetail?.estbYear)}</small>
                                                    {/* <span className="big-dots"></span>
                                                    <small className={`${educatorDetail?.company_status=='Active'?'text-success':'text-danger'}`}><strong>{educatorDetail?.company_status}</strong></small> */}
                                                </span>
                                              
                                            </div>
                                        <div id="questAns" className="w-100 rounded text-black-60 my-4 leads cn-desc" >
                                            <div dangerouslySetInnerHTML={{
                                                __html: modifyAnchorTags(`${educatorDetail?.educator?.metaDescription}`)
                                            }}></div>
                                        </div>
                                       
                                        <div className="w-100 rounded text-black-60 mt-2 border p-3 cn-desc" style={{background:'none'}}>
                                            
                                            <div dangerouslySetInnerHTML={{
                                                __html: modifyAnchorTags(`${educatorDetail?.educator?.aboutCollege}`)
                                            }}></div>
                                        </div>
                                            
                                        <div className="w-100 rounded text-black-60 mt-2 border p-3 cn-desc" style={{background:'none'}}>
                                          
                                        <ul className="nav nav-pills mb-3 tab-custom" id="pills-tab" role="tablist">
                                                {
                                                    tabItems?.map((tab, itab)=>(
                                                        <li className="nav-item" key={itab+'tab'} role="presentation">
                                                        <button className={`nav-link ${itab==0?'active':''}`} id={`pills-${tab?.value}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${tab?.value}`} type="button" role="tab" aria-controls={`pills-${tab?.value}`} aria-selected="true">
                                                            {tab?.name}
                                                        </button>
                                                        </li>
                                                    ))
                                                }
                                                
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">                                               
                                                <div class="tab-pane fade" id="pills-exams" role="tabpanel" aria-labelledby="pills-exams-tab">
                                                    {
                                                            educatorDetail?.educator?.exams?.length?(<div className='table-responsive'>
                                                                <table className='table table-bordered table-striped'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th><strong>Exam Name</strong></th>
                                                                            <th><strong>Time</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {}
                                                                    <tbody>
                                                                        {
                                                                            educatorDetail?.educator?.exams?.map((exam, iExam)=>(
                                                                                <tr key={iExam+'exam'}>
                                                                            <td><Link to={'/'} className='theme-color-hover'>{exam?.name}</Link></td>
                                                                            <td className='text-capitalize'>{exam?.duration_value} {exam?.duration_unit}</td>
                                                                        </tr>
                                                                            ))
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>):<h4 className='text-center py-5 color-gray'>Exams not available</h4>
                                                        }
                                                </div>
                                                <div class="tab-pane fade" id="pills-educators" role="tabpanel" aria-labelledby="pills-educators-tab">
                                                    {
                                                            educatorDetail?.educator?.exams?.length?(<div className='table-responsive'>
                                                                <table className='table table-bordered table-striped'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th><strong>Exam Name</strong></th>
                                                                            <th><strong>Time</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {}
                                                                    <tbody>
                                                                        {
                                                                            educatorDetail?.educator?.exams?.map((exam, iExam)=>(
                                                                                <tr key={iExam+'exam'}>
                                                                            <td><Link to={'/'} className='theme-color-hover'>{exam?.name}</Link></td>
                                                                            <td className='text-capitalize'>{exam?.duration_value} {exam?.duration_unit}</td>
                                                                        </tr>
                                                                            ))
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>):<h4 className='text-center py-5 color-gray'>Educators not available</h4>
                                                        }
                                                </div>
                                               
                                            </div>
                                           
                                        </div>

                                            
                                           
                                            
                                        </div>

                                    </div>


                                </div>
                            </div>

                         
                       
                        </div>


                    </div>
                </>
            )}

        </>
    );
}

export default CourseDetail;
